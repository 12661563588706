import { useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { LocalDataTrack } from "twilio-video"
import { Creators as RoomActions } from "../redux/RoomRedux"

/**
 * References:
 * https://github.com/twilio/video-quickstart-js/issues/64
 */
export default function useToggleRemoteMicrophone() {
  const dispatch = useDispatch()
  const { room, dataTrack } = useSelector((state) => state.room)
  const stateRoom = useSelector((state) => state.room)

  const sendData = useCallback(
    async (data) => {
      try {
        if (!dataTrack) {
          console.log("tidak ada data track")
          const dataTrack = new LocalDataTrack()
          await room.localParticipant.publishTrack(dataTrack)
          setTimeout(() => {
            dataTrack.send(JSON.stringify(data))
            dispatch(RoomActions.setDataTrack(dataTrack))
          },1000)
        } else {
          console.log("ada track")
          await dataTrack.send(JSON.stringify(data))
        }
      } catch (error) {
        console.log(error)
      }
    },
    [dataTrack, room, dispatch]
  )

  useEffect(() => {
    if (room && dataTrack) {
      return () => {
        room.localParticipant.unpublishTrack(dataTrack)
      }
    }
  }, [room, dataTrack])
  return { sendData }
}
