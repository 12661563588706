import React, { useEffect } from "react"
import { useHistory } from "react-router-dom"
import Loading from "../../components/Loading"
import useQuery from "../../hooks/useQuery"
import useRoomDisconnect from "../../hooks/useRoomDisconnect"

const LoadingPage = () => {
  const history = useHistory()
  const query = useQuery()
  const with_real = query.get("with_real")
  const { disconnect } = useRoomDisconnect()
  const [message, setMessage] = React.useState("Sedang menutup harap menunggu")
  useEffect(() => {
    let intervalDisconnect = null
    setTimeout(() => {
      history.replace("/lobby")
    }, 25000)

    let itteration = 0
    // const with_real = false
    if (with_real === "true") {
      intervalDisconnect = setInterval(() => {
        console.log("percobaan menutup halaman ke-" + itteration)
        if(itteration === 5){
          setMessage("Terjadi gangguan sistem, Mencoba menutup room")
        }
        itteration++
        disconnect()
      }, 1000);
    }

    return () => {
      if(intervalDisconnect){
        console.log("clean up")
        clearInterval(intervalDisconnect)
      }
    }
  }, [])

  return <Loading message={message} where="mengakhiri" />
}

export default LoadingPage
