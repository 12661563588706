import { memo, useCallback, useEffect, useRef, useState } from "react"
import bookmarkIcon from "../../assets/icons/bookmark-sidebar.png"
import tagIcon from "../../assets/icons/skill-tag.png"
import removeIcon from "../../assets/icons/remove-skill.png"
import Autocomplete from "@material-ui/lab/Autocomplete"
import TextField from "@material-ui/core/TextField"
import Paper from "@material-ui/core/Paper"
import { MentionsInput, Mention } from "react-mentions"
import { mentionsToKompetensi } from "../../lib/bookmarkFormatter"
import {
  formatStringTimer,
  formatStringTimestamp
} from "../../lib/counterFormatter"
import Draggable from "react-draggable"

function AddBookmark({
  show,
  onHide,
  listPeserta,
  listKompetensi: skillList,
  roomTimestamp,
  data,
  setAddHighlightData,
  onSubmit,
  refHook
}) {
  const [stringTimestamp, setStringTimestamp] = useState("")
  const [videoClip, setVideoClip] = useState(true)
  const node = useRef(null)

  const [note, setNote] = useState("")
  const [rawString, setRawString] = useState("")

  const [kompetensi, setKompetensi] = useState([])
  const [kompetensiAutocomplete, setKompetensiAutocomplete] = useState("")

  // update string timestamp based on videoClip
  useEffect(() => {
    if (data && roomTimestamp) {
      if (videoClip) {
        const string = formatStringTimestamp({
          startTimestamp: data.duration.startTimestamp,
          endTimestamp: data.duration.endTimestamp,
          initialTimestamp: roomTimestamp
        })
        setStringTimestamp(string)
      } else {
        const string = formatStringTimestamp({
          startTimestamp: data.duration.startTimestamp,
          initialTimestamp: roomTimestamp
        })
        setStringTimestamp(string)
      }
    }
  }, [data, roomTimestamp, videoClip])

  const handleChangeNote = useCallback(
    (e, newValue, newPlainTextValue, mentions) => {
      setNote(newValue)
      setRawString(newPlainTextValue)

      // add mentions to kompetensi
      const mentioned = mentionsToKompetensi(mentions)
      setKompetensi((c) => [...c, ...mentioned.filter((v) => !c.includes(v))])
    },
    []
  )

  const reset = useCallback(() => {
    setStringTimestamp("")
    setVideoClip(true)
    setNote("")
    setRawString("")
    setKompetensi([])
    setKompetensiAutocomplete("")
    setAddHighlightData && setAddHighlightData(null)
    onHide()
  }, [])

  const handleBatal = useCallback((e) => {
    if (window.getSelection) {
      if (window.getSelection().empty) {
        // Chrome
        window.getSelection().empty()
      } else if (window.getSelection().removeAllRanges) {
        // Firefox
        window.getSelection().removeAllRanges()
      }
    } else if (document.selection) {
      // IE?
      document.selection.empty()
    }
    reset()
  })

  const handleOutsideClick = useCallback((e) => {
    if (show) {
      if (
        e.target.className === "MuiAutocomplete-option" ||
        e.target.className === "MuiAutocomplete-listbox"
      ) {
        return
        // DO NOTHING
      } else if (node !== null && !node.current.contains(e.target)) {
        handleBatal()
      }
    }
  })

  useEffect(() => {
    if (show) {
      document.addEventListener("mousedown", handleOutsideClick)
    } else {
      document.removeEventListener("mousedown", handleOutsideClick)
    }
    return () => document.removeEventListener("mousedown", handleOutsideClick)
  }, [show])

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault()

      const { transcript, transcriptIdx, duration, selection, indexInWords } =
        data

      const values = {
        content: {
          rawString: encodeURIComponent(rawString),
          note: encodeURIComponent(note),
          kompetensi
        },
        duration: {
          ...duration,
          stringTimestamp
        },
        videoClip,
        transcript,
        transcriptIdx,
        indexInWords
      }
      onSubmit && onSubmit(values)

      // reset
      reset()
    },
    [
      data,
      kompetensi,
      note,
      onSubmit,
      reset,
      rawString,
      stringTimestamp,
      videoClip
    ]
  )

  return (
    <div ref={node}>
      <Draggable handle=".handle">
        <div
          id="edit-bookmark-modal"
          className={`edit-bookmark-modal ${!show ? "d-none" : ""}`}
        >
          <div className="modal-body">
            <form onSubmit={handleSubmit}>
              <div className="edit-bookmark-header d-flex justify-content-between handle">
                <div className="time-stamp d-flex">
                  <img src={bookmarkIcon} alt="bookmark" />
                  <p>{stringTimestamp}</p>
                </div>

                {/* <label className="switch">
                  <input
                    type="checkbox"
                    checked={videoClip}
                    onChange={(e) => setVideoClip(e.target.checked)}
                  />
                  <span className="slider round"></span>
                </label> */}
              </div>

              <div className="search-skill free-solo-2-demo">
                <Autocomplete
                  value={kompetensiAutocomplete}
                  onChange={(e, value) => {
                    setKompetensi((c) =>
                      c.findIndex((v) => v === value.display) > -1
                        ? c
                        : [...c, value.display]
                    )
                    setKompetensiAutocomplete(value)
                  }}
                  getOptionLabel={(option) =>
                    option !== "" ? option.display : ""
                  }
                  freeSolo
                  disableClearable
                  options={skillList}
                  PaperComponent={({ children }) => (
                    <Paper style={{ fontFamily: "Nunito", fontSize: "13px" }}>
                      {children}
                    </Paper>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      margin="normal"
                      variant="outlined"
                      style={{ backgroundColor: "aliceblue" }}
                      InputProps={{ ...params.InputProps, type: "search" }}
                    />
                  )}
                />
              </div>
              <div className="selected-skill-wrapper" style={{ marginTop: 10 }}>
                {kompetensi.map((v, i) => (
                  <div
                    key={i}
                    className="selected-skill d-flex justify-content-between"
                  >
                    <p>{v.length > 36 ? v.slice(0, 38) + "...." : v}</p>
                    <img
                      src={removeIcon}
                      alt="remove"
                      onClick={() =>
                        setKompetensi((v) => v.filter((_, idx) => idx !== i))
                      }
                    />
                  </div>
                ))}
              </div>

              <div style={{ zIndex: 200 }} className="mentions-input">
                <MentionsInput
                  style={{ minHeight: "93px" }}
                  value={note}
                  onChange={handleChangeNote}
                  className="mention-list"
                >
                  <Mention
                    trigger="@"
                    markup="@[__display__](__id__)"
                    displayTransform={(id, display) => `@${display}`}
                    data={listPeserta}
                    style={{ height: "100%", minHeight: "93px" }}
                  />
                  <Mention
                    trigger="#"
                    markup="#[__display__](__id__)"
                    displayTransform={(id, display) => `#${display}`}
                    data={skillList}
                    style={{ height: "100%", minHeight: "93px" }}
                  />
                </MentionsInput>
              </div>
              <br />
              {/* <textarea
            name='skill description'
            cols='24.5'
            rows='4'
            value={rawString}
            onChange={e => setRawString(e.target.value)}
          ></textarea> */}

              <div className="action-btn d-flex justify-content-between">
                <button
                  type="button"
                  className="btn cancel-btn"
                  onClick={handleBatal}
                >
                  Batalkan
                </button>
                <button
                  className={`btn primary-btn`}
                  // className={`btn primary-btn ${
                  //   kompetensi.length === 0 ? 'btn-disabled' : ''
                  // }`}
                  // disabled={kompetensi.length === 0}
                  onClick={onHide}
                  type="submit"
                >
                  Simpan
                </button>
              </div>
            </form>
          </div>
        </div>
      </Draggable>
    </div>
  )
}

export default AddBookmark
