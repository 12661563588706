import React from 'react'

function BookmarkTranscript () {
  return (
        <div>
            <h1>This is Bookmark Transcript</h1>
        </div>
  )
}

export default BookmarkTranscript
