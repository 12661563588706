import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import firebase from "firebase/app"
import { Creators as SnackActions } from "../redux/SnackbarRedux"
import short from "../assets/sound/Short.mp3"

import { Creators as RoomActions } from "../redux/RoomRedux"
import { useLocation } from "react-router"
import { createLocalTracks } from "twilio-video"
import useRoomService from "./roomService"
import { variableSession } from "../constants/otherConstants"
/**
 * create or connect to a room
 *
 * @param {object} options connection options
 *
 * References:
 * https://www.twilio.com/docs/video/javascript-getting-started#connect-to-a-room
 * https://sdk.twilio.com/js/video/releases/2.14.0/docs/module-twilio-video.html#.connect__anchor
 * https://sdk.twilio.com/js/video/releases/2.8.0/docs/global.html#ConnectOptions
 * https://www.twilio.com/docs/video/javascript-getting-started#specify-constraints
 */
export default function useRoomConnect({ options, setPermissionError }) {
  const dispatch = useDispatch()
  const location = useLocation()
  const camName = new URLSearchParams(location.search).get("camera")
  const audioName = new URLSearchParams(location.search).get("audio")
  const config = useSelector((state) => state.config)

  const { updateLogPeserta, updateRoomLog } = useRoomService()

  const { room, isConnecting, isError, error, errorMessage } = useSelector(
    (state) => state.room
  )

  const playAudio = (playedAudio) => {
    const audio = new Audio(playedAudio)
    audio.play()
  }

  const myState = useSelector((state) => state.room)
  // room connection
  useEffect(() => {
    const createLocalTrackAndConnect = (constraint) => {
      createLocalTracks(constraint)
        .then((localTracks) => {
          // const cameraTrack = localTracks.find(track => track.kind === 'video')
          const newOptions = { ...options }
          delete newOptions.audio
          delete newOptions.video
          const connectRoom = (data) =>
            dispatch(RoomActions.connectRoomRequest(data))
          const { twilio_join_token: token, roomName } = config.configData
          connectRoom({
            token,
            options: { name: roomName, ...newOptions, tracks: localTracks }
          })
        })
        .catch((e) => {
          // UPDATE LOG PESERTA
          updateRoomLog({ succes: false, message: e.message })
          updateLogPeserta({ succes: false, message: e.message })
          dispatch(
            SnackActions.newSnack(
              `Tidak bisa bergabung ke dalam ruangan. ${e.message} ➔ Mohon untuk mereload halaman`
            )
          )

          if (typeof setPermissionError === "function") {
            setPermissionError(e.message)
          }
        })
    }
    if (config && config.configData) {
      let audioConstraint = true
      let videoConstraint = true
      navigator.mediaDevices
        .enumerateDevices()
        .then((mediaDevices) => {
          mediaDevices.forEach((device) => {
            if (device.label === camName) {
              videoConstraint = { deviceId: device.deviceId }
            }
            if (device.label === audioName)
              audioConstraint = { deviceId: device.deviceId }
          })
          createLocalTrackAndConnect({
            audio: audioConstraint,
            video: videoConstraint
          })
        })
        .catch((error) => console.log(`Error: ${error.name}: ${error.message}`))
    }
  }, [config, dispatch, options])

  // log connect success
  useEffect(async () => {
    if (room) {
      playAudio(short)
      console.log(`Successfully joined a Room: ${room}`)

      if (!config || (config && !config.configData)) return
      let { timer_reference, label, type, accessor, data_peserta, batchId } =
        config.configData

      if (label === undefined) label = type

      const database = firebase.database()
      const currentTimestamp = await database
        .ref(`${timer_reference}/${room.sid}/timestamp`)
        .once("value")
        .then((snapshot) => snapshot.val())

      console.log(`Current timestamp: ${currentTimestamp}`)
      if (!currentTimestamp)
        await database
          .ref(`${timer_reference}/${room.sid}`)
          .update({ timestamp: Date.now() })
      const currentLabel = await database
        .ref(`${timer_reference}/${room.sid}/label`)
        .once("value")
        .then((snapshot) => snapshot.val())

      if (accessor) {
        if (
          currentLabel &&
          (currentLabel === "fgd" || currentLabel === "interview")
        ) {
          if (label !== "fgd" || label !== "interview") {
            await database
              .ref(`${timer_reference}/${room.sid}/label`)
              .set(label)
          }
        } else if (!currentLabel) {
          await database.ref(`${timer_reference}/${room.sid}/label`).set(label)
        }
      } else {
        // UPDATE LOG PESERTA
        updateLogPeserta({
          succes: true,
          message: `Menjalani Interaksi`
        })
      }

      updateRoomLog({ succes: true, message: "Bergabung ke dalam ruangan" })
    }
  }, [room])

  // log error
  useEffect(() => {
    if (isError) {
      console.error(`Unable to connect to Room: ${error.message}`)
      updateRoomLog({ succes: false, message: error.message })
      updateLogPeserta({ succes: false, message: error.message })

      const tokenExpMsg = "Access Token expired or expiration date invalid"
      let errorMsg = ``
      if (error.message === tokenExpMsg) {
        errorMsg = "Mohon menunggu, otomatis reload frame."
        setTimeout(() => {
          localStorage.setItem(variableSession.specialReload, true)
          document.location.href = document.location.href
        },2000)
      } else {
        errorMsg = "Mohon untuk mereload halaman"
      }

      dispatch(
        SnackActions.newSnack(
          `Tidak bisa bergabung ke dalam ruangan. ${error.message} ➔ ${errorMsg}`
        )
      )
    }
  }, [errorMessage, isError])

  return { room, isConnecting, isError, error, errorMessage }
}
