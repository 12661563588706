import { all, call, put, takeLatest } from "@redux-saga/core/effects"
import {
  Types as ConfigTypes,
  Creators as ConfigActions
} from "../redux/ClientLoadingRedux"

function* getClientLoading(action) {
  put(ConfigActions.setClientLoading(action.data))
}

export function* setClientLoadingSaga() {
  yield takeLatest(ConfigTypes.SET_CLIENT_LOADING, getClientLoading)
}

export function* clientSaga() {
  yield all([call(setClientLoadingSaga)])
}
