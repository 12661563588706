import { KeyboardArrowDown } from '@material-ui/icons'
import React from 'react'
import talentlyticaLogo from './../assets/images/logo.png'

function LobbyHeader () {
  return (
    <div className='d-flex justify-content-between align-items-center lobby-header'>
      <img src={talentlyticaLogo} alt='talentlytica' />
      <div className='user-profile d-flex'>
        <div className='user-photo'>S</div>
        <div className='user-name'>User01</div>
        <div className='arrow'>
          <KeyboardArrowDown />
        </div>
      </div>
    </div>
  )
}

export default LobbyHeader
