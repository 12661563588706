import React from 'react'
import Snackbar from "@material-ui/core/Snackbar"

function ErrorPage2({message}) {
  const wrapper = {
    backgroundColor: "black",
    position: "absolute",
    zIndex: 10,
    width: "100vw",
    height: "100vh",
    right: 0,
    left: 0,
    top: 0,
    bottom: 0,
  }
  return (
    <div style={wrapper}>
      <Snackbar
          className='toast-loading-error-2'
          open={true}
          autoHideDuration={1000000}
          // onClose={() => setIsShowToast(false)}
          message={message}
        />
    </div>
  )
}

export default ErrorPage2
