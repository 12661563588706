import React from 'react'
import SessionEndsWrapper from '../../components/sessionEnds/SessionEndsWrapper'

function SessionEnds () {
  return (
    <div>
      <SessionEndsWrapper />
    </div>
  )
}

export default SessionEnds
