import { useState, useRef, useEffect } from "react"
import ReactPlayer from "react-player"
import ReactTooltip from "react-tooltip"
import BootstrapSwitchButton from "bootstrap-switch-button-react"
import playIcon from "../../assets/icons/play.png"
import tagICon from "../../assets/icons/tag-white.png"
import bookmarkIcon from "../../assets/icons/bookmark-white.png"
import personIcon from "../../assets/icons/assessor-white.png"
import dictionaryIcon from "../../assets/icons/dictionary.png"
import searchIcon from "../../assets/icons/search-term.png"
import forwardIcon from "../../assets/icons/forward.png"
import rewindIcon from "../../assets/icons/rewind.png"
import smallPlayButton from "../../assets/icons/play-blue.png"
import smallPauseButton from "../../assets/icons/pause.png"
import { Fragment } from "react"

function HighlightVideo({
  onClickFind,
  onToogleFocus,
  focusMode,
  onConfidenceFocus,
  confidenceMode,
  onClickDictionary,
  onTimeUpdate,
  handleProgress,
  url,
  setDuration,
  seekedTo,
  note,
  assessorName,
  timestamp,
  progress,
  skills,
  isPlaying,
  setIsPlaying,
  onForward,
  onRewind
}) {
  const playerRef = useRef()
  let note2 = ``

  try {
    note2 = decodeURIComponent(note)
  } catch (e) {
    note2 = note
  }

  useEffect(() => {
    seekedTo > -1 && playerRef.current.seekTo(seekedTo, "fraction")
  }, [seekedTo])

  return (
    <div>
      <ReactTooltip />
      <div className="video-player">
        <div className="video-mask" onClick={() => setIsPlaying(!isPlaying)}>
          <ReactPlayer
            url={url}
            playing={isPlaying}
            onPause={() => setIsPlaying(false)}
            onPlay={() => setIsPlaying(true)}
            onProgress={handleProgress}
            onDuration={(duration) => setDuration(duration)}
            ref={playerRef}
            config={{
              file: {
                attributes: {
                  onTimeUpdate: (a) => {
                    onTimeUpdate(a.target.currentTime)
                  }
                }
              }
            }}
          />
        </div>
        <button
          className={`play-btn ${isPlaying ? "hide" : ""}`}
          onClick={() => setIsPlaying(true)}
        >
          <img src={playIcon} alt="play" />
        </button>
        <div className="video-footer d-flex">
          <div className={`bookmark d-flex ${!timestamp ? "hidden" : ""}`}>
            <>
              <img src={bookmarkIcon} alt="bookmark" />
              <p>{timestamp}</p>
            </>
          </div>
          <div className="competency-tag d-flex">
            {skills && (
              <>
                <img src={tagICon} alt="tag" />
                {skills.map((skill, key) => (
                  <Fragment key={key}>
                    <p>{skill}&nbsp;</p>
                    {key < skills.length - 1 && <p>;&nbsp;</p>}
                  </Fragment>
                ))}
              </>
            )}
          </div>
          <div
            className={`assessor-info d-flex ${!assessorName ? "hidden" : ""} `}
          >
            <>
              <img src={personIcon} alt="person" />
              <p>{assessorName}</p>
            </>
          </div>
        </div>
        <div className={`video-tools d-flex`}>
          <img
            className="play-control"
            src={isPlaying ? smallPauseButton : smallPlayButton}
            data-tip="Play"
            alt="Play"
            onClick={() => setIsPlaying(!isPlaying)}
          />
          <img
            src={rewindIcon}
            data-tip="Rewind"
            alt="Rewind"
            onClick={onRewind}
          />
          <img
            src={forwardIcon}
            data-tip="Forward"
            alt="Forward"
            onClick={onForward}
          />
          <img
            src={searchIcon}
            data-tip="Find And Replace"
            alt="Find And Replace"
            onClick={onClickFind}
          />
          <img
            src={dictionaryIcon}
            data-tip="Dictionary"
            alt="dictionary"
            onClick={onClickDictionary}
          />

          <div data-tip="Focus Mode">
            <BootstrapSwitchButton
              checked={focusMode}
              onChange={(change) => onToogleFocus(change)}
              size="xs"
            />
          </div>

          <div data-tip="Confidence Level Mode">
            <BootstrapSwitchButton
              checked={confidenceMode}
              onChange={(change) => onConfidenceFocus(change)}
              size="xs"
            />
          </div>
        </div>
        <div className={`video-notes`}>
          {(note2 !== "undefined" && note2) || "-"}
        </div>
      </div>
    </div>
  )
}

export default HighlightVideo
