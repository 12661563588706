import React, { useEffect, useRef } from "react"
import closeIcon from '../../assets/icons/close.png'
import senderChatIcon from '../../assets/icons/sent.png'
import receiverChatIcon from '../../assets/icons/received.png'
import Draggable, { DraggableCore } from 'react-draggable'

function Chatroom ({ show, onHide }) {

  const nodeTextarea = useRef(null)
  const node = useRef(null)
  const handleChatOutsideClick = (e) => {
    if (!show) return
    const elm = e.target
    const elmClass = elm.className
    const test = node.current.contains(elm)
    if (!test) {
      if (/chat/i.test(elmClass)) return
      onHide()
    }
  }

  useEffect(() => {
    if (show) {
      document.addEventListener("mousedown", handleChatOutsideClick)
      nodeTextarea && nodeTextarea.current && nodeTextarea.current.focus()
    } else {
      document.removeEventListener("mousedown", handleChatOutsideClick)
    }
    return () => document.removeEventListener("mousedown", handleChatOutsideClick)
  }, [show])

  return (
        <div ref={node}>
            <Draggable handle=".handle">
                <div className={`chatroom-modal ${!show ? 'd-none' : ''}`}>
                    <div className='modal-header handle'>
                        <p>Chatroom</p>
                        <img src={closeIcon} alt="close" onClick={onHide} />
                    </div>
                    <div className='modal-body chatroom-modal-body'>
                        <div className="received-chat d-flex">
                            <div className="received-dialog">
                                <div>
                                    <p className="received-name">
                                        Asesor 02
                                    </p>
                                    <p className='received-text'>
                                        Apakah suara saya bisa terdengar dengan baik?
                                    </p>
                                    <div className="d-flex justify-content-end received-time">12.20</div>
                                </div>
                            </div>
                        </div>
                        <div className="sent-chat d-flex justify-content-end">
                            <div></div>
                            <div className="d-flex" >
                                <div className="sent-dialog">
                                    <p className="sent-text" >Iya pak, terdengar dengan jelas</p>
                                    <div className="sent-time">
                                        <p>12.22</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <textarea
                          ref={nodeTextarea}
                          name="typing-box"
                          cols="27"
                          rows="3"
                          defaultValue=""
                          placeholder="Silakan tulis pesan..."
                        />
                    </div>
                </div>
            </Draggable>
        </div>
  )
}

export default Chatroom
