import Snackbar from "@material-ui/core/Snackbar"
import React, { useState } from "react"
import CountUp from "react-countup"
import { variableSession } from "../constants/otherConstants"

let waitTimeLoading
const Loading = ({ message = "Sedang menyiapkan, harap menunggu...", where }) => {
  const [isShowToast, setIsShowToast] = useState(false)
  let timeNow = (JSON.parse(localStorage.getItem(variableSession.loadingSession)) || {}).counter || 0
  window.clearTimeout(waitTimeLoading)
  window.addEventListener("pagehide", () => {
    localStorage.removeItem(variableSession.loadingSession)
  })
  if (/mengakhiri/i.test(where)) {
    localStorage.removeItem(variableSession.loadingSession)
  }

  return (
    <div>
      <div className="loading-wrapper">
        <div className="loading-spinner">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <Snackbar
          className={message === "Terjadi gangguan sistem, Mencoba menutup room" ? "toast-loading-error" : "toast-loading"}
          open={true}
          autoHideDuration={1000000}
          onClose={() => setIsShowToast(false)}
          message={message}
        />

        <>
          {/mengakhiri/i.test(where)
            ? ""
            : <h5 className="text-white text-center">
                <CountUp
                  start={timeNow || 0}
                  duration={1200}
                  end={60000}
                  startOnMount={true}
                  formattingFn={(number) => {
                    let display
                    timeNow = (JSON.parse(localStorage.getItem(variableSession.loadingSession)) || {}).counter || 0
                    localStorage.setItem(variableSession.loadingSession, JSON.stringify({counter: number}))
                    
                    display = (timeNow / 100).toFixed(2)
                    // display = number

                    window.clearTimeout(waitTimeLoading)
                    waitTimeLoading = window.setTimeout(() => {
                      if (display !== "0.00") {
                        console.log(`${where.toUpperCase()} dimuat dalam ${display} s`)
                        localStorage.removeItem(variableSession.loadingSession)
                      }
                    }, 100)

                    return display
                  }}
                /> s
              </h5>
          }
        </>

      </div>
    </div>
  )
}

export default Loading
