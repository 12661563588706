import React from 'react'

import iconDefaultParticipant from "../../assets/icons/mobile/icon-default-participant.svg"
import iconMuteParticipantList from "../../assets/icons/mobile/icon-mute-participant-list.svg"
import iconClose from "../../assets/icons/mobile/icon-close.svg"
import useTrack from '../../hooks/useTrack'
import useTrackAudio from '../../hooks/useTrackAudio'
import useTrackData from '../../hooks/useTrackData'
import MobileAvatar from '../../components/MobileRoom/general/MobileAvatar'

function MobileListParticipants2({ 
  setShow,
  listParticipant
}) {
  
  return (
    <div className='mobile-container-modal'>
      <div className='mobile-list-participant-close' onClick={() => setShow(false)}>
        <img src={ iconClose } />
      </div>
      <div className='mobile-list-participants'>
        <div className='mobile-list-participans-title mobile-modal-title'>
          Dalam Panggilan
        </div>
        <div className='mobile-list-participans-items'>
          {/* <div className='mobile-list-participans-item'>
            <div className='mobile-list-participant-name'>
              <img src={ iconDefaultParticipant } className='mobile-list-participant-items'/>
              <div>Siti Azizah</div>
            </div>
            <div>
              <img src={ iconMuteParticipantList } />
            </div>
          </div> */}
          {/* { chunks &&
            chunks.length > 0 &&
            numberofchunks &&
            length &&
            chunks.map( (chunk, index) =>{
              const list = chunk.map((data, idx) => {
                const identity = JSON.parse(data.identity)
                // console.log(identity)
                return <div className='mobile-list-participans-item' key={identity+index}>
                  <div className='mobile-list-participant-name'>
                    <img src={ iconDefaultParticipant } className='mobile-list-participant-items'/>
                    <div>{ identity.name }</div>
                  </div>
                  { checkAudio(data, index, idx) && <div>
                      <img src={ iconMuteParticipantList } />
                    </div>
                  }
                </div>
              
              
            })
            return list
          })} */}

          {
            listParticipant.map((data, index) => {
              return <div className='mobile-list-participans-item' key={index}>
                  <div className='mobile-list-participant-name'>
                    {/* <img src={ iconDefaultParticipant } className='mobile-list-participant-items'/> */}
                    <MobileAvatar
                      char={data.name.charAt(0)}
                      style={{
                        width: "24px",
                        height: "24px",
                        fontSize: "18px",
                        textShadow: "0 1px 1px #000",
                        fontWeight: "700",
                        defaultColor: data.defaultColor,
                        color: "#EDF2F7",
                        marginRight: "8px"
                      }}
                    />
                    <div>{ data.name }</div>
                  </div>
                  { !data.audioOn && <div>
                      <img src={ iconMuteParticipantList } />
                    </div>
                  }
                </div>
            })
          }
        </div>
      </div>
    </div>
  )
}

export default MobileListParticipants2
