import { useEffect, useState } from "react"
import { formatStringTimer } from "../lib/counterFormatter"

export default function useTimeCounter ({ timestamp }) {
  const [stringTimeCounter, setStringTimeCounter] = useState('')

  useEffect(() => {
    if (timestamp) {
      // console.log('timestamp', timestamp)
      const tick = () => {
        const current = Date.now() - timestamp
        const formatted = formatStringTimer(current / 1000)
        setStringTimeCounter(formatted)
      }

      const interval = setInterval(() => tick(), 1000)

      return () => clearInterval(interval)
    }
  }, [timestamp])

  return { stringTimeCounter }
}
