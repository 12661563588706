import PropTypes from 'prop-types'
import { MenuItem, Select } from '@material-ui/core'
import { KeyboardArrowDown } from '@material-ui/icons'
import { useState } from 'react'
import speakerIcon from '../../assets/icons/tes-speaker.png'
import closeIcon from '../../assets/icons/close.png'
import audioTest from '../../assets/sound/audio-test.mp3'

function MicMoreMenus ({
  isMicrophoneOn,
  show,
  microphone,
  microphoneOptions,
  onMicrophoneChange,
  speaker,
  speakerOptions,
  onSpeakerChange,
  onHide
}) {
  const [openDropdownMic, setOpenDropdownMic] = useState(false)
  const [openDropdownSpeaker, setOpenDropdownSpeaker] = useState(false)
  const [isPlayingAudio, setIsPlayingAudio] = useState(false)

  const playAudio = () => {
    if (!isPlayingAudio) {
      setIsPlayingAudio(true)
      const audio = new Audio(audioTest)
      audio.play()
      audio.onended = () => setIsPlayingAudio(false)
    }
  }

  return (
    <div>
      <div
        className={`more-menu-modal popup-menu-modal ${!show ? 'd-none' : ''}`}
      >
        <div className='more-menu-modal-body modal-body'>
          <div>
            <div className='menu-item-device'>
              <div className='pop-up-menu-title d-flex justify-content-between'>
                <p>Mikrofon</p>
                <img src={closeIcon} alt='close' onClick={onHide} />
              </div>
              <div className='device-menu-item'>
                <div className='select-menu'>
                  <Select
                    labelId='demo-controlled-open-select-label'
                    id='select-menu'
                    open={openDropdownMic}
                    onClose={() => setOpenDropdownMic(false)}
                    onOpen={() => setOpenDropdownMic(true)}
                    value={microphone}
                    onChange={onMicrophoneChange}
                    IconComponent={KeyboardArrowDown}
                    disableUnderline
                    displayEmpty
                  >
                    {(!microphoneOptions || microphoneOptions.length === 0) && (
                      <MenuItem value='' disabled>
                        Device tidak ditemukan
                      </MenuItem>
                    )}
                    {microphoneOptions?.map(({ label, value }, i) => (
                      <MenuItem key={i} value={value}>
                        {label}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <p className='device-status'>
                  Status: {isMicrophoneOn ? 'aktif' : 'nonaktif'}
                </p>
              </div>
            </div>
            <div className='menu-item-device'>
              <p className='pop-up-menu-title'>{'Speaker'}</p>
              <div className='device-menu-item last'>
                <div className='select-menu'>
                  <Select
                    labelId='demo-controlled-open-select-label'
                    id='select-menu'
                    open={openDropdownSpeaker}
                    onClose={() => setOpenDropdownSpeaker(false)}
                    onOpen={() => setOpenDropdownSpeaker(true)}
                    value={speaker}
                    onChange={onSpeakerChange}
                    IconComponent={KeyboardArrowDown}
                    disableUnderline
                    displayEmpty
                  >
                    {(!speakerOptions || speakerOptions.length === 0) && (
                      <MenuItem value={''} disabled>
                        Device tidak ditemukan
                      </MenuItem>
                    )}
                    {speakerOptions?.map(({ label, value }, i) => (
                      <MenuItem key={i} value={value}>
                        {label}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div
                  className={`d-flex speaker-test ${
                    isPlayingAudio ? 'playing' : ''
                  }`}
                  onClick={playAudio}
                >
                  <img src={speakerIcon} alt='speaker' />
                  <p>Tes Speaker</p>
                </div>
              </div>
            </div>
          </div>
          <button className='btn close-menu' onClick={onHide}>
            Tutup
          </button>
        </div>
      </div>
    </div>
  )
}

MicMoreMenus.propTypes = {
  microphone: PropTypes.string,
  microphoneOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })
  ),
  onMicrophoneChange: PropTypes.func,
  speaker: PropTypes.string,
  speakerOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })
  ),
  onSpeakerChange: PropTypes.func
}

export default MicMoreMenus
