/**
 * get timestamp relative to initTimestamp
 * @param {number} initTimestamp unixTimestamp
 * @param {number} seconds
 * @returns unixTimestamp (ms)
 */
export const getTimestamp = ({ initTimestamp, seconds, nanos }) => {
  return initTimestamp + (seconds || 0) * 1000 + (nanos || 0) / 1000000
}
/**
 * get duration start & end timestamp relative to initTimestamp
 * @param {number} initTimestamp unixTimestamp
 * @param {number} duration transcript duration eg. {start: '4', end: '12'}
 * @returns {object} { ...duration, startTimestamp, endTimestamp }
 */
export const getDurationTimestamp = ({ initTimestamp, duration }) => ({
  ...duration,
  startTimestamp: getTimestamp({
    initTimestamp,
    seconds: Number(duration?.start)
  }),
  endTimestamp: getTimestamp({
    initTimestamp,
    seconds: Number(duration?.end)
  })
})

/**
 * get word timestamp relative to initTimestamp
 * @param {number} initTimestamp unixTimestamp
 * @param {object} word a word in transcript eg.
 * {
 *    "endTime": {
 *        "nanos": 300000000,
 *        "seconds": 6
 *    },
 *    "startTime": {
 *        "nanos": 700000000,
 *        "seconds": 4
 *    },
 *    "word": "Oke"
 * }
 * @returns {object} { ...word, startTimestamp, endTimestamp }
 */
export const getWordTimestamp = ({ initTimestamp, word }) => ({
  ...word,
  startTimestamp: getTimestamp({
    initTimestamp,
    seconds: Number(word?.startTime?.seconds),
    nanos: Number(word?.startTime?.nanos)
  }),
  endTimestamp: getTimestamp({
    initTimestamp,
    seconds: Number(word?.endTime?.seconds),
    nanos: Number(word?.endTime?.nanos)
  })
})

/**
 * prepare start & end timestamp for transcript & words
 * @param {object[]} transcriptData raw transcriptData
 * @param {number} initTimestamp roomTimestamp
 */
export const prepareTranscriptData = ({ transcriptData, initTimestamp }) => {
  let result = []

  if (transcriptData?.length > 0) {
    result = transcriptData.map((t) => {
      return {
        ...t,
        duration: getDurationTimestamp({
          initTimestamp,
          duration: { ...t.duration }
        }),
        words: t.words.map((word) =>
          getWordTimestamp({
            initTimestamp,
            word
          })
        )
      }
    })
  }

  return result
}

/**
 * update word using indexTranscript & indexWord
 * @param {object[]} transcriptDataOri from database
 * @param {number} indexTranscript
 * @param {number} indexWord
 * @param {string} newWord
 * @param {boolean} removeWordIfEmpty default false
 * @return transcript at the indexTranscript with updated words
 */
export const prepareUpdateTranscript = (
  { transcriptDataOri, indexTranscript, indexWord, newWord },
  removeWordIfEmpty = false
) => {
  const currentTranscript = { ...transcriptDataOri[indexTranscript] }
  let newWords = []

  if (removeWordIfEmpty && newWord === "") {
    // console.log('removeWord', removeWordIfEmpty)
    newWords = currentTranscript.words.filter((v, i) => i !== indexWord)
  } else {
    // update word with newWord
    newWords = currentTranscript.words.map((v, i) =>
      i === indexWord ? { ...v, word: newWord } : v
    )
  }

  const updatedTranscript = {
    ...currentTranscript,
    transcript: newWords.map(({ word }) => word).join(" "),
    words: [...newWords]
  }

  return updatedTranscript
}
