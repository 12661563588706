import React, { useEffect, useRef } from "react"
import PropTypes from 'prop-types'
import Draggable from 'react-draggable'
import closeIcon from '../../assets/icons/close.png'

function FGDPopup ({ show, onHide, children, label }) {
  const titleSpace = (string) => {
    let result = string
    if (/^panelpresentation/i.test(string)) result = "PANEL PRESENTATION"
    if (/^individualroleplay/i.test(string)) result = "INDIVIDUAL ROLE PLAY"
    if (/^roleplay/i.test(string)) result = "ROLE PLAY"
    if (/^panelinterview/i.test(string)) result = "PANEL INTERVIEW"
    return result
  }
  const labelRoom = titleSpace(label).toUpperCase()

  const node = useRef(null)
  const handleMateriOutsideClick = (e) => {
    const elm = e.target
    const elmClass = elm.className
    const test = node.current.contains(elm)
    if (!test) {
      if (/note/i.test(elmClass)) return
      // onHide()
    }
  }

  useEffect(() => {
    if (show) {
      document.addEventListener("mousedown", handleMateriOutsideClick)
    } else {
      document.removeEventListener("mousedown", handleMateriOutsideClick)
    }
    return () => document.removeEventListener("mousedown", handleMateriOutsideClick)
  }, [show])

  return (
    <div ref={node}>
      <Draggable handle=".handle">
        <div className={`fgd-modal ${!show ? 'd-none' : ''}`}>
          <div className='modal-header handle'>
            <p>Materi {labelRoom}</p>
            <img src={closeIcon} alt='close' onClick={onHide} />
          </div>
          <div className='modal-body'>{children}</div>
        </div>
      </Draggable>
    </div>
  )
}

FGDPopup.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func
}

export default FGDPopup
