import PropTypes from "prop-types"
import { useCallback, useEffect, useRef, useState, memo } from "react"
import { variableSession } from "../../constants/otherConstants"
import DynamicInputText from "../DynamicInputText"

function Word({
  // transcript word db
  word,
  // additional props of word
  indexTranscript,
  indexWord,
  highlighted,
  searchIdx,
  // other
  tempHighlighted,
  resultIndex,
  onClickWord,
  onWordUpdated,
  isEditing,
  setIsEditing,
  isBookmarked,
  addHighlightData,
  isPlayed,
  confidenceMode,
  color,
  confidence
}) {
  /**
   * Double click edit word
   */
  const renderCounter = useRef(0)
  const wordRef = useRef(undefined)
  renderCounter.current = renderCounter.current + 1
  if (word === "diklik") {
    // console.log(renderCounter)
  }

  // UNTUK AUTO SCROLLING KETIKA VIDEO PLAY
  useEffect(() => {
    if (isPlayed) {
      if (wordRef && wordRef.current && wordRef.current.scrollIntoView) {
        const lastElementPosition = localStorage.getItem(variableSession.elementPosition)
        var elementPosition = wordRef.current.getBoundingClientRect().top

        if (parseInt(lastElementPosition) === parseInt(elementPosition)) {
          return
        } else {
          localStorage.setItem(variableSession.elementPosition, elementPosition)
          wordRef.current.scrollIntoView({
            behavior: "instant",
            block: "center"
          })
        }
      }
    }
  }, [isPlayed])

  const [isEditWord, setIsEditWord] = useState(false)
  const [editedWord, setEditedWord] = useState(word)

  const inputRef = useRef()

  useEffect(() => {
    word && setEditedWord(word)
  }, [word])

  useEffect(() => {
    if (isEditWord) {
      inputRef.current.focus()
    }
  }, [isEditWord])

  const handleSubmitEdit = useCallback(() => {
    setIsEditWord(false)
    setIsEditing(false)
    const wordInfo = {
      indexTranscript,
      indexWord,
      word
    }

    onWordUpdated && onWordUpdated(wordInfo, editedWord)
  }, [
    editedWord,
    indexTranscript,
    indexWord,
    onWordUpdated,
    setIsEditing,
    word
  ])

  const handleCancelEdit = useCallback(() => {
    setEditedWord(word)
    setIsEditWord(false)
    setIsEditing(false)
  }, [setIsEditing, word])

  return isEditing && isEditWord ? (
    <DynamicInputText
      forwardedRef={inputRef}
      value={editedWord}
      onChange={(e) => setEditedWord(e.target.value)}
      onBlur={handleCancelEdit}
      onKeyDown={(e) => {
        if (e.keyCode === 27) {
          // reset word on escape
          inputRef.current.blur()
        } else if (e.keyCode === 13) {
          // submit on enter
          handleSubmitEdit()
          inputRef.current.blur()
        }
      }}
    />
  ) : (
    <span
      ref={wordRef}
      onClick={onClickWord}
      onDoubleClick={() => {
        setIsEditWord(true)
        setIsEditing(true)
      }}
      className={`${
        highlighted?.length > 0 &&
        highlighted.filter((v) => v.startWord).length > 0
          ? "markStart noselect"
          : ""
      } ${
        highlighted?.length > 0
          ? `marked ${isBookmarked ? "isWordBookmarked" : "noselect"}`
          : ""
      } `}
      style={{
        background:
          searchIdx > -1 && searchIdx === resultIndex
            ? "#fff3e3"
            : highlighted?.length > 0 &&
              highlighted.findIndex((v) => v.mark) > -1 // videoClip = false
            ? `rgba(195, 213, 223, ${
                (highlighted.filter((v) => v.mark).length * // TODO
                  40 +
                  20) /
                100
              })`
            : tempHighlighted && addHighlightData
            ? "#156efd"
            : "white",
        fontWeight: isPlayed ? "700" : "initial",
        color:
          confidenceMode
            ? color
            : searchIdx > -1
              ? "#FD9F28"
              : tempHighlighted && addHighlightData
                ? "white"
                : "#58595B"
      }}
      id={`${searchIdx > -1 ? `searched${searchIdx}` : ""}`}
      data-tip={confidenceMode ? `Level Confidence ${Math.round((confidence / 1) * 100)} %` : ""}
    >
      {word}&nbsp;
    </span>
  )
}

Word.propTypes = {
  word: PropTypes.string,
  highlighted: PropTypes.arrayOf(
    PropTypes.shape({
      bookmarkId: PropTypes.any,
      highlightId: PropTypes.any
    })
  )
}

export default memo(Word, (prevState, nextState) => {
  const memorize =
    prevState.isPlayed === nextState.isPlayed &&
    prevState.highlighted === nextState.highlighted &&
    prevState.isEditing === nextState.isEditing &&
    prevState.isBookmarked === nextState.isBookmarked &&
    prevState.searchIdx === nextState.searchIdx &&
    JSON.stringify(prevState.addHighlightData) ===
      JSON.stringify(nextState.addHighlightData) &&
    prevState.word === nextState.word &&
    prevState.confidenceMode === nextState.confidenceMode
  return memorize
})
