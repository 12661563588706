import { Rating } from '@material-ui/lab'
import React, { useEffect, useState } from 'react'
import starIcon from '../../assets/icons/star.png'
import emptyStar from '../../assets/icons/star-empty.png'

function SessionEndsFeedback ({
  type,
  ratingValue,
  handleRating,
  feedbackText,
  onChangeText
}) {
  const [hover, setHover] = useState(3)
  const [isDisabled, setIsDisabled] = useState(true)

  useEffect(() => {
    feedbackText ? setIsDisabled(false) : setIsDisabled(true)
  }, [feedbackText])

  return (
    <div className='session-ends-feedback'>
      <div className='feedback-title'>
        {type === 'assessor' ? (
          <>
            <h1>Anda telah keluar dari video call</h1>
            <p>
              Rekaman Video Call akan tersimpan otomatis dan diproses selama
              beberapa jam untuk menghasilkan transkrip
            </p>
          </>
        ) : (
          <h1>Video Call telah diakhiri</h1>
        )}
      </div>
      <div className='feedback-box'>
        <div className='title'>Berikan penilaian Anda:</div>
        <Rating
          name='hover-feedback'
          value={ratingValue}
          max={5}
          min={0}
          precision={1}
          onChange={(event, newValue) => {
            handleRating(newValue)
          }}
          onChangeActive={(event, newHover) => {
            setHover(newHover)
          }}
          size='small'
          emptyIcon={
            <img
              src={emptyStar}
              alt='empty'
              style={{ height: '40px', marginRight: '8px', marginLeft: '8px' }}
            />
          }
          icon={
            <img
              src={starIcon}
              alt='filled'
              style={{ height: '40px', marginRight: '8px', marginLeft: '8px' }}
            />
          }
        />
        <br />
        <textarea
          name='feedback'
          cols='30'
          rows='10'
          placeholder='Tuliskan komentar dan saran Anda mengenai platform video call kami...'
          onChange={onChangeText}
          value={feedbackText}
        ></textarea>
        <br />
        <button
          disabled={isDisabled}
          className={`btn ${isDisabled ? 'btn-disabled' : 'primary-btn'}`}
        >
          Kirim Penilaian
        </button>
      </div>
    </div>
  )
}

export default SessionEndsFeedback
