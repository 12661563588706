import cursorIcon from "../../assets/icons/play-blue.png"
import greyPersonIcon from "../../assets/icons/speaker-grey.png"
import bluePersonIcon from "../../assets/icons/speaker-blue.png"
import bookmarkBlueIcon from "../../assets/icons/bookmark-blue.png"
import { useState, useEffect } from "react"
import TranscriptionWord from "./TranscriptionWord"

function Transcription({ onClickBookmark, transcriptionWords, dialogues }) {
  const [positions, setPositions] = useState([])
  const [totalHeights, setTotalHeights] = useState(0)
  useEffect(() => {
    const marks = []
    const heights = []
    for (let i = 0; i < document.getElementsByTagName("mark").length; i++) {
      let element
      const mark = document.getElementsByTagName("mark")
      if (mark.length > 0 && i !== 0) {
        element = {
          offsetTop: mark[i].offsetTop,
          offsetHeight: mark[i].offsetHeight,
          top: mark[i].offsetTop - 162 - heights.reduce((a, b) => a + b),
          bookmark: mark[i].offsetTop - 162 - heights.reduce((a, b) => a + b),
          left:
            mark[i - 1].offsetTop + mark[i - 1].offsetHeight > mark[i].offsetTop
              ? "100px"
              : "0px"
        }
        marks.push(element)
        heights.push(mark[i].offsetHeight)
      } else if (mark.length > 0) {
        heights.push(mark[i].offsetHeight)
        element = {
          offsetTop: mark[i].offsetTop,
          offsetHeight: mark[i].offsetHeight,
          left: "0px",
          top: mark[i].offsetTop - 162,
          bookmark: mark[i].offsetTop - 162
        }
        marks.push(element)
      }
    }
    setPositions(marks)
    // setTotalHeights(heights.reduce((a, b) => a + b))
  }, [])

  // const data = dialogues.map(dialogue => {
  //   const alternatives = JSON.parse(dialogue[0].transcription).map(
  //     alternative => alternative.alternatives[0].words
  //   )
  //   return {
  //     name: dialogue[0].name,
  //     content: alternatives.map(alternative =>
  //       alternative.map(word => <TranscriptionWord wordData={word} />)
  //     )
  //   }
  // })

  // console.log(`data`, data)
  const data = [
    {
      name: "Pembicara",
      content: (
        <p>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nulla, quas,
          voluptatum saepe architecto incidunt{" "}
          <mark>
            voluptate assumenda aliquid perferendis voluptates maxime harum
            nostrum error minus?
          </mark>
          Debitis praesentium nihil explicabo reprehenderit. Rem iusto
          voluptatum repudiandae porro, illum ipsum fuga cupiditate modi,
          ducimus fugiat aperiam saepe explicabo fugit veritatis possimus
          laborum. Enim, dolorem!
        </p>
      )
    },
    {
      name: "Asesor01",
      content: (
        <p>
          <mark>Lorem ipsum dolor sit amet, consectetur adipisicing elit</mark>.
          Aliquid aliquam laboriosam dolore? Officia itaque possimus voluptas at
          rem quaerat accusantium temporibus, vitae quibusdam, placeat nihil,
          magnam repellat consectetur est reprehenderit! Quisquam qui corrupti
          ratione repellat suscipit quibusdam unde optio similique vel adipisci
          inventore minus explicabo dolores aperiam laudantium mollitia ullam,
          nam iste facere accusantium aliquid. Delectus laudantium iure iste
          itaque, culpa voluptates autem laborum, praesentium sed voluptas eaque
          molestias quo ex minima.
        </p>
      )
    },
    {
      name: "Pembicara",
      content: (
        <p>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nulla, quas,
          voluptatum saepe architecto incidunt{" "}
          <mark>
            voluptate assumenda aliquid perferendis voluptates maxime harum
            nostrum error minus?
          </mark>
          Debitis praesentium nihil explicabo reprehenderit. Rem iusto
          voluptatum repudiandae porro, illum ipsum fuga cupiditate modi,
          ducimus fugiat aperiam saepe explicabo fugit veritatis possimus
          laborum. Enim, dolorem!
        </p>
      )
    },
    {
      name: "Asesor01",
      content: (
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid
          aliquam laboriosam dolore? Officia itaque possimus voluptas at rem
          quaerat accusantium temporibus, vitae quibusdam, placeat nihil, magnam
          <mark>
            repellat consectetur est reprehenderit! Quisquam qui corrupti
            ratione repellat suscipit quibusdam unde optio similique vel
            adipisci
          </mark>
          inventore minus explicabo dolores aperiam laudantium mollitia ullam,
          nam iste facere accusantium aliquid. Delectus laudantium iure iste
          itaque, culpa voluptates autem laborum,{" "}
          <mark> praesentium sed voluptas eaque molestias quo ex minima.</mark>
        </p>
      )
    },
    {
      name: "Pembicara",
      content: (
        <p>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nulla, quas,
          voluptatum saepe architecto incidunt{" "}
          <mark>
            voluptate assumenda aliquid perferendis voluptates maxime harum
            nostrum error minus?
          </mark>
          Debitis praesentium <mark>nihil explicabo reprehenderit. Rem </mark>
          iusto voluptatum repudiandae porro, illum ipsum fuga cupiditate modi,
          ducimus fugiat aperiam saepe explicabo fugit veritatis possimus
          laborum. Enim, dolorem!
        </p>
      )
    }
  ]

  return (
    <div className="speech-to-text d-flex">
      <div>
        {data.map((item, i) => (
          <div key={i} className="speaker-transcript">
            <div className="transcript-header d-flex">
              <img className="cursor" src={cursorIcon} alt="curson" />
              <img className="person" src={greyPersonIcon} alt="person" />
              <p>{item.name}:</p>
            </div>
            <div className="transcript-content d-flex">{item.content}</div>
          </div>
        ))}
      </div>
      <div className="bookmark d-flex">
        <div>
          {positions.length > 1 &&
            positions.map((position, key) => (
              <div key={key} className="d-flex">
                <div
                  key={key}
                  className="d-flex"
                  style={{
                    position: "relative",
                    left: position?.left,
                    top: position?.top
                  }}
                >
                  <div
                    className="bookmark-line"
                    style={{
                      height: position?.offsetHeight
                    }}
                  >
                    <div className="d-flex">
                      <div className="icon-wrapper" onClick={onClickBookmark}>
                        <img src={bookmarkBlueIcon} alt="bookmark" />
                      </div>
                      <div className="skill-wrapper">
                        <div className="skill-number">
                          <span>1.3.</span>
                        </div>
                        <div className="skill-number">
                          <span>2.3</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
        <div></div>
      </div>
    </div>
  )
}

export default Transcription
