import { Snackbar } from '@material-ui/core'
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import ErrorPage from '../../components/ErrorPage'
import Loading from '../../components/Loading'
import MobileTopBar from '../../components/MobileRoom/MobileTopBar'
import MobileContent2 from '../../components/MobileRoom2/MobileContent2'
import { collaboration } from '../../constants/connectionOptions'
import useRoomService from '../../hooks/roomService'
import useBookmark from '../../hooks/useBookmark'
import useChat from '../../hooks/useChat'
import useConfig from '../../hooks/useConfig'
import useDominantSpeaker from '../../hooks/useDominantSpeaker'
import useFirebase from '../../hooks/useFirebase'
import useLocalAudio from '../../hooks/useLocalAudio'
import useLocalVideo from '../../hooks/useLocalVideo'
import useParticipant from '../../hooks/useParticipant'
import usePopupVideoFrame from '../../hooks/usePopupVideoFrame'
import useQuery from '../../hooks/useQuery'
import useRoomConnect from '../../hooks/useRoomConnect'
import useRoomDisconnect from '../../hooks/useRoomDisconnect'
import useSnackbar from '../../hooks/useSnackbar'
import useTimeCounter from '../../hooks/useTimeCounter'
import useTimestamp from '../../hooks/useTimestamp'
import useToggleCamera from '../../hooks/useToggleCamera'
import useToggleMicrophone from '../../hooks/useToggleMicrophone'
import useToggleRemoteMicrophone from '../../hooks/useToggleRemoteMicrophone'
import useToggleScreenSharing from '../../hooks/useToggleScreenShare'
import useVideoResolution from '../../hooks/useVideoResolution'
import MobileTopBar2 from './MobileTopBar2'
import MobileChat2 from './MobileChat2'
import MobileFooter2 from './MobileFooter2'
import ParticipantMobile from './ParticipantMobile'
import { Creators as ChatActions } from "../../redux/ChatRedux"
import MobileListParticipants2 from './MobileListParticipants2'
import SharedScreenFrame from '../../components/SharedScreenFrame'
import iconExpandTopBlue from "../../assets/icons/mobile/expand-top-blue.svg"
import iconExpandBottomBlue from "../../assets/icons/mobile/expand-bottom-blue.svg"
import iconArrowDown from "../../assets/icons/mobile/arrow_down.svg"
import iconArrowSide from "../../assets/icons/mobile/arrow-side.svg"
import MobileMateriGroup from '../../components/MobileRoom/MobileMateriGroup'
import { getRandomColor } from '../../lib/general'

function MobileRoom2() {
  const dispatch = useDispatch()
  const history = useHistory()
  const query = useQuery()
  const queryString = query.get("query")
  const urlConfig = query.get("url_config")
  const config = useConfig({ query: queryString, urlConfig })
  const [permissionerror, setPermissionError] = useState(false)

  const [image, setImage] = React.useState({})
  const [openAttachment, setOpenAttachment] = React.useState(false)
  const handleOpenAttachment = () => setOpenAttachment(true)
  const handleCloseAttachment = () => setOpenAttachment(false)
  // const handleClose = () => setOpen(false)

  // kebutuhan content mobile
  window.scrollTo(0,1)
  // const elementContent = document.getElementById("mobile-content")
  const contentRef = useRef()

  // const [listPeserta, setListPeserta] = useState(listPesertas)
  const [type, setType] = useState("one-on-one")
  const [orientation, setOrientation] = useState(screen.orientation.type.match(/\w+/)[0])
  const [isFullShareScreen, setIsFullShareScreen] = useState(false)
  const [listParticipant, setListParticipant] = useState([])

  const [ isOverflow, setIsOverFlow ] = useState(true)
  const [ isBottomScroll, setIsBottomScroll ] = useState(false)
  // const [ isTopScroll, setIsTopScroll ] = useState(true)

  // detect orientation layar
  screen.orientation.addEventListener("change", function(e) {
    setOrientation(screen.orientation.type.match(/\w+/)[0])
    // console.log(screen.orientation.type.match(/\w+/)[0])
  })

  

  const overFlow = () => {
    const element = document.getElementById("mobile-content")
    if ( element ) {
      return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
    }else{
      return
    }
  }

  const toBottomOverflow = () => {
    const element = document.getElementById("mobile-content")
    if (orientation === 'landscape') {
      element.scrollLeft = element.scrollWidth
    } else {
      element.scrollTop = element.scrollHeight
    }
  }

  const onScroll = () => {
    if (contentRef.current) {
      const { scrollTop, scrollHeight, clientHeight, scrollLeft, scrollWidth, clientWidth} = contentRef.current;
      // console.log(scrollLeft)
      // console.log(clientWidth)
      // console.log(scrollWidth)
      if (orientation === 'landscape') {
        if ((scrollLeft + clientWidth + 1) >= scrollWidth) {
          setIsBottomScroll(true)
        } else {
          setIsBottomScroll(false)
        }
      } else {
        if ((scrollTop + clientHeight + 1) >= scrollHeight) {
          setIsBottomScroll(true)
        } else {
          setIsBottomScroll(false)
        }
      }
    }
  };

  const [ isMuteSite, setIsMuteSite ] = useState(false)
  const muteSite = () => {
    console.log("click")
    // const elm = document.querySelectorAll("audio")
    if ( !isMuteSite ) {
      // elm.forEach((el) => {
      //   el.muted = true
      // })
      muteElementAudio(true)
      setIsMuteSite(true)
    } else {
      // elm.forEach((el) => {
      //   el.muted = false
      // })
      muteElementAudio(false)
      setIsMuteSite(false)
    }
  }

  const muteElementAudio = (mute) => {
    const elm = document.querySelectorAll("audio")
    elm.forEach((el, index) => {
      console.log(el)
      if ( index !== 0) {
        el.muted = mute
      }
    })
  }

  // useEffect(() => {
  //   if (elementContent.scrollTop === elementContent.scrollHeight) setIsBottomScroll(true)
  // }, [elementContent.scrollTop])

  // useEffect(() => {
  //   setType(listPeserta.length > 2 ? "fgd" : "one-on-one")
  //   if (overFlow()) {
  //     setIsOverFlow(true)
  //   } else {
  //     setIsOverFlow(false)
  //   }
  // }, [listPeserta, orientation])
  // end kebutuhan content mobile

  const { updateRoomLog } = useRoomService()

  const roomConnect = useRoomConnect({
    options: collaboration,
    setPermissionError
  })
  const { disconnect } = useRoomDisconnect()
  useEffect(() => {
    return () => {
      if (roomConnect.room) disconnect()
    }
  }, [roomConnect.room, disconnect])

  const {
    localParticipant,
    remoteParticipants,
    localParticipantConnectionLevel
  } = useParticipant()

  const handleError = (e) => {
    if (!permissionerror) {
      setPermissionError(e.name)
    }
    // setPermissionError(e)
  }

  const mailBodyFormat = (rawString) => {
    let result = rawString.replace(/\n/g, "<br />").replace(/-{4,}/g, "<hr>")
    return result
  }

  const dummyData = Array.from(Array(9).keys())

  const { isCameraOn, toggleCamera } = useToggleCamera()
  const { isMicrophoneOn, toggleMicrophone } = useToggleMicrophone()
  const {
    toggleScreenShare,
    screenTrack,
    remoteScreenTrack,
    handleRemoteScreenTrack,
    screenShareIdentity
  } = useToggleScreenSharing()
  const { sendData } = useToggleRemoteMicrophone()
  const { dominantSpeaker } = useDominantSpeaker()

  const {
    audioInputList,
    audioOutputList,
    audioInputDeviceId,
    audioOutputDeviceId,
    updateAudioInput,
    updateAudioOutput
  } = useLocalAudio()

  const { videoInputList, videoInputDeviceId, updateVideoInput } =
    useLocalVideo()
  const { videoResolution, updateVideoResolution } = useVideoResolution({
    initial: { ...collaboration.video, deviceId: videoInputDeviceId }
  })

  /**
   * toggle microphone remote participant
   */
  const onDataReceived = useCallback(
    (message) => {
      const data = JSON.parse(message)
      if (
        data &&
        data.type === "toggleMic" &&
        data.participant === roomConnect.room.localParticipant.sid
      ) {
        toggleMicrophone()
      }
    },
    [roomConnect.room, toggleMicrophone]
  )

  useFirebase()

  // duration / time counter
  const { timestamp } = useTimestamp()
  const { stringTimeCounter } = useTimeCounter({ timestamp })

  // ----- component state
  const {
    isShowMoreMenu,
    isShowCamMenu,
    isShowMicMenu,
    isShowQuestionList,
    isShowFGD,
    isShowConfirmation,
    isShowBookmark,
    isShowChatroom,
    isShowListParticipant,
    setIsShowMoreMenu,
    setIsShowCamMenu,
    setIsShowMicMenu,
    setIsShowQuestionList,
    setIsShowFGD,
    setIsShowConfirmation,
    setIsShowBookmark,
    setIsShowChatroom,
    setIsShowListParticipants
  } = usePopupVideoFrame()

  // chat
  const { unRead, sendMessage, chat } = useChat()
  useEffect(() => {
    if (unRead && isShowChatroom) {
      dispatch(ChatActions.messageRead())
    }
  }, [dispatch, isShowChatroom, unRead])

  const [fgdParticipantList, setFgdParticipantList] = useState([])

  useEffect(() => {
    const getFGDParticipantData = async (path) => {
      try {
        let pesertaFGD = await getData(`${path}/peserta`)
        let batchId = config.configData.batchId || ""
        let batchPath = batchId ? batchId : ""
        // PATH PROJECTS
        if (batchPath !== "" && !/^projects\//.test(batchPath)) {
          batchPath = `projects/${batchPath}`
        }
        pesertaFGD = pesertaFGD.map((peserta) => {
          return {
            email: peserta.email,
            uid: peserta.uid,
            reloadPath: `${batchPath}/raw_input/${peserta.uid}/in_basket/manualReload`
          }
        })

        setFgdParticipantList(pesertaFGD)
      } catch (e) {
        console.log({ e })
      }
    }
    if (
      config.configData &&
      config.configData.accessor &&
      config.configData.type === "fgd" &&
      fgdParticipantList.length === 0
    ) {
      const fgdPath = config.configData.observerPath.replace("/observer", "")
      getFGDParticipantData(fgdPath)
    }
  }, [config.configData, config])

  // bookmark
  const { bookmark, addBookmark } = useBookmark()
  const [currentTimestamp, setCurrentTimestamp] = useState(Date.now())
  useEffect(() => {
    if (isShowBookmark) setCurrentTimestamp(Date.now())
  }, [isShowBookmark])

  const onClickMoreMenu = () => {
    if (isShowCamMenu || isShowMicMenu) {
      setIsShowCamMenu(false)
      setIsShowMicMenu(false)
    } else {
      setIsShowMoreMenu(!isShowMoreMenu)
    }
  }

  const arrayToChunks = (arr, perChunk) => {
    const results = arr.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / perChunk)

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = [] // start a new chunk
      }

      resultArray[chunkIndex].push(item)

      return resultArray
    }, [])

    return results
  }

  const [chunks, setChunks] = useState()
  const [numberofchunks, setnumberofchunks] = useState()
  const [length, setLength] = useState()

  useEffect(() => {
    let newArray = []
    if (1 === 1) {
      newArray = [...remoteParticipants]
      newArray.unshift(localParticipant)
    } else {
      newArray = [...dummyData]
    }
    
    let arrL = newArray.length
    let newnumberofchunks = 1
    let newLength = 1

    if (newArray[0] === null) return

    if (arrL === 2) newLength = 2
    else if (arrL >= 3 && arrL <= 4) newLength = 4
    else if (arrL >= 5 && arrL <= 6) newLength = 6
    else if (arrL >= 7 && arrL <= 9) newLength = 8
    else if (arrL >= 10 && arrL <= 12) newLength = 12
    else if (arrL >= 13) newLength = 16

    if (newLength === 4) newnumberofchunks = 2
    else if (newLength === 8 || newLength === 6) newnumberofchunks = 3
    else if (newLength === 12 || newLength === 16) newnumberofchunks = 4

    setType(newLength > 2 ? "fgd" : "one-on-one")
    if (overFlow()) {
      setIsOverFlow(true)
    } else {
      setIsOverFlow(false)
    }
    const newChunks = arrayToChunks(newArray, newnumberofchunks)
    
    setLength(newLength)
    setnumberofchunks(newnumberofchunks)
    setChunks(newChunks)
    console.log("participant")
    // isMuteSite && muteElementAudio(true)
  }, [remoteParticipants, localParticipant, orientation])

  // useEffect(() => {
  //   if ( isMuteSite ) {
  //     setTimeout(() => {
  //       muteElementAudio(true)
  //     }, 3);
  //   }
  // }, [chunks, length, numberofchunks])


  const {
    open: snackOpen,
    messageInfo,
    handleClose,
    handleExited
  } = useSnackbar()

  // footer
  const [ showListParticipant, setShowListParticipant ] = useState(false)
  const [ showChat, setShowChat ] = useState(false)
  const [ showShareScreen, setShowShareScreen ] = useState(false)

  const avatarName = []

  if (config.isLoading || roomConnect.isConnecting || !timestamp) {
    return (
      <>
        {permissionerror && (
          <ErrorPage deviceError={permissionerror}></ErrorPage>
        )}
        {!permissionerror && (
          <>
            <Loading where="interaksi" />
            <Snackbar
              className={`toast-left`}
              open={snackOpen}
              autoHideDuration={150000}
              onClose={handleClose}
              onExited={handleExited}
              message={messageInfo ? messageInfo.message : undefined}
            />
          </>
        )}
      </>
    )
  }

  if (!queryString || config.isError || roomConnect.isError) {
    return (
      <>
        {!queryString && <p>Query undefined</p>}
        {config.isError && <p>{config.error.message}</p>}
        {roomConnect.isError && <p>{roomConnect.error.message}</p>}
      </>
    )
  }

  return (
    <>
      { roomConnect.room &&
        <div className='mobile-container'>
          {/* <MobileTopBar /> */}
          <MobileTopBar2 
            title={ config?.configData?.label }
            isCameraOn={isCameraOn}
            cameraValue={
              videoInputDeviceId || (videoInputList && videoInputList[0]?.value)
            }
            cameraOptions={videoInputList}
            onCameraChange={(e) => {
              updateVideoInput(e)
            }}
            isMuteSite = { isMuteSite }
            muteSite = { () => muteSite() }
            setShowMateriGroup={ setIsShowFGD }
            label = {config.configData?.label}
            materiFGD = {config.configData?.materiFGD}
          />

          {/* container content */}
          <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
          <div 
            className={`mobile-container-content ${type === 'one-on-one' && orientation === 'landscape' && 'mobile-container-content-one-on-one'}`}
          >

            <div 
              className='mobile-content' 
              style={{justifyContent : type === "fgd" ? "flex-start" : "center"}} 
              id="mobile-content"
              onScroll={() => onScroll()} 
              ref={contentRef}
            >
              
              {/* share screen */}
              { remoteScreenTrack && <div className={ `mobile-content-items-${type} mobile-share-screen-items` }>
                <div className={ `mobile-content-item-${type} mobile-share-screen-item` }>
                  <div className={ `${isFullShareScreen ? 'mobile-full-screen' : 'mobile-share-screen-item-video'} mobile-content-item-video-${type}` }>
                    <div className='mobile-container-share-screen'>
                      <SharedScreenFrame
                        screenTrack={screenTrack || remoteScreenTrack}
                        mobile={true}
                      />
                    </div>
                    <div className='mobile-expand-screen-button' onClick={_=>setIsFullShareScreen(!isFullShareScreen)}>
                      <img src={ iconExpandTopBlue } className="img1"/>
                      <img src={ iconExpandBottomBlue } className="img2"/>
                    </div>
                  </div>
                  <div className={ `mobile-content-item-name-${type} mobile-share-screen-item-name` }>
                    { screenShareIdentity && JSON.parse(screenShareIdentity).name } is presenting
                  </div>
                </div>
              </div> }
              {/* { console.log(showShareScreen + orientation)} */}
              {/* content video peserta */}
              {/* chunk */}
          {chunks &&
            chunks.length > 0 &&
            numberofchunks &&
            length &&
            chunks.map((chunk, index) => {
                return chunk.map((p, key) => {
                  const identity = JSON.parse(p.identity)
                  
                  const firstCharacter = identity?.name?.charAt(0).toUpperCase()
                  let repeat = 1
                  if (avatarName[firstCharacter]) {
                    repeat = avatarName[firstCharacter] + 1
                    avatarName[firstCharacter] = avatarName[firstCharacter] + 1
                  }else{
                    avatarName[firstCharacter] = 1
                  }

                  const defaultColor = getRandomColor(firstCharacter, repeat)

                  if (index === 0 && key === 0)
                    return (
                      <ParticipantMobile
                        key={`${index}-${key}`}
                        frames={remoteParticipants?.length + 1}
                        participant={localParticipant}
                        name={`${
                          JSON.parse(localParticipant.identity).name
                        } ( Anda )`}
                        isLocal={true}
                        isAssessor={
                          config.configData?.accessor ||
                          config.configData?.data_peserta?.role === "admin"
                        }
                        audioOutputDeviceId={audioOutputDeviceId}
                        showShareScreen={showShareScreen}
                        type={type}
                        orientation={orientation}
                        setListParticipant = { setListParticipant }
                        listParticipant = { listParticipant }
                        defaultColor = { defaultColor }
                        chunks={chunks}
                      />
                    )
                  else
                    return (
                      <ParticipantMobile
                        key={p.sid}
                        frames={remoteParticipants.length + 1}
                        participant={p}
                        identity={identity}
                        name={`${identity.name} ${
                          identity.role !== "peserta"
                            ? `(${identity.role})`
                            : ""
                        }`}
                        fgdParticipantList={fgdParticipantList}
                        config={config.configData}
                        role={JSON.parse(localParticipant.identity).role}
                        isLocal={false}
                        isAssessor={
                          JSON.parse(p.identity).role !== "peserta"
                        }
                        isTalking={dominantSpeaker === p}
                        handleScreenTrack={handleRemoteScreenTrack}
                        handleDataReceived={onDataReceived}
                        handleMuteAudio={() => {
                          console.log("handleMuteAudio #2")
                          try {
                            sendData({
                              type: "toggleMic",
                              participant: p.sid
                            })
                          } catch (error) {
                            console.log(error)
                          }
                        }}
                        audioOutputDeviceId={audioOutputDeviceId}
                        showShareScreen={showShareScreen}
                        type={type}
                        orientation={orientation}
                        setListParticipant = { setListParticipant }
                        listParticipant = { listParticipant }
                        defaultColor = { defaultColor }
                        chunks={chunks}
                      />
                    )
                })
            })}
            {/* end of chunk */}
            </div>
            {/* { console.log(`overflow ${isOverflow}`)} */}
            {/* arrow */}
            {isOverflow && !isBottomScroll && type !== 'one-on-one' &&
            <div className='mobile-content-arrow' onClick={_ => toBottomOverflow()}>
              <img src={ orientation === 'landscape' ? iconArrowSide : iconArrowDown } />
            </div>
            }
          </div>
          {/* end container content */}
          
          {/* chat content */}
          { isShowChatroom && 
            <MobileChat2 
              setShowChat = { setIsShowChatroom }
              uuid = {config.configData?.data_peserta.uid}
              chat = { chat }
              onSubmit={(v) => v && sendMessage(encodeURIComponent(v))}
            />
          }
          { isShowListParticipant &&  
            <MobileListParticipants2
              setShow = { setIsShowListParticipants }
              listParticipant = { listParticipant }
              // chunks = { chunks }
              // numberofchunks = { numberofchunks }
              // length = { length }
              // localParticipant = { localParticipant }
              // handleScreenTrack={handleRemoteScreenTrack}
              // handleDataReceived={onDataReceived}
            />
          }
          {
            config.configData?.label !== "interview" &&
            config.configData?.materiFGD &&
            isShowFGD &&
            <MobileMateriGroup
              setShowMateriGroup={setIsShowFGD}
              materi={config.configData?.materiFGD[0]?.materi}
              attachment={config.configData?.materiFGD[0]?.attachments}
            />
          }

          {/* footer */}
          <MobileFooter2
            showListParticipant = { showListParticipant } 
            setShowListParticipant = { setShowListParticipant }
            showChat = { showChat }
            setShowChat = { setShowChat }
            showShareScreen = { showShareScreen }
            setShowShareScreen = { setShowShareScreen }

            isMicOn={isMicrophoneOn}
            isCameraOn={isCameraOn}
            onClickToggleMic={() => toggleMicrophone()}
            onClickToggleCamera={() => toggleCamera()}

            isShowChatroom={ isShowChatroom }
            setIsShowChatroom={ setIsShowChatroom }

            isShowListParticipant={ isShowListParticipant }
            setIsShowListParticipants={ setIsShowListParticipants }

            isUnread = { unRead }
          />
          
        </div>
      }
    </>
  )
}

export default MobileRoom2
