export const bookmarkList = [
  {
    content: {
      rawString: 'raw string',
      note: 'string',
      kompetensi: ['kompetensi 1', 'kompetensi 2']
    },
    timestamp: {
      stringTimestamp: '00:00:00',
      unixTimestamp: Date.now()
    },
    videoClip: false,
    name: 'name',
    email: 'name@email.com',
    uid: 'jdfaknqoekf',
    role: 'assessor'
  },
  {
    content: {
      rawString: ' @SKYSHI 01 mampu menjelaskan tujuan dan pencapaian saat ini  #Initiative      ',
      note: ' @[SKYSHI 01](participant01@skyshi.com) mampu menjelaskan tujuan dan pencapaian saat ini  #[Initiative](4)      ',
      kompetensi: ['Initiative']
    },
    timestamp: {
      stringTimestamp: '00:08:29 - 00:08:49',
      unixTimestamp: 1624206447463
    },
    videoClip: false,
    name: 'Asesor 01',
    email: 'name@email.com',
    uid: 'jdfaknqoekf',
    role: 'assessor'
  }
]
