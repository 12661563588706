import React, { useEffect, useRef, useState } from 'react'

import previewVideo from "../../assets/images/image-video.png"
import iconArrowDown from "../../assets/icons/mobile/arrow_down.svg"
import iconArrowSide from "../../assets/icons/mobile/arrow-side.svg"
import imagesShareScreen from "../../assets/icons/mobile/share-screen-images.svg"
import iconExpandTopBlue from "../../assets/icons/mobile/expand-top-blue.svg"
import iconExpandTopWhite from "../../assets/icons/mobile/expand-top-white.svg"
import iconExpandBottomBlue from "../../assets/icons/mobile/expand-bottom-blue.svg"
import iconExpandBottomWhite from "../../assets/icons/mobile/expand-bottom-white.svg"
import muteIcon from "../../assets/icons/mute.png"
import avatarDefault from "../../assets/icons/mobile/avatar-mobile.svg"
import ReactTooltip from 'react-tooltip'
import MobileAvatar from './general/MobileAvatar'
import { getRandomColor } from '../../lib/general'

const listPesertas = [
  // {
  //   name : "Siti Azizah",
  //   image : previewVideo
  // },
  // {
  //   name : "Eris",
  //   image : previewVideo
  // },
  // {
  //   name : "Johan Paul",
  //   image : previewVideo
  // },
  // {
  //   name : "Alifia Zahra Firdaus",
  //   image : previewVideo
  // },
  // {
  //   name : "Shemwel Denis",
  //   image : previewVideo
  // },  
  // {
  //   name : "Razaf Pari",
  //   image : previewVideo
  // },
  // {
  //   name : "Widya Salma",
  //   image : previewVideo
  // },
  // {
  //   name : "Devia Sandra",
  //   image : previewVideo
  // },
  // {
  //   name : "Rani Puspita Dewi",
  //   image : previewVideo
  // },
  // {
  //   name : "Shemwel Denis",
  //   image : previewVideo
  // },  
  // {
  //   name : "Razaf Pari",
  //   image : previewVideo
  // },
  // {
  //   name : "Widya Salma",
  //   image : previewVideo
  // },
  // {
  //   name : "Devia Sandra",
  //   image : previewVideo
  // },
  {
    name : "Pani Puspita Dewi",
    image : previewVideo
  },
  {
    name : "Pani Puspita Dewi",
    image : previewVideo
  },
  {
    name : "Pani Puspita Dewi",
    image : previewVideo
  },
  {
    name : "Pani Puspita Dewi",
    image : previewVideo
  },
  {
    name : "Pani Puspita Dewi",
    image : previewVideo
  }
]


function MobileContent({ showShareScreen }) {
  window.scrollTo(0,1)
  // const elementContent = document.getElementById("mobile-content")
  const contentRef = useRef()

  const [listPeserta, setListPeserta] = useState(listPesertas)
  const [type, setType] = useState(listPeserta.length > 2 ? "fgd" : "one-on-one")
  const [orientation, setOrientation] = useState(screen.orientation.type.match(/\w+/)[0])
  const [isFullShareScreen, setIsFullShareScreen] = useState(false)

  const [ isOverflow, setIsOverFlow ] = useState(true)
  const [ isBottomScroll, setIsBottomScroll ] = useState(false)
  // const [ isTopScroll, setIsTopScroll ] = useState(true)

  // detect orientation layar
  screen.orientation.addEventListener("change", function(e) {
    setOrientation(screen.orientation.type.match(/\w+/)[0])
    console.log(screen.orientation.type.match(/\w+/)[0])
  })

  

  const overFlow = () => {
    const element = document.getElementById("mobile-content")
    return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
  }

  const toBottomOverflow = () => {
    const element = document.getElementById("mobile-content")
    if (orientation === 'landscape') {
      element.scrollLeft = element.scrollWidth
    } else {
      element.scrollTop = element.scrollHeight
    }
  }

  const onScroll = () => {
    if (contentRef.current) {
      const { scrollTop, scrollHeight, clientHeight, scrollLeft, scrollWidth, clientWidth} = contentRef.current;
      console.log(scrollLeft)
      console.log(clientWidth)
      console.log(scrollWidth)
      if (orientation === 'landscape') {
        if ((scrollLeft + clientWidth + 1) >= scrollWidth) {
          setIsBottomScroll(true)
        } else {
          setIsBottomScroll(false)
        }
      } else {
        if ((scrollTop + clientHeight + 1) >= scrollHeight) {
          setIsBottomScroll(true)
        } else {
          setIsBottomScroll(false)
        }
      }
    }
  };

  // useEffect(() => {
  //   if (elementContent.scrollTop === elementContent.scrollHeight) setIsBottomScroll(true)
  // }, [elementContent.scrollTop])

  useEffect(() => {
    setType(listPeserta.length > 2 ? "fgd" : "one-on-one")
    if (overFlow()) {
      setIsOverFlow(true)
    } else {
      setIsOverFlow(false)
    }
  }, [listPeserta, orientation])

  const avatarName = {}


  return (
    <React.Fragment>
      {/* <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta> */}
      <div 
        className={`mobile-container-content ${type === 'one-on-one' && orientation === 'landscape' && 'mobile-container-content-one-on-one'}`}
      >

        <div 
          className='mobile-content' 
          style={{justifyContent : type === "fgd" ? "flex-start" : "center"}} 
          id="mobile-content"
          onScroll={() => onScroll()} 
          ref={contentRef}
        >
          
          {/* share screen */}
          { showShareScreen && <div className={ `mobile-content-items-${type} mobile-share-screen-items` }>
            <div className={ `mobile-content-item-${type} mobile-share-screen-item` }>
              <div className={ `${isFullShareScreen ? 'mobile-full-screen' : 'mobile-share-screen-item-video'} mobile-content-item-video-${type}` }>
                <img src={ imagesShareScreen } alt="video" className={ `mobile-share-screen-img-${type} ${isFullShareScreen && 'mobile-object-fit-contain'}` }/>
                <div className='mobile-expand-screen-button' onClick={_=>setIsFullShareScreen(!isFullShareScreen)}>
                  <img src={ iconExpandTopBlue } className="img1"/>
                  <img src={ iconExpandBottomBlue } className="img2"/>
                </div>
              </div>
              <div className={ `mobile-content-item-name-${type} mobile-share-screen-item-name` }>
                Screen ShareScreen
              </div>
            </div>
          </div> }
          { console.log(showShareScreen + orientation + listPeserta.length)}
          {/* content video peserta */}
          { listPeserta.map((peserta,index) => {
            const firstCharacter = peserta?.name?.charAt(0).toUpperCase()
            let repeat = 1
            if (avatarName[firstCharacter]) {
              repeat = avatarName[firstCharacter] + 1
              avatarName[firstCharacter] = avatarName[firstCharacter] + 1
            }else{
              avatarName[firstCharacter] = 1
            }
            let defaultColor = getRandomColor(firstCharacter, repeat)
            return (
              <div className={ `mobile-content-items-${type} ${showShareScreen && orientation === 'landscape' && listPeserta.length <= 2 && 'mobile-width-100'}`} key={index}>
                <div className={ `mobile-content-item-${type}` }>
                  <div className={ `mobile-content-item-video-${type}` }>
                    {/* {index % 2 === 0 ? */}
                    {true ?
                    // <img 
                    //   src={ avatarDefault } 
                    //   alt="video"
                    //   style={{height: "auto", width: "auto"}}
                    // />
                    <MobileAvatar
                      char={peserta?.name?.charAt(0)}
                      style={{
                        width: "5rem",
                        height: "5rem",
                        fontSize: "2.5rem",
                        textShadow: "0 1px 1px #000",
                        fontWeight: "700",
                        defaultColor,
                        color: "#EDF2F7"
                      }}
                    />
                    :
                    <img src={peserta.image} alt="video" />
                    }
                  </div>
                  <div className={ `mobile-content-item-name-${type}` } data-tip data-for={`chat${index}`}>
                    { peserta.name }
                  </div>
                  <ReactTooltip 
                    id={`chat${index}`}
                    place="top" 
                    effect="solid"
                    backgroundColor='white'
                    border
                    borderColor='black'
                    textColor='black'
                  >
                    { name }
                  </ReactTooltip>
                  <div
                    className="icon-mic-wrapper-mobile"
                  >
                    <img
                      className="utils-icon"
                      style={{
                        marginTop: 0,
                        padding: 7,
                        height: 30
                      }}
                      src={muteIcon}
                      alt="mute"
                    />
                  </div>
                </div>
              </div>
            )
          }) }
        </div>
        { console.log(`overflow ${isOverflow}`)}
        {/* arrow */}
        {isOverflow && !isBottomScroll && type !== 'one-on-one' &&
        <div className='mobile-content-arrow' onClick={_ => toBottomOverflow()}>
          <img src={ orientation === 'landscape' ? iconArrowSide : iconArrowDown } />
        </div>
        }
      </div>
    </React.Fragment>
    
  )
}

export default MobileContent
