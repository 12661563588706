import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Creators as ConfigActions } from "../redux/ConfigRedux"
import { Creators as SnackActions } from "../redux/SnackbarRedux"

export default function useConfig({ query, urlConfig, page }) {
  const dispatch = useDispatch()
  const { configData, isLoading, isError, error } = useSelector(
    (state) => state.config
  )

  useEffect(() => {
    if (query !== null) {
      dispatch(ConfigActions.getConfigRequest({ query, urlConfig, page }))
    }
  }, [query, dispatch, urlConfig])

  useEffect(() => {
    if (isError) {
      dispatch(
        SnackActions.newSnack(
          `Mohon maaf terjadi gangguan: ${error.message} - Mohon coba reload halaman`
        )
      )
    }
  }, [isError])

  return {
    isLoading,
    configData,
    isError,
    error
  }
}
