import { fromRange } from "xpath-range"
import { v4 as uuidv4 } from "uuid"
import { snapToWord } from "./snapToWord"

function getSelection() {
  if (window.getSelection) {
    return snapToWord(window.getSelection())
  }

  if (document.selection) {
    return snapToWord(document.selection)
  }

  return null
}

const getXpathParameters = (xpath) => {
  const startOffset = xpath.startOffset
  const endOffset = xpath.endOffset
  let startContainer = xpath.start
  // /div[2]/p[7]/text()[1] -> /div[2]/p[7]/text[1]
  startContainer = startContainer.replace(/(|)/g, "")
  let endContainer = xpath.end
  endContainer = endContainer.replace(/(|)/g, "")
  return { startOffset, endOffset, startContainer, endContainer }
}

/**
 * References:
 * https://pargles.com/posts/medium-like-text-highlighting-in-react/
 */
export function onHighlightAction({
  colorName,
  highlightable,
  decision,
  setShowToolTip,
  userAnnotations,
  el
}) {
  const text =
    (getSelection() &&
      decodeURIComponent(
        encodeURIComponent(getSelection().toString()).replace(
          "%C2%A0%0A",
          "%20"
        )
      )) ||
    ""

  if (
    !text ||
    text === " " ||
    text.split(" ").filter((v) => v !== "").length <= 1 ||
    !highlightable
  ) {
    getSelection().removeAllRanges()
    setShowToolTip(false)
    return
  } else {
    const selection = getSelection()

    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0)
      // const content = document.getElementById("decision-reader-body-root");
      const content = el
      let xpath = null

      const validRange = rangeCompareNode(range, content) === 2

      if (content && validRange) {
        xpath = fromRange(range, content)
      }

      if (xpath) {
        try {
          const { startOffset, endOffset, startContainer, endContainer } =
            getXpathParameters(xpath)
          const highlightId = uuidv4()
          const typename = "highlight"
          userAnnotations.saveUserAnnotation({
            text,
            colorName,
            decisionId: decision.id,
            highlightId,
            startOffset,
            endOffset,
            startContainer,
            endContainer,
            typename
          })
        } catch (e) {
          console.error("user highlight failed: ", e)
        }
      }
    }
  }

  setShowToolTip(false)
}

function rangeCompareNode(range, node) {
  const nodeRange = node.ownerDocument.createRange()
  try {
    nodeRange.selectNode(node)
  } catch (e) {
    nodeRange.selectNodeContents(node)
  }
  const nodeIsBefore =
    range.compareBoundaryPoints(Range.START_TO_START, nodeRange) === 1
  const nodeIsAfter =
    range.compareBoundaryPoints(Range.END_TO_END, nodeRange) === -1

  if (nodeIsBefore && !nodeIsAfter) return 0
  if (!nodeIsBefore && nodeIsAfter) return 1
  if (nodeIsBefore && nodeIsAfter) return 2

  return 3
}
