import PermissionErrorImage from "../assets/images/permission.png"
import ErrorPage from "./ErrorPage"

function PermissionError({
  message = "Mohon untuk mengizinkan Camera dan Microphone permission"
}) {
  return (
    <div>
      <div
        className="loading-wrapper"
        style={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <img src={PermissionErrorImage} />
        <div style={{ marginTop: 40 }}></div>
        <h2
          style={{
            fontWeight: 400,
            lineHeight: 1.43,
            borderRadius: "4px",
            backgroundColor: "rgb(253, 237, 237)",
            padding: 10,
            color: "rgb(95, 33, 32)"
          }}
        >
          {message}
        </h2>
      </div>
    </div>
  )
}

export default PermissionError
