import { createActions, createReducer } from "reduxsauce"

/** --------------- Types and Action Creators --------------- */
export const { Types, Creators } = createActions({
  loginRequest: ["data"],
  loginSuccess: ["payload"],
  loginFailed: ["error"],

  logoutRequest: ["data"],
  logoutSuccess: ["payload"],
  logoutFailed: ["error"]
})

/** -------------------- Initial State ------------------- */
export const INITIAL_STATE = {
  isLoading: false,
  isError: false,
  error: null,
  isLoggedIn: false
}

/** -------------------- Reducers ------------------------ */
export const loginRequest = (state) => ({
  ...state,
  ...INITIAL_STATE,
  isLoading: true
})

export const loginSuccess = (state, action) => ({
  ...state,
  isLoading: false,
  isLoggedIn: true
})

export const loginFailed = (state, action) => ({
  ...state,
  isLoading: false,
  isError: true,
  error: action.error
})

// logout
export const logoutRequest = (state) => ({
  ...state,
  isLoading: true,
  isError: false,
  error: null
})

export const logoutSuccess = (state, action) => ({
  ...state,
  isLoading: false,
  isLoggedIn: false
})

export const logoutFailed = (state, action) => ({
  ...state,
  isLoading: false,
  isError: true,
  error: action.error
})

/** ----------- Hookup Reducers to Type ------------- */
export const authReducer = createReducer(INITIAL_STATE, {
  [Types.LOGIN_REQUEST]: loginRequest,
  [Types.LOGIN_SUCCESS]: loginSuccess,
  [Types.LOGIN_FAILED]: loginFailed,

  [Types.LOGOUT_REQUEST]: logoutRequest,
  [Types.LOGOUT_SUCCESS]: logoutSuccess,
  [Types.LOGOUT_FAILED]: logoutFailed
})
