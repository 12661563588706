import PropTypes from 'prop-types'

function RoomWrapper ({ children, isShowBookmark }) {
  return (
    <div className={`room-wrapper ${isShowBookmark === 'initial-value' ? '' : isShowBookmark === 'show' ? 'show-bookmark' : 'hide-bookmark'} d-flex justify-content-end align-items-center`}>
      {children}
    </div>
  )
}

RoomWrapper.propTypes = {
  children: PropTypes.any
}

export default RoomWrapper
