import { useEffect, useState } from "react"

/**
 * participant track handler
 */
export default function useTrack({ participant, handleScreenTrack }) {
  const [videoTrack, setVideoTrack] = useState(null)
  const [audioTrack, setAudioTrack] = useState(null)
  const [dataTrack, setDataTrack] = useState(null)

  useEffect(() => {
    if (participant) {
      /**
       * participant track subs
       */
      const trackSubscribed = (track) => {
        if (track.name === "screen") {
          handleScreenTrack(track, participant.identity)
        } else if (track.kind === "video") {
          setVideoTrack(track)
        } else if (track.kind === "audio") {
          setAudioTrack(track)
        } else if (track.kind === "data") {
          setDataTrack(track)
        }
      }

      participant.tracks.forEach((publication) => {
        if (publication.track) {
          trackSubscribed(publication.track)
        }
      })

      participant.on("trackSubscribed", trackSubscribed)

      /**
       * participant track unsubs
       */
      const trackUnsubscribed = (track) => {
        if (track.name === "screen") {
          handleScreenTrack(null)
        } else if (track.kind === "video") {
          setVideoTrack(null)
        } else if (track.kind === "audio") {
          setAudioTrack(null)
        } else if (track.kind === "data") {
          setDataTrack(null)
        }
      }
      participant.on("trackUnsubscribed", trackUnsubscribed)

      return () => {
        participant.off("trackSubscribed", trackSubscribed)
        participant.off("trackUnsubscribed", trackUnsubscribed)
      }
    }
  }, [participant, handleScreenTrack])

  return { audioTrack, videoTrack, dataTrack }
}
