import React from 'react'
import infoIcon from '../../assets/icons/info.png'
import sampleVideo from '../../assets/images/image-video.png'
import muteIcon from '../../assets/icons/mute.png'

function WaitingRoomContent () {
  return (
    <div className='waiting-room-content'>
      <div className='content-header'>
        <h1>Mohon menunggu untuk dipanggil</h1>
        <div className='subtitle d-flex'>
          <img src={infoIcon} alt='info' />
          <p>Anda akan berinteraksi lewat video call</p>
        </div>
      </div>
      <div className='video'>
        <div className='user-info d-flex'>
          <p>Peserta01</p>
          <img src={muteIcon} alt='mute' />
        </div>
        <div className='user-video'>
          {/* {!isCameraOn ? (
            <div className='no-video'>S</div>
          ) : ( */}
          <img src={sampleVideo} alt='video' />
          {/* )} */}
        </div>
      </div>
    </div>
  )
}

export default WaitingRoomContent
