import React, { useState } from 'react'

import tombolx from "../../assets/icons/mobile/tombol-x.svg"
import attachmentIcon from "../../assets/icons/mobile/attachment.svg"
import iconArrowDown from "../../assets/icons/mobile/arrow_down.svg"
import iconArrowTop from "../../assets/icons/mobile/arrow_top.svg"

const MobileMateriGroup = ({
  setShowMateriGroup,
  materi,
  attachment
}) => {
  const ref = React.createRef()
  const [isOverflow,setIsOverflow] = useState(false)
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [image, setImage] = useState(null)
  const [showImage, setShowImage] = useState(false)

  const mailBodyFormat = (rawString) => {
    let result = rawString.replace(/\n/g, "<br />").replace(/-{4,}/g, "<hr>")
    return result
  }

  React.useLayoutEffect(() => {
    if (ref.current.clientHeight <  ref.current.scrollHeight){
      setIsOverflow(true)
    }
  },[ref])

  return (
    <div className='mobile-container-modal mobile-container-materi-group'>
      {/* header */}
      <div className='mobile-materi-group-header'>
        <div className='mobile-materi-group-header-title'>
          Materi
        </div>
        <div>
          <img src={tombolx} onClick={_=> setShowMateriGroup(false)}/>
        </div>
      </div>
      {/* end header */}
      {/* content */}
      <div 
        className={`mobile-materi-group-content ${isFullScreen ? "mobile-max-height-materi-group-100" : "mobile-max-height-materi-group-50"}`} 
        ref={ref}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: mailBodyFormat(
              materi || "-"
            )
          }}
        ></div>
        <div className={`${!isFullScreen && 'mobile-sticky-bottom'}`} style={{backgroundColor: "#FFFFFF"}}>
          {isOverflow && 
            <div className='mobile-materi-group-arrow'>
              <img src={isFullScreen ? iconArrowTop : iconArrowDown} onClick={_=>setIsFullScreen(!isFullScreen)}/>
            </div>
          }
          {attachment?.length !== 0 &&
            <Attachment 
              isFullScreen={isFullScreen}
              attachment={attachment}
              showImage={showImage}
              image={image}
              setShowImage={(showImage) => setShowImage(showImage)}
              setImage={(image) => setImage(image)}
            />
          }
        </div>
      </div>
      {/* end content */}
      {showImage && 
        <div className='mobile-attachment-show-image'>
          {/* <div style={{
            display: "flex",
            justifyContent: "space-between"
          }}>
            <p>{image.name}</p>
          </div> */}
          <img 
            src={tombolx} 
            onClick={_=>setShowImage(false)}
            style={{
              position: "fixed",
              top: "20px",
              right: "20px"
            }}
          />
          <img 
            src={image.url}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain"
            }} 
          />
        </div>
      }

    </div>
  )
}

const Attachment = ({
  isFullScreen,
  attachment,
  showImage,
  image,
  setShowImage,
  setImage
}) => {

  const handleClickImage = (data) => {
    console.log(data)
    setShowImage(() => true)
    setImage(() => data)
  }
  
  return (
    <React.Fragment>
      {/* attachment */}
      <div className={`mobile-materi-group-attachment-title`}>
        Attachment
      </div>
      <div className={`mobile-materi-group-attachment-content`}>
        {attachment.map((data, index) => {
        return (
          <div className='mobile-materi-group-attachment-items' key={index} onClick={_=>handleClickImage(data)}>
            <img src={ attachmentIcon } width="45px" height="50px"/>
            <p>
              {data.name}
            </p>
          </div>
        )
        })}
      </div>
      
      {/* end attachment */}
    </React.Fragment>
  )
}

export default MobileMateriGroup
