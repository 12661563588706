import PropTypes from "prop-types"

function ParticipantWrapper({
  frames,
  children,
  isShowBookmark,
  isSharingScreen,
  length,
  parti
}) {
  return (
    // {/* give className of "twilio2", if there are 2 frames, "twilio3", if there are 3 frames, etc */}
    <div
      className={`twilio-wrapper ${
        isShowBookmark === "initial-value"
          ? "hide-bookmark"
          : isShowBookmark === "show"
          ? !isSharingScreen || isSharingScreen === "initial-value"
            ? "show-bookmark"
            : ""
          : "hide-bookmark"
      } ${
        isSharingScreen === "initial-value"
          ? "hide-sharing"
          : isSharingScreen
          ? `sharing${frames > 8 ? 7 : frames} show-sharing`
          : "hide-sharing"
      } twilio${
        frames > 10 ? 10 : frames
      }`}
    >
      {/* <div
        id="videoContainer"
        style={{ maxWidth: "70vw" }}
        className={`grid-container ${
          length > 2 ? "more-two" : ""
        } ${`video-${length}`} ${
          isSharingScreen && isSharingScreen !== "initial-value"
            ? "sharing"
            : ""
        }`}
      >
        {children} */}
        <div
          id="videoContainer"
          // style={{maxWidth: "90vw", maxHeight: "90vh"}}
          // className={`grid-container ${
          //   length > 2 ? "more-two" : ""
          // } ${`video-${length}`} ${isSharingScreen ? "sharing" : ""}`}
        >
          <div className="conference">
            <div className="dish">
              {parti}
            </div>
          </div>
      </div>
    </div>
  )
}

ParticipantWrapper.propTypes = {
  frames: PropTypes.number.isRequired,
  children: PropTypes.any
}

export default ParticipantWrapper
