import React, { useEffect, useState } from "react"
import muteIcon from "../../assets/icons/mute.png"
import previewVideo from "../../assets/images/image-video.png"
import profile from "../../assets/images/profile.png"
import useTrackVideo from "../../hooks/useTrackVideo"
import VideoTrack from "../VideoTrack"
import muteParticipantIcon from "../../assets/icons/mic-large.png"

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  })
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    window.addEventListener("resize", handleResize)
    handleResize()
    return () => window.removeEventListener("resize", handleResize)
  }, [])
  return windowSize
}

function VideoFrame({ data, isShowBookmark, isSharingScreen }) {
  const [camera, setCamera] = useState()
  const [chunks, setChunks] = useState()
  const [parti, setParti] = useState("")
  const [numberofchunks, setnumberofchunks] = useState()
  const [length, setLength] = useState()
  let withImage = true

  useEffect(() => {
    const getVideoDevices = () => {
      navigator.mediaDevices
        .enumerateDevices()
        .then((mediaDevices) => {
          let cameras = []
          mediaDevices.forEach((device) => {
            const arrayVirtual = ["OBS", "Virtual", "VCam", "Snap", "XSplit", "ManyCam", "ChromaCam", "SplitCam", "AlterCam", "WebcamMax", "YouCam", "vMix", "EpocCam", "DroidCam", "iVCam", "e2eSoft", "NDI", "CamTwist", "SplitmediaLabs", "SparkoCam", "Webcamoid", "WebcamStudio"]
            const regexPattern = new RegExp(arrayVirtual.join("|"), "i")

            if (device.kind === "videoinput" && !regexPattern.test(device.label)) {
              // console.log({ device })
              cameras.push({ label: device.label, value: device.deviceId })
              // menyesuaikan format selection option
            }
          })
          setCamera(cameras[0])
        })
        .catch((error) => {
          console.log(`Error: ${error.name}: ${error.message}`)
          window.top.postMessage("errorVideoFrame", "*")
        })
    }

    getVideoDevices()
  }, [])

  const arrayToChunks = (arr, perChunk) => {
    const results = arr.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / perChunk)

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = [] // start a new chunk
      }

      resultArray[chunkIndex].push(item)

      return resultArray
    }, [])

    return results
  }

  const size = useWindowSize()
  useEffect(() => {
    // let arrL = data.length
    // let newnumberofchunks = 1
    // let newLength = 1

    // if (arrL === 2) newLength = 2
    // else if (arrL >= 3 && arrL <= 4) newLength = 4
    // else if (arrL >= 5 && arrL <= 6) newLength = 6
    // else if (arrL >= 7 && arrL <= 9) newLength = 8
    // else if (arrL >= 10 && arrL <= 12) newLength = 12
    // else if (arrL >= 13) newLength = 16

    // if (newLength === 4) newnumberofchunks = 2
    // else if (newLength === 8 || newLength === 6) newnumberofchunks = 3
    // else if (newLength === 12 || newLength === 16) newnumberofchunks = 4

    // const newChunks = arrayToChunks(data, newnumberofchunks)
    // setLength(newLength)
    // setnumberofchunks(newnumberofchunks)
    // setChunks(newChunks)
    let width = 0
    let height = 0
    let ratio = 3/4
    let margin = 5
    const resizer = (width) => {

      const html = data.map((data, index) => {
        const styles = {
          height : width * ratio,
          width : width,
          margin : margin
        }

        return (
          <div className="box" key={index} style={styles}>
            <div className="name-display mx-3 d-flex" style={{color : "blue"}}>
              <h5 className="trim-name">{`Peserta ${index + 1} Peserta ${
                index + 1
              } Peserta ${index + 1}`}</h5>
            </div>
            <div class="icon-mic-wrapper">
              <img
                className="utils-icon"
                style={{
                  marginTop: 0,
                  padding: 8
                }}
                src={muteIcon}
                alt="mute"
              />
            </div>
            <div className="participant-mute">
              <div className="peserta-utils-container">
                <img
                  className="utils-icon"
                  src={muteParticipantIcon}
                  alt="participant-mute"
                />
              </div>
            </div>
            {withImage ? (
              <img src={previewVideo} alt="video" />
            ) : (
              <div className="no-photo d-flex justify-content-center align-items-center">
                <h4 style={{textShadow: "0 1px 1px #000"}}>{key}</h4>
              </div>
            )}
          </div>
        )
      })
      return html
    }

    const areas = (increment) => {

        let i = 0
        let w = 0
        let h = increment * ratio + (margin * 2)
        while (i < (data.length)) {
            if ((w + increment) > width) {
                w = 0
                h = h + (increment * ratio) + (margin * 2)
            }
            w = w + increment + (margin * 2)
            i++
        }
        if (h > height || increment > width) return false
        else return increment

    }

    const dimensions = () => {
      let videoContainer
      if (isSharingScreen || isShowBookmark === "show") {
        videoContainer = document.getElementsByClassName("twilio-wrapper")[0]
        width = videoContainer.offsetWidth
      } else {
        width = (window.innerWidth) - (margin * 4)
      }
      height = (window.innerHeight * 0.8) - (margin)
    }

    const resize = () => {

        dimensions()

        let max = 0
        let i = 1
        while (i < 5000) {
            let area = areas(i)
            if (area === false) {
                max = i - 1
                break
            }
            i++
        }

        max = max - (margin * 2)
        return resizer(max)
    }

    setParti(resize())
  }, [data, size.width, isShowBookmark, isSharingScreen])

  return (
    <div
      className={`twilio-wrapper ${
        isShowBookmark === "initial-value"
          ? ""
          : isShowBookmark === "show"
          ? !isSharingScreen
            ? "show-bookmark"
            : ""
          : "hide-bookmark"
      } ${
        isSharingScreen === "initial-value"
          ? ""
          : isSharingScreen
          ? `sharing${data.length} show-sharing`
          : "hide-sharing"
      } twilio${data.length > 10 ? 10 : data.length}`}
    >
      <div
        id="videoContainer"
        // style={{maxWidth: "90vw", maxHeight: "90vh"}}
        // className={`grid-container ${
        //   length > 2 ? "more-two" : ""
        // } ${`video-${length}`} ${isSharingScreen ? "sharing" : ""}`}
      >
        <div className="conference">
            <div className="dish">
            {parti}
          </div>
        </div>
        {/* {chunks &&
          chunks.length > 0 &&
          numberofchunks &&
          length &&
          chunks.map((chunk, index) => {
            if (chunk.length !== numberofchunks) {
              const wrapperClass = `${
                isSharingScreen
                  ? "sharing box-wrapper-hide"
                  : `box-wrapper${
                      numberofchunks >= 2 ? `-${numberofchunks}` : ""
                    }`
              }`
              return (
                <div key={index} className={wrapperClass}>
                  {chunk.map((peserta, key) => {
                    return (
                      <div className="box" key={key}>
                        <div className="name-display mx-3 d-flex">
                          <h5 className="trim-name">{`Peserta ${
                            key + 1
                          } Peserta ${key + 1} Peserta ${key + 1}`}</h5>
                          <img
                            style={{ marginTop: 0 }}
                            src={muteIcon}
                            alt="mute"
                          />
                        </div>
                        <div className="participant-mute">
                          <div className="peserta-utils-container">
                            <img
                              className="utils-icon"
                              src={muteParticipantIcon}
                              alt="participant-mute"
                            />
                          </div>
                        </div>
                        {withImage ? (
                          <img src={previewVideo} alt="video" />
                        ) : (
                          <div className="no-photo d-flex justify-content-center align-items-center">
                            <h4 style={{textShadow: "0 1px 1px #000"}}>{key}</h4>
                          </div>
                        )}
                      </div>
                    )
                  })}
                </div>
              )
            } else {
              return chunk.map((peserta, key) => {
                return (
                  <div className="box" key={key}>
                    <div className="name-display mx-3 d-flex">
                      <h5 className="trim-name">{`Peserta ${key + 1} Peserta ${
                        key + 1
                      } Peserta ${key + 1}`}</h5>
                      <img style={{ marginTop: 0 }} src={muteIcon} alt="mute" />
                    </div>
                    <div className="participant-mute">
                      <div className="peserta-utils-container">
                        <img
                          className="utils-icon"
                          src={muteParticipantIcon}
                          alt="participant-mute"
                        />
                      </div>
                    </div>
                    {withImage ? (
                      <img src={previewVideo} alt="video" />
                    ) : (
                      <div className="no-photo d-flex justify-content-center align-items-center">
                        <h4 style={{textShadow: "0 1px 1px #000"}}>{key}</h4>
                      </div>
                    )}
                  </div>
                )
              })
            }
          })} */}
      </div>
    </div>
  )
}

export default VideoFrame
