import React, { useState } from 'react'

import iconCamera from "../../assets/icons/mobile/camera.svg"
import iconCameraOff from "../../assets/icons/mobile/camera_off.svg"
import iconMicOff from "../../assets/icons/mobile/mic_off.svg"
import iconMicOn from "../../assets/icons/mobile/mic_on.svg"
import iconShareScreen from "../../assets/icons/mobile/share_screen.svg"
import iconChat from "../../assets/icons/mobile/chat.svg"
import iconMoreMenu from "../../assets/icons/mobile/kebab_menu.svg"

function MobileFooter2(props) {
  const {
    showListParticipant,
    setShowListParticipant,
    showChat,
    setShowChat,
    showShareScreen,
    setShowShareScreen,
    isMicOn,
    onClickToggleMic,
    isCameraOn,
    onClickToggleCamera,
    isShowChatroom,
    setIsShowChatroom,
    isShowListParticipant,
    setIsShowListParticipants,
    isUnread
  } = props


  const toggleShareScreen = () => {
    const a = document.getElementById('mobile-content')
    a.scrollTo(0,0);
    setShowShareScreen(!showShareScreen)
  }
  return (
    <div className='mobile-footer'>
      <div className='mobile-footer-items'>
        <div className={`mobile-footer-item ${isCameraOn ? 'not-active' : 'active'}`} onClick={ onClickToggleCamera }>
          <img src={ isCameraOn ? iconCamera : iconCameraOff } />
        </div>
        <div className={`mobile-footer-item ${isMicOn ? 'not-active' : 'active'}`} onClick={ onClickToggleMic }>
          <img src={ isMicOn ? iconMicOn : iconMicOff } />
        </div>
        {/* <div className='mobile-footer-item not-active' onClick={() => toggleShareScreen()}>
          <img src={ iconShareScreen } />
        </div> */}
        <div 
          className='mobile-footer-item not-active'
          style={{ position: "relative" }} 
          onClick={() => setIsShowChatroom(!isShowChatroom)}
        >
          {isUnread && <div className="mobile-unread-chat"></div>}
          <img src={ iconChat } />
        </div>
        <div 
          className='mobile-footer-item not-active' 
          onClick={() => setIsShowListParticipants(!isShowListParticipant)}>
          <img src={ iconMoreMenu} />
        </div>
      </div>
    </div>
  )
}

export default MobileFooter2
