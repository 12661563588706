import { getData, removeData, updateData } from "../sevices/firebaseService"
import { formatStringToSecond } from "./counterFormatter"
import {
  getBookmarkDuration,
  getBookmarkTranscript,
  getIndexInWords,
  getTranscriptIdx
} from "./highlightFunctions"

const TIME_CONST = 1 * 1000 // miliseconds

let wordIndexes = {}
let avgTimes = []

/**
 * position & duration based on transcription
 * @param {object[]} arrBookmarks
 * @param {object[]} transcript with startTimestamp & endTimestamp
 * @param {number} initTimestamp
 */
export const bookmarkToHighlight = ({
  arrBookmarks,
  transcript,
  initTimestamp,
  path
}) => {
  let result = []
  // for each bookmark
  for (let i = 0; i < arrBookmarks.length; i++) {
    // clone bookmark to a variable
    let bookmark = { ...arrBookmarks[i] }

    const timestamp = formatStringToSecond(bookmark.timestamp.stringTimestamp)
    // if bookmark doesn't have duration, add duration
    if (!bookmark.duration) {
      //    - find transcriptIdx by bookmark.timestamp.unixTimestamp
      let { index: transcriptIdx, mid } = getTranscriptIdx({
        transcript,
        timestamp,
        avgTimes
      })

      if (transcriptIdx === -1) {
        let pathFU = `${path}/${bookmark.bookmarkId}`
        updateData({ path: pathFU, data: null })
        break
      }

      //    - get estimate timestamp
      const estimateStart = timestamp.start
      const estimateEnd = timestamp.end

      //    - find indexInWords
      const indexInWords = getIndexInWords({
        words: transcript[transcriptIdx].words,
        estimateStart,
        estimateEnd,
        transcriptIdx,
        transcript,
        wordIndexes
      })

      transcriptIdx = indexInWords.newTranscriptIdx
      //    - get duration
      const duration = getBookmarkDuration({
        words: transcript[transcriptIdx].words,
        wordIndexStart: indexInWords.startIndex,
        wordIndexEnd: indexInWords.endIndex,
        initTimestamp,
        transcript: transcript[transcriptIdx]
      })

      // update bookmark
      bookmark.transcriptIdx = transcriptIdx
      bookmark.indexInWords = indexInWords
      bookmark.duration = duration
    }

    // update transcript
    bookmark.transcript = getBookmarkTranscript({
      words: transcript[bookmark.transcriptIdx].words,
      wordIndexStart: bookmark.indexInWords.startIndex,
      wordIndexEnd: bookmark.indexInWords.endIndex
    })

    result = result.concat(bookmark)
  }

  // return updated bookmark
  return result
}
