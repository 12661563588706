import axios from 'axios'
import QueryString from 'qs'
import { variableSession } from '../constants/otherConstants'

export function getConfig ({ query, urlConfig }) {
    let result = {}
    // cek apakah ada data config yang disimpan di storage lokal
    let configFromParent = JSON.parse(sessionStorage.getItem(variableSession.configFromParent))
    // configFromParent = false
    if (configFromParent &&
      configFromParent.roomName) {
      result.data = configFromParent
      sessionStorage.removeItem("configFromParent")
    } else {
      const config = {
        method: 'post',
        url: urlConfig,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: QueryString.stringify({ query })
      }
      result = axios(config)
    }

    return result
}
