import React, { useState } from 'react'
import cameraToggle from "../../assets/icons/mobile/switch_camera.svg"
import soundOn from "../../assets/icons/mobile/sound_on.svg"
import soundMute from "../../assets/icons/mobile/sound_mute.svg"
import materiGroupIcon from "../../assets/icons/mobile/materi-group.svg" 

function MobileTopBar2({
  title,
  isMuteSite,
  isCameraOn,
  cameraValue,
  cameraOptions,
  onCameraChange,
  muteSite,
  setShowMateriGroup,
  label,
  materiFGD
}) {
  // const [ toggleSound, setToggleSound ] = useState(true)
  const onClickCamera = () => {
    if (cameraOptions && cameraOptions.length > 1){
      const camerasId = cameraOptions.filter(({ label, value }) => {
        console.log(value)
        console.log(label)
        return value !== cameraValue
      })
      try {
        onCameraChange(camerasId[0].value)
      } catch (error) {
        console.log("error bro" + error)
      }
    }
  }

  const onClickSound = () => {
    muteSite()
  }
  return (
    <div className='mobile-top-bar'>
      <div className='mobile-top-bar-title'>
        { title && title.toUpperCase() }
      </div>
      <div className='mobile-top-bar-icon'>
        {label !== "interview" && materiFGD &&
          <img 
            src={ materiGroupIcon } 
            onClick={ _=> setShowMateriGroup(true) }
            style={{ marginRight: "16px"}}
          />
        }
        <img 
          className='margin-right' 
          src={cameraToggle}
          onClick={ _=> onClickCamera() } 
        />
        {/* <img 
          src={ !isMuteSite ? soundOn : soundMute } 
          onClick={ _=> onClickSound() }
          style={{ height : '20px', width : '20px'}}
        /> */}
      </div>
    </div>
  )
}

export default MobileTopBar2
