import React from "react";
const greyHr = {
  margin : "2px 0",
  border: 0,
  borderTop: "1px solid #bbb"
}
export default function QuestionItem ({ question, kompetensi }) {
  return (
    <React.Fragment>
      <div className="d-flex question-item">
          <input type="checkbox" id="question1" />
          <label htmlFor="question1">
            {question}
            <hr style={greyHr}></hr>
            <div style={{ fontSize: "11px", color: "#005589" }}>{ kompetensi.join(", ") }</div>
          </label>
      </div>
    </React.Fragment>
  )
}
