import React from "react"
import PropTypes from "prop-types"

function SharedScreenWrapper({ show, isShowBookmark, presenter, content }) {
  return show !== "initial-value" && show ? (
    <div
      className={`shared-screen show-sharing`}
    >
      <p>{presenter} is presenting...</p>
      {content}
    </div>
  ) : (
    <div
      className={`shared-screen hide-sharing d-none ${
        isShowBookmark === "initial-value"
          ? ""
          : isShowBookmark === "show"
          ? "show-bookmark"
          : "hide-bookmark"
      }`}
    ></div>
  )
}

SharedScreenWrapper.propTypes = {
  // show: PropTypes.string.isRequired,
  // isShowBookmark: PropTypes.string.isRequired,
  presenter: PropTypes.string,
  content: PropTypes.element.isRequired
}

// SharedScreenWrapper.propTypes = {
//   show: PropTypes.oneOf(["initial-value", "show", "hide"]).isRequired,
//   isShowBookmark: PropTypes.oneOf(["initial-value", "show", "hide"]).isRequired,
//   presenter: PropTypes.string,
//   content: PropTypes.element.isRequired
// }

export default SharedScreenWrapper
