import SesEndsInfoWrapper from '../../components/sessionEnds/SesEndsInfoWrapper'

function SessionEndsInfo () {
  return (
    <div>
      <SesEndsInfoWrapper />
    </div>
  )
}

export default SessionEndsInfo
