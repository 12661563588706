const kompetensiRaw = {
  'Business Competencies': [
    {
      index: 4,
      kompetensi: 'Initiative'
    },
    {
      index: 7,
      kompetensi: 'Results Orientation'
    }
  ],
  'People Competencies': [
    {
      index: 0,
      kompetensi: 'Building Collaborative Relationships'
    }
  ],
  'Self-Competencies': [
    {
      index: 8,
      kompetensi: 'Self Confidence'
    }
  ]
}

const getKompetensi = (rawData) => {
  const kompetensi = []

  Object.values(rawData).forEach(v => {
    v.forEach(item => {
      kompetensi.push({ id: item.index, display: item.kompetensi })
    })
  })

  return kompetensi
}

export const listKompetensi = getKompetensi(kompetensiRaw)
