import { all, call, put, takeLatest } from "redux-saga/effects"
import { Types as RoomTypes, Creators as RoomActions } from "../redux/RoomRedux"

import { connect } from "twilio-video"

export function connectRoom({ token, options }) {
  return connect(token, { ...options, networkQuality: true })
}

function* connectRoomSaga(action) {
  try {
    const room = yield call(connectRoom, action.data)

    yield put(RoomActions.connectRoomSuccess(room))
  } catch (error) {
    yield put(RoomActions.connectRoomFailure(error))
  }
}

function* connectRoomRequestSaga() {
  yield takeLatest(RoomTypes.CONNECT_ROOM_REQUEST, connectRoomSaga)
}

export function* roomSaga() {
  yield all([call(connectRoomRequestSaga)])
}
