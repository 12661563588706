import closeIcon from "./close.png"
import muteIcon from "./mute.png"
import muteParticipantIcon from "./mic-large.png"
import deleteIcon from "./delete.png"
import chatIcon from "./chat.png"
import recordIcon from "./record.png"
import shareScreenIcon from "./share-screen.png"
import onMicIcon from "./mic.png"
import onCamIcon from "./opencam.png"
import endCallIcon from "./endcall.png"
import meatballIcon from "./meatball.svg"
import offcamIcon from "./offcam.png"
import offmicIcon from "./offmic.png"
import bookmarkIcon from "./bookmark-menu.png"
import noteIcon from "./note.png"
import bookmarkIconSidebar from "./bookmark-sidebar.png"
import personIcon from "./person.png"
import existingBookmarkIcon from "./bookmark-existing.png"
import bookmarkRedIcon from "./bookmark-sidebar-red.png"
import undoIcon from "./undo.png"
import redoIcon from "./redo.png"
import playIcon from "./play.png"
import tagICon from "./tag-white.png"
import bookmarkIconWhite from "./bookmark-white.png"
import personIconWhite from "./assessor-white.png"
import dictionaryIcon from "./dictionary.png"
import searchIcon from "./search-term.png"
import forwardIcon from "./forward.png"
import rewindIcon from "./rewind.png"
import smallPauseButton from "./pause.png"
import cursorIcon from "./play-blue.png"
import greyPersonIcon from "./speaker-grey.png"
import bookmarkBlueIcon from "./bookmark-blue.png"
import iconClose from "./mobile/icon-close.svg"
import iconSendChat from "./mobile/send-button-chat.svg"
import iconArrowDown from "./mobile/arrow_down.svg"
import iconArrowSide from "./mobile/arrow-side.svg"
import imagesShareScreen from "./mobile/share-screen-images.svg"
import iconExpandTopBlue from "./mobile/expand-top-blue.svg"
import iconExpandBottomBlue from "./mobile/expand-bottom-blue.svg"
import iconCamera from "./mobile/camera.svg"
import iconCameraOff from "./mobile/camera_off.svg"
import iconMicOff from "./mobile/mic_off.svg"
import iconMicOn from "./mobile/mic_on.svg"
import iconChat from "./mobile/chat.svg"
import iconMoreMenu from "./mobile/kebab_menu.svg"
import iconDefaultParticipant from "./mobile/icon-default-participant.svg"
import iconMuteParticipantList from "./mobile/icon-mute-participant-list.svg"
import tombolx from "./mobile/tombol-x.svg"
import attachmentIcon from "./mobile/attachment.svg"
import iconArrowTop from "./mobile/arrow_top.svg"
import cameraToggle from "./mobile/switch_camera.svg"
import materiGroupIcon from "./mobile/materi-group.svg"
import tagIcon from "./skill-tag.png"
import removeIcon from "./remove-skill.png"
import noteIconTimeline from "./note-timeline.png"
import speakerIcon from "./tes-speaker.png"
import camMenuIcon from "./camera-menu.png"
import micMenuIcon from "./mic-menu.png"
import listShortcutIcon from "./list-shortcut.png"
import bookmarkIconShortcut from "./bookmark-shortcut.png"
import chatroomIcon from "./chat-shortcut.png"
import micShortcutIcon from "./mic-shortcut.png"
import camShortcutIcon from "./cam-shortcut.png"
import webIcon from "./web.png"
import linkedinIcon from "./linkedin.png"
import instagramIcon from "./instagram.png"
import starIcon from "./star.png"
import emptyStar from "./star-empty.png"
import bookmarkActiveIcon from "./bookmark-active.png"
import pauseIcon from "./pause.png"


const ICONS = [
  closeIcon,
  muteIcon,
  muteParticipantIcon,
  deleteIcon,
  chatIcon,
  shareScreenIcon,
  recordIcon,
  onCamIcon,
  onMicIcon,
  endCallIcon,
  muteIcon,
  meatballIcon,
  offcamIcon,
  offmicIcon,
  bookmarkActiveIcon,
  bookmarkBlueIcon,
  bookmarkIcon,
  bookmarkIconShortcut,
  bookmarkIconSidebar,
  bookmarkIconWhite,
  bookmarkRedIcon,
  bookmarkRedIcon,
  iconArrowDown,
  iconArrowSide,
  iconArrowTop,
  iconCamera,
  iconCameraOff,
  iconChat,
  iconClose,
  iconDefaultParticipant,
  iconExpandBottomBlue,
  iconExpandTopBlue,
  iconMicOff,
  iconMicOn,
  iconMoreMenu,
  iconMuteParticipantList,
  iconSendChat,
  pauseIcon,
  emptyStar,
  starIcon,
  instagramIcon,
  linkedinIcon,
  webIcon,
  camShortcutIcon,
  micShortcutIcon,
  chatroomIcon,
  listShortcutIcon,
  micMenuIcon,
  camMenuIcon,
  speakerIcon,
  noteIconTimeline,
  removeIcon,
  tagIcon,
  materiGroupIcon,
  cameraToggle,
  attachmentIcon,
  tombolx,
  imagesShareScreen,
  greyPersonIcon,
  cursorIcon,
  smallPauseButton,
  rewindIcon,
  forwardIcon,
  searchIcon,
  dictionaryIcon,
  personIcon,
  personIconWhite,
  tagICon,
  playIcon,
  undoIcon,
  redoIcon,
  existingBookmarkIcon,
  noteIcon
]

export default ICONS