import React, { useState } from "react"
import MoreMenus from "../popups/MoreMenus"
import Chatroom from "../popups/Chatroom"
import VideoFrame from "./VideoFrame"
import AssessmentMenu from "./AssessmentMenu"

function ParticipantRoomContainer() {
  const [isShowChatroom, setIsShowChatroom] = useState(false)
  const [isOnMic, setIsOnMic] = useState(true)
  const [isOnCam, setisOnCam] = useState(true)
  const [isSharingScreen, setIsSharingScreen] = useState(false)
  const [isShowMoreMenu, setIsShowMoreMenu] = useState(false)

  const dummyData = [1, 2, 3]

  return (
    <div>
      <MoreMenus
        show={isShowMoreMenu}
        onHide={() => setIsShowMoreMenu(false)}
        type="participant"
      />
      <Chatroom show={isShowChatroom} onHide={() => setIsShowChatroom(false)} />
      <div className="room-wrapper d-flex justify-content-end align-items-center">
        <AssessmentMenu
          type="participant"
          isShowChatroom={isShowChatroom}
          setIsShowChatroom={setIsShowChatroom}
          isOnMic={isOnMic}
          setIsOnMic={setIsOnMic}
          isOnCam={isOnCam}
          setisOnCam={setisOnCam}
          isSharingScreen={isSharingScreen}
          setIsSharingScreen={setIsSharingScreen}
          isShowMoreMenu={isShowMoreMenu}
          setIsShowMoreMenu={setIsShowMoreMenu}
        />
        <VideoFrame data={dummyData} />
      </div>
    </div>
  )
}

export default ParticipantRoomContainer
