import { createActions, createReducer } from "reduxsauce"

/** --------------- Types and Action Creators --------------- */
export const { Types, Creators } = createActions({
  getConfigRequest: ["data"],
  getConfigSuccess: ["payload"],
  getConfigFailure: ["error"],
  setLoadingTrue: null,

  resetConfig: null
})

/** -------------------- Initial State ------------------- */
export const INITIAL_STATE = {
  isLoading: false,
  isError: false,
  error: null,
  configData: null
}

/** -------------------- Reducers ------------------------ */
export const getConfigRequest = (state) => ({
  ...state,
  isLoading: true,
  isError: false,
  error: null,
  configData: null
})

export const getConfigSuccess = (state, action) => ({
  ...state,
  isLoading: false,
  configData: action.payload.data
})

export const getConfigFailure = (state, action) => ({
  ...state,
  isLoading: false,
  isError: true,
  error: action.error
})

export const setLoadingTrue = (state, action) => ({
  ...state,
  isLoading: true
})

export const resetConfig = () => INITIAL_STATE

/** ----------- Hookup Reducers to Type ------------- */
export const configReducer = createReducer(INITIAL_STATE, {
  [Types.GET_CONFIG_REQUEST]: getConfigRequest,
  [Types.GET_CONFIG_SUCCESS]: getConfigSuccess,
  [Types.GET_CONFIG_FAILURE]: getConfigFailure,
  [Types.SET_LOADING_TRUE]: setLoadingTrue,
  [Types.RESET_CONFIG]: resetConfig
})
