import PropTypes from "prop-types"
import { useEffect, useRef, useState } from "react"
import playIcon from "../../assets/icons/play-blue.png"
import pauseIcon from "../../assets/icons/pause.png"
import greyPersonIcon from "../../assets/icons/speaker-grey.png"
import Word from "../../components/Transcription/Word"
import { onHighlightAction } from "../../lib/onHighlightAction"
import usePopupHighlight from "../../hooks/usePopupHighlight"

function SpeakerTranscript({
  name,
  words,
  confidence,
  duration,
  timestampPlayed,
  transcriptIdx,
  onSelect,
  isShowAddBookmark,
  resultIndex,
  onClickWord,
  onWordUpdated,
  isEditing,
  setIsEditing,
  isPlaying,
  onClickPlay,
  onClickPause,
  bookmarks,
  email,
  highlightedUser,
  focusMode,
  confidenceMode,
  addHighlightData,
  index
}) {
  const ref = useRef()
  const [transcriptWords, setTranscriptWords] = useState(words)

  useEffect(() => {
    if (!isShowAddBookmark) {
      setTranscriptWords(words)
    }
  }, [isShowAddBookmark, words])

  const saveUserAnnotation = (result) => {
    if (result) {
      const startWordIndex =
        result.startContainer.match(/(?<=span\[).+?(?=\])/g)[0]
      const endWordIndex = result.endContainer.match(/(?<=span\[).+?(?=\])/g)[0]

      const data = {
        transcriptIdx,
        selection: result,
        transcript: result.text.replace(/(\n)/g, ""),
        indexInWords: {
          startIndex: startWordIndex - 1,
          endIndex: endWordIndex - 1
        },
        duration: {
          startTimestamp: words[startWordIndex - 1].startTimestamp,
          endTimestamp: words[endWordIndex - 1].endTimestamp,
          startTime: words[startWordIndex - 1].startTime,
          endTime: words[endWordIndex - 1].endTime
        }
      }

      // highlight words
      setTranscriptWords((c) => {
        return c.map((v, i) => ({
          ...v,
          tempHighlighted:
            i >= data.indexInWords.startIndex &&
            i <= data.indexInWords.endIndex &&
            (email === highlightedUser || email === "dynamic@email.com")
        }))
      })
      // console.log('selection: ', data)

      onSelect && onSelect(data)
    }
  }

  const isBookmark = (index) => {
    let isBookmarked = true
    if (bookmarks && bookmarks.length > 0) {
      for (let idx = 0; idx < bookmarks.length; idx++) {
        const bookmark = bookmarks[idx]
        if (
          index >= bookmark.indexInWords.startIndex &&
          index <= bookmark.indexInWords.endIndex
        ) {
          if (
            bookmark.content.kompetensi &&
            bookmark.content.kompetensi.length > 0
          ) {
            for (
              let idx2 = 0;
              idx2 < bookmark.content.kompetensi.length;
              idx2++
            ) {
              const kompetensi = bookmark.content.kompetensi[idx2]
              if (kompetensi.split("-").length > 1) {
                isBookmarked = false
                break
              }
            }
          }
          if (isBookmarked === false) break
        }
      }
    }
    return isBookmarked
  }

  const onMouseUp = () => {
    console.log({isEditing})
    if (!isEditing) {
      const el = ref.current
      onHighlightAction({
        colorName: "default", // TODO
        highlightable:
          email === highlightedUser || email === "dynamic@email.com",
        decision: { id: "" }, // TODO
        setShowToolTip: () => {}, // TODO
        userAnnotations: { saveUserAnnotation },
        el
      })
    }
  }

  const checkConfindence = () => {
    if (confidence > 0.8) return "darkgreen"
    if (confidence >= 0.6 && confidence <= 0.8) return "blue"
    if (confidence < 0.6) return "red"
  }

  return (
    <div
      className="speaker-transcript"
      id={`transcript${duration.start}`}
      style={{
        opacity:
          email === highlightedUser || email === "dynamic@email.com"
            ? 1
            : focusMode
            ? 0.2
            : 1
      }}
    >
      <div className="transcript-header d-flex">
        {!isPlaying ? (
          <img
            className="cursor"
            src={playIcon}
            alt="curson"
            onClick={onClickPlay}
          />
        ) : (
          <img
            className="cursor"
            src={pauseIcon}
            alt="curson"
            onClick={onClickPause}
          />
        )}
        <img className="person" src={greyPersonIcon} alt="person" />
        <p>{name}:</p>
      </div>
      <div
        ref={ref}
        onMouseUp={onMouseUp}
        className="transcript-content d-flex"
      >
        {transcriptWords.map((v, i) => {
          const isPlayed =
            (timestampPlayed >= v.startTimestamp &&
              timestampPlayed < v.endTimestamp) ||
            v.searchIdx > -1

          const isBookmarked = isBookmark(i)

          return (
            <Word
              key={i}
              {...v}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              isBookmarked={isBookmarked}
              indexWord={i}
              addHighlightData={
                (addHighlightData &&
                  addHighlightData.transcriptIdx === transcriptIdx &&
                  addHighlightData) ||
                undefined
              }
              indexTranscript={transcriptIdx}
              resultIndex={resultIndex}
              isPlayed={isPlayed}
              onClickWord={() => onClickWord(v)}
              onWordUpdated={onWordUpdated}
              confidenceMode={confidenceMode}
              color={checkConfindence()}
              confidence={confidence}
            />
          )
        })}
      </div>
    </div>
  )
}

SpeakerTranscript.propTypes = {
  name: PropTypes.string.isRequired,
  content: PropTypes.any
}

export default SpeakerTranscript
