import React, { createRef, useEffect, useState } from "react"
import { useHistory, useLocation } from "react-router"
import { rawDataToQuery } from "../lib/queryConverter"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"
import { Card } from "@material-ui/core"

// const CONFIG_URL =
//   "https://asia-east2-fourth-cirrus-264510.cloudfunctions.net/twilio/get-config"
const CONFIG_URL =
  "https://asia-east2-vania-demo-gti.cloudfunctions.net/twilio/get-config"

// const CONFIG_URL =
//   "https://asia-southeast1-vania-master.cloudfunctions.net/twilio/get-config"

const CONFIG_HIGHLIGHT_URL =
  "https://asia-southeast1-vania-master.cloudfunctions.net/twilio/staging/get-highlighter-config"
/**
 * temporary component for testing
 */
export default function Lobby() {
  const history = useHistory()
  let passwordInput = createRef()
  const [password, setPassword] = useState("")
  const [isLogin, setIsLogin] = useState(false)

  const handleClick = (rawData, mobile = false) => {
    const query = rawDataToQuery(rawData)
    // const query =
    //   "eyJaIjoiWVpxQGRtYVZpdC5oWiIsImsiOiJkaVdWbmZabyIsIlYiOm9tcFosIm0iOiJ4eHcxeDIzMjB3MXZ6MzEiLCJkIjoiZGlvWm1xZFpyIiwiVyI6IktPS18zeTIiLCJqIjoieHh3MXgyMzIwdzF2ejMxIn0=="
    if ( mobile ) {
      history.push(`/mobile-room-2?query=${query}&url_config=${CONFIG_URL}`)
    } else {
      history.push(`/room?query=${query}&url_config=${CONFIG_URL}`)
    }
  }

  useEffect(() => {
    const lg = sessionStorage.getItem("lg")
    if (lg) {
      setIsLogin(true)
    } else {
      setIsLogin(false)
    }
  }, [])

  const handleChange = (e) => {
    const { value } = e.target
    setPassword(value)
  }

  const handleSubmit = () => {
    if (password && password.includes("!inbasket")) {
      setIsLogin(true)
      sessionStorage.setItem("lg", true)
    }
  }

  const handleClickHighlight = (rawData) => {
    let query = rawDataToQuery(rawData)
    query =
      "eyJSIjoiS1BEQ1VNR1YiLCJDIjoiVlRXRyIsIkQiOiIxcHVfZ2NsIiwiVCI6ImVlZGllbGpjZGtnY2NpaSIsIlEiOiJlZWRpZWxqY2RrZ2NjaWkiLCJLIjoiS1BWR1RYS0dZIiwiVDRLRiI6IjN5Q2xlSERDa0VmZUREaWNsSGtqY2lqSGxoQ0VsR2VoZWUiLCJHIjoiRkdYQEtUSENQYS5PRyIsIkpXIjoiVVZDSUtQSS1VQ1RDSl9SQ1ZWUVBAVllLTktRVkdVVi5FUU8iLCJOIjoiS1BWR1RYS0dZIn0="
    history.push(`/highlight?query=${query}&url_config=${CONFIG_HIGHLIGHT_URL}`)
  }

  const CoreLobby = () => {
    return (
      <div className="d-flex lobby-wrapper">
        <button onClick={() => handleClick(rawDataAdmin1)}>
          ADMIN untuk INTERVIEW DENGAN SKYSHI 01
        </button>
        {/* <br />
        <button onClick={() => handleClick(rawDataAdmin2)}>
          ADMIN untuk INTERVIEW DENGAN SKYSHI 02
        </button>
        <br /> */}
        <br />
        <button onClick={() => handleClick(rawDataPeserta1)}>
          SKYSHI 01 untuk INTERVIEW
        </button>

        <button onClick={() => handleClick(rawDataPeserta1, true)}>
          SKYSHI 01 untuk INTERVIEW Mobile
        </button>
        {/* <br />
        <button onClick={() => handleClick(rawDataPeserta2)}>
          SKYSHI 02 untuk INTERVIEW
        </button>
        <br />
        <br />
        <button onClick={() => handleClick(rawDataAdminFGD)}>
          ADMIN untuk FGD dengan SKYSHI 02 DAN SKYSHI 01
        </button>
        <br />
        <button onClick={() => handleClick(rawDataAdminFGD2)}>
          ADMIN 2 untuk FGD dengan SKYSHI 02 DAN SKYSHI 01
        </button>
        <br />
        <button onClick={() => handleClick(FGDPeserta01)}>FGD SKYSHI 01</button>
        <br />
        <button onClick={() => handleClick(FGDPeserta02)}>FGD SKYSHI 02</button>
        <br /> */}
        <br />
        <button onClick={() => handleClickHighlight(rawDataHighlight2)}>
          HIGHLIGHT
        </button>
        <br />
        <br />
        <div style={{ margin: "auto" }}>
          Slicing:
          <button onClick={() => history.push("/assessor-room")}>
            Assessor room
          </button>
          <button onClick={() => history.push("/mobile-room")}>
            Mobile room
          </button>
          {/* <button onClick={() => history.push('/room-slicing')}>
    Participant room
  </button> */}
          {/* <button onClick={() => history.push("/highlight-slicing")}>
            Highlight
          </button> */}
        </div>
      </div>
    )
  }

  return (
    <div>
      {isLogin ? (
        <CoreLobby />
      ) : (
        <div
          className="d-flex"
          style={{
            position: "relative",
            width: "100vw",
            height: "100vh",
            textAlign: "center",
            background: "aliceblue"
          }}
        >
          <div
            className="main-pad default-shadow"
            style={{
              zIndex: 1000,
              margin: "auto",
              width: 400,
              borderRadius: 6,
              background: "white"
            }}
          >
            <div
              className="main-pad"
              style={{
                background: "aliceblue",
                color: "#3f51b5",
                fontWeight: "bold",
                borderRadius: "4"
              }}
            >
              VANIA FRAME <sup>1.31</sup>
            </div>
            <form onSubmit={handleSubmit}>
              <TextField
                style={{ width: "100%", marginTop: 20 }}
                inputProps={{ style: {
                    textAlign: "center",
                    padding: 20
                  }
                }}
                key="password-input"
                type="password"
                name="password"
                placeholder="Password"
                variant="standard"
                value={password}
                onChange={handleChange}
              />
              <Button
                style={{ margin: "auto", marginTop: 20 }}
                color="primary"
                variant="contained"
                onClick={handleSubmit}
              >
                Masuk
              </Button>
            </form>
          </div>
        </div>
      )}
    </div>
  )
}

const rawDataAdmin1 = {
  p: "inbasket",
  a: true,
  r: "221634530084039",
  i: "interview",
  e: "dev@irfany.me",
  b: "PSI_584"
}

const rawDataAdmin2 = {
  p: "inbasket",
  a: true,
  r: 221611112550235,
  i: "interview",
  e: "recruiter@email.com"
}

const rawDataPeserta1 = {
  p: "mK4FJC",
  a: false,
  r: 221634530084039,
  i: "interview",
  e: "sarah_patton@twiliotest.com",
  b: "PSI_584"
}

const rawDataPeserta2 = {
  p: "Gvcs48UD",
  a: false,
  r: 221611112550235,
  i: "interview",
  e: "participant02@skyshi.com"
}

const rawDataAdminFGD = {
  p: "inbasket",
  a: true,
  r: "SKYSHI_996",
  i: "fgd",
  e: "recruiter@email.com"
}

const rawDataAdminFGD2 = {
  p: "inbasket",
  a: true,
  r: "SKYSHI_996",
  i: "fgd",
  e: "dev@irfany.me"
}

const FGDPeserta01 = {
  p: "8w3t5fA4",
  a: false,
  r: "SKYSHI_996",
  i: "fgd",
  e: "participant01@skyshi.com"
}

const FGDPeserta02 = {
  p: "Gvcs48UD",
  a: false,
  r: "SKYSHI_996",
  i: "fgd",
  e: "participant02@skyshi.com"
}

const rawDataHighlight2 = {
  p: "inbasket",
  a: true,
  r: 181606382915562,
  i: "interview",
  rSid: "RMa37cd3a958d92c40e533bb78d9e5b9de",
  e: "recruiter@email.com"
}
