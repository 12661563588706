import { variableSession } from "../constants/otherConstants"

/**
 *
 * @param {object} rawData config.kompetensi dari get-config
 * @returns array of {id, display}
 */
export const getKompetensi = (rawData) => {
  const kompetensi = []

  rawData &&
    Object.values(rawData).forEach((v) => {
      for (let i = 0; i < v.length; i++) {
        for (let j = 0; j < v[i].perilaku.length; j++) {
          kompetensi.push({
            id: v[i].index,
            display: `${v[i].index + 1}.${j + 1}. ${v[i].perilaku[j]} - ${
              v[i].kompetensi
            }`,
            number: `${v[i].index + 1}.${j + 1}.`
          })
        }
      }
    })
  return kompetensi
}

export const getKompetensiRoom = (rawData) => {
  const kompetensi = []
  let input = rawData
  const configFromParent = JSON.parse(sessionStorage.getItem(variableSession.configFromParent))
  if ((!input || !Object.keys(input).length) && configFromParent.accessor){
    console.warn(`kompetensi (running): ${JSON.stringify(input)}`)
    console.log("Get kompetensi dari localStorage")
    input = configFromParent && configFromParent.kompetensi
  }

  input &&
    Object.values(input).forEach((v) => {
      v.forEach((item) => {
        kompetensi.push({ id: item.index, display: item.kompetensi })
      })
    })

  return kompetensi
}

export const getKompetensiDictionary = (data) => {
  const arrCompetencies = []
  const entries = Object.entries(data)
  const alphabets = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("")
  for (let i = 0; i < entries.length; i++) {
    arrCompetencies.push({
      display: `${alphabets[i]}. ${entries[i][0]}`,
      type: "cluster"
    })
    for (let j = 0; j < entries[i][1].length; j++) {
      arrCompetencies.push({
        display: `${j + 1}. ${entries[i][1][j].kompetensi}`,
        type: "competency",
        description: entries[i][1][j].deskripsi[0],
        behaviors: entries[i][1][j].perilaku
      })
    }
  }
  return arrCompetencies
}

/**
 * @param {string[]} kompetensiPeserta
 * @param {object} listKompetensi eg. https://gist.github.com/nlatifahulfah/90f59aebdead02ca22a441ed92f7436a#file-new-highlight-response-2-json-L9924
 * @returns eg. [1.1., 1.2.]
 */
export const getIndexKompetensi = ({ listKompetensi, kompetensiPeserta }) => {
  let filterKompetensi = []

  if (kompetensiPeserta) {
    filterKompetensi = Object.entries(listKompetensi).map((d, i) => {
      return d[1]
        .map((item, j) => ({ ...item, listIndex: `${i + 1}.${j + 1}.` }))
        .filter((item) => kompetensiPeserta.includes(item.kompetensi))
        .map((item) => item.listIndex)
    })
  }

  let result = []
  for (let i = 0; i < filterKompetensi.length; i++) {
    result = result.concat(filterKompetensi[i])
  }

  return result
}

/**
 *
 * @param {object[]} rawData list remote participant (twilio)
 * @returns array of {id, display}
 */
export const getListPeserta = (rawData) => {
  return rawData
    .map((p) => JSON.parse(p.identity))
    .filter((p) => p.role === "peserta")
    .map((p) => ({ id: p.email, display: p.name }))
}

/**
 * @param {object} rawTranscriptionData eg. https://gist.github.com/nlatifahulfah/90f59aebdead02ca22a441ed92f7436a#file-new-highlight-response-2-json-L9886
 * @returns [id: 'email', display: 'name]
 */
export const rawTranscriptToPeserta = (rawTranscriptionData) => {
  const arr = Object.values(rawTranscriptionData || {}).map((v) => Object.values(v))

  let result = []
  for (let i = 0; i < arr.length; i++) {
    if (arr[i][0].role === "peserta") {
      result = result.concat(arr[i])
    }
  }

  return result.map((p) => ({ id: p.email, display: p.name }))
}

export const bookmarkTimeline = ({ rawData, duration, highlightData }) => {
  const arrCompetencies = getKompetensi(rawData.filteredCompetencies)
  const timeline = highlightData.map((data) => {
    const time = data.duration.stringTimestamp
    return {
      video: data.videoClip,
      start:
        ((parseInt(time.slice(0, 2)) * 3600 +
          parseInt(time.slice(3, 5)) * 60 +
          parseInt(time.slice(6, 8))) /
          duration) *
        100,
      end:
        ((parseInt(time.slice(11, 13)) * 3600 +
          parseInt(time.slice(14, 16)) * 60 +
          parseInt(time.slice(17, 19))) /
          duration) *
        100,
      note: data.content.note ? decodeURI(data.content.note) : "",
      name: data.name,
      stringTimestamp: data.duration.stringTimestamp,
      skills: data.content.kompetensi
        ? data.content.kompetensi.map(
            (kompetensi) =>
              arrCompetencies.find((item) => item.display === kompetensi)
                ?.number
          )
        : "",
      fromHighlight: data.fromHighlight
    }
  })
  return timeline
}

/**
 * get mentioned kompetensi
 * @param {object[]} mentions react-mentions
 * @returns eg. ['kompetensi1', 'kompetensi2']
 */
export const mentionsToKompetensi = (mentions) =>
  mentions
    .filter(({ id }) => !isNaN(id))
    .map(({ display }) => display?.slice(1))
