import { useEffect, useState } from "react"
import { useSelector } from "react-redux"

export default function useLastSaved() {
  const { timestamp } = useSelector((state) => state.lastSaved)
  const [lastSavedString, setLastSavedString] = useState("")
  const [connectionStatus, setConnectionStatus] = useState(true)

  useEffect(() => {
    // under x minutes
    if (timestamp && (Date.now() - timestamp) / 60000 < 60) {
      const tickMinutes = () => {
        const minutes = Math.floor((Date.now() - timestamp) / 60000)
        const str = `terakhir disimpan ${
          minutes < 1 ? `kurang dari 1` : minutes
        } menit yang lalu`
        setLastSavedString(str)
      }

      tickMinutes()
      const interval = setInterval(() => tickMinutes(), 60000)

      return () => clearInterval(interval)
    } else if (timestamp) {
      const date = new Date(timestamp)
      const formatted = new Intl.DateTimeFormat("id", {
        dateStyle: "full",
        timeStyle: "long"
      }).format(date)
      const str = `terakhir disimpan ${formatted}`
      setLastSavedString(str)
    }
  }, [timestamp])

  useEffect(() => {
    setInterval(() => {
      window.navigator.onLine
        ? setConnectionStatus(true)
        : setConnectionStatus(false)
    }, 3000)
  }, [])

  return { lastSavedString, connectionStatus }
}
