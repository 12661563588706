import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Creators as RoomActions } from "../redux/RoomRedux"
import { Creators as SnackActions } from "../redux/SnackbarRedux"
import connected from "../assets/sound/Connected.mp3"
import disconnect from "../assets/sound/disconnect.mp3"
import firebase from "firebase/app"
import { variableSession } from "../constants/otherConstants"

/**
 * handle participant events
 *
 * References:
 * https://www.twilio.com/docs/video/javascript-getting-started#connect-to-a-room
 * https://www.twilio.com/docs/video/javascript-getting-started#handle-connected-participants
 */
export default function useParticipant() {
  const [ipData, setIpData] = useState(null)

  const checkIp = async () => {
    const dataFinal = {}
    const jsonIp = async (url) => {
      return await fetch(url).then((res) => res.json())
    }

    let apiKey = '500542189d47d23ad7e8740b389d2029de2432c19d16cf3c7d03e20c'
    const dataRaw = await jsonIp(`https://api.ipdata.co?api-key=${apiKey}`)
      .then((data) => data)
      .catch((error) => {
        console.error(error)
        return null
      })

    if (!dataRaw || !dataRaw?.ip) return null

    dataFinal.provider = dataRaw?.asn?.name || 'Tidak Dapat Diakses'
    dataFinal.city = dataRaw?.city || 'Tidak Dapat Diakses'
    dataFinal.region = dataRaw?.region || 'Tidak Dapat Diakses'
    dataFinal.ip = dataRaw?.ip || 'Tidak Dapat Diakses'

    return dataFinal
  }

  useEffect(() => {
    const fetchData = async () => {
      const result = await checkIp()
      if (result) {
        setIpData(result)
      }
    }

    fetchData()
  }, [])

  const dispatch = useDispatch()
  const { room, participants: remoteParticipants } = useSelector(
    (state) => state.room
  )

  const [localParticipantConnectionLevel, setLocalParticipantConnectionLevel] =
    useState(1)
  const localParticipant = room && room.localParticipant
  const config = useSelector((state) => state.config)
  const playAudio = (playedAudio) => {
    const audio = new Audio(playedAudio)
    audio.play()
  }

  useEffect(() => {
    if (room) {
      /**
       * When Participants connect to or disconnect from a Room that you're connected to,
       * you'll be notified via Participant connection events
       */
      const handleAlreadyConnected = (participant) =>
        dispatch(RoomActions.addParticipant(participant))

      const handleNewConnected = (participant) => {
        playAudio(connected)
        dispatch(RoomActions.addParticipant(participant))
        dispatch(
          SnackActions.newSnack(
            `${
              JSON.parse(participant.identity).name
            } bergabung ke dalam ruangan`
          )
        )
      }

      const handleParticipantDisconnected = (participant) => {
        playAudio(disconnect)
        dispatch(RoomActions.removeParticipant(participant))
        dispatch(
          SnackActions.newSnack(
            `${JSON.parse(participant.identity).name} meninggalkan ruangan`
          )
        )
      }

      const printNetworkQualityStats = (
        networkQualityLevel,
        networkQualityStats
      ) => {
        // Print in console the networkQualityLevel using bars
        // console.log(
        //   {
        //     1: "▃",
        //     2: "▃▄",
        //     3: "▃▄▅",
        //     4: "▃▄▅▆",
        //     5: "▃▄▅▆▇"
        //   }[networkQualityLevel] || ""
        // )

        setLocalParticipantConnectionLevel(networkQualityLevel)
        const database = firebase.database()
        let {
          data_peserta,
          batchId
        } = config.configData
        let batchPath = batchId ? batchId : ""
        // PATH PROJECTS
        if (batchPath !== "" && !/^projects\//.test(batchPath)) {
          batchPath = `projects/${batchPath}`
        }

        const dateNow = Date.now()
        let newPing = {
          ms: networkQualityLevel,
          timestamp: dateNow, 
          url: "twilio"
        } 

        if (data_peserta.role !== "admin") {
          const existingPingDataString = localStorage.getItem(variableSession.ping)
          const existingPingData = existingPingDataString ? JSON.parse(existingPingDataString) : {}
          existingPingData[newPing.timestamp] = newPing
          const updatedPingDataString = JSON.stringify(existingPingData)
          localStorage.setItem(variableSession.ping, updatedPingDataString)
      
          const lengthPingData = Object.keys(existingPingData).length

          let pingPath = `${batchPath}/raw_input/${data_peserta.uid}/in_basket/env/${ipData.ip.replace(/\./g, '')}/ping`

          if (lengthPingData === 20) {
            database.ref(pingPath).update(existingPingData)
            localStorage.removeItem(variableSession.ping)
          }
        }

        if (networkQualityStats) {
          // Print in console the networkQualityStats, which is non-null only if Network Quality
          // verbosity is 2 (moderate) or greater
          // console.log(networkQualityStats)
        }
      }

      if (localParticipant) {
        localParticipant.on(
          "networkQualityLevelChanged",
          printNetworkQualityStats
        )
      }

      // Log any Participants already connected to the Room
      room.participants.forEach(handleAlreadyConnected)
      // new participant connected
      room.on("participantConnected", handleNewConnected)

      room.on("participantDisconnected", handleParticipantDisconnected)

      return () => {
        room.off("participantConnected", handleNewConnected)
        room.off("participantDisconnected", handleParticipantDisconnected)
      }
    }
  }, [dispatch, room])

  return {
    localParticipant,
    remoteParticipants,
    localParticipantConnectionLevel
  }
}
