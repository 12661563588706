import { BrowserRouter } from 'react-router-dom'
import { variableSession } from './constants/otherConstants'
import Routes from './Routes'

function App () {
  // localStorage.removeItem(variableSession.configFromParent)
  return (
    <div className="App">
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </div>
  )
}

export default App
