import React, { useState } from 'react'
import LobbyHeader from '../LobbyHeader'
import WaitingRoomContent from './WaitingRoomContent'
import WaitingRoomControl from './WaitingRoomControl'
import useLocalVideo from '../../hooks/useLocalVideo'
import useLocalAudio from '../../hooks/useLocalAudio'

function WaitingRoomWrapper () {
  const {
    videoInputList,
    videoInputDeviceId,
    updateVideoInput
  } = useLocalVideo()

  const {
    audioInputList,
    audioInputDeviceId,
    updateAudioInput
  } = useLocalAudio()

  return (
    <div className='lobby-wrapper'>
      <LobbyHeader />
      <WaitingRoomContent
      isCameraOn={true} />
      <WaitingRoomControl
        cameraValue={videoInputDeviceId}
        cameraOptions={videoInputList}
        onCameraChange={e => {
          updateVideoInput(e.target.value)
        }}
        microphone={audioInputDeviceId}
        microphoneOptions={audioInputList}
        onMicrophoneChange={e => {
          updateAudioInput(e.target.value)
        }}
      />
    </div>
  )
}

export default WaitingRoomWrapper
