import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import rootSaga from '../sagas'
import { rootReducer } from './index'

// const composerEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export default function configureStore () {
  const sagaMiddleware = createSagaMiddleware()
  const store = createStore(rootReducer, applyMiddleware(sagaMiddleware))
  // const store = createStore(rootReducer, composerEnhancer(applyMiddleware(sagaMiddleware)))
  sagaMiddleware.run(rootSaga)

  return store
}
