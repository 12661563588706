import { useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import firebase from "firebase/app"

import { Creators as RoomActions } from "../redux/RoomRedux"
import { Creators as ConfigActions } from "../redux/ConfigRedux"
import { Creators as AuthActions } from "../redux/AuthRedux"
import { updateData } from "../sevices/firebaseService"
import useRoomService from "./roomService"

import { signInAsynchronus } from "../sevices/authService"

import {
  derotateUserPassword
} from "../lib/queryConverter"
import { variableSession } from "../constants/otherConstants"

/**
 * Disconnect from Room
 *
 * References:
 * https://www.twilio.com/docs/video/javascript-getting-started#disconnect-from-a-room
 */
export default function useRoomDisconnect() {
  const dispatch = useDispatch()
  const { configData: config } = useSelector((state) => state.config)
  const { room } = useSelector((state) => state.room)

  const { updateRoomLog, updateLogPeserta } = useRoomService()

  const updateDataInteraksi = async (path, config) => {
    const checkUser = firebase.auth().currentUser;
    // const checkUser = false

    if(!checkUser){
      console.log("session expired")
      const relogin = await signInAsynchronus(config.data_peserta.email, derotateUserPassword(config.data_peserta.p))
      if(relogin){
        console.log("sukses login ulang")
        console.log(relogin)
      }else{
        console.log("gagal relogin")
        return false
      }
    }

    if (config?.type === "interview") {
        try {
          await updateData({ path: `${path}/active`, data: false })
        } catch (error) {
          return false
        }
    } else if (config?.type === "fgd") {
      try {
        await updateData({
          path: `${path}/${config.data_peserta.uid}`,
          data: null
        })
      } catch (error) {
        return false
      }
    }

    return true

  }

  // clean up
  const disconnect = useCallback(async () => {
    console.log("masuk disconnect")
    // console.log(config)
    if (room && room.state === "connected") {
      const specialReload = localStorage.getItem(variableSession.specialReload)
      console.log("masuk kondisi room")
      let updateData = true
      if (!specialReload) {
        console.log("masuk spesial reload")
        if (config?.accessor) {
          console.log("masuk config assesor")
          const path = config?.peserta_path_listen
          updateData = await updateDataInteraksi(path, config)
        } else {
          updateLogPeserta({
            succes: true,
            message: `Meninggalkan ruangan`
          })
        }
      }
      // console.log("room disconnect")
      localStorage.removeItem(variableSession.specialReload)
      sessionStorage.removeItem(variableSession.configFromParent)
      updateRoomLog({ succes: true, message: "Meninggalkan ruangan" })

      if(updateData){
        console.log("Disconnect")
        room.disconnect()
      }
    }
  }, [room, config])

  useEffect(() => {
    if (room) {
      room.once("disconnected", () => {
        console.log("Disconnected")
        // disconnect()
        window.removeEventListener("beforeunload", disconnect)
        window.removeEventListener("pagehide", disconnect)
        dispatch(RoomActions.disconnectRoom())
        dispatch(ConfigActions.resetConfig())
        dispatch(AuthActions.logoutRequest())
      })

      window.addEventListener("beforeunload", disconnect)
      window.addEventListener("pagehide", disconnect)

      return () => {
        window.removeEventListener("beforeunload", disconnect)
        window.removeEventListener("pagehide", disconnect)
      }
    }
  }, [disconnect, room, dispatch])

  return { disconnect }
}
