import { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import firebase from "firebase/app"
import {
  prepareAddHighlightData,
  prepareHighlightData,
  prepareUpdateHighlightData
} from "../lib/highlightFunctions"
import {
  addNewKey,
  getData,
  removeData,
  updateData,
  updateMultipleData
} from "../sevices/firebaseService"

import { Creators as LastSavedActions } from "../redux/LastSavedRedux"
import { ContactlessOutlined } from "@material-ui/icons"

export default function useHighlight({ transcriptData }) {
  const dispatch = useDispatch()
  const { configData: configHighlight } = useSelector((state) => state.config)
  const { isLoggedIn } = useSelector((state) => state.auth)

  const [highlightData, setHighlightData] = useState([])
  const [firstLoad, setFirstLoad] = useState(true)

  /**
   * listen to highlightData edit
   */
  useEffect(() => {
    if (configHighlight && isLoggedIn && transcriptData?.length > 0) {
      let containMinus = false
      const database = firebase.database()

      // highlightData edit
      const path = `${configHighlight.highlight_path}/highlightData`
      const highlightEditRef = database.ref(path)

      highlightEditRef.on("value", async (snapshot) => {
        const data = snapshot.val()

        // prepare
        const preparedHighlight = prepareHighlightData({
          highlightData: data,
          competencies: configHighlight.filteredCompetencies,
          initTimestamp: configHighlight.roomTimestamp,
          transcript: transcriptData,
          path
        })

        // if firstload -> save preparedHighlight
        if (firstLoad) {
          const hData = {}
          for (let i = 0; i < preparedHighlight.length; i++) {
            const id = preparedHighlight[i].bookmarkId
            hData[`${path}/${id}`] = { ...preparedHighlight[i] }
          }

          updateMultipleData({ data: hData }).then(() =>
            dispatch(LastSavedActions.setLastSaved())
          )
          setFirstLoad(false)
        }

        // save state
        setHighlightData(preparedHighlight)
      })

      return () => {
        highlightEditRef.off("value")
      }
    }
  }, [configHighlight, dispatch, firstLoad, isLoggedIn, transcriptData])

  /**
   * add new highlight data
   * @param {object} data
   */
  const addHighlight = useCallback(
    (data) => {
      if (configHighlight) {
        const path = `${configHighlight.highlight_path}/highlightData`
        const newKey = addNewKey({ path })

        // prepare data
        const newHighlightData = prepareAddHighlightData({
          data,
          competencies: configHighlight.filteredCompetencies,
          dataPeserta: configHighlight.data_peserta,
          newKey
        })
        // add to database
        updateData({
          path: `${path}/${newKey}`,
          data: newHighlightData
        })
          .then(() => dispatch(LastSavedActions.setLastSaved()))
          .catch((error) => console.log("error", error))
      }
    },
    [configHighlight, dispatch]
  )

  /**
   * update highlight data
   * @param {object} data
   */
  const updateHighlight = useCallback(
    (data) => {
      const updatedHighlight = prepareUpdateHighlightData({
        highlightData,
        data,
        competencies: configHighlight.filteredCompetencies
      })

      if (updatedHighlight) {
        // update to database
        const path = `${configHighlight.highlight_path}/highlightData`
        const key = updatedHighlight.tempId
        updateData({
          path: `${path}/${key}`,
          data: updatedHighlight
        })
          .then(() => dispatch(LastSavedActions.setLastSaved()))
          .catch((error) => console.log("error", error))
      }
    },
    [configHighlight, dispatch, highlightData]
  )

  /**
   * remove highlight data
   * @param {object} data
   */
  const removeHighlight = useCallback(
    (data) => {
      if (data.tempId) {
        const path = `${configHighlight.highlight_path}/highlightData`
        removeData({ path: `${path}/${data.tempId}` })
          .then(() => dispatch(LastSavedActions.setLastSaved()))
          .catch((error) => console.log("error", error))
      }
    },
    [configHighlight, dispatch]
  )

  /**
   * submit highlight data
   */
  const submitHighlightData = useCallback(() => {
    if (configHighlight && highlightData) {
      const path = `${configHighlight.submit_path}/highlightData`
      const hData = {}
      for (let i = 0; i < highlightData.length; i++) {
        const id = highlightData[i].bookmarkId
        hData[`${path}/${id}`] = { ...highlightData[i] }
      }

      updateMultipleData({ data: hData })
        .then(() => dispatch(LastSavedActions.setLastSaved()))
        .catch((error) => console.log("error", error))
    }
  }, [configHighlight, dispatch, highlightData])
  return {
    highlightData,
    addHighlight,
    updateHighlight,
    removeHighlight,
    submitHighlightData,
    firstLoad
  }
}
