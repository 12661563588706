import { useEffect, useState, useRef } from "react"
import { bookmarkTimeline } from "../../lib/bookmarkFormatter"
import { highlightData, highlightRawData } from "../../sample/highlightData"
import CompetencyDictionary from "../popups/CompetencyDictionary"
import EditBookmark from "../popups/EditBookmark"
import FindAndReplace from "../popups/FindAndReplace"
import BookmarkTranscript from "./BookmarkTranscript"
import HighlightHeader from "./HighlightHeader"
import HighlightVideo from "./HighlightVideo"
import Transcription from "./Transcription"

// dummy data
import { config } from "../../sample/configHighlight"
const { configData: configHighlight } = config

function HighlightWrapper() {
  const [isShowFindAndReplace, setIsShowFindAndReplace] = useState(false)
  const [searchedWord, setSearchedWord] = useState("")
  const [isShowDictionary, setIsShowDictionary] = useState(false)
  const [progress, setProgress] = useState(0)
  const [isSeeking, setIsSeeking] = useState(false)
  const [isShowEditBookmark, setIsShowEditBookmark] = useState(false)
  const [searchedCompetency, setSearchedCompetency] = useState("")
  const [duration, setDuration] = useState(1)
  const [secondsPlayed, setSecondsPlayed] = useState(0)
  const [seekedTo, setSeekedTo] = useState(-1)
  const [selectedBookmark, setSelectedBookmark] = useState("")
  const [dialogues, setDialogues] = useState("")

  const handleProgress = (e) => {
    if (!isSeeking) {
      setProgress(e.played * 100)
      setSecondsPlayed(e.playedSeconds)
    }
  }

  const handleSeekChange = (e, newValue) => {
    setSeekedTo(parseFloat(newValue / 100))
    setProgress(newValue)
    setSecondsPlayed((newValue / 100) * duration)
  }

  useEffect(() => {
    setSelectedBookmark(
      bookmarkTimeline({
        rawData: highlightRawData,
        duration,
        highlightData: highlightRawData.bookmarks
      }).find(
        (bookmark) => bookmark.start < progress && bookmark.end > progress
      )
    )
  }, [progress, duration])

  return (
    <div className="highlight-wrapper">
      <FindAndReplace
        show={isShowFindAndReplace}
        onHide={() => setIsShowFindAndReplace(false)}
        searchedWord={searchedWord}
        setSearchedWord={setSearchedWord}
      />
      <CompetencyDictionary
        show={isShowDictionary}
        onHide={() => setIsShowDictionary(false)}
        data={configHighlight.filteredCompetencies}
      />
      <HighlightHeader
        onSeekChange={handleSeekChange}
        played={progress}
        isSeeking={isSeeking}
        setIsSeeking={setIsSeeking}
        duration={duration}
        seconds={secondsPlayed}
        bookmarks={bookmarkTimeline({
          rawData: highlightRawData,
          duration,
          highlightData: highlightRawData.bookmarks
        })}
      />
      <EditBookmark
        show={isShowEditBookmark}
        onHide={() => setIsShowEditBookmark(false)}
        searchedCompetency={searchedCompetency}
        setSearchedCompetency={setSearchedCompetency}
      />
      <div className="d-flex">
        <HighlightVideo
          onClickFind={() => setIsShowFindAndReplace(!isShowFindAndReplace)}
          onClickDictionary={() => setIsShowDictionary(!isShowDictionary)}
          handleProgress={handleProgress}
          url={configHighlight.recordingUrl[0]}
          setDuration={setDuration}
          seekedTo={seekedTo}
          note={selectedBookmark?.note}
          assessorName={selectedBookmark?.name}
          timestamp={selectedBookmark?.stringTimestamp}
          progress={progress}
          skills={selectedBookmark?.skills}
        />
        <Transcription
          onClickBookmark={() => setIsShowEditBookmark(true)}
          dialogues={dialogues}
        />
      </div>
    </div>
  )
}

export default HighlightWrapper
