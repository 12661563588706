import PropTypes from "prop-types"

import chatIcon from "../assets/icons/chat.png"
import recordIcon from "../assets/icons/record.png"
import shareScreenIcon from "../assets/icons/share-screen.png"
import onMicIcon from "../assets/icons/mic.png"
import onCamIcon from "../assets/icons/opencam.png"
import endCallIcon from "../assets/icons/endcall.png"
import meatballIcon from "../assets/icons/meatball.svg"
import offcamIcon from "../assets/icons/offcam.png"
import offmicIcon from "../assets/icons/offmic.png"
import bookmarkIcon from "../assets/icons/bookmark-menu.png"
import noteIcon from "../assets/icons/note.png"
import RefreshIcon from "@material-ui/icons/Refresh"
import { IconButton } from "@material-ui/core"
import useRoomService from "../hooks/roomService"
import { variableSession } from "../constants/otherConstants"

/**
 * Button group
 */
function RoomHeader({
  isMicOn,
  isCameraOn,
  isSharingScreen,
  onClickNote,
  onClickSharingScreen,
  onClickToggleMic,
  onClickToggleCamera,
  onClickEndCall,
  onClickMoreMenu,
  isShowBookmark,
  setIsShowBookmark,
  isShowChatroom,
  setIsShowChatroom,
  type,
  stringTimeCounter,
  isUnread,
  presenter,
  localEmail,
  materiFGD
}) {
  const isLocalPresenter = isSharingScreen ? presenter === localEmail : false
  const { updateRoomLog, updateLogPeserta } = useRoomService()
  const handleRefresh = async () => {
    if (confirm("Apakah anda ingin mereload halaman interaksi?")) {
      await updateRoomLog({ succes: true, message: "Mereload Halaman" })
      if (type !== "assessor") {
        await updateLogPeserta({ succes: true, message: "Mereload Halaman" })
      }
      localStorage.setItem(variableSession.specialReload, true)
      document.location.href = document.location.href
    } else {
      // DO NOTHING
    }
  }
  return (
    <div>
      <div
        className={`menu-wrapper ${
          type === "assessor" ? "assessor" : "participant"
        } ${
          isShowBookmark === "initial-value"
            ? "d-block"
            : isShowBookmark === "show"
            ? "d-none"
            : "d-block"
        } d-flex justify-content-end`}
      >
        {type === "assessor" && (
          <>
            <div className="time-counter">
              <p>
                <img
                  className="record-icon blink"
                  src={recordIcon}
                  alt="record"
                />
                {stringTimeCounter}
              </p>
            </div>
            <div
              className="bookmark-wrapper d-flex"
              onClick={() => setIsShowBookmark("show")}
            >
              <img src={bookmarkIcon} alt="bookmark" />
              <p>Bookmark</p>
            </div>
            <div
              className="interview-note icon-wrapper note d-flex justify-content-center"
              onClick={onClickNote}
            >
              <img src={noteIcon} alt="note" className="interview-note-img" />
            </div>
          </>
        )}
        {type !== "assessor" && (
          <div className="d-flex justify-content-center record-peserta">
            <img className="record-icon blink" src={recordIcon} alt="record" />
          </div>
        )}
        { type !== "assessor" && materiFGD && (
          <div
            className="interview-note icon-wrapper note d-flex justify-content-center"
            onClick={onClickNote}
          >
            <img src={noteIcon} alt="note" className="interview-note-img" />
          </div>
        )}
        <div className="chat-icon">
          {isUnread && <div className="unread"></div>}
          <div
            className="chat-icon-btn icon-wrapper d-flex justify-content-center"
            onClick={() => setIsShowChatroom(!isShowChatroom)}
          >
            <img src={chatIcon} alt="chat" className="chat-icon-img"/>
          </div>
        </div>
        <div className="">
          <IconButton
            title="Muat ulang video call"
            color="secondary"
            className="refresh-icon"
            onClick={handleRefresh}
          >
            <RefreshIcon />
          </IconButton>
        </div>
        <div
          className={`icon-wrapper ${
            isSharingScreen ? (isLocalPresenter ? "sharing" : "disabled") : ""
          } d-flex justify-content-center`}
          onClick={() => {
            if (isLocalPresenter) {
              onClickSharingScreen()
            } else if (isSharingScreen && !isLocalPresenter) {
              // Do Nothing
            } else {
              onClickSharingScreen()
            }
          }}
          title={
            isSharingScreen && !isLocalPresenter
              ? "Sedang digunakan"
              : isSharingScreen
              ? "Berhenti Share Screen"
              : "Share screen"
          }
        >
          <img src={shareScreenIcon} alt="sharescreen" />
        </div>
        <div
          className={`icon-wrapper ${
            !isMicOn ? "off" : ""
          } d-flex justify-content-center`}
          onClick={onClickToggleMic}
        >
          <img src={isMicOn ? onMicIcon : offmicIcon} alt="mic" />
        </div>
        <div
          className={`icon-wrapper ${
            !isCameraOn ? "off" : ""
          } d-flex justify-content-center `}
          onClick={onClickToggleCamera}
        >
          <img src={isCameraOn ? onCamIcon : offcamIcon} alt="cam" />
        </div>
        {type === "assessor" && (
          <div
            title="Hentikan Panggilan"
            className="icon-wrapper d-flex justify-content-center"
            onClick={onClickEndCall}
          >
            <img src={endCallIcon} alt="endcall" />
          </div>
        )}
        <div className="moremenu-parent meatball-icon" onClick={onClickMoreMenu}>
          <div className="moremenu-subparent d-flex justify-content-center">
            <img src={meatballIcon} alt="meatball" className="moremenu-btn" />
          </div>
        </div>
      </div>
    </div>
  )
}

RoomHeader.propTypes = {
  isMicOn: PropTypes.bool,
  isCameraOn: PropTypes.bool,
  isSharingScreen: PropTypes.bool,
  onClickNote: PropTypes.func,
  onClickSharingScreen: PropTypes.func,
  onClickToggleMic: PropTypes.func,
  onClickToggleCamera: PropTypes.func,
  onClickEndCall: PropTypes.func,
  onClickMoreMenu: PropTypes.func,
  // timeCounter: PropTypes.number
  stringTimeCounter: PropTypes.string
}

export default RoomHeader
