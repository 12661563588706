import { createActions, createReducer } from "reduxsauce"

/** --------------- Types and Action Creators --------------- */
export const { Types, Creators } = createActions({
  newSnack: ["data"],
  closeSnack: ["event", "reason"],
  snackExited: null,
  popSnackPack: null
})

/** -------------------- Initial State ------------------- */
export const INITIAL_STATE = {
  snackPack: [],
  open: false,
  messageInfo: undefined
}

/** -------------------- Reducers ------------------------ */
export const newSnack = (state, actions) => ({
  ...state,
  snackPack: [
    ...state.snackPack,
    { message: actions.data, key: new Date().getTime() }
  ]
})

export const closeSnack = (state, action) => {
  if (action.reason === "clickaway") {
    return state
  }
  return { ...state, open: false }
}

export const snackExited = (state) => ({
  ...state,
  messageInfo: undefined
})

export const popSnackPack = (state) => {
  if (state.snackPack.length && !state.messageInfo) {
    return {
      ...state,
      messageInfo: { ...state.snackPack[0] },
      snackPack: state.snackPack.slice(1),
      open: true
    }
  } else if (state.snackPack.length && state.messageInfo && state.open) {
    return { ...state, open: false }
  }

  return state
}

/** ----------- Hookup Reducers to Type ------------- */
export const snackbarReducer = createReducer(INITIAL_STATE, {
  [Types.NEW_SNACK]: newSnack,
  [Types.CLOSE_SNACK]: closeSnack,
  [Types.SNACK_EXITED]: snackExited,
  [Types.POP_SNACK_PACK]: popSnackPack
})
