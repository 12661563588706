import React, { useEffect, useRef, useState, createRef } from "react"
import { Modal } from "react-bootstrap"
import sampleImage from "../../assets/images/image-video.png"
import noteIcon from "../../assets/icons/note-timeline.png"
import ReactPlayer from "react-player"
import playIcon from "../../assets/icons/vid-play.png"
import { WarningOutlined } from "@material-ui/icons"

function HighlightReview({ show, onHide, data, url, onSubmitReview }) {
  const refList = []

  const [isPlaying, setIsPlaying] = useState(-1)
  const [duration, setDuration] = useState(0)
  const [displayData, setDisplayData] = useState([])

  const handlePlay = (key) => {
    isPlaying === key ? setIsPlaying(-1) : setIsPlaying(key)
  }

  useEffect(() => {
    if (data && data.length > 0) {
      const newData = []
      data.map((dt) => {
        if (dt.competency) {
          newData.push(dt)
        }
      })
      setDisplayData(newData)
    }
  }, [data])

  for (let i = 0; i < data.length; i++) {
    const newRef = createRef()
    refList.push(newRef)
  }

  const handleOnProgress = (e, start, end, key) => {
    if (e.playedSeconds < start) {
      refList[key]?.current.seekTo(parseInt(start))
    } else if (e.playedSeconds > end) {
      refList[key]?.current?.seekTo(parseInt(start))
      setIsPlaying(-1)
    }
  }

  const handleSubmitReview = () => {
    onSubmitReview()
    onHide()
  }

  const competencyHeader = (str) => {
    str.split("-")[1].slice(0)
  }

  return (
    <div>
      <Modal show={show} onHide={onHide} className="modal-review-highlight">
        <Modal.Header>Review Highlight Bukti Perilaku</Modal.Header>
        <Modal.Body>
          {displayData &&
            displayData.map((review, key) => {
              let decoded = ``
              try {
                decoded = decodeURIComponent(review.note)
              } catch (e) {
                decoded = review.note
              }
              if (review.competency) {
                return (
                  <div key={key} className="highlight-item d-flex">
                    <div
                      className="video-preview"
                      onClick={() => handlePlay(key)}
                    >
                      <img
                        className={`${isPlaying === key ? "hide" : ""}`}
                        src={playIcon}
                        alt="play"
                      />
                      <div className="review-video">
                        <ReactPlayer
                          url={url}
                          playing={isPlaying === key}
                          ref={refList[key]}
                          onProgress={(e) =>
                            handleOnProgress(
                              e,
                              review.startTime,
                              review.endTime,
                              key
                            )
                          }
                          onDuration={(value) => setDuration(value)}
                        />
                      </div>
                    </div>
                    <div
                      className="review-description"
                      onClick={() => setIsPlaying(!key)}
                    >
                      <div className="description-header d-flex">
                        <div className="timestamp">{review.timestamp}</div>{" "}
                        {review.competency && `  |  `}
                        {review.competency && (
                          <div className="competencies">
                            {review.competency}
                          </div>
                        )}
                      </div>
                      <div className="description-title">{review.behavior}</div>
                      <div className="description-content">
                        {review.transcript}
                      </div>
                      {review.note && (
                        <div className="description-footer d-flex">
                          <img src={noteIcon} alt="note" />
                          <p>{decoded}</p>
                        </div>
                      )}
                    </div>
                  </div>
                )
              } else {
                return <></>
              }
            })}
          {displayData.length === 0 && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <span style={{ color: "orange", fontSize: 64 }}>
                <WarningOutlined fontSize="inherit" />
              </span>
              <div
                style={{
                  position: "sticky",
                  top: 0,
                  padding: 20,
                  backgroundColor: "#ffe5b4",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "darkred",
                  fontSize: 24,
                  fontWeight: "bold",
                  borderRadius: 8,
                  textAlign: "center",
                  boxShadow: "0 1px 3px #bbb"
                }}
              >
                <span>
                  Anda tidak meng-highlight apapun, apakah lanjut submit?
                </span>
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button className="cancel-btn btn" onClick={onHide}>
            Kembali
          </button>
          <button className="primary-btn btn" onClick={handleSubmitReview}>
            Submit
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default HighlightReview
