import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import firebase from "firebase/app"

import { Creators as AuthActions } from "../redux/AuthRedux"
import {
  derotateUserPassword,
  parseFirebaseConfig
} from "../lib/queryConverter"

export default function useFirebase() {
  const history = useHistory()
  const dispatch = useDispatch()
  const { configData: config } = useSelector((state) => state.config)

  useEffect(() => {
    try {
      if (config) {
        // 1. initiate firebase app
        if (firebase.apps.length === 0) {
          firebase.initializeApp(parseFirebaseConfig(config.firebase_config))
        }
        // 2. user login
        dispatch(
          AuthActions.loginRequest({
            email: config.data_peserta.email,
            password: derotateUserPassword(config.data_peserta.p)
          })
        )
        // 3. If suddenly user logged out in the middle of session, login again
        firebase.auth().onAuthStateChanged((user) => {
          if (!user) {
            dispatch(
              AuthActions.loginRequest({
                email: config.data_peserta.email,
                password: derotateUserPassword(config.data_peserta.p)
              })
            )
          }
        })
      }
    } catch (error) {
      console.log("use Firebase", error)
      window.top.postMessage("errorFirebase", "*")
    }
  }, [config, dispatch])

  return null
}
