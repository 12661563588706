import { createActions, createReducer } from 'reduxsauce'

/** --------------- Types and Action Creators --------------- */
export const { Types, Creators } = createActions({
  setLastSaved: null
})

/** -------------------- Initial State ------------------- */
export const INITIAL_STATE = {
  timestamp: null
}

/** -------------------- Reducers ------------------------ */
export const setLastSaved = state => ({
  ...state,
  timestamp: Date.now()
})

/** ----------- Hookup Reducers to Type ------------- */
export const lastSavedReducer = createReducer(INITIAL_STATE, {
  [Types.SET_LAST_SAVED]: setLastSaved
})
