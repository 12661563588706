import React, { useRef, useState } from 'react'
import closeIcon from '../../assets/icons/close.png'

function CompetencyDictionary ({ show, onHide, data }) {
  const [active, setActive] = useState(data[1])

  return (
    <div>
      <div className={`competency-dictionary-modal ${!show ? 'd-none' : ''}`}>
        <div className='d-flex justify-content-between modal-header'>
          <p>Kamus Kompetensi</p>
          <img src={closeIcon} alt='close' onClick={onHide} />
        </div>
        <div className='d-flex modal-body'>
          <div className='competency-list'>
            <ul>
              {/* Add className active for selected competency */}
              {data &&
                data.map((item, i) => (
                  <li
                    key={i}
                    className={`${
                      active.display === item.display ? 'active' : ''
                    } ${item.type === 'cluster' ? 'cluster' : 'competency'} `}
                    onClick={() =>
                      item.type === 'competency' && setActive(item)
                    }
                  >
                    {item.display}
                  </li>
                ))}
            </ul>
          </div>
          <div className='competency-detail'>
            <div className='detail-title'>{active?.display}</div>
            <div className='detail-content'>
              {active?.behaviors?.length > 0 &&
                active.behaviors.map((v, i) => (
                  <p key={i}>{`${active.display.slice(0, 2)}${i + 1} ${v}`}</p>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CompetencyDictionary
