import { createActions, createReducer } from 'reduxsauce'

/** --------------- Types and Action Creators --------------- */
export const { Types, Creators } = createActions({
  listenChat: ['data'],
  messageUnread: null,
  messageRead: null
})

/** -------------------- Initial State ------------------- */
export const INITIAL_STATE = {
  chat: null,
  unRead: false
}

/** -------------------- Reducers ------------------------ */
export const listenChat = (state, action) => ({
  ...state,
  chat: action.data
})

export const messageUnread = state => ({
  ...state,
  unRead: true
})

export const messageRead = state => ({
  ...state,
  unRead: false
})

/** ----------- Hookup Reducers to Type ------------- */
export const chatReducer = createReducer(INITIAL_STATE, {
  [Types.LISTEN_CHAT]: listenChat,
  [Types.MESSAGE_UNREAD]: messageUnread,
  [Types.MESSAGE_READ]: messageRead
})
