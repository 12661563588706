import { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Creators as RoomActions } from "../redux/RoomRedux"
import { LocalAudioTrack, LocalVideoTrack } from "twilio-video"
import useRoomService from "./roomService"
/**
 * References:
 * https://www.twilio.com/blog/screen-sharing-javascript-twilio-programmable-video
 */
export default function useToggleScreenSharing() {
  const dispatch = useDispatch()
  const { room, screenTrack } = useSelector((state) => state.room)
  const { configData } = useSelector((state) => state.config)
  const [screenShareIdentity, setScreenShareIdentity] = useState(null)

  const { updateRoomLog, updateLogPeserta } = useRoomService()

  /**
   * Trigger by local participant
   */
  const toggleScreenShare = useCallback(async () => {
    if (!screenTrack) {
      // share screen
      navigator.mediaDevices
        .getDisplayMedia({ video: true, audio: true })
        .then((stream) => {
          const track = new LocalVideoTrack(stream.getVideoTracks()[0], {
            name: "screen"
          })
          room.localParticipant.publishTrack(track)

          setScreenShareIdentity(room.localParticipant.identity)
          dispatch(RoomActions.toggleScreenShare(track))
          updateRoomLog({ succes: true, message: "Mulai share screen" })
          updateLogPeserta({ succes: true, message: "Mulai share screen" })
        })
        .catch((err) => {
          // alert('Could not share the screen.')
          console.log(err)
        })
    } else {
      // stop share screen
      room.localParticipant.unpublishTrack(screenTrack)
      screenTrack.stop()
      dispatch(RoomActions.toggleScreenShare(null))
      updateRoomLog({ succes: true, message: "Berhenti share screen" })
      updateLogPeserta({ succes: true, message: "Berhenti share screen" })
    }
  }, [screenTrack, room, dispatch])

  useEffect(() => {
    if (room && screenTrack) {
      console.log("onended")
      screenTrack.mediaStreamTrack.onended = () => {
        toggleScreenShare()
      }

      return () => {
        if (screenTrack) {
          toggleScreenShare()
        }
      }
    }
  }, [screenTrack, room, toggleScreenShare])

  const [remoteScreenTrack, setRemoteScreenTrack] = useState(null)
  const handleRemoteScreenTrack = useCallback((track, identity) => {
    setRemoteScreenTrack(track)
    setScreenShareIdentity(identity)
  }, [])

  return {
    screenTrack,
    toggleScreenShare,
    remoteScreenTrack,
    handleRemoteScreenTrack,
    screenShareIdentity
  }
}
