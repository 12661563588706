import logo from '../../assets/images/logo-lg.png'
import webIcon from '../../assets/icons/web.png'
import linkedinIcon from '../../assets/icons/linkedin.png'
import instagramIcon from '../../assets/icons/instagram.png'

function InfoContent () {
  return (
    <div className='session-ends-info'>
      <div className='title'>
        <h1>Terima Kasih Atas Penilaian Anda!</h1>
      </div>
      <div className='content'>
        <div className='logo-icon'>
          <img src={logo} alt='logo' />
        </div>
        <p>
          Talentlytica adalah perusahaan penyedia platform online assessment
          berbasis cloud untuk kebutuhan Talent Management terkait recruitment,
          assessment center, online interview dan konsultasi.
        </p>
        <div className='contacts'>
          <div className='contact d-flex'>
            <img src={webIcon} alt='web' />
            <p>https://www.talentlytica.com/</p>
          </div>
          <div className='contact d-flex'>
            <img src={linkedinIcon} alt='linkedin' />
            <p>Talentlytica</p>
          </div>
          <div className='contact d-flex'>
            <img src={instagramIcon} alt='instagram' />
            <p>talentlytica.id</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InfoContent
