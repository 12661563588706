import PropTypes from "prop-types"
import { useEffect, useRef } from "react"
import senderChatIcon from "../assets/icons/sent.png"

export default function ChatSent({ text, time, last }) {
  const ref = useRef()
  useEffect(() => {
    if (last) {
      ref.current.scrollIntoView()
    }
  }, [last])

  return (
    <div ref={ref} className="sent-chat d-flex justify-content-end">
      <div></div>
      <div className="d-flex">
        <div className="sent-dialog">
          <p className="sent-text">{text}</p>
          <div className="sent-time">
            <p>{time}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

ChatSent.propTypes = {
  text: PropTypes.string,
  time: PropTypes.string
}
