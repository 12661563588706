import React, { useEffect, useRef } from "react"
import PropTypes from "prop-types"
import camMenuIcon from "../../assets/icons/camera-menu.png"
import micMenuIcon from "../../assets/icons/mic-menu.png"
import listShortcutIcon from "../../assets/icons/list-shortcut.png"
import bookmarkIcon from "../../assets/icons/bookmark-shortcut.png"
import chatroomIcon from "../../assets/icons/chat-shortcut.png"
import micShortcutIcon from "../../assets/icons/mic-shortcut.png"
import camShortcutIcon from "../../assets/icons/cam-shortcut.png"
import CamMoreMenus from "./CamMoreMenus"
import { useState } from "react"
import MicMoreMenus from "./MicMoreMenus"
import closeIcon from "../../assets/icons/close.png"

function MoreMenus({
  type,
  show,
  onHide,
  isShowCamMenu,
  isShowMicMenu,
  setIsShowCamMenu,
  setIsShowMicMenu,
  // CamMoreMenus
  isCameraOn,
  cameraValue,
  cameraOptions,
  onCameraChange,
  virtualBackgroundOn,
  onVirtualBackgroundChange,
  sendResolution,
  onChangeSendResolution,
  // MicMoreMenus
  isMicrophoneOn,
  microphone,
  microphoneOptions,
  onMicrophoneChange,
  speaker,
  speakerOptions,
  onSpeakerChange
}) {
  const handleCamMenu = () => {
    onHide()
    setIsShowCamMenu(!isShowCamMenu)
  }

  const handleMicMenu = () => {
    onHide()
    setIsShowMicMenu(!isShowMicMenu)
  }

  const node = useRef(null)
  const handleMoreMenuOutsideClick = (e) => {
    const elm = e.target
    const elmClass = elm.className
    const test = node.current.contains(elm)
    if (!test) {
      if (/moremenu/i.test(elmClass)) return
      onHide()
    }
  }

  useEffect(() => {
    if (show) {
      document.addEventListener("mousedown", handleMoreMenuOutsideClick)
    } else {
      document.removeEventListener("mousedown", handleMoreMenuOutsideClick)
    }
    return () => document.removeEventListener("mousedown", handleMoreMenuOutsideClick)
  }, [show])

  return (
    <div ref={node}>
      <CamMoreMenus
        isCameraOn={isCameraOn}
        show={isShowCamMenu}
        onHide={() => setIsShowCamMenu(false)}
        cameraValue={cameraValue}
        cameraOptions={cameraOptions}
        onCameraChange={onCameraChange}
        sendResolution={sendResolution}
        onChangeSendResolution={onChangeSendResolution}
        virtualBackgroundOn={virtualBackgroundOn}
        onVirtualBackgroundChange={onVirtualBackgroundChange}

      />
      <MicMoreMenus
        isMicrophoneOn={isMicrophoneOn}
        show={isShowMicMenu}
        onHide={() => setIsShowMicMenu(false)}
        microphone={microphone}
        microphoneOptions={microphoneOptions}
        onMicrophoneChange={onMicrophoneChange}
        speaker={speaker}
        speakerOptions={speakerOptions}
        onSpeakerChange={onSpeakerChange}
      />
      <div
        className={`more-menu-modal popup-menu-modal ${!show ? "d-none" : ""}`}
      >
        <div className="more-menu-modal-body modal-body">
          <div>
            <div className="menu-item-active">
              <div className="pop-up-menu-title d-flex justify-content-between">
                <p>Pengaturan</p>
                <img src={closeIcon} alt="close" onClick={onHide} />
              </div>
              <div className="pop-up-menu-item" onClick={handleCamMenu}>
                <div className="d-flex">
                  <img src={camMenuIcon} alt="cam" />
                  <p>Kamera Video</p>
                </div>
                <div></div>
              </div>
              <div className="pop-up-menu-item" onClick={handleMicMenu}>
                <div className="d-flex">
                  <img src={micMenuIcon} alt="mic" />
                  <p>Mikrofon &amp; Speaker</p>
                </div>
                <div></div>
              </div>
            </div>
            <div className="menu-item-shortcut">
              <p className="pop-up-menu-title">Shortcut</p>
              {type === "assessor" && (
                <div>
                  <div className="pop-up-menu-item">
                    <div className="d-flex">
                      <img src={listShortcutIcon} alt="list" />
                      <p>List Pertanyaan</p>
                    </div>
                    <div>
                      <p>{"(Alt + L)"}</p>
                    </div>
                  </div>
                  <div className="pop-up-menu-item">
                    <div className="d-flex">
                      <img src={bookmarkIcon} alt="bookmark" />
                      <p>Bookmark</p>
                    </div>
                    <div>
                      <p>{"(Alt + B)"}</p>
                    </div>
                  </div>
                </div>
              )}

              <div className="pop-up-menu-item">
                <div className="d-flex">
                  <img src={chatroomIcon} alt="chatroom" />
                  <p>Chatroom</p>
                </div>
                <div>
                  <p>{"(Alt + C)"}</p>
                </div>
              </div>
              <div className="pop-up-menu-item">
                <div className="d-flex">
                  <img src={micShortcutIcon} alt="mic-short" />
                  <p>On/off Mikrofon</p>
                </div>
                <div>
                  <p>{"(Alt + M)"}</p>
                </div>
              </div>
              <div className="pop-up-menu-item">
                <div className="d-flex">
                  <img src={camShortcutIcon} alt="cam-short" />
                  <p>On/off Kamera</p>
                </div>
                <div>
                  <p>{"(Alt + K)"}</p>
                </div>
              </div>
            </div>
          </div>
          <button className="btn close-menu" onClick={onHide}>
            Tutup
          </button>
        </div>
      </div>
    </div>
  )
}
// TODO: spesifikasikan tipe yang boleh (sebisa mungkin jangan any)
MoreMenus.propTypes = {
  type: PropTypes.oneOf(["assessor", "participant"]).isRequired,
  show: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  isShowCamMenu: PropTypes.bool,
  isShowMicMenu: PropTypes.bool,
  setIsShowCamMenu: PropTypes.func,
  setIsShowMicMenu: PropTypes.func,
  ...CamMoreMenus.propTypes,
  ...MicMoreMenus.propTypes
}
export default MoreMenus
