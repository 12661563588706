import { all, call } from "redux-saga/effects"
import { authSaga } from "./AuthSaga"
import { configSaga } from "./ConfigSaga"
import { roomSaga } from "./RoomSaga"
import { clientSaga } from "./ClientLoadingSaga"

export default function* rootSaga() {
  yield all([
    call(configSaga),
    call(roomSaga),
    call(authSaga),
    call(clientSaga)
  ])
}
