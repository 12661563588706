import talentlyticaLogo from "./logo.png"
import PermissionErrorImage from "./permission.png"
import fgdImage from "./fgd-image.png"
import previewVideo from "./image-video.png"
import profile from "./profile.png"
import logo from "./logo-lg.png"
import SampleImage from "./living_room.jpg"


const IMAGES = [
  PermissionErrorImage,
  talentlyticaLogo,
  fgdImage,
  previewVideo,
  profile,
  logo,
  SampleImage
]

export default IMAGES