import React from 'react'
import ParticipantRoomContainer from '../components/AssessmentRoom/ParticipantRoomContainer'

function Room () {
  return (
        <div>
            <ParticipantRoomContainer />
        </div>
  )
}

export default Room
