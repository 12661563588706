import React, { useRef, useEffect } from "react"
import PropTypes from "prop-types"
import Draggable from "react-draggable"
import closeIcon from "../assets/icons/close.png"

function QuestionsListPopup({ show, onHide, children }) {

  const node = useRef(null)
  const handleMateriOutsideClick = (e) => {
    const elm = e.target
    const elmClass = elm.className
    const test = node.current.contains(elm)
    if (!test) {
      if (/note/i.test(elmClass)) return
      // onHide()
    }
  }

  useEffect(() => {
    if (show) {
      document.addEventListener("mousedown", handleMateriOutsideClick)
    } else {
      document.removeEventListener("mousedown", handleMateriOutsideClick)
    }
    return () => document.removeEventListener("mousedown", handleMateriOutsideClick)
  }, [show])

  return (
    <div ref={node}>
      <Draggable handle=".handle">
        <div className={`question-list-modal ${!show ? "d-none" : ""}`}>
          <div className="modal-header handle">
            <p>List Pertanyaan</p>
            <img src={closeIcon} alt="close" onClick={onHide} />
          </div>
          <div className="modal-body">{children}</div>
        </div>
      </Draggable>
    </div>
  )
}

QuestionsListPopup.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func
}
export default QuestionsListPopup
