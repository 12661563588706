export const rotate = (string, size) => {
  if (!string) return string
  const howMany = !isNaN(size) ? size : string.length
  const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split(
    ''
  )
  const cl = chars.length
  const result = string.split('')
  result.forEach((v, i) => {
    let index, newIndex
    if (chars.indexOf(v) > -1) {
      index = chars.indexOf(v)
      newIndex = index + (howMany % cl)
      if (newIndex > cl - 1) {
        newIndex = newIndex - cl
      }
      result[i] = chars[newIndex]
    }
  })
  return result.join('')
}

export const derotate = (string, size) => {
  if (!string) return string
  const howMany = !isNaN(size) ? size : string.length
  const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split(
    ''
  )
  const cl = chars.length
  const result = string.split('')
  result.forEach((v, i) => {
    let index, newIndex
    if (chars.indexOf(v) > -1) {
      index = chars.indexOf(v)
      newIndex = index - (howMany % cl)
      if (newIndex < 0) {
        newIndex = newIndex + cl
      }
      result[i] = chars[newIndex]
    }
  })
  return result.join('')
}

/**
 * convert raw data to query
 * @param {object} rawData
 * @returns
 */
export const rawDataToQuery = rawData => btoa(rotate(JSON.stringify(rawData)))

/**
 * convert query to raw data
 * @param {string} query
 * @returns
 */
export const queryToRawData = query => {
  let result = null
  try {
    result = JSON.parse(derotate(atob(query)))
  } catch (error) {
    console.log("queryToRawData", error)
    window.top.postMessage("errorQueryToRawData", "*")
  }
  return result
}

// export const parseFirebaseConfig = query => {
//   let result = null
//   try {
//     result = JSON.parse((atob(query)))
//   } catch (error) {
//     console.log("parseFirebaseConfig", error)
//   }
//   return result
// }

export const parseFirebaseConfig = query => JSON.parse((atob(query)))

export const derotateUserPassword = query => {
  let result = null
  try {
    result = derotate(atob(query))
  } catch (error) {
    console.log("derotateUserPassword", error)
    window.top.postMessage("derotatePassword", "*")
  }
  return result
}
