import { useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import firebase from "firebase/app"

import { Creators as BookmarkActions } from "../redux/BookmarkRedux"

export default function useBookmark() {
  const dispatch = useDispatch()
  const { configData: config } = useSelector((state) => state.config)
  const { room } = useSelector((state) => state.room)
  const { isLoggedIn } = useSelector((state) => state.auth)
  const { bookmark } = useSelector((state) => state.bookmark)

  /**
   * listen bookmark
   */
  useEffect(() => {
    if (config && room && isLoggedIn && config.accessor) {
      const database = firebase.database()

      const bookmarkNode = `${config.database_paths.bookmark}/${room.sid}`
      const bookmarkRef = database.ref(bookmarkNode)
      bookmarkRef.on("value", (snapshot) => {
        const data = snapshot.val()
        dispatch(BookmarkActions.listenBookmark(data))
      })

      return () => {
        bookmarkRef.off("value")
      }
    }
  }, [config, dispatch, isLoggedIn, room])

  /**
   * @param {string} rawString
   * @param {string} note encoded string
   * @param {string[]} kompetensi
   * @param {boolean} videoClip
   * @param {number} unixTimestamp
   * @param {string} stringTimestamp
   */
  const addBookmark = useCallback(
    (data, onSuccess) => {
      const newBookmark = {
        content: {
          rawString: encodeURIComponent(data.rawString),
          note: encodeURIComponent(data.note),
          kompetensi: data.kompetensi
        },
        timestamp: {
          stringTimestamp: data.stringTimestamp, //
          unixTimestamp: data.unixTimestamp
        },
        videoClip: data.videoClip,
        name: config.data_peserta.name,
        email: config.data_peserta.email,
        uid: config.data_peserta.uid,
        role: config.data_peserta.role // asesor / admin
      }

      const bookmarkNode = `${config.database_paths.bookmark}/${room.sid}`
      // console.log('newBookmark', newBookmark)
      // console.log('bookmarkNode', bookmarkNode)
      if (bookmarkNode) {
        const newKey = firebase.database().ref().child(bookmarkNode).push().key

        const updates = {}
        updates[`${bookmarkNode}/${newKey}`] = newBookmark

        return firebase
          .database()
          .ref()
          .update(updates, (error) => {
            if (error) {
              console.log(error)
            } else {
              onSuccess && onSuccess()
            }
          })
      }

      return null
    },
    [config, room]
  )

  return { bookmark, addBookmark }
}
