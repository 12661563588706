import { memo, useCallback, useEffect, useRef, useState } from "react"
import bookmarkIcon from "../../assets/icons/bookmark-sidebar.png"
import tagIcon from "../../assets/icons/skill-tag.png"
import removeIcon from "../../assets/icons/remove-skill.png"
import Autocomplete from "@material-ui/lab/Autocomplete"
import TextField from "@material-ui/core/TextField"
import Paper from "@material-ui/core/Paper"
import { MentionsInput, Mention } from "react-mentions"
import { mentionsToKompetensi } from "../../lib/bookmarkFormatter"
import {
  formatStringTimer,
  formatStringTimestamp
} from "../../lib/counterFormatter"
import Draggable from "react-draggable"

function EditBookmark({
  show,
  onHide,
  listPeserta,
  listKompetensi: skillList,
  roomTimestamp,
  data,
  onSubmit,
  onRemoveBookmark,
  removeMarker,
  refHook
}) {
  const [stringTimestamp, setStringTimestamp] = useState("")
  const [videoClip, setVideoClip] = useState(true)

  const [note, setNote] = useState("")
  const [rawString, setRawString] = useState("")

  const [kompetensi, setKompetensi] = useState([])
  const node = useRef(null)

  // init
  useEffect(() => {
    if (data) {
      const { content, duration, videoClip } = data

      setStringTimestamp(duration.stringTimestamp)
      setVideoClip(videoClip)

      setRawString(decodeURIComponent(content.rawString))
      setNote(decodeURIComponent(content.note))

      setKompetensi(content.kompetensi || [])
    }
  }, [data])

  // update string timestamp based on videoClip
  useEffect(() => {
    if (data && roomTimestamp) {
      if (videoClip) {
        const string = formatStringTimestamp({
          startTimestamp: data.duration.startTimestamp,
          endTimestamp: data.duration.endTimestamp,
          initialTimestamp: roomTimestamp
        })
        setStringTimestamp(string)
      } else {
        const string = formatStringTimestamp({
          startTimestamp: data.duration.startTimestamp,
          initialTimestamp: roomTimestamp
        })
        setStringTimestamp(string)
      }
    }
  }, [data, roomTimestamp, videoClip])

  const handleChangeNote = useCallback(
    (e, newValue, newPlainTextValue, mentions) => {
      setNote(newValue)
      setRawString(newPlainTextValue)

      // add mentions to kompetensi
      const mentioned = mentionsToKompetensi(mentions)
      setKompetensi((c) => [...c, ...mentioned.filter((v) => !c.includes(v))])
    },
    []
  )

  const handleOutsideClick = useCallback((e) => {
    e.preventDefault()
    if (show) {
      if (e.target.className === "MuiAutocomplete-option") {
        return
        // DO NOTHING
      } else if (node !== null && !node.current.contains(e.target)) {
        console.log("hide2")
        onHide()
      }
    }
  })

  useEffect(() => {
    if (show) {
      document.addEventListener("mouseup", handleOutsideClick)
    } else {
      document.removeEventListener("mouseup", handleOutsideClick)
    }
    return () => document.removeEventListener("mouseup", handleOutsideClick)
  }, [show])

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault()
      const values = {
        tempId: data.tempId, //
        content: {
          rawString: encodeURIComponent(rawString),
          note: encodeURIComponent(note),
          kompetensi
        },
        duration: {
          ...data.duration,
          stringTimestamp
        },
        videoClip
      }
      // console.log(values)
      onSubmit && onSubmit(values)
      onHide()
    },
    [data, kompetensi, note, onSubmit, rawString, stringTimestamp, videoClip]
  )

  const handleRemove = useCallback(
    (e) => {
      e.preventDefault()
      const values = {
        tempId: data.tempId //
      }
      // console.log(values)
      onRemoveBookmark && onRemoveBookmark(values)
      removeMarker(values.tempId)
      onHide()
    },
    [data, onRemoveBookmark, onHide, removeMarker]
  )

  // useEffect(() => {
  //   if (data) {
  //     console.log({ id: data.bookmarkId })
  //   }
  // }, [show])

  return (
    <div ref={node} className={!show ? "d-none" : ""}>
      <Draggable handle=".handle">
        <div
          id="edit-bookmark-modal"
          className={`edit-bookmark-modal ${!show ? "d-none" : ""}`}
        >
          <div className="modal-body">
            <form onSubmit={handleSubmit}>
              <div className="edit-bookmark-header d-flex justify-content-between handle">
                <div className="time-stamp d-flex ">
                  <img src={bookmarkIcon} alt="bookmark" />
                  <p>{stringTimestamp}</p>
                </div>
                {/* <label className="switch">
                  <input
                    type="checkbox"
                    checked={videoClip}
                    onChange={(e) => setVideoClip(e.target.checked)}
                  />
                  <span className="slider round"></span>
                </label> */}
              </div>

              <div className="search-skill free-solo-2-demo">
                <Autocomplete
                  onChange={(e, value) => {
                    setKompetensi((c) =>
                      c.findIndex((v) => v === value.display) > -1
                        ? c
                        : [...c, value.display]
                    )
                  }}
                  getOptionLabel={(option) => option.display}
                  freeSolo
                  disableClearable
                  options={skillList}
                  PaperComponent={({ children }) => (
                    <Paper
                      style={{
                        fontFamily: "Nunito",
                        fontSize: "13px",
                        backgroundColor: "aliceblue"
                      }}
                    >
                      {children}
                    </Paper>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      margin="normal"
                      variant="outlined"
                      style={{ backgroundColor: "aliceblue" }}
                      InputProps={{ ...params.InputProps, type: "search" }}
                    />
                  )}
                />
              </div>
              <div className="selected-skill-wrapper" style={{ marginTop: 10 }}>
                {kompetensi.map((v, i) => (
                  <div
                    key={i}
                    className="selected-skill d-flex justify-content-between"
                  >
                    <p>{v.length > 18 ? v.slice(0, 22) + "...." : v}</p>
                    <img
                      src={removeIcon}
                      alt="remove"
                      onClick={() =>
                        setKompetensi((v) => v.filter((_, idx) => idx !== i))
                      }
                    />
                  </div>
                ))}
              </div>
              <div style={{ zIndex: 200 }} className="mentions-input">
                <MentionsInput
                  value={note}
                  style={{ minHeight: "93px" }}
                  onChange={handleChangeNote}
                >
                  <Mention
                    trigger="@"
                    markup="@[__display__](__id__)"
                    displayTransform={(id, display) => `@${display}`}
                    data={listPeserta}
                  />
                  <Mention
                    trigger="#"
                    markup="#[__display__](__id__)"
                    displayTransform={(id, display) => `#${display}`}
                    data={skillList}
                  />
                </MentionsInput>
              </div>
              <br />
              {/* <textarea
            name='skill description'
            cols='24.5'
            rows='4'
            value={rawString}
            onChange={e => setRawString(e.target.value)}
          ></textarea> */}

              <div className="action-btn d-flex justify-content-between">
                <button
                  className="btn cancel-btn remove"
                  onClick={handleRemove}
                >
                  Hapus
                </button>
                <button
                  className={`btn primary-btn`}
                  // className={`btn primary-btn ${
                  //   kompetensi.length === 0 ? 'btn-disabled' : ''
                  // }`}
                  // disabled={kompetensi.length === 0}
                  onClick={onHide}
                  type="submit"
                >
                  Simpan
                </button>
              </div>
            </form>
          </div>
        </div>
      </Draggable>
    </div>
  )
}

export default EditBookmark
