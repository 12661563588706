import { all, call, put, takeLatest } from '@redux-saga/core/effects'
import { getConfig } from '../sevices/configService'
import {
  Types as ConfigTypes,
  Creators as ConfigActions
} from '../redux/ConfigRedux'
import { checkDataResponFrameConfig } from '../lib/general'

function * getConfigSaga (action) {
  try {
    const result = yield call(getConfig, action.data)
    const { page } = action.data
    const checkData = checkDataResponFrameConfig(result.data, page)
    console.log(checkData)
    if (!checkData.status) {
      throw new Error(`${checkData.dataNotComplete.join(", ")} tidak tersedia`)
    }
    yield put(ConfigActions.getConfigSuccess(result))
  } catch (error) {
    yield put(ConfigActions.getConfigFailure(error))
  }
}

export function * getConfigRequestSaga () {
  yield takeLatest(ConfigTypes.GET_CONFIG_REQUEST, getConfigSaga)
}

export function * configSaga () {
  yield all([call(getConfigRequestSaga)])
}
