import { useCallback, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import Video from "twilio-video"

/**
 * References:
 * https://www.twilio.com/docs/video/configuring-audio-video-input-and-output-devices
 * https://www.twilio.com/blog/2018/06/switching-cameras-twilio-video-chat.html
 */
export default function useLocalAudio() {
  const { room } = useSelector((state) => state.room)
  const [audioInputList, setAudioInputList] = useState([])
  const [audioOutputList, setAudioOutputList] = useState([])

  // init audio input/output options
  useEffect(() => {
    if (room) {
      if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
        console.log("enumerateDevices() not supported.")
      } else {
        const getAudioDevices = () => {
          navigator.mediaDevices
            .enumerateDevices()
            .then((mediaDevices) => {
              let audios = []
              let audioOutput = []
              mediaDevices.forEach((device) => {
                if (device.kind === "audioinput") {
                  audios.push({
                    label: device.label || `Audio input ${prev.length + 1}`,
                    value: device.deviceId
                  })
                  // menyesuaikan format selection option
                } else if (device.kind === "audiooutput") {
                  audioOutput.push({
                    label: device.label || `Audio output ${prev.length + 1}`,
                    value: device.deviceId
                  }) // menyesuaikan format selection option
                }
              })
              setAudioInputList(audios)
              setAudioOutputList(audioOutput)
            })
            .catch((error) => {
              console.log(`Error: ${error.name}: ${error.message}`)
              window.top.postMessage("errorAudio", "*")
            })
        }

        getAudioDevices()

        navigator.mediaDevices.ondevicechange = function (event) {
          setAudioInputList([])
          setAudioOutputList([])
          getAudioDevices()
        }
      }

      return () => {
        setAudioOutputList([])
        setAudioInputList([])
      }
    }
  }, [room])

  // audio input publication
  const [audioInputDeviceId, setAudioInputDeviceId] = useState("")

  /**
   * Set default audio input
   * - the default capture devices will be listed first.
   * References:
   * https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/enumerateDevices#return_value
   */
  const [defaultInUpdated, setDefaultInUpdated] = useState(false)
  useEffect(() => {
    if (audioInputList.length > 0 && !defaultInUpdated) {
      const audioName = new URLSearchParams(location.search).get("audio")
      const usedAudio = audioInputList.filter(
        (audio) => audio.label === audioName
      )[0]
      setAudioInputDeviceId(usedAudio?.value || audioInputList[0].value)
      if (!audioInputDeviceId) {
        if (usedAudio) updateAudioInput(usedAudio.value)
        else updateAudioInput(audioInputList[0].value)
      }
      setDefaultInUpdated(true)
    }
  }, [defaultInUpdated, audioInputList])

  const updateAudioInput = useCallback(
    (deviceId) => {
      if (room) {
        const localAudioTrack = Array.from(
          room.localParticipant.audioTracks.values()
        )[0]?.track
        if (localAudioTrack) {
          localAudioTrack.restart({ deviceId })
          setAudioInputDeviceId(deviceId)
        }
      }
    },
    [room]
  )

  // audio output
  const [audioOutputDeviceId, setAudioOutputDeviceId] = useState("")
  /**
   * Set default audio output
   * - the default capture devices will be listed first.
   * References:
   * https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/enumerateDevices#return_value
   */
  const [defaultOutUpdated, setDefaultOutUpdated] = useState(false)
  useEffect(() => {
    if (audioOutputList.length > 0 && !defaultOutUpdated) {
      const speakerName = new URLSearchParams(location.search).get("speaker")
      const usedSpeaker = audioOutputList.filter(
        (audio) => audio.label === speakerName
      )[0]
      setAudioInputDeviceId(usedSpeaker?.value || audioOutputList[0].value)
      if (!audioOutputDeviceId) {
        if (usedSpeaker) updateAudioOutput(usedSpeaker.value)
        else updateAudioOutput(audioOutputList[0].value)
      }
      setDefaultOutUpdated(true)
    }
  }, [audioOutputList, defaultOutUpdated])

  const updateAudioOutput = useCallback(
    (deviceId) => {
      if (room) {
        setAudioOutputDeviceId(deviceId)
      }
    },
    [room]
  )

  return {
    audioInputList,
    audioOutputList,
    audioInputDeviceId,
    audioOutputDeviceId,
    updateAudioInput,
    updateAudioOutput
  }
}
