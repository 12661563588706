import PropTypes from "prop-types"
import { useEffect, useRef } from "react"
import receiverChatIcon from "../assets/icons/received.png"

export default function ChatReceived({ name, text, time, last }) {
  const ref = useRef()
  useEffect(() => {
    if (last) {
      ref.current.scrollIntoView()
    }
  }, [last])

  return (
    <div ref={ref} className="received-chat d-flex">
      <div className="received-dialog">
        <div>
          { name.length < 18 ? 
            <p className="received-name">{name}</p>
            :
            <p className="received-name" title={name}>{name.substring(0,17) + "..."}</p>
          }
          <p className="received-text">{text}</p>
          <div className="d-flex justify-content-end received-time">{time}</div>
        </div>
      </div>
    </div>
  )
}

ChatReceived.propTypes = {
  name: PropTypes.string,
  text: PropTypes.string,
  time: PropTypes.string
}
