import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Creators as RoomActions } from '../redux/RoomRedux'

/**
 * hanya remote participant yang dapat didetect as dominant speaker
 * https://www.twilio.com/docs/video/detecting-dominant-speaker#javascript
 */
export default function useDominantSpeaker () {
  const dispatch = useDispatch()
  const { room, dominantSpeaker } = useSelector(state => state.room)

  useEffect(() => {
    if (room) {
      const handleDominantSpeakerChanged = (participant) => dispatch(RoomActions.setDominantSpeaker(participant))
      room.on('dominantSpeakerChanged', handleDominantSpeakerChanged)

      return () => {
        room.off('dominantSpeakerChanged', handleDominantSpeakerChanged)
      }
    }
  }, [room, dispatch])

  return { dominantSpeaker }
}
