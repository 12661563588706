import PropTypes from 'prop-types'
import { MenuItem, Select } from '@material-ui/core'
import { KeyboardArrowDown } from '@material-ui/icons'
import { useState } from 'react'
import closeIcon from '../../assets/icons/close.png'
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import { isSupported } from '@twilio/video-processors'

function CamMoreMenus ({
  isCameraOn,
  show,
  cameraValue,
  cameraOptions,
  onCameraChange,
  sendResolution,
  onChangeSendResolution,
  virtualBackgroundOn,
  onVirtualBackgroundChange,
  onHide
}) {
  const [openDropdownCam, setOpenDropdownCam] = useState(false)
  const [openDropdownSendRes, setOpenDropdownSendRes] = useState(false)

  return (
    <div>
      <div
        className={`more-menu-modal popup-menu-modal ${!show ? 'd-none' : ''}`}
      >
        <div className='more-menu-modal-body modal-body'>
          <div>
            <div className='menu-item-device'>
              <div className='pop-up-menu-title d-flex justify-content-between'>
                <p>Kamera</p>
                <img src={closeIcon} alt='close' onClick={onHide} />
              </div>
              <div className='device-menu-item'>
                <div className='select-menu'>
                  <Select
                    labelId='demo-controlled-open-select-label'
                    id='select-menu'
                    open={openDropdownCam}
                    onClose={() => setOpenDropdownCam(false)}
                    onOpen={() => setOpenDropdownCam(true)}
                    // defaultValue="USB 2.0 UVC HD Webcam (13d3..."
                    value={cameraValue}
                    onChange={onCameraChange}
                    IconComponent={KeyboardArrowDown}
                    disableUnderline
                    displayEmpty
                  >
                    {(!cameraOptions || cameraOptions.length === 0) && (
                      <MenuItem value='' disabled>
                        Device tidak ditemukan
                      </MenuItem>
                    )}
                    {cameraOptions?.map(({ label, value }, i) => (
                      <MenuItem key={i} value={value}>
                        {label}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <p className='device-status'>
                  Status: {isCameraOn ? 'aktif' : 'nonaktif'}
                </p>
              </div>
            </div>
            <div className='menu-item-device'>
              <p className='pop-up-menu-title'>
                {'Resolusi Pengiriman (maksimum)'}
              </p>
              <div className='device-menu-item'>
                <div className='select-menu'>
                  <Select
                    labelId='demo-controlled-open-select-label'
                    id='select-menu'
                    open={openDropdownSendRes}
                    onClose={() => setOpenDropdownSendRes(false)}
                    onOpen={() => setOpenDropdownSendRes(true)}
                    defaultValue="Otomatis"
                    value={sendResolution}
                    onChange={onChangeSendResolution}
                    IconComponent={KeyboardArrowDown}
                    disableUnderline
                  >
                    {[
                      {
                        label: 'FullHD (Full High Definition)',
                        value: JSON.stringify({ width: 1920, height: 1080 })
                      },
                      {
                        label: 'HD (High Definition)',
                        value: JSON.stringify({ width: 1280, height: 720 })
                      },
                      {
                        label: 'qHD (Quarter High Definition)',
                        value: JSON.stringify({ width: 960, height: 540 })
                      },
                      {
                        label: 'VGA (Video Graphics Array)',
                        value: JSON.stringify({ width: 640, height: 480 })
                      },
                      {
                        label: 'QCIF (Quarter Common Interface Format)',
                        value: JSON.stringify({ width: 176, height: 144 })
                      },
                      {
                        label: "Otomatis",
                        value: "Otomatis"
                      }
                    ].map(({ label, value }, i) => (
                      <MenuItem key={i} value={value}>
                        {label}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
            
            {/* virtual background change */}
            {isSupported && 
              <div className='menu-item-device'>
                <div className='pop-up-menu-title d-flex justify-content-between'>
                  <p>Virtual Background</p>
                </div>
                <div className='device-menu-item'>
                <BootstrapSwitchButton
                  checked={virtualBackgroundOn}
                  onChange={(change) => onVirtualBackgroundChange(change)}
                  size="xs"
                />
                </div>
              </div>
            }
          </div>
          <button className='btn close-menu' onClick={onHide}>
            Tutup
          </button>
        </div>
      </div>
    </div>
  )
}

CamMoreMenus.propTypes = {
  cameraValue: PropTypes.string,
  cameraOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })
  ),
  onCameraChange: PropTypes.func,
  sendResolution: PropTypes.string,
  onChangeSendResolution: PropTypes.func
}

export default CamMoreMenus
