import React, { useRef, useState } from 'react'

import iconDefaultParticipant from "../../assets/icons/mobile/icon-default-participant.svg"
import iconClose from "../../assets/icons/mobile/icon-close.svg"
import iconSendChat from "../../assets/icons/mobile/send-button-chat.svg"
import ReactTooltip from 'react-tooltip'

let chats = [
  {
    name : "Eris Duljaelani",
    message : "Hallo apakah suara saya terdengar ?",
    me : true
  },
  {
    name : "Rojiun Duljalaliwal ikrom",
    message : "Terdengar jelas bos Terdengar jelas bos Terdengar jelas bos Terdengar jelas bos Terdengar jelas bos Terdengar jelas bos Terdengar jelas bos Terdengar jelas bos Terdengar jelas bos Terdengar jelas bos Terdengar jelas bos Terdengar jelas bos Terdengar jelas bos Terdengar jelas bos Terdengar jelas bos Terdengar jelas bos "
  },
  {
    name : "Eris Duljaelani",
    message : "Hallo apakah suara saya terdengar Hallo apakah suara saya terdengar Hallo apakah suara saya terdengar Hallo apakah suara saya terdengar Hallo apakah suara saya terdengar ?",
    me : true
  },
  {
    name : "Rojiun Duljalaliwal ikrom ",
    message : "Terdengar jelas bos"
  },
  {
    name : "Roji",
    message : "Terdengar jelas bos"
  },
  {
    name : "Roji",
    message : "Rojiun Duljalaliwal ikrom Rojiun Duljalaliwal ikrom Rojiun Duljalaliwal ikrom Rojiun Duljalaliwal ikrom Rojiun Duljalaliwal ikrom Rojiun Duljalaliwal ikrom Rojiun Duljalaliwal ikrom Rojiun Duljalaliwal ikrom Rojiun Duljalaliwal ikrom Rojiun Duljalaliwal ikrom Rojiun Duljalaliwal ikrom Rojiun Duljalaliwal ikrom "
  }  
]

function MobileChat({ setShowChat }) {
  const [chat, setChat] = useState(chats)
  const [currentChat, setCurrentChat] = useState("")
  
  const [showKeyboard, setShowKeyboard] = useState(false)

  const sendChat = () => {
    if ( currentChat ){
      setChat([...chat, { name : "Eris Duljaelani", message : currentChat}])
      setCurrentChat("")
    }
  }

  return (
    <div className={`mobile-container-modal`}>
      <div className='mobile-list-participant-close' onClick={() => setShowChat(false)}>
        <img src={ iconClose } />
      </div>
      <div className='mobile-list-chats'>
        <div className='mobile-list-chats-title mobile-modal-title'>
          Percakapan
        </div>
        <div className='mobile-list-chats-items'>
          { chat.map((dataChat, index) => (
            <div className={`mobile-list-chats-item ${dataChat.me ? 'mobile-margin-left-30 mobile-align-items-flex-end' : 'mobile-margin-right-30 mobile-align-items-flex-start'}`} key={index}>
              <div className={`mobile-list-chat-header ${dataChat.me && "mobile-flex-row-reverse"}`}>
                <div 
                  className={`${dataChat.me ? "mobile-margin-left-16px mobile-chat-name-me" : "mobile-margin-right-16px mobile-chat-name-other"}`}
                  data-tip data-for={`chat${index}`}
                >
                  { dataChat.name.length < 18 ? dataChat.name : (dataChat.name.substring(0,17) + "...") }
                </div>
                <ReactTooltip 
                  id={`chat${index}`} 
                  place="top" 
                  effect="solid"
                  backgroundColor='white'
                  border
                  borderColor='black'
                  textColor='black'
                  clickable
                >
                  { dataChat.name }
                </ReactTooltip>
                <div className='mobile-time-chat'>12:10</div>
              </div>
              <div className='mobile-chat-content'>
                { dataChat.message }
              </div>
            </div>

          ))}
        </div>
      </div>
      {/* form send */}
      <div className={`${showKeyboard ? "mobile-bottom-10" : "mobile-bottom-0"} mobile-chat-form`}>
        <div className='mobile-chat-input-container'>
          <textarea
            // security
            name='chat' 
            type='text'
            className='mobile-chat-input'
            autoComplete="false"
            onFocus={(e) => setShowKeyboard(true)}
            onBlur={(e) => {
              setTimeout(() => {
                setShowKeyboard(false)
              }, 100); 
            }}
            value= { currentChat }
            onChange = { (e) => setCurrentChat(e.target.value) }
          />
        </div>
        <div className='mobile-chat-button-send' onClick={ _=> sendChat()}>
          <img src={ iconSendChat } />
        </div>
      </div>
      {/* <div ref={chatRef}></div> */}
    </div>
  )
}

export default MobileChat
