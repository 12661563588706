import { Spinner, Modal, Button as BootstrapButton } from "react-bootstrap"
import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"
import Grid from "@material-ui/core/Grid"
import CircularProgress from "@material-ui/core/CircularProgress"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import InfoIcon from "@material-ui/icons/Info"
import { useCallback, useEffect, useRef, useState } from "react"
import CompetencyDictionary from "../../components/popups/CompetencyDictionary"
import EditBookmark from "../../components/popups/EditBookmark"
import AddBookmark from "../../components/popups/AddBookmark"
import FindAndReplace from "../../components/popups/FindAndReplace"
import HighlightHeader from "../../components/highlight/HighlightHeader"
import HighlightVideo from "../../components/highlight/HighlightVideo"
import Loading from "../../components/Loading"
import Transcription from "../../components/Transcription"
import SpeakerTranscript from "../../components/Transcription/SpeakerTranscript"
import HighlightReview from "../../components/popups/HighlightReview"
import { List } from "react-content-loader"

import useQuery from "../../hooks/useQuery"

import useConfig from "../../hooks/useConfig"
import useFirebase from "../../hooks/useFirebase"
import useTranscript from "../../hooks/useTranscript"
import useHighlight from "../../hooks/useHighlight"
import useTranscriptHighlight from "../../hooks/useTranscriptHighlight"

import { Creators as ConfigActions } from "../../redux/ConfigRedux"
import { Creators as AuthActions } from "../../redux/AuthRedux"
import { useDispatch } from "react-redux"

import {
  bookmarkTimeline,
  getKompetensi,
  getKompetensiDictionary,
  rawTranscriptToPeserta
} from "../../lib/bookmarkFormatter"
import useFindAndReplace from "../../hooks/useFindAndReplace"
import { updateData, getData, removeData } from "../../sevices/firebaseService"
import { reviewFormatter } from "../../lib/reviewFormatter"
import useLastSaved from "../../hooks/useLastSaved"
import usePopupHighlight from "../../hooks/usePopupHighlight"
import ErrorPage2 from "../../components/ErrorPage2"
import { queryToRawData } from "../../lib/queryConverter"

export default function Highlight() {
  const bookmarkRef = useRef(null)
  const dispatch = useDispatch()
  const query = useQuery()
  const queryString = query.get("query")
  const urlConfig = query.get("url_config")
  const config = useConfig({ query: queryString, urlConfig, page: "highlight" })
  const interaction = config?.configData?.tipeInteraksi
  useFirebase()
  useEffect(() => {
    return () => {
      dispatch(ConfigActions.resetConfig())
      dispatch(AuthActions.logoutRequest())
    }
  }, [dispatch])

  const { transcriptData, updateWord, submitTranscriptData, transcriptEmpty } =
    useTranscript()
  const {
    highlightData,
    addHighlight,
    updateHighlight,
    removeHighlight,
    submitHighlightData,
    firstLoad
  } = useHighlight({ transcriptData })

  const { lastSavedString, connectionStatus } = useLastSaved()
  /**
   * handle click button submit highlight
   */
  const onSubmitHighlight = useCallback(() => {
    submitTranscriptData()
    submitHighlightData()

    if (config.configData) {
      // pathPesertaCredential
      const path = config.configData.data_peserta.pathPesertaCredential
      const data = {
        name: config.configData.data_peserta.name,
        email: config.configData.data_peserta.email,
        timestamp: Date.now(),
        role: config.configData.data_peserta.role
      }

      if (path && data) {
        updateData({ path, data })
          .then((result) => console.log("success", result))
          .catch((error) => console.log("error", error))
      }
    }
  }, [submitHighlightData, submitTranscriptData, config.configData])

  const { transcriptHighlight, loadingTranscription, timeoutError } =
    useTranscriptHighlight({
      transcriptData,
      highlightData,
      transcriptEmpty
    })

  const {
    searchedWord,
    setSearchedWord,
    totalRes,
    searchedPositions,
    removeMarker
  } = useFindAndReplace({ transcriptHighlight })

  const {
    isShowAddBookmark,
    isShowDictionary,
    isShowEditHighlight,
    isShowFindAndReplace,
    focusMode,
    confidenceMode,
    handleFocusMode,
    handleConfidenceMode,
    setIsShowAddBookmark,
    setIsShowDictionary,
    setIsShowEditHighlight,
    setIsShowFindAndReplace,
    handleShowAddBookmark,
    handleShowDictionary,
    handleShowFindAndReplace,
    handleShowHighlight
  } = usePopupHighlight()

  // highlight popup
  const [editHighlightData, setEditHighlightData] = useState(null)
  const [addHighlightData, setAddHighlightData] = useState(null)

  // UI state
  const [isShowReview, setIsShowReview] = useState(false)
  const [progress, setProgress] = useState(0)
  const [isPlaying, setIsPlaying] = useState(false)
  const [isSeeking, setIsSeeking] = useState(false)
  const [searchedCompetency, setSearchedCompetency] = useState("")
  const [duration, setDuration] = useState(1)
  const [secondsPlayed, setSecondsPlayed] = useState(0)
  const [seekedTo, setSeekedTo] = useState(-1)
  const [selectedBookmark, setSelectedBookmark] = useState("")
  // const [dialogues, setDialogues] = useState([])
  const [timeUpdate, setTimeUpdate] = useState(0)
  const [resultIndex, setResultIndex] = useState(-1)
  const [newWord, setNewWord] = useState("")
  const [hasSpeakerKey, setHasSpeakerKey] = useState(false)
  const [speakers, setSpeakers] = useState([])
  const [speakersDefault, setSpeakersDefault] = useState([])
  const [speakersOptions, setSpeakersOptions] = useState([])
  const [show, setShow] = useState(false)
  const [count, setCount] = useState(0)
  const [stateIndex, setStateIndex] = useState("")
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [loading, setLoading] = useState(false)
  const [navigation, setNavigation] = useState(1)

  let hasEmptyTranscription = speakers.some(
    (item) => item.transcription === "[]"
  )

  const handleProgress = (e) => {
    if (!isSeeking) {
      setProgress(e.played * 100)
      setSecondsPlayed(e.playedSeconds)
    }
  }

  const onHideSearch = () => {
    setIsShowFindAndReplace(false)
    setSearchedWord("")
    setNewWord("")
  }

  const handleSeekChange = (e, newValue) => {
    setSeekedTo(parseFloat(newValue / 100))
    setProgress(newValue)
    setSecondsPlayed((newValue / 100) * duration)
    setIsShowEditHighlight(false)
    setIsShowAddBookmark(false)
  }

  const handleSeekBookmark = (data) => {
    setSeekedTo(parseInt(data) / duration)
    setProgress((parseInt(data) / duration) * 100)
    setSecondsPlayed(parseInt(data))
  }

  const handleClickWord = (word) => {
    const wordDurationStart =
      (word.startTimestamp - config.configData?.roomTimestamp) / 1000
    const percent = (wordDurationStart / duration) * 100
    handleSeekChange(null, percent)
  }

  const onReplaceWord = () => {
    const idx = resultIndex < 0 ? 0 : resultIndex
    updateWord({
      indexTranscript: searchedPositions[idx].indexTranscript,
      indexWord: searchedPositions[idx].indexWord,
      newWord
    })
    setTimeout(() => {
      if (resultIndex < totalRes) {
        resultIndex < 1 ? setResultIndex(0) : setResultIndex(resultIndex)
      }
    }, 100)
  }

  const onChangeSearch = (e) => {
    setSearchedWord(e)
    setResultIndex(0)
  }

  const onForward = () => {
    setIsSeeking(true)
    const bookmarkStart = highlightData.find(
      (data) => data.duration.startTime.seconds > secondsPlayed
    )?.duration.startTime.seconds
    if (bookmarkStart) {
      setSeekedTo(parseInt(bookmarkStart) / duration)
      setProgress((parseInt(bookmarkStart) / duration) * 100)
      setSecondsPlayed(parseInt(bookmarkStart))
    }
    setTimeout(() => {
      setIsSeeking(false)
    }, 50)
  }

  const onRewind = () => {
    setIsSeeking(true)
    const prevBookmarks = highlightData.filter(
      (data) => data.duration.startTime.seconds < secondsPlayed
    )
    const bookmarkStart =
      prevBookmarks[prevBookmarks.length - 1]?.duration.startTime.seconds
    if (bookmarkStart) {
      setSeekedTo(parseInt(bookmarkStart) / duration)
      setProgress((parseInt(bookmarkStart) / duration) * 100)
      setSecondsPlayed(parseInt(bookmarkStart))
    }
    setTimeout(() => {
      setIsSeeking(false)
    }, 50)
  }

  const getSpeakersInterview = async () => {
    setLoading(true)
    const getConfig = config?.configData
    const getPath = getConfig.highlight_path.split("/highlight")[0]
    const getRoomId = getConfig.data_peserta.pathPesertaCredential.split("/")
    const roomId = getRoomId[getRoomId.length - 1]

    const getSpeakers = await getData(
      `${getPath}/speechToText/Recordings/${roomId}`
    )
    const hasSpeakerKey = Object.keys(getSpeakers || {}).some((key) =>
      key.startsWith("speaker")
    )

    if (hasSpeakerKey) {
      let speakersValue = []
      let optionsValue = []
      for (let key in getSpeakers) {
        if (!key.startsWith("speaker")) {
          let innerObj = getSpeakers[key]

          for (let innerKey in innerObj) {
            if (innerKey.startsWith("RT")) {
              optionsValue.push(innerObj[innerKey])
            }
          }
        } else {
          let innerObj = getSpeakers[key]

          for (let innerKey in innerObj) {
            if (innerKey.startsWith("RT")) {
              speakersValue.push(innerObj[innerKey])
            }
          }
        }
      }
      setHasSpeakerKey(hasSpeakerKey)
      setSpeakers(speakersValue)
      setSpeakersDefault(speakersValue)
      setSpeakersOptions(optionsValue)

      setNavigation(2)
    } else {
      setNavigation(3)
    }
  }

  const getSpeakersFgd = async () => {
    setLoading(true)
    const getConfig = config?.configData
    const getRoomName = getConfig.roomName
    const getPath = getConfig.highlight_path.split("//")[0]
    const getRoomId = getConfig.data_peserta.pathPesertaCredential.split("/")
    const roomId = getRoomId[getRoomId.length - 1]
    const getSpeakers = await getData(
      `${getPath}/fgd/${getRoomName}/speechToText/Recordings/${roomId}`
    )
    const hasSpeakerKey = Object.keys(getSpeakers || {}).some((key) =>
      key.startsWith("speaker")
    )

    if (hasSpeakerKey) {
      let speakersValue = []
      let optionsValue = []
      for (let key in getSpeakers) {
        if (!key.startsWith("speaker")) {
          let innerObj = getSpeakers[key]

          for (let innerKey in innerObj) {
            if (innerKey.startsWith("RT")) {
              optionsValue.push(innerObj[innerKey])
            }
          }
        } else {
          let innerObj = getSpeakers[key]

          for (let innerKey in innerObj) {
            if (innerKey.startsWith("RT")) {
              speakersValue.push(innerObj[innerKey])
            }
          }
        }
      }
      const optionsFiltered = optionsValue.filter(
        (speakerObj, index, self) =>
          index === self.findIndex((obj) => obj.email === speakerObj.email)
      )
      setHasSpeakerKey(hasSpeakerKey)
      setSpeakers(speakersValue)
      setSpeakersDefault(speakersValue)
      setSpeakersOptions(optionsFiltered)

      setNavigation(2)
    } else {
      setNavigation(3)
    }
  }

  const resetLoading = () => {
    setLoading(false)
  }

  const handleSpeakerChange = (newValue, index) => {
    if (newValue && count < speakers.length && stateIndex !== index) {
      setStateIndex(index)
      setCount(count + 1)
    } else if (newValue && count === speakers.length) {
      setCount(count + 0)
    } else if (newValue && stateIndex === index) {
      setCount(count - 0)
    } else {
      setCount(count - 1)
    }
    setSpeakers((prevState) => {
      const updatedSpeakers = [...prevState]
      updatedSpeakers[index] = newValue
        ? {
            ...newValue,
            transcription: prevState[index].transcription,
            gladia: prevState[index].gladia,
            recordingSid: newValue?.recordingSid.split(".")[0],
            roomSid: newValue?.roomSid.split(".")[0]
          }
        : speakersDefault[index]
      return updatedSpeakers
    })
  }

  const handleSubmitSpeakerInterview = async () => {
    setLoadingSubmit(true)
    const getConfig = config?.configData
    const getPath = getConfig.highlight_path.split("/highlight")[0]
    const getRoomId = getConfig.data_peserta.pathPesertaCredential.split("/")
    const roomId = getRoomId[getRoomId.length - 1]

    for (let key in speakers) {
      const getParamUpdate1 = speakers[key].email.replace(/\./g, "--DoT--")
      const getParamUpdate2 = speakers[key].recordingSid.split(".")[0]
      const getEndpoint = `${getPath}/speechToText/Recordings/${roomId}/${getParamUpdate1}/${getParamUpdate2}`

      await updateData({ path: getEndpoint, data: speakers[key] })
        .then(async (result) => {
          for (let key in speakersDefault) {
            const getParamBackup1 = speakersDefault[key].email.replace(
              ".",
              "--DoT--"
            )
            const getParamBackup2 =
              speakersDefault[key].recordingSid.split(".")[0]
            const getParamDelete = speakersDefault[key].email.replace(
              ".",
              "--DoT--"
            )

            const getBackupEndpoint = `${getPath}/backup/speechToText/Recordings/${roomId}/${getParamBackup1}/${getParamBackup2}`
            const getDeleteEndpoint = `${getPath}/speechToText/Recordings/${roomId}/${getParamDelete}`

            await updateData({
              path: getBackupEndpoint,
              data: speakersDefault[key]
            })
            await removeData({ path: getDeleteEndpoint })
          }
        })
        .catch((error) => console.log("error", error))
    }
    setLoadingSubmit(false)
    const getPathDeleteHighlight = `${getPath}/highlight/interview/${roomId}`
    await removeData({ path: getPathDeleteHighlight })
    location.reload()
  }
  const handleSubmitSpeakerFgd = async () => {
    setLoadingSubmit(true)
    const getConfig = config?.configData
    const getRoomName = getConfig.roomName
    const getPath = getConfig.highlight_path.split("//")[0]
    const getPathHighlight =
      "/" +
      getConfig.highlight_path.replace(/\/\//g, "/").replace(/\/draft$/, "")
    const getRoomId = getConfig.data_peserta.pathPesertaCredential.split("/")
    const roomId = getRoomId[getRoomId.length - 1]

    for (let key in speakers) {
      const getParamUpdate1 = speakers[key].email.replace(/\./g, "--DoT--")
      const getParamUpdate2 = speakers[key].recordingSid
      const getEndpoint = `${getPath}/fgd/${getRoomName}/speechToText/Recordings/${roomId}/${getParamUpdate1}/${getParamUpdate2}`

      await updateData({ path: getEndpoint, data: speakers[key] })
        .then(async (result) => {
          for (let key in speakersDefault) {
            const getParamBackup1 = speakersDefault[key].email.replace(
              ".",
              "--DoT--"
            )
            const getParamBackup2 =
              speakersDefault[key].recordingSid.split(".")[0]
            const getParamDelete = speakersDefault[key].email.replace(
              ".",
              "--DoT--"
            )

            const getBackupEndpoint = `${getPath}/fgd/${getRoomName}/backup/speechToText/Recordings/${roomId}/${getParamBackup1}/${getParamBackup2}`
            const getDeleteEndpoint = `${getPath}/fgd/${getRoomName}/speechToText/Recordings/${roomId}/${getParamDelete}`

            await updateData({
              path: getBackupEndpoint,
              data: speakersDefault[key]
            })
            await removeData({ path: getDeleteEndpoint })
          }
        })
        .catch((error) => console.log("error", error))
    }
    await removeData({ path: getPathHighlight })
    setLoadingSubmit(false)
    location.reload()
  }

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  useEffect(() => {
    if (config.configData) {
      if (navigation === 1) {
        if (interaction === "interview") {
          getSpeakersInterview()
        } else {
          getSpeakersFgd()
        }
      } else if (navigation === 2) {
        resetLoading()
      }
      setSelectedBookmark(
        bookmarkTimeline({
          rawData: config.configData,
          duration,
          highlightData
        }).find(
          (bookmark) => bookmark.start < progress && bookmark.end > progress
        )
      )
    }

    if (isSeeking) {
      const durations = transcriptHighlight
        .map((dialog) => dialog.duration)
        .find(
          (range) => range.start <= secondsPlayed && range.end >= secondsPlayed
        )

      const currentTranscription = document.getElementById(
        `transcript${Math.round(durations?.start)}`
      )
      currentTranscription && currentTranscription.scrollIntoView()
    }
  }, [
    progress,
    duration,
    config.configData,
    secondsPlayed,
    transcriptHighlight,
    highlightData,
    isSeeking,
    isPlaying,
    navigation
  ])

  useEffect(() => {
    const searchedLocation = document.getElementById(`searched${resultIndex}`)
    searchedLocation &&
      setTimeout(() => {
        searchedLocation.scrollIntoView()
      }, 100)
  }, [resultIndex, totalRes])

  // edit word
  const [isEditing, setIsEditing] = useState(false)
  useEffect(() => {
    if (isEditing) {
      isShowEditHighlight && setIsShowEditHighlight(false)
      isShowAddBookmark && setIsShowAddBookmark(false)
      isShowFindAndReplace && setIsShowFindAndReplace(false)
      isShowDictionary && setIsShowDictionary(false)
      isShowReview && setIsShowReview(false)
    }
    // PAUSE VIDEO ON EDIT WORD & ADD/EDIT BOOKMARK
    if (isEditing || isShowEditHighlight || isShowAddBookmark) {
      setIsPlaying(false)
    }
  }, [
    isEditing,
    isShowAddBookmark,
    isShowDictionary,
    isShowEditHighlight,
    isShowFindAndReplace,
    isShowReview
  ])

  if (config.isLoading || (firstLoad && !config.isError)) {
    return <Loading where="halaman highlighter" />
  }

  if (!queryString || !urlConfig || config.isError) {
    return (
      <>
        {!queryString && <p>Query undefined</p>}
        {!urlConfig && <p>urlConfig undefined</p>}
        {/* {config.isError && <p>{config.error.message}</p>} */}
        {config.isError && (
          <ErrorPage2
            message={`Mohon maaf terjadi gangguan: ${config.error.message} - Mohon coba reload halaman`}
          />
        )}
      </>
    )
  }

  if (
    !firstLoad &&
    highlightData?.length === 0 &&
    typeof config.configData.highlightData !== "string"
  ) {
    const dataRequest = queryToRawData(queryString)
    let name = ""
    for (const data of config.configData.transcriptData) {
      if (data.email === dataRequest.hu) {
        name = data.name
        break
      }
    }
    return (
      <ErrorPage2
        message={`Mohon maaf terjadi gangguan. Data ${name} - ${dataRequest.l} - ${dataRequest.b} invalid. Mohon hubungi administrator.`}
      />
    )
  }
  console.log({ transcriptHighlight })
  return (
    config.configData &&
    !firstLoad &&
    (highlightData?.length > 0 ||
      typeof config.configData.highlightData === "string") && (
      <div className="highlight-wrapper">
        <FindAndReplace
          show={isShowFindAndReplace}
          onHide={onHideSearch}
          searchedWord={searchedWord}
          setSearchedWord={onChangeSearch}
          totalRes={totalRes}
          onNextResult={() => setResultIndex(resultIndex + 1)}
          onPreviewResult={() => setResultIndex(resultIndex - 1)}
          resultIndex={resultIndex}
          setNewWord={setNewWord}
          newWord={newWord}
          onReplaceWord={onReplaceWord}
          refHook={bookmarkRef}
        />
        <HighlightReview
          show={isShowReview}
          onHide={() => setIsShowReview(false)}
          highlights={highlightData}
          url={config.configData?.recordingUrl?.[0]}
          onSubmitReview={onSubmitHighlight}
          data={reviewFormatter(highlightData)}
        />
        <CompetencyDictionary
          show={isShowDictionary}
          onHide={() => setIsShowDictionary(false)}
          data={getKompetensiDictionary(
            config.configData?.filteredCompetencies
          )}
          refHook={bookmarkRef}
        />
        <HighlightHeader
          onSeekChange={handleSeekChange}
          played={progress}
          isSeeking={isSeeking}
          setIsSeeking={setIsSeeking}
          duration={duration}
          seconds={secondsPlayed}
          bookmarks={bookmarkTimeline({
            rawData: config.configData,
            duration,
            highlightData
          })}
          onSubmitHighlight={onSubmitHighlight}
          setIsShowReview={setIsShowReview}
          lastSaved={lastSavedString}
          connectionStatus={connectionStatus}
        />
        <AddBookmark
          show={isShowAddBookmark}
          onHide={() => setIsShowAddBookmark(false)}
          listPeserta={rawTranscriptToPeserta(
            config.configData.rawTranscriptionData
          )}
          listKompetensi={getKompetensi(
            config.configData?.filteredCompetencies
          )}
          roomTimestamp={config.configData?.roomTimestamp}
          setAddHighlightData={setAddHighlightData}
          data={addHighlightData}
          onSubmit={addHighlight}
          refHook={bookmarkRef}
        />
        <EditBookmark
          show={isShowEditHighlight}
          onHide={() => setIsShowEditHighlight(false)}
          listPeserta={rawTranscriptToPeserta(
            config.configData.rawTranscriptionData
          )}
          listKompetensi={getKompetensi(
            config.configData?.filteredCompetencies
          )}
          roomTimestamp={config.configData?.roomTimestamp}
          data={editHighlightData}
          onSubmit={updateHighlight}
          onRemoveBookmark={removeHighlight}
          removeMarker={removeMarker}
          refHook={bookmarkRef}
        />
        <div className="d-flex">
          <div className="left-highlight">
            <HighlightVideo
              onClickFind={handleShowFindAndReplace}
              onClickDictionary={handleShowDictionary}
              onTimeUpdate={(t) =>
                setTimeUpdate(t * 1000 + config.configData.roomTimestamp)
              }
              focusMode={focusMode}
              confidenceMode={confidenceMode}
              handleProgress={handleProgress}
              onToogleFocus={handleFocusMode}
              onConfidenceFocus={handleConfidenceMode}
              url={config.configData?.recordingUrl?.[0]}
              setDuration={setDuration}
              seekedTo={seekedTo}
              note={selectedBookmark?.note}
              assessorName={selectedBookmark?.name}
              timestamp={selectedBookmark?.stringTimestamp}
              progress={progress}
              skills={selectedBookmark?.skills}
              isPlaying={isPlaying}
              setIsPlaying={setIsPlaying}
              onForward={onForward}
              onRewind={onRewind}
            />
            {hasSpeakerKey ? (
              <div className="remap-speakers">
                {loading ? (
                  <Grid justifyContent="center" container>
                    <CircularProgress />
                  </Grid>
                ) : (
                  <Grid container spacing={2}>
                    <Grid
                      container
                      item
                      xs={12}
                      spacing={3}
                      style={{ margin: 0, padding: 0 }}
                    >
                      <Grid item xs={10}>
                        <Typography variant="h5">Speakers</Typography>
                        <Typography variant="subtitle2">
                          Anda dapat memetakan daftar speaker di bawah ke user
                          yang bersangkutan
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={handleShow}
                          disabled={
                            speakers.length !== count && !hasEmptyTranscription
                          }
                        >
                          Simpan
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid className="speakers-list" container item spacing={2}>
                      {speakers.map((item, index) => (
                        <Grid item xs={6} key={index} style={{ marginTop: 10 }}>
                          <Autocomplete
                            freeSolo
                            options={speakersOptions}
                            getOptionLabel={(option) => option.name}
                            style={{ padding: 5 }}
                            onChange={(event, newValue) =>
                              handleSpeakerChange(newValue, index)
                            }
                            getOptionDisabled={(option) => {
                              return speakers.some(
                                (item) => item.name === option.name
                              )
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={`Speaker ${index + 1}`}
                                variant="outlined"
                              />
                            )}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                )}
              </div>
            ) : null}
            <Modal show={show} onHide={handleClose} centered>
              <Modal.Header>
                <Modal.Title>
                  <InfoIcon color="primary" /> Perhatian
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Apkah anda yakin untuk menyimpan nama pada kolom speaker?
                <b>CATATAN:</b> Anda hanya dapat mengubah nama speaker satu
                kali.
              </Modal.Body>
              <Modal.Footer>
                <BootstrapButton
                  variant="outline-primary"
                  onClick={handleClose}
                >
                  Kembali
                </BootstrapButton>
                <BootstrapButton
                  variant="primary"
                  onClick={
                    interaction === "interview"
                      ? handleSubmitSpeakerInterview
                      : handleSubmitSpeakerFgd
                  }
                  disabled={loadingSubmit}
                >
                  {loadingSubmit ? "Menyimpan…" : "Simpan"}
                </BootstrapButton>
              </Modal.Footer>
            </Modal>
          </div>

          {transcriptHighlight &&
          transcriptHighlight.length > 0 &&
          !loadingTranscription ? (
            <Transcription
              show={isShowAddBookmark || isShowEditHighlight}
              timestampPlayed={timeUpdate}
              speakerTranscriptions={
                <div className="speaker-transcript-wrapper">
                  {transcriptHighlight.map((v, i) => (
                    <SpeakerTranscript
                      confidence={v.confidence}
                      key={i}
                      index={i}
                      {...v}
                      isPlaying={
                        isPlaying &&
                        v.duration.startTimestamp <= timeUpdate &&
                        timeUpdate <= v.duration.endTimestamp
                      }
                      onClickPlay={() => {
                        const durationStart =
                          (v.duration.startTimestamp -
                            config.configData?.roomTimestamp) /
                          1000
                        const percent = (durationStart / duration) * 100
                        handleSeekChange(null, percent)
                        setIsPlaying(true)
                      }}
                      addHighlightData={addHighlightData}
                      onClickPause={() => setIsPlaying(false)}
                      isEditing={isEditing}
                      email={v.email}
                      highlightedUser={
                        config.configData?.highlightedUser || "-"
                      }
                      focusMode={focusMode}
                      confidenceMode={confidenceMode}
                      setIsEditing={setIsEditing}
                      resultIndex={resultIndex}
                      timestampPlayed={timeUpdate}
                      transcriptIdx={i}
                      onSelect={(data) => {
                        if (
                          v.email === config.configData?.highlightedUser ||
                          v.email === "dynamic@email.com"
                        ) {
                          handleShowAddBookmark()
                          setAddHighlightData(data)
                        }
                      }}
                      isShowAddBookmark={isShowAddBookmark}
                      onClickWord={handleClickWord}
                      onWordUpdated={(current, newWord) => {
                        const { indexTranscript, indexWord } = current
                        updateWord({
                          indexTranscript,
                          indexWord,
                          newWord,
                          removeWordIfEmpty: true
                        })
                        // console.log('current', current, 'newWord', newWord)
                      }}
                    />
                  ))}
                </div>
              }
              bookmarks={highlightData}
              onClickBookmark={(data) => {
                handleShowHighlight(data)
                setEditHighlightData(data)
                handleSeekBookmark(
                  parseInt(data.duration.startTime.seconds) + 1
                )
              }}
              editHighlightData={editHighlightData}
              isShowEditHighlight={isShowEditHighlight}
            />
          ) : transcriptHighlight &&
            transcriptHighlight.length === 0 &&
            !loadingTranscription ? (
            <div style={{ marginLeft: 12 }}>
              {transcriptEmpty ? (
                <strong className="text-warning">
                  MAAF, TRANSKRIP TIDAK TERSEDIA
                </strong>
              ) : timeoutError ? (
                <strong className="text-danger">
                  MAAF, ADA KESALAHAN MEMUAT DATA. MOHON RELOAD HALAMAN
                </strong>
              ) : (
                // : <span className="text-info">Maaf, transkripsi tidak tersedia karena memilih speech to text <strong>nonaktif</strong>.</span>
                <>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    variant="primary"
                    aria-hidden="true"
                  />
                  &nbsp;
                  <span className="text-primary">MEMUAT DATA</span>
                  &nbsp;
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    variant="primary"
                    aria-hidden="true"
                  />
                </>
              )}
            </div>
          ) : (
            <div
              style={{
                marginLeft: 60,
                width: "40%",
                height: "20%",
                opacity: 0.2
              }}
            >
              <List backgroundColor={"#333"} foregroundColor={"#999"} />
              <div style={{ marginTop: 40 }} />
              <List backgroundColor={"#333"} foregroundColor={"#999"} />
              <div style={{ marginTop: 40 }} />
              <List backgroundColor={"#333"} foregroundColor={"#999"} />
              <div style={{ marginTop: 40 }} />
              <List backgroundColor={"#333"} foregroundColor={"#999"} />
            </div>
          )}
        </div>
      </div>
    )
  )
}
