import { useEffect, useRef } from 'react'

export default function AudioTrack ({ track, audioOutputDeviceId }) {
  const ref = useRef()

  useEffect(() => {
    if (track) {
      const el = ref.current
      track.attach(el)

      return () => {
        track.detach(el)
      }
    }
  }, [track])

  useEffect(() => {
    audioOutputDeviceId && ref.current?.setSinkId?.(audioOutputDeviceId)
  }, [audioOutputDeviceId])

  return <audio ref={ref} />
}
