import React, { useState } from "react"
import MoreMenus from "../popups/MoreMenus"
import Chatroom from "../popups/Chatroom"
import FGDPopup from "../popups/FGDPopup"
import fgdImage from "../../assets/images/fgd-image.png"
import QuestionsListPopup from "../popups/QuestionsListPopup"
import VideoFrame from "./VideoFrame"
import AssessmentMenu from "./AssessmentMenu"
import BookmarkSidebar from "./BookmarkSidebar"
import SharedScreenWrapper from "../SharedScreenWrapper"
import BookmarkForm from "./BookmarkForm"
import { listKompetensi } from "../../sample/kompetensi"
import { listPeserta } from "../../sample/peserta"
import { bookmarkList } from "../../sample/bookmark"
import BookmarkItem from "./BookmarkItem"
import Snackbar from "@material-ui/core/Snackbar"
import usePopupVideoFrame from "../../hooks/usePopupVideoFrame"


function RoomContainer() {
  // const [isShowChatroom, setIsShowChatroom] = useState(false)
  const [isOnMic, setIsOnMic] = useState(true)
  const [isOnCam, setisOnCam] = useState(true)
  const [isSharingScreen, setIsSharingScreen] = useState(false)
  // const [isShowMoreMenu, setIsShowMoreMenu] = useState(false)
  // const [isShowFGD, setIsShowFGD] = useState(false)
  // const [isShowQuestionList, setIsShowQuestionList] = useState(false)
  // const [isShowBookmark, setIsShowBookmark] = useState("initial-value")
  // const [isShowCamMenu, setIsShowCamMenu] = useState(false)
  // const [isShowMicMenu, setIsShowMicMenu] = useState(false)
  const [isShowJoinToast, setIsShowJoinToast] = useState(false)
  const [isShowLeaveToast, setIsShowLeaveToast] = useState(false)
  const {
    isShowMoreMenu,
    isShowCamMenu,
    isShowMicMenu,
    isShowQuestionList,
    isShowFGD,
    isShowConfirmation,
    isShowBookmark,
    isShowChatroom,
    setIsShowMoreMenu,
    setIsShowCamMenu,
    setIsShowMicMenu,
    setIsShowQuestionList,
    setIsShowFGD,
    setIsShowConfirmation,
    setIsShowBookmark,
    setIsShowChatroom
  } = usePopupVideoFrame()

  const networkQuality = {
    0: "Terputus",
    1: "Sangat Buruk",
    2: "Buruk",
    3: "Sedang",
    4: "Bagus",
    5: "Sangat Bagus"
  }

  const dummyData = Array.from(Array(7).keys())

  const onClickMoreMenu = () => {
    if (isShowCamMenu || isShowMicMenu) {
      setIsShowCamMenu(false)
      setIsShowMicMenu(false)
    } else {
      setIsShowMoreMenu(!isShowMoreMenu)
    }
  }

  return (
    <div>
      <FGDPopup
      show={isShowFGD}
      onHide={() => setIsShowFGD(false)}
      label="fgd">
        <img src={fgdImage} alt="fgd" />
        <p>
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Vel eius
          blanditiis maiores iure? Dolorum consequuntur id cumque nam incidunt
          tenetur eum, cum iste, fugiat nesciunt ab accusamus! Mollitia
          obcaecati saepe beatae illo voluptate at ipsa, consequuntur dolor
          quasi veritatis minima quidem ex consectetur repudiandae dolorem omnis
          numquam nam, magnam animi.
        </p>
      </FGDPopup>
      <QuestionsListPopup
        show={isShowQuestionList}
        onHide={() => setIsShowQuestionList(false)}
      />
      <MoreMenus
        show={isShowMoreMenu}
        onHide={() => setIsShowMoreMenu(false)}
        type="assessor"
        isShowCamMenu={isShowCamMenu}
        isShowMicMenu={isShowMicMenu}
        setIsShowCamMenu={setIsShowCamMenu}
        setIsShowMicMenu={setIsShowMicMenu}
      />
      <Chatroom show={isShowChatroom} onHide={() => setIsShowChatroom(false)} />
      <div
        className={`room-wrapper ${
          isShowBookmark === "initial-value"
            ? ""
            : isShowBookmark === "show"
            ? "show-bookmark"
            : "hide-bookmark"
        } d-flex justify-content-end align-items-center`}
      >
        {/* notification join room, set isShowJoinToast=true when participant join */}
        <Snackbar
          className="toast-join"
          open={isShowJoinToast}
          autoHideDuration={2000}
          onClose={() => setIsShowJoinToast(false)}
          message="Asesor01 bergabung ke dalam ruangan"
        />
        {/* notification left room, set isShowLeaveToast=true when participant leave */}
        <Snackbar
          className="toast-left"
          open={isShowLeaveToast}
          autoHideDuration={2000}
          onClose={() => setIsShowLeaveToast(false)}
          message="Asesor01 meninggalkan ruangan"
        />
        <AssessmentMenu
          type="assessor"
          isShowChatroom={isShowChatroom}
          setIsShowChatroom={setIsShowChatroom}
          isOnMic={isOnMic}
          setIsOnMic={setIsOnMic}
          isOnCam={isOnCam}
          setisOnCam={setisOnCam}
          isSharingScreen={isSharingScreen}
          setIsSharingScreen={setIsSharingScreen}
          isShowMoreMenu={isShowMoreMenu}
          setIsShowMoreMenu={setIsShowMoreMenu}
          setIsShowFGD={setIsShowFGD}
          isShowQuestionList={isShowQuestionList}
          setIsShowQuestionList={setIsShowQuestionList}
          isShowFGD={isShowFGD}
          isShowBookmark={isShowBookmark}
          setIsShowBookmark={setIsShowBookmark}
          isShowCamMenu={isShowCamMenu}
          isShowMicMenu={isShowMicMenu}
          onClickMoreMenu={onClickMoreMenu}
          isUnread={true}
        />
        <SharedScreenWrapper
          show={isSharingScreen}
          isShowBookmark={isShowBookmark}
          presenter="Peserta01"
          content={
            <>
              <div className="video-container">
                <img src="https://st.depositphotos.com/1428083/2946/i/600/depositphotos_29460297-stock-photo-bird-cage.jpg" />
              </div>
            </>
          }
        />
        <VideoFrame
          data={dummyData}
          isShowBookmark={isShowBookmark}
          isSharingScreen={isSharingScreen}
        />
        <BookmarkSidebar
          show={isShowBookmark}
          onHide={() => setIsShowBookmark(false)}
          formComponent={
            <BookmarkForm
              timestamp={Date.now() - 15000}
              listPeserta={listPeserta}
              listKompetensi={listKompetensi}
              show={isShowBookmark}
            />
          }
          bookmarkItems={bookmarkList.map((v, i) => (
            <BookmarkItem
              key={i}
              stringTimestamp={v.timestamp.stringTimestamp}
              kompetensi={v.content.kompetensi}
              name={v.name}
              note={v.content.note}
            />
          ))}
        />
      </div>
      <div className="network-quality" title="Network Quality">
        <span>Koneksi: {networkQuality[4]}</span>
      </div>
    </div>
  )
}

export default RoomContainer
