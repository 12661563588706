import React, { useState } from 'react'

import iconDefaultParticipant from "../../assets/icons/mobile/icon-default-participant.svg"
import iconClose from "../../assets/icons/mobile/icon-close.svg"
import iconSendChat from "../../assets/icons/mobile/send-button-chat.svg"
import ReactTooltip from 'react-tooltip'

let chats = [
  {
    name : "Eris Duljaelani",
    message : "Hallo apakah suara saya terdengar ?"
  },
  {
    name : "Rojiun Duljalaliwal ikrom",
    message : "Terdengar jelas bos"
  },
  {
    name : "Eris Duljaelani",
    message : "Hallo apakah suara saya terdengar ?"
  },
  {
    name : "Rojiun Duljalaliwal ikrom",
    message : "Terdengar jelas bos"
  }  
]

function MobileChat2({ 
  setShowChat,
  uuid,
  chat,
  onSubmit 
}) {
  // const [chatss, setChat] = useState(chats)
  const [currentChat, setCurrentChat] = useState("")

  const [showKeyboard, setShowKeyboard] = useState(false)
  
  const sendChat = () => {
    if ( currentChat ){
      onSubmit(currentChat)
      // setChat([...chat, { name : "Eris Duljaelani", message : currentChat}])
      setCurrentChat("")
    }
  }

  return (
    <div className='mobile-container-modal'>
      <div className='mobile-list-participant-close' onClick={() => setShowChat(false)}>
        <img src={ iconClose } />
      </div>
      <div className='mobile-list-chats'>
        <div className='mobile-list-chats-title mobile-modal-title'>
          Percakapan
        </div>
        <div className='mobile-list-chats-items'>
          { chat &&
            JSON.stringify(chat) !== "{}" &&
            Object.entries(chat).map((dataChat, index, arr) => {
              const text = decodeURIComponent(dataChat[1].string)
              const d = new Date(dataChat[1].timestamp)
              let minutes = d.getMinutes()
              if (minutes < 10) minutes = `0${minutes}`
              const time = `${d.getHours()}:${minutes}`

              const isMe = dataChat[1].uid === uuid
              const trimName = dataChat[1].name.length < 18 ? dataChat[1].name : (dataChat[1].name.substring(0,17) + "...")
              return (
                <div className={`mobile-list-chats-item ${isMe ? 'mobile-margin-left-30 mobile-align-items-flex-end' : 'mobile-margin-right-30 mobile-align-items-flex-start'}`} key={index}>
                  <div className={`mobile-list-chat-header ${isMe && "mobile-flex-row-reverse"}`}>
                    {
                      dataChat[1].name.length < 18 ? 
                      <div 
                        className={`${isMe ? "mobile-margin-left-16px mobile-chat-name-me" : "mobile-margin-right-16px mobile-chat-name-other"}`}
                      >
                        { trimName }
                      </div> : 
                      <div 
                        className={`${isMe ? "mobile-margin-left-16px mobile-chat-name-me" : "mobile-margin-right-16px mobile-chat-name-other"}`}
                        data-tip data-for={`chat${index}`}
                      >
                        { trimName }
                      </div>
                    }
                    {
                      dataChat[1].name.length >= 18 &&
                      <ReactTooltip 
                        id={`chat${index}`} 
                        place="top" 
                        effect="solid"
                        backgroundColor='white'
                        border
                        borderColor='black'
                        textColor='black'
                        clickable
                      >
                        { dataChat[1].name }
                      </ReactTooltip>
                    }
                    <div className='mobile-time-chat'>{ time }</div>
                  </div>
                  <div className='mobile-chat-content'>
                    { text }
                  </div>
                </div>

              )
          })}
        </div>
      </div>

      {/* form send */}
      <div className={`${showKeyboard ? "mobile-bottom-10" : "mobile-bottom-0"} mobile-chat-form`}>
        <div className='mobile-chat-input-container'>
          {/* <input 
            type="text" 
            className='mobile-chat-input'
            value= { currentChat }
            onChange = { (e) => setCurrentChat(e.target.value) }
          /> */}
          <textarea
            name='chat' 
            type='text'
            className='mobile-chat-input'
            autoComplete="false"
            onFocus={(e) => setShowKeyboard(true)}
            onBlur={(e) => {
              setTimeout(() => {
                setShowKeyboard(false)
              }, 100); 
            }}
            value= { currentChat }
            onChange = { (e) => setCurrentChat(e.target.value) }
          /> 
        </div>
        <div className='mobile-chat-button-send' onClick={ _=> sendChat()}>
          <img src={ iconSendChat } />
        </div>
      </div>
    </div>
  )
}

export default MobileChat2