import { KeyboardArrowDown } from '@material-ui/icons'
import { MenuItem, Select } from '@material-ui/core'
import { useState } from 'react'

function WaitingRoomControl ({
  cameraValue,
  cameraOptions,
  onCameraChange,
  microphone,
  onMicrophoneChange,
  microphoneOptions
}) {
  const [openDropdownCam, setOpenDropdownCam] = useState(false)
  const [openDropdownMic, setOpenDropdownMic] = useState(false)

  return (
    <div className='waiting-control d-flex'>
      <div className='device-options'>
        <p className='menu-title'>Kamera</p>
        <div className='device-menu-item'>
          <div className='select-menu'>
            <Select
              labelId='demo-controlled-open-select-label'
              id='select-menu'
              open={openDropdownCam}
              onClose={() => setOpenDropdownCam(false)}
              onOpen={() => setOpenDropdownCam(true)}
              // defaultValue="USB 2.0 UVC HD Webcam (13d3..."
              value={cameraValue}
              onChange={onCameraChange}
              IconComponent={KeyboardArrowDown}
              disableUnderline
            >
              {cameraOptions?.map(({ label, value }, i) => (
                <MenuItem key={i} value={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </div>
          <p className='device-status'>Status: Aktif</p>
        </div>
      </div>
      <div className='device-options'>
        <p className='menu-title'>Mikrofon</p>
        <div className='device-menu-item'>
          <div className='select-menu'>
            <Select
              labelId='demo-controlled-open-select-label'
              id='select-menu'
              open={openDropdownMic}
              onClose={() => setOpenDropdownMic(false)}
              onOpen={() => setOpenDropdownMic(true)}
              value={microphone}
              onChange={onMicrophoneChange}
              IconComponent={KeyboardArrowDown}
              disableUnderline
            >
              {microphoneOptions?.map(({ label, value }, i) => (
                <MenuItem key={i} value={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </div>
          <p className='device-status'>Status: Aktif</p>
        </div>
      </div>
    </div>
  )
}

export default WaitingRoomControl
