import React, { useEffect, useRef, useState } from 'react'
import MobileChat from './MobileChat'

import MobileContent from './MobileContent'
import MobileFooter from './MobileFooter'
import MobileListParticipants from './MobileListParticipants'
import MobileMateriGroup from './MobileMateriGroup'
import MobileTopBar from './MobileTopBar'
 

function MobileRoomContainer() {
  const [ showListParticipant, setShowListParticipant ] = useState(false)
  const [ showChat, setShowChat ] = useState(false)
  const [ showShareScreen, setShowShareScreen ] = useState(false)
  const [ showMateriGroup, setShowMateriGroup ] = useState(false)

  return (
    <div className='mobile-container'>
      { showMateriGroup && 
        <MobileMateriGroup 
          showMateriGroup={showMateriGroup}
          setShowMateriGroup={setShowMateriGroup}
          materi={"lorem ipsum dulur lorem ipsum dulur lorem ipsum dulur lorem ipsum dulur lorem ipsum dulur lorem ipsum dulur lorem ipsum dulur lorem ipsum dulur lorem ipsum dulur lorem ipsum dulur lorem ipsum dulur lorem ipsum dulur lorem ipsum dulur lorem ipsum dulur lorem ipsum dulurlorem ipsum dulur lorem ipsum dulur lorem ipsum dulur lorem ipsum dulur lorem ipsum dulur lorem ipsum dulur lorem ipsum dulur lorem ipsum dulur lorem ipsum dulur lorem ipsum dulur lorem ipsum dulur lorem ipsum dulurlorem ipsum dulur lorem ipsum dulur lorem ipsum dulur lorem ipsum dulur lorem ipsum dulur lorem ipsum dulur lorem ipsum dulur lorem ipsum dulur lorem ipsum dulur lorem ipsum dulur lorem ipsum dulur lorem ipsum dulur lorem ipsum dulur lorem ipsum dulur lorem ipsum dulur lorem ipsum dulur lorem ipsum dulur lorem ipsum dulur lorem ipsum dulur lorem ipsum dulur lorem ipsum dulur lorem ipsum dulur lorem ipsum dulur lorem ipsum dulurlorem ipsum dulur lorem ipsum dulur lorem ipsum dulur lorem ipsum dulur lorem ipsum dulur lorem ipsum dulur lorem ipsum dulur lorem ipsum dulur lorem ipsum dulur lorem ipsum dulur lorem ipsum dulur lorem ipsum dulurlorem ipsum dulur lorem ipsum dulur lorem ipsum dulur lorem ipsum dulur lorem ipsum dulur lorem ipsum dulur lorem ipsum dulur lorem ipsum dulur lorem ipsum dulur lorem ipsum dulur lorem ipsum dulur lorem ipsum dulurlorem ipsum dulur lorem ipsum dulur lorem ipsum dulur lorem ipsum dulur lorem ipsum dulur lorem ipsum dulur lorem ipsum dulur lorem ipsum dulur lorem ipsum dulur lorem ipsum dulur lorem ipsum dulur lorem ipsum dulur "}
          attachment={[
            {name: "gambar 5.png", url: "https://salamadian.com/wp-content/uploads/2019/10/pengertian-microsoft-power-point.jpg"}
          ]}
        />
      } 
      {/* header */}
      <MobileTopBar 
        showMateriGroup = { showMateriGroup }
        setShowMateriGroup = { setShowMateriGroup }
      />

      {/* content */}
      <MobileContent 
        showShareScreen = { showShareScreen }
      />

      { showListParticipant && 
        <MobileListParticipants 
          setShowListParticipant = { setShowListParticipant }
        /> 
      }

      { showChat && 
        <MobileChat 
          setShowChat = { setShowChat }
        />
      }

      {/* footer */}
      <MobileFooter
        showListParticipant = { showListParticipant } 
        setShowListParticipant = { setShowListParticipant }
        showChat = { showChat }
        setShowChat = { setShowChat }
        showShareScreen = { showShareScreen }
        setShowShareScreen = { setShowShareScreen }
      />
    </div>
  )
}

export default MobileRoomContainer
