import { useEffect, useState } from "react"

export default function useFindAndReplace({ transcriptHighlight }) {
  const [searchedWord, setSearchedWord] = useState("")
  const [totalRes, setTotalRes] = useState(0)
  const [searchedPositions, setSearchedPositions] = useState([])

  useEffect(() => {
    let searchIdx = -1
    const listPositions = []
    if (searchedWord === "") {
      for (let i = 0; i < transcriptHighlight.length; i++) {
        for (let j = 0; j < transcriptHighlight[i].words.length; j++) {
          transcriptHighlight[i].words[j].searchIdx = -1
        }
      }
      setTotalRes(searchIdx + 1)
    } else {
      for (let i = 0; i < transcriptHighlight.length; i++) {
        for (let j = 0; j < transcriptHighlight[i].words.length; j++) {
          if (
            transcriptHighlight[i].words[j].word
              .toLowerCase()
              .includes(searchedWord.toLowerCase())
          ) {
            searchIdx++
            transcriptHighlight[i].words[j].searchIdx = searchIdx
            listPositions.push({ indexTranscript: i, indexWord: j })
          } else {
            transcriptHighlight[i].words[j].searchIdx = -1
          }
        }
      }
      setTotalRes(searchIdx + 1)
      setSearchedPositions(listPositions)
    }
  }, [searchedWord, transcriptHighlight])

  const removeMarker = (bookmarkId) => {
    for (let i = 0; i < transcriptHighlight.length; i++) {
      if (transcriptHighlight[i].bookmarks) {
        transcriptHighlight[i].bookmarks = transcriptHighlight[
          i
        ].bookmarks.filter((bookmark) => bookmark.bookmarkId !== bookmarkId)
        for (let j = 0; j < transcriptHighlight[i].words.length; j++) {
          if (transcriptHighlight[i].words[j].highlighted?.length > 0) {
            transcriptHighlight[i].words[j].highlighted = transcriptHighlight[
              i
            ].words[j].highlighted.filter(
              (highlighted) => highlighted.bookmarkId !== bookmarkId
            )
          }
        }
      }
    }
  }

  return {
    searchedWord,
    setSearchedWord,
    totalRes,
    searchedPositions,
    removeMarker
  }
}
