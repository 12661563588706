import React, { useState, useEffect } from "react"
import { Route, Switch, useHistory, useLocation, useParams } from "react-router"
import Room from "./pages/ParticipantRoom"
import AssessorRoomContainer from "./pages/AssessorRoom"
import Lobby from "./pages/Lobby"
import VideoRoom from "./pages/Room/index"
import { Link, Redirect } from "react-router-dom"
import WaitingRoom from "./pages/WaitingRoom"
import SessionEnds from "./pages/SessionEnds/SessionEnds"
import SessionEndsInfo from "./pages/SessionEnds/SessionEndsInfo"
import HighlightSlicing from "./pages/Highlight"
import Highlight from "./pages/Highlight/index"
import LoadingPage from "./pages/Loading/LoadingPage"
import { IconButton } from "@material-ui/core"
import RefreshIcon from "@material-ui/icons/Refresh"
import { useSelector } from "react-redux"
import Loading from "./components/Loading"
import ErrorPage from "./components/ErrorPage"
import { variableSession } from "./constants/otherConstants"
import MobileRoom from "./pages/MobileRoom"
import MobileRoom2 from "./pages/MobileRoom/MobileRoom2"
import { checkDataResponFrameConfig } from "./lib/general"

import ImagesSource from './assets/images'
import IconsSource from './assets/icons'


function Routes() {
  // receive data dari message parent untuk config firebase
  useEffect(() => {
    window.onmessage = (e) => {
        // console.log(e.data)
        if (e.origin !== window.location.origin && e.data.roomName) {
          console.log("PostMessage received")
          console.log(e.data)
          
          const checkData = checkDataResponFrameConfig(e.data)
          if (checkData.status) {
            sessionStorage.setItem(variableSession.configFromParent, JSON.stringify(e.data))
          }

          const configFromParent = JSON.parse(sessionStorage.getItem(variableSession.configFromParent))
          console.log(configFromParent)

          const kompetensi = e && e.data && e.data.kompetensi
          if ((!kompetensi || !Object.keys(kompetensi).length) && e.data.accessor){
            console.warn(`kompetensi (init): ${JSON.stringify(kompetensi)}`)
          }

        }
    }
  }, [])
  const [hideReload, setHideReload] = useState(false)
  const [deviceError, setDeviceError] = useState(false)
  const [isLoading, setLoading] = useState(true)
  const state = useSelector((state) => state)
  const location = useLocation()
  const { client, room } = state
  const handleRefresh = () => {
    if (confirm("Apakah anda ingin mereload halaman interaksi?")) {
      localStorage.setItem(variableSession.specialReload, true)
      document.location.href = document.location.href
    } else {
      // DO NOTHING
    }
  }

  const cacheImages = (images) => {
    images.forEach(src => {
      const img = new Image()
  
      img.src = src
    })
  }

  useEffect(() => {
    const source = [...ImagesSource, ...IconsSource]
    cacheImages(source)
  },[])

  useEffect(() => {
    const stopTracks = (stream) => {
      stream.getVideoTracks().forEach(function (track) {
        track.stop()
      })
    }
    const getVideoDevices = () => {
      navigator.mediaDevices
        .getUserMedia({ audio: true, video: true })
        .then((stream) => {
          stopTracks(stream)
          setDeviceError(false)
          setLoading(false)
        })
        .catch((error) => {
          setDeviceError(error.message)
        })
    }
    console.log(location.pathname)
    if (location.pathname !== "/highlight" && location.pathname !== "/" && location.pathname !== "/lobby") {
      getVideoDevices()
    } else {
      setDeviceError(false)
      setLoading(false)
    }
  }, [location.pathname])

  useEffect(() => {
    if (!state.client.loadingClient) {
      setHideReload(true)
    }
  }, [state.client.loadingClient])

  useEffect(() => {
    if (room.room !== null && !room.isConnecting && !room.isError)
      setHideReload(true)
  }, [room.room])

  const isMobile = () => {
    return /mobile|android|phone|tablet|ip(ad|hone)/i.test(window.navigator.userAgent)
  }

  return (
    <div>
      {!deviceError && isLoading && <Loading where="halaman slicing"/>}
      {deviceError && <ErrorPage deviceError={deviceError} />}
      {!isLoading && (
        <>
          <Switch>
            <Route exact path="/room" component={isMobile() ? MobileRoom2 : VideoRoom} />
            <Route exact path="/lobby" component={Lobby} />
            <Route
              exact
              path="/assessor-room"
              component={AssessorRoomContainer}
            />
            <Route
              exact
              path="/mobile-room"
              component={MobileRoom}
            />
            <Route
              exact
              path="/mobile-room-2"
              component={MobileRoom2}
            />
            <Redirect exact from="/" to="lobby" />
            <Route exact path="/room-slicing" component={Room} />
            <Route exact path="/loading" component={LoadingPage} />
            <Route
              exact
              path="/highlight-slicing"
              component={HighlightSlicing}
            />
            <Route exact path="/highlight" component={Highlight} />
            <Route exact path="/waiting-room" component={WaitingRoom} />
            <Route exact path="/session-ends" component={SessionEnds} />
            <Route
              exact
              path="/session-ends/info"
              component={SessionEndsInfo}
            />
          </Switch>
        </>
      )}

      {/* <Switch>
        <Route exact path="/room" component={VideoRoom} />
        <Route exact path="/lobby" component={Lobby} />
        <Route exact path="/assessor-room" component={AssessorRoomContainer} />
        <Redirect exact from="/" to="lobby" />
        <Route exact path="/room-slicing" component={Room} />
        <Route exact path="/loading" component={LoadingPage} />
        <Route exact path="/highlight-slicing" component={HighlightSlicing} />
        <Route exact path="/highlight" component={Highlight} />
        <Route exact path="/waiting-room" component={WaitingRoom} />
        <Route exact path="/session-ends" component={SessionEnds} />
        <Route exact path="/session-ends/info" component={SessionEndsInfo} />
      </Switch> */}

      <div
        className={`refresh-container ${
          hideReload ? "refresh-container__hide" : ""
        }`}
      >
        <IconButton
          title="Muat Ulang"
          color="secondary"
          className="refresh-icon"
          onClick={handleRefresh}
        >
          <RefreshIcon />
        </IconButton>
      </div>
    </div>
  )
}

export default Routes