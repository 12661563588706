import Slider from "@material-ui/core/Slider"
import saveIcon from "../../assets/icons/save.png"
import bookmarkTimeline from "../../assets/icons/bookmark-timeline.png"
import bookmarkBlueIcon from "../../assets/icons/bookmark-sidebar.png"
import bookmarkRedIcon from "../../assets/icons/bookmark-sidebar-red.png"
import noteTimeline from "../../assets/icons/note-timeline.png"
import undoIcon from "../../assets/icons/undo.png"
import redoIcon from "../../assets/icons/redo.png"
import { useDispatch, useSelector } from "react-redux"

import {
  addLeadingZero,
  getHours,
  getMinutes,
  getSeconds
} from "../../lib/counterFormatter"
import { makeStyles } from "@material-ui/core"

function HighlightHeader({
  played,
  isSeeking,
  setIsSeeking,
  onSeekChange,
  duration,
  seconds,
  bookmarks,
  onSubmitHighlight,
  setIsShowReview,
  lastSaved,
  connectionStatus
}) {
  const marks = bookmarks.map((bookmark) => {
    return {
      value: (bookmark.end + bookmark.start) / 2,
      label: !bookmark.fromHighlight ? (
        <img
          style={{ maxWidth: 12, maxHeight: 20 }}
          src={bookmark.video ? bookmarkRedIcon : bookmarkBlueIcon}
          alt="bookmark"
        />
      ) : (
        ""
      )
    }
  })

  const { configData } = useSelector((state) => state.config)
  return (
    <div className="highlight-header">
      <div className="auto-save d-flex">
        <p>
          {connectionStatus ? (
            <>
              <span>
                Highlight <b>{configData.highlightedUser}</b>
              </span>
              <span className="success">{` [Tersimpan otomatis] `}</span>
            </>
          ) : (
            <span className="failed">{`[Gagal menyimpan otomatis] `}</span>
          )}
          {connectionStatus ? lastSaved : "Koneksi anda terputus..."}
        </p>
      </div>
      <div className="video-timeline d-flex align-items-center">
        <div className="btn btn-action undo">
          <img src={undoIcon} alt="undo" />
        </div>
        <div className="btn btn-action redo">
          <img src={redoIcon} alt="redo" />
        </div>
        <p>{`${addLeadingZero(getHours(seconds))}:${addLeadingZero(
          getMinutes(seconds)
        )}:${addLeadingZero(getSeconds(seconds))}`}</p>
        <div
          className="material-slider"
          onMouseDown={() => setIsSeeking(true)}
          onMouseUp={() => setIsSeeking(false)}
        >
          <Slider
            marks={marks}
            color="secondary"
            value={played}
            onChange={(e, newValue) => onSeekChange(e, newValue)}
            step={0.1}
          />
        </div>
        <p>{`${addLeadingZero(getHours(duration))}:${addLeadingZero(
          getMinutes(duration)
        )}:${addLeadingZero(getSeconds(duration))}`}</p>
        <div className="save-button" onClick={() => setIsShowReview(true)}>
          Submit
        </div>
      </div>
      <div className="full-timeline">
        {bookmarks.map((bookmark, key) => (
          <div
            key={key}
            className="marker-area"
            style={{
              position: "relative",
              left: `calc(${(bookmark.start / 100) * 66}vw + 63px)`,
              top: `-${key * 9}px`,
              width: `${((bookmark.end - bookmark.start) / 100) * 66}vw`
            }}
          ></div>
        ))}
      </div>
    </div>
  )
}

export default HighlightHeader
