import React, { useEffect, useRef } from "react"
import PropTypes from 'prop-types'
import closeIcon from '../assets/icons/close.png'
import ChatForm from './ChatForm'
import Draggable from 'react-draggable'

function Chatroom ({ show, onHide, children, onSubmit }) {

  const node = useRef(null)
  const handleChatOutsideClick = (e) => {
    if (!show) return
    const elm = e.target
    const elmClass = elm.className
    const test = node.current.contains(elm)
    if (!test) {
      if (/chat/i.test(elmClass)) return
      onHide()
    }
  }

  useEffect(() => {
    if (show) {
      document.addEventListener("mousedown", handleChatOutsideClick)
    } else {
      document.removeEventListener("mousedown", handleChatOutsideClick)
    }
    return () => document.removeEventListener("mousedown", handleChatOutsideClick)
  }, [show])

  return (
    <div ref={node}>
      <Draggable handle=".handle">
        <div className={`chatroom-modal ${!show ? 'd-none' : ''}`}>
          <div className='modal-header handle'>
            <p>Chatroom</p>
            <img src={closeIcon} alt="close" onClick={onHide} />
          </div>
          <div className='modal-body chatroom-modal-body'>
            {/* TODO: refactor style */}
            <div style={{ height: '300px', overflow: 'auto' }}>{children}</div>
            <ChatForm onSubmit={onSubmit} show={show}/>
          </div>
        </div>
      </Draggable>
    </div>
  )
}

Chatroom.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  children: PropTypes.any,
  onSubmit: PropTypes.func
}

export default Chatroom
