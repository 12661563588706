import firebase from 'firebase/app'

/**
 * User sign in firebase auth with email and password
 *
 * References:
 * https://firebase.google.com/docs/auth/web/password-auth#sign_in_a_user_with_an_email_address_and_password
 *
 * @param {*} email
 * @param {*} password
 */
export function signIn ({ email, password }) {
  return firebase
    .auth()
    .signInWithEmailAndPassword(email, password)
    .then(userCredential => {
      const user = userCredential.user
      return user
    })
}

export const signInAsynchronus = async (email, password) => {
  try {
    const userCredential = await firebase.auth().signInWithEmailAndPassword(email, password)
    console.log("sukses login ulang")
    console.log(userCredential.user)
    return userCredential.user
  } catch (error) {
    return false
  }
}

/**
 * user sign out firebase auth
 *
 * References:
 * https://firebase.google.com/docs/auth/web/password-auth#next_steps
 */
export function signOut () {
  return firebase
    .auth()
    .signOut()
}
