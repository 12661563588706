import { useCallback, useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import bookmarkIcon from "../../assets/icons/bookmark-sidebar.png"
import { formatStringTimer } from "../../lib/counterFormatter"
import { MentionsInput, Mention } from "react-mentions"
import useRoomService from '../../hooks/roomService'

function BookmarkForm({
  timestamp,
  currentTimestamp,
  onClickTimestamp,
  listPeserta,
  listKompetensi,
  onSubmit,
  onCancel,
  show
}) {
  const [stringTimestamp, setStringTimestamp] = useState("")
  const [videoClip, setVideoClip] = useState(true)
  const [note, setNote] = useState("")
  const [rawString, setRawString] = useState("")
  const [mentions, setMentions] = useState([])
  const nodeTextarea = useRef(null)
  const { updateRoomLog } = useRoomService()

  const resetForm = useCallback(() => {
    setNote("")
    setRawString("")
    setMentions([])
    onClickTimestamp && onClickTimestamp()
  }, [onClickTimestamp])

  useEffect(() => {
    if (timestamp && currentTimestamp) {
      const start = currentTimestamp - timestamp
      const stringStart = formatStringTimer(
        start - 10000 < 0 ? 0 : (start - 10000) / 1000
      )
      const stringEnd = formatStringTimer(
        start - 10000 < 0 ? 10 : (start + 10000) / 1000
      )

      if (videoClip) {
        setStringTimestamp(`${stringStart} - ${stringEnd}`)
      } else {
        setStringTimestamp(`${formatStringTimer(start < 0 ? 0 : start / 1000)}`)
      }
    }

    let textareaElm
    if (show === "show") {
      if (nodeTextarea && nodeTextarea.current) {
        textareaElm = nodeTextarea.current.getElementsByTagName("textarea")
        textareaElm = textareaElm && textareaElm[0]
        if (textareaElm) textareaElm.focus()
      }
    }
  }, [timestamp, currentTimestamp, videoClip, show])

  const handleChangeNote = (e, newValue, newPlainTextValue, mentions) => {
    setNote(newValue)
    setRawString(newPlainTextValue)
    setMentions(mentions)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const values = {
      rawString,
      note,
      kompetensi: mentions
        .filter(({ id }) => !isNaN(id))
        .map(({ display }) => display?.slice(1)),
      videoClip,
      unixTimestamp: currentTimestamp,
      stringTimestamp
    }

    updateRoomLog({ succes: true, message: `Mengisi Bookmark ➔ ${new Date()}` })
    resetForm()
    if (note.trim()) {
      // console.log('bookmark values: ', values)
      onSubmit && onSubmit(values, { resetForm })
    }
  }

  const competencies = listKompetensi
    .sort((a, b) => a.id - b.id)
    .map((kompetensi, i) => {
      return {
        id: kompetensi.number,
        display: kompetensi.display.split(". ")[1]
      }
    })

  return (
    <form onSubmit={handleSubmit}>
      <div className="new-bookmark">
        <div className="d-flex justify-content-between">
          {/* timer */}
          <div className="timestamp d-flex" onClick={onClickTimestamp}>
            <img src={bookmarkIcon} alt="bookmark-icon" />
            <p>{stringTimestamp}</p>
          </div>
          {/* switch btn */}
          {/* <label className="switch">
            <input
              type="checkbox"
              name="videoClip"
              checked={videoClip}
              onChange={(e) => setVideoClip(e.target.checked)}
            />
            <span className="slider round"></span>
          </label> */}
        </div>

        <div ref={nodeTextarea}>
          <MentionsInput
            placeholder="Silakan ketik catatan..."
            className="mention-list"
            style={{ marginTop: 10 }}
            value={note}
            onChange={handleChangeNote}
            onKeyDown={(event) => {
              if (/enter/i.test(event.key || "") || event.keyCode === 13) {
                if (!event.shiftKey) {
                  event.preventDefault()
                  handleSubmit(event)
                }
              }
            }}
          >
            <Mention
              trigger="@"
              markup="@[__display__](__id__)"
              displayTransform={(id, display) => `@${display}`}
              style={{ zIndex: "200 !important" }}
              data={listPeserta}
              className="mention-list"
            />
            <Mention
              trigger="#"
              markup="#[__display__](__id__)"
              displayTransform={(id, display) => `#${display}`}
              style={{ zIndex: "200 !important" }}
              data={listKompetensi}
              className="mention-list"
            />
          </MentionsInput>
        </div>
        <br />
        <div className="d-flex justify-content-between action-button">
          <button
            type="button"
            className="btn cancel-btn"
            onClick={() => {
              resetForm()
            }}
          >
            Kosongkan
          </button>
          <button type="submit" className="btn btn-primary">
            Simpan
          </button>
        </div>
      </div>
    </form>
  )
}

BookmarkForm.propTypes = {
  timestamp: PropTypes.number.isRequired,
  listPeserta: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any,
      display: PropTypes.any
    })
  ),
  listKompetensi: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any,
      display: PropTypes.any
    })
  ),
  onSubmit: PropTypes.func
}

export default BookmarkForm
