import { getIndexKompetensi } from "./bookmarkFormatter"
import { bookmarkToHighlight } from "./bookmarkToHighlight"
import { formatStringTimestamp } from "./counterFormatter"

/**
 * get index of transcript containing a highlight
 * @param {object[]} transcript with startTimestamp & endTimestamp
 * @param {number} timestamp highlight unixTimestamp
 * @returns {number} index of transcript containing the highlight
 */
// export const getTranscriptIdx = ({ transcript, timestamp }) => {
//   return transcript.findIndex(
//     (t, i, arr) => t.duration.endTimestamp > timestamp || i === arr.length - 1
//   )
// }

export const getTranscriptIdx = ({ transcript, timestamp }) => {
  const mid = (timestamp.start + timestamp.end) / 2
  const index = transcript.findIndex((t, i, arr) => {
    return mid <= parseInt(t.duration.end) && mid >= parseInt(t.duration.start)
  })

  return { index, mid }
}

/**
 * get start & end index of words containing a highlight
 * @param {object[]} words
 * @param {number} estimateStart unixTimestamp
 * @param {number} estimateEnd unixTimestamp
 * @returns {object} {startIndex, endIndex}
 */
export const getIndexInWords = ({
  words,
  estimateStart,
  estimateEnd,
  transcriptIdx,
  transcript,
  wordIndexes
}) => {
  let succes = false
  let startIndex, endIndex
  let currentWords = words
  let counter = 0
  let index = transcriptIdx

  while (!succes) {
    counter++
    if (counter >= 10) {
      console.error("Something went wrong please forgive me :'(")
      succes = true
      break
    }
    if (currentWords?.length > 0) {
      let wordIndexStart = currentWords.findIndex(
        (w) =>
          (w.startTime.seconds || 0) >= estimateStart &&
          (w.startTime.seconds || 0) <= estimateEnd &&
          w.endTime.seconds >= estimateStart &&
          w.endTime.seconds <= estimateEnd
      )
      let wordIndexEnd = currentWords.findIndex(
        (w) => (w.startTime.seconds || 0) >= estimateEnd
      )

      if (!wordIndexes[index]) {
        wordIndexes[index] = []
      }
      startIndex = wordIndexStart > -1 ? wordIndexStart : 0
      endIndex = wordIndexEnd > -1 ? wordIndexEnd : currentWords.length - 1
      let toBreak = false
      let c = 0
      while (!toBreak) {
        if (c === 100) {
          toBreak = true
        }
        if (wordIndexes[index].includes(startIndex)) {
          startIndex = startIndex + 1
        } else {
          toBreak = true
        }
        c++
      }
      if (startIndex >= currentWords.length - 1) {
        index += 1
        currentWords = transcript[index].words
        continue
        // Shift to next transcript
      }
      if (endIndex < startIndex) {
        endIndex = startIndex + 1
        if (endIndex > currentWords.length - 1) {
          endIndex = currentWords.length - 1
        }
      }
      wordIndexes[index].push(startIndex)
      succes = true
    }
  }
  return {
    startIndex,
    endIndex,
    newTranscriptIdx: index
  }

  return {}
}

/**
 * get highlight duration
 * @param {object[]} words words in transcript
 * @param {number} wordIndexStart highlight start index in words
 * @param {number} wordIndexEnd highlight end index in words
 * @param {number} initTimestamp unixTimestamp
 */
export const getBookmarkDuration = ({
  words,
  wordIndexStart,
  wordIndexEnd,
  initTimestamp: initialTimestamp
}) => {
  return {
    stringTimestamp: formatStringTimestamp({
      startTimestamp: words[wordIndexStart]?.startTimestamp,
      endTimestamp: words[wordIndexEnd]?.endTimestamp,
      initialTimestamp
    }),
    startTimestamp: words[wordIndexStart]?.startTimestamp,
    endTimestamp: words[wordIndexEnd]?.endTimestamp,
    startTime: words[wordIndexStart]?.startTime || words[wordIndexEnd]?.endTime,
    endTime: words[wordIndexEnd]?.endTime
  }
}

/**
 * get highlight transcript / transcript highlighted
 * @param {object[]} words
 * @param {number} wordIndexStart
 * @param {number} wordIndexEnd
 */
export const getBookmarkTranscript = ({
  words,
  wordIndexStart,
  wordIndexEnd
}) =>
  words
    .filter((w, i) => wordIndexStart <= i && i <= wordIndexEnd)
    .map(({ word }) => word)
    .join(" ")

/**
 * highlight sort function
 * @param {object} a highlight
 * @param {object} b highlight
 */
export const sortByTimestamp = (a, b) => {
  if (!a.duration) return 0
  if (a.duration.startTime && b.duration.startTime) {
    if (a.duration.startTime.seconds > b.duration.startTime.seconds) {
      if (a.transcriptIdx < b.transcriptIdx) return -1
      return 1
    } else if (a.duration.startTime.seconds < b.duration.startTime.seconds) {
      if (a.transcriptIdx > b.transcriptIdx) return 1
      return -1
    }
    return 0
  }
  return 0
}

/**
 *
 * @param {object} highlightData raw highlightData
 * @param {object} competencies configHighlight.filteredCompetencies
 * @param {number} initTimestamp configHighlight.roomTimestamp
 * @param {object[]} transcript preparedTranscript
 */
export const prepareHighlightData = ({
  highlightData,
  competencies,
  initTimestamp,
  transcript,
  path
}) => {
  let arrBookmarks = []
  let toNewHighlightData = highlightData

  if (toNewHighlightData === "Empty" || toNewHighlightData === null)
    toNewHighlightData = []

  arrBookmarks = Object.entries(toNewHighlightData).map((v) => ({
    ...v[1],
    bookmarkId: v[0],
    tempId: v[0]
  }))

  // update indexKompetensi
  arrBookmarks = arrBookmarks.map((b) => ({
    ...b,
    indexKompetensi: getIndexKompetensi({
      listKompetensi: competencies,
      kompetensiPeserta: b.content.kompetensi
    })
  }))

  // update duration & transcript
  arrBookmarks = bookmarkToHighlight({
    arrBookmarks,
    transcript,
    initTimestamp,
    path
  })

  // sort by duration asc
  arrBookmarks = arrBookmarks.sort(sortByTimestamp)
  return arrBookmarks
}

/**
 * @param {object} data add highlight data
 * @param {object} competencies configHighlight.filteredCompetencies
 * @param {object} dataPeserta configHighlight.data_peserta
 */
export const prepareAddHighlightData = ({
  data,
  competencies,
  dataPeserta,
  newKey
}) => {
  // get updated indexKompetensi
  const indexKompetensi = getIndexKompetensi({
    listKompetensi: competencies,
    kompetensiPeserta: data.content.kompetensi
  })

  // prepare
  const newHighlightData = {
    ...data,
    indexKompetensi,
    email: dataPeserta.email,
    name: dataPeserta.name,
    role: dataPeserta.role,
    uid: dataPeserta.uid,
    timestamp: {
      stringTimestamp: data.duration.stringTimestamp,
      unixTimestamp: Math.floor(
        (data.duration.startTimestamp + data.duration.endTimestamp) / 2
      )
    },
    bookmarkId: newKey,
    tempId: newKey,
    fromHighlight: true
  }

  return newHighlightData
}

/**
 * @param {object[]} highlightData existing highlightData
 * @param {object} data add highlight data
 * @param {object} competencies configHighlight.filteredCompetencies
 */
export const prepareUpdateHighlightData = ({
  highlightData,
  data,
  competencies
}) => {
  const currentData = highlightData.find((b) => b.tempId === data.tempId)

  if (currentData) {
    // get updated indexKompetensi
    const indexKompetensi = getIndexKompetensi({
      listKompetensi: competencies,
      kompetensiPeserta: data.content.kompetensi
    })

    const updatedHighlight = {
      ...currentData,
      ...data,
      indexKompetensi
    }

    return updatedHighlight
  }

  return null
}
