import { useEffect, useState } from "react"

export default function usePopupHighlight() {
  const [isShowFindAndReplace, setIsShowFindAndReplace] = useState(false)
  const [focusMode, setFocusMode] = useState(true)
  const [confidenceMode, setConfidenceMode] = useState(false)
  const [isShowDictionary, setIsShowDictionary] = useState(false)
  const [isShowAddBookmark, setIsShowAddBookmark] = useState(false)
  const [isShowEditHighlight, setIsShowEditHighlight] = useState(false)
  const [editData, setEditData] = useState("")

  const handleFocusMode = (value) => {
    setFocusMode(value)
  }

  const handleConfidenceMode = (value) => {
    setConfidenceMode(value)
  }

  const handleShowHighlight = (data) => {
    if (!isShowEditHighlight) {
      setIsShowEditHighlight(true)
      setIsShowAddBookmark(false)
      setIsShowDictionary(false)
      setIsShowFindAndReplace(false)
    } else {
      editData === data && setIsShowEditHighlight(false)
    }
    setEditData(data)
  }

  const handleShowAddBookmark = () => {
    if (!isShowAddBookmark) {
      setIsShowEditHighlight(false)
      setIsShowAddBookmark(true)
      setIsShowDictionary(false)
      setIsShowFindAndReplace(false)
    } else {
      setIsShowAddBookmark(false)
    }
  }

  const handleShowDictionary = () => {
    if (!isShowDictionary) {
      setIsShowEditHighlight(false)
      setIsShowAddBookmark(false)
      setIsShowDictionary(true)
      setIsShowFindAndReplace(false)
    } else {
      setIsShowDictionary(false)
    }
  }

  const handleShowFindAndReplace = () => {
    if (!isShowFindAndReplace) {
      setIsShowEditHighlight(false)
      setIsShowAddBookmark(false)
      setIsShowDictionary(false)
      setIsShowFindAndReplace(true)
    } else {
      setIsShowFindAndReplace(false)
    }
  }

  return {
    handleShowAddBookmark,
    handleShowDictionary,
    handleShowFindAndReplace,
    handleShowHighlight,
    handleFocusMode,
    handleConfidenceMode,
    focusMode,
    confidenceMode,
    isShowAddBookmark,
    isShowDictionary,
    isShowEditHighlight,
    isShowFindAndReplace,
    setIsShowAddBookmark,
    setIsShowDictionary,
    setIsShowEditHighlight,
    setIsShowFindAndReplace
  }
}
