import PropTypes from "prop-types"
import personIcon from "../../assets/icons/person.png"
import existingBookmarkIcon from "../../assets/icons/bookmark-existing.png"
import { MentionsInput, Mention } from "react-mentions"

function BookmarkItem({ stringTimestamp, kompetensi, name, note }) {
  return (
    // <div className="existing-bookmark-item">
    //   <div className="timestamp d-flex">
    //     <div className="assessor-info d-flex">
    //       <img src={personIcon} alt="person" />
    //       <p>{name}</p>
    //     </div>
    //     <div className="time d-flex">
    //       <img src={existingBookmarkIcon} alt="existing-bookmark" />
    //       <p>{stringTimestamp}</p>
    //     </div>
    //   </div>
    // <div className="mentions-input">
    //   <MentionsInput className="mention" value={note} disabled>
    //     <Mention
    //       trigger="@"
    //       markup="@[__display__](__id__)"
    //       displayTransform={(id, display) => `@${display}`}
    //       className="mention-list"
    //       // data={listPeserta}
    //     />
    //     <Mention
    //       trigger="#"
    //       markup="#[__display__](__id__)"
    //       displayTransform={(id, display) => `#${display}`}
    //       className="mention-list"
    //       // data={listKompetensi}
    //     />
    //   </MentionsInput>
    // </div>
    //   <div style={{ display: "flex" }}>
    //     {kompetensi?.map((v, i) => (
    //       <span style={{ width: 200, height: 40 }} key={i}>
    //         {v}
    //       </span>
    //     ))}
    //   </div>
    //   {/* <span>Kepemimpinan</span> */}
    //   {/* <span>Daya Analisa</span> */}
    // </div>
    <div className="bookmark-item">
      <div className="timestamp d-flex">
        <div className="assessor-info d-flex">
          <img src={personIcon} alt="person" />
          <p>{name}</p>
        </div>
        <div className="time d-flex">
          <img src={existingBookmarkIcon} alt="existing-bookmark" />
          <p>{stringTimestamp}</p>
        </div>
      </div>
      <div className="mentions-input">
        <MentionsInput className="mention" value={note} disabled>
          <Mention
            trigger="@"
            markup="@[__display__](__id__)"
            displayTransform={(id, display) => `@${display}`}
            className="mention-list"
            // data={listPeserta}
          />
          <Mention
            trigger="#"
            markup="#[__display__](__id__)"
            displayTransform={(id, display) => `#${display}`}
            className="mention-list"
            // data={listKompetensi}
          />
        </MentionsInput>
      </div>
      <div className="kompetensi-container">
        {kompetensi?.map((v, i) => (
          <span key={i}>{v}</span>
        ))}
      </div>
    </div>
  )
}

BookmarkItem.propTypes = {
  stringTimestamp: PropTypes.string,
  kompetensi: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string
}

export default BookmarkItem
