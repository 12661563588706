import { createActions, createReducer } from "reduxsauce"

/** --------------- Types and Action Creators --------------- */
export const { Types, Creators } = createActions({
  setClientLoading: ["payload"],
  resetClientLoading: null
})

/** -------------------- Initial State ------------------- */
export const INITIAL_STATE = {
  loadingClient: true
}

/** -------------------- Reducers ------------------------ */
export const setClientLoading = (state, action) => ({
  ...state,
  loadingClient: action.payload
})

export const resetClientLoading = () => INITIAL_STATE

/** ----------- Hookup Reducers to Type ------------- */
export const clientReducer = createReducer(INITIAL_STATE, {
  [Types.SET_CLIENT_LOADING]: setClientLoading,
  [Types.RESET_CLIENT_LOADING]: resetClientLoading
})
