import React from 'react'
import WaitingRoomWrapper from '../components/waitingRoom/WaitingRoomWrapper'

function WaitingRoom () {
  return (
    <div>
      <WaitingRoomWrapper />
    </div>
  )
}

export default WaitingRoom
