import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import firebase from 'firebase/app'

import { Creators as ChatActions } from '../redux/ChatRedux'

export default function useChat () {
  const dispatch = useDispatch()
  const { configData: config } = useSelector(state => state.config)
  const { isLoggedIn } = useSelector(state => state.auth)
  const { room } = useSelector(state => state.room)
  const { chat, unRead } = useSelector(state => state.chat)

  /**
   * listen
   */
  useEffect(() => {
    if (config && room && isLoggedIn) {
      const database = firebase.database()
      const chatNode = config.database_paths.chat
        ? `${config.database_paths.chat}/${room.sid}`
        : `${config.database_paths.fgd_chat}/${room.sid}`

      // listen chat
      const chatRef = database.ref(chatNode)
      chatRef.on('value', snapshot => {
        const data = snapshot.val()
        dispatch(ChatActions.listenChat(data))
        if (data) {
          dispatch(ChatActions.messageUnread())
        }
      })

      return () => {
        chatRef.off('value')
      }
    }
  }, [config, dispatch, isLoggedIn, room])

  const sendMessage = useCallback(
    text => {
      const newMessage = {
        name: config.data_peserta.name,
        email: config.data_peserta.email,
        uid: config.data_peserta.uid,
        role: config.data_peserta.role,
        string: text,
        timestamp: new Date().getTime()
      }

      const chatNode = config.database_paths.chat
        ? `${config.database_paths.chat}/${room.sid}`
        : `${config.database_paths.fgd_chat}/${room.sid}`

      if (chatNode) {
        const newKey = firebase
          .database()
          .ref()
          .child(chatNode)
          .push().key

        const updates = {}
        updates[`${chatNode}/${newKey}`] = newMessage

        return firebase
          .database()
          .ref()
          .update(updates)
      }

      return null
    },
    [config, room]
  )

  return { chat, sendMessage, unRead }
}
