import firebase from "firebase/app"
import { useEffect, useState } from "react"
import { batch, useSelector } from "react-redux"
import { useLocation } from "react-router"
import { getData } from "../sevices/firebaseService"

export default function useRoomService() {
  const { room } = useSelector((state) => state.room)
  const config = useSelector((state) => state.config)

  const location = useLocation()

  const [messageRoomLog, updateRoomLog] = useState({})
  const [messageLogPeserta, updateLogPeserta] = useState({})

  // useEffect(() => {
  //   const getLabel = async () => {
  //     let { timer_reference } = config.configData
  //     const roomL = await getData(`${timer_reference}/${room.sid}/label`)
  //     setRoomLabel(roomL)
  //   }

  //   getLabel()
  // }, [])

  const noDot = (a) => {
    if (!a) return a
    return a.replace(/\./g, "--DoT--")
  }

  // ROOM LOG
  useEffect(() => {
    if (
      config &&
      config.configData &&
      messageRoomLog !== {} &&
      messageRoomLog.message
    ) {
      if (config.configData.inProgressRoom || (room && room.sid)) {
        let sid = config.configData.inProgressRoom || room.sid
        const database = firebase.database()
        let { timer_reference, data_peserta, label, batchId } =
          config.configData
        let logRefference = timer_reference.replace("/composition", "/roomLog")
        let commonBatch = batchId ? batchId : ""
        // PATH PROJECTS
        if (commonBatch !== "" && !/^projects\//.test(commonBatch)) {
          commonBatch = `projects/${commonBatch}`
        }
        let errorLog = `raw_transcript/${commonBatch}/rawError`
        let action = messageRoomLog.succes
          ? `${messageRoomLog.message}`
          : `Error : ${messageRoomLog.message}`
        const data = {
          email: data_peserta.email,
          action,
          type: label,
          timestamp: Date.now(),
          role: data_peserta.role,
          search: location.search
        }
        if (!messageRoomLog.succes) {
          try {
            database.ref(`${errorLog}/${noDot(data_peserta.email)}`).push(data)
          } catch (e) {
            let errorLog = timer_reference.replace("/composition", "/rawError")
            database.ref(`${errorLog}/${noDot(data_peserta.email)}`).push(data)
          }
        } else {
          database
            .ref(`${logRefference}/${sid}/${noDot(data_peserta.email)}`)
            .push(data)
        }
      }
    }
  }, [messageRoomLog.message])

  // UPDATE LOG PESERTA
  useEffect(() => {
    if (
      config &&
      config.configData &&
      messageLogPeserta !== {} &&
      messageLogPeserta.message
    ) {
      const database = firebase.database()
      let {
        data_peserta,
        batchId,
        label = "INTERAKSI DEFAULT"
      } = config.configData
      let batchPath = batchId ? batchId : ""
      // PATH PROJECTS
      if (batchPath !== "" && !/^projects\//.test(batchPath)) {
        batchPath = `projects/${batchPath}`
      }
      let credentialPath = `${batchPath}/credential/${data_peserta.indexPeserta}`
      let updateData = messageLogPeserta.succes
        ? `Interaksi:`
        : `Error Interaksi:`
      database.ref(credentialPath).update({
        position: `${updateData} ${
          messageLogPeserta.message
        } ${label.toUpperCase()}`
      })
    }
  }, [messageLogPeserta.message])

  return { updateRoomLog, updateLogPeserta }
}
