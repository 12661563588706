import {
  KeyboardArrowDownRounded,
  KeyboardArrowUp,
  KeyboardArrowUpRounded
} from '@material-ui/icons'
import React, { useRef, useState } from 'react'
import closeIcon from '../../assets/icons/close.png'

function FindAndReplace ({
  show,
  onHide,
  searchedWord,
  setSearchedWord,
  totalRes,
  onPreviewResult,
  onNextResult,
  resultIndex,
  setNewWord,
  newWord,
  onReplaceWord
}) {
  return (
    <div>
      <div className={`find-and-replace-modal ${!show ? 'd-none' : ''}`}>
        <div className='modal-header d-flex justify-content-between'>
          <p>Find And Replace</p>
          <img src={closeIcon} alt='close' onClick={onHide} />
        </div>
        <div className='modal-body'>
          <div className='find-words'>
            <div className='d-flex justify-content-between'>
              <label htmlFor='input-find'>Cari kata</label>
              <p>{totalRes} kata</p>
            </div>
            <div className='d-flex find-field'>
              <input
                type='text'
                id='input-find'
                onChange={e => setSearchedWord(e.target.value)}
                value={searchedWord}
              />
              <div className='d-flex navigation-icon'>
                <KeyboardArrowUpRounded
                  style={{ color: resultIndex > 0 ? '#125589' : '#BDBDBD' }}
                  onClick={resultIndex > 0 ? onPreviewResult : null}
                />
                <KeyboardArrowDownRounded
                  style={{
                    color: resultIndex < totalRes - 1 ? '#125589' : '#BDBDBD'
                  }}
                  onClick={resultIndex < totalRes - 1 ? onNextResult : null}
                />
              </div>
            </div>
            <br />
            <label htmlFor='input-replace'>Diganti dengan</label>
            <br />
            <input
              type='text'
              id='input-replace'
              onChange={e => setNewWord(e.target.value)}
              value={newWord}
            />
          </div>
        </div>
        <div className='modal-footer'>
          <div className='btn-group'>
            <button className='btn cancel-btn mx-3' onClick={onHide}>
              Batalkan
            </button>
            <button
              className={`btn primary-btn ${
                searchedWord ? '' : 'btn-disabled'
              }`}
              disabled={!searchedWord}
              onClick={onReplaceWord}
            >
              Ganti Kata
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FindAndReplace
