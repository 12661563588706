import React, { useState } from 'react'
import cameraToggle from "../../assets/icons/mobile/switch_camera.svg"
import soundOn from "../../assets/icons/mobile/sound_on.svg"
import soundMute from "../../assets/icons/mobile/sound_mute.svg"
import materiGroupIcon from "../../assets/icons/mobile/materi-group.svg" 

function MobileTopBar({ showMateriGroup, setShowMateriGroup}) {
  const [ toggleSound, setToggleSound ] = useState(true)
  return (
    <div className='mobile-top-bar'>
      <div className='mobile-top-bar-title'>
        Interview User (Staff HR)...
      </div>
      <div className='mobile-top-bar-icon'>
        <img 
          src={ materiGroupIcon } 
          onClick={ _=> setShowMateriGroup(!showMateriGroup) }
          style={{ marginRight: "16px"}}
        />
        <img className='margin-right' src={cameraToggle} />
      </div>
    </div>
  )
}

export default MobileTopBar
