import React from 'react'
import LobbyHeader from '../LobbyHeader'
import InfoContent from './InfoContent'
import SesssionEndsFooter from './SesssionEndsFooter'

function SessionEndsInfo () {
  return (
    <div className='lobby-wrapper' >
      <LobbyHeader/>
      <InfoContent />
      <SesssionEndsFooter type='assessor'/>
    </div>
  )
}

export default SessionEndsInfo
