export const reviewFormatter = rawData => {
  const arrHighlight = []
  for (let i = 0; i < rawData.length; i++) {
    if (rawData[i].content.kompetensi) {
      for (let j = 0; j < rawData[i].content.kompetensi.length; j++) {
        arrHighlight.push({
          timestamp: rawData[i].duration.stringTimestamp,
          startTime: rawData[i].duration.startTime.seconds,
          endTime: rawData[i].duration.endTime.seconds,
          competency: rawData[i].content.kompetensi[j].split(' - ')[1],
          behavior: rawData[i].content.kompetensi[j]
            ?.split('. ')[1]
            ?.split(' - ')[0],
          transcript: rawData[i].transcript,
          note: decodeURIComponent(rawData[i].content.note)
        })
      }
    } else {
      arrHighlight.push({
        timestamp: rawData[i].duration.stringTimestamp,
        startTime: rawData[i].duration.startTime.seconds,
        endTime: rawData[i].duration.endTime.seconds,
        transcript: rawData[i].transcript,
        note: decodeURIComponent(rawData[i].content.note)
      })
    }
  }
  return arrHighlight
}
