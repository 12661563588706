// export const loadingSession = `loading-${location.origin.split(":")[1].replace(/\//g, "")}-${Date.now()}`

const variableSession = {
  loadingSession : `loading-${location.origin.split(":")[1].replace(/\//g, "")}-${Date.now()}`,
  specialReload : "specialReload",
  configFromParent : "configFromParent",
  elementPosition : "elementPosition",
  ping: "pingPeserta"
}

const expectResponDataHighlight = [
  {
    name : "highlight_path",
    type: "mandatory"
  },
  {
    name : "submit_path",
    type: "mandatory"
  },
  {
    name : "transcriptData",
    type: "mandatory"
  },
  {
    name : "data_peserta",
    type: "mandatory"
  },
  {
    name : "highlightData",
    type: "mandatory"
  },
  {
    name : "roomTimestamp",
    type: "mandatory"
  },
  {
    name : "recordingUrl",
    type: "mandatory"
  },
  {
    name : "firebase_config",
    type: "mandatory"
  },
  {
    name : "highlightedUser",
    type: "mandatory"
  },
  {
    name : "filteredCompetencies",
    type: "mandatory"
  },
  {
    name : "rawTranscriptionData",
    type: "mandatory",
    // typeData: "object"
  }
]

const expectResponDataInteraksi = [
  {
    name : "data_peserta",
    type: "mandatory"
  },
  {
    name : "firebase_config",
    type: "mandatory"
  },
  {
    name : "twilio_join_token",
    type: "mandatory"
  },
  {
    name : "roomName",
    type: "optional"
  },
  {
    name : "accessor",
    type: "mandatory"
  },
  {
    name : "type",
    type: "mandatory"
  },
  {
    name : "observerPath",
    type: "mandatory"
  },
  {
    name : "peserta_path_listen",
    type: "mandatory"
  },
  {
    name : "database_paths",
    type: "optional"
  },
  {
    name : "timer_reference",
    type: "mandatory"
  },
  {
    name : "label",
    type: "mandatory"
  },
  {
    name : "batchId",
    type: "mandatory"
  },
  {
    name : "inProgressRoom",
    type: "mandatory"
  },    
  
  // {
  //   name : "kompetensi",
  //   type: "mandatory",
  //   notes: "ada ketika respon accessor = true"
  // }, 
  
  // {
  //   name : "list_pertanyaan",
  //   type: "optional",
  //   notes: "ada ketika respon type = interview & accessor = true"
  // },
  // {
  //   name : "materiFGD",
  //   type: "optional",
  //   notes: "ada ketika respon type = fgd & accessor = true"
  // },
]

export { variableSession, expectResponDataHighlight, expectResponDataInteraksi }