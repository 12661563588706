import React from 'react'

function Warning ({
  show,
  onHide,
  mainMessage,
  cancelMessage,
  confirmMessage,
  onConfirm
}) {
  return (
    <div>
      <div className={`modal-warning ${show ? 'd-block' : 'd-none'}`}>
        <div className='modal-body'>
          <div className='main-message'>{mainMessage}</div>
          <div className='d-flex justify-content-between action-btn'>
            <button className='btn cancel-btn' onClick={onHide}>
              {cancelMessage}
            </button>
            <button className='btn confirm-btn' onClick={onConfirm}>
              {confirmMessage}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Warning
