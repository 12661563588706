import { createActions, createReducer } from 'reduxsauce'

/** --------------- Types and Action Creators --------------- */
export const { Types, Creators } = createActions({
  listenBookmark: ['data']
})

/** -------------------- Initial State ------------------- */
export const INITIAL_STATE = {
  bookmark: null
}

/** -------------------- Reducers ------------------------ */
export const listenBookmark = (state, action) => ({
  ...state,
  bookmark: action.data
})

/** ----------- Hookup Reducers to Type ------------- */
export const bookmarkReducer = createReducer(INITIAL_STATE, {
  [Types.LISTEN_BOOKMARK]: listenBookmark
})
