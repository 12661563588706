import React, { useRef, memo, useEffect } from "react"
import bookmarkBlueIcon from "../../assets/icons/bookmark-blue.png"
import bookmarkActiveIcon from "../../assets/icons/bookmark-active.png"

const MarkerComponent = ({
  position,
  bookmarks,
  onClickBookmark,
  isPlayed,
  isActive,
  index,
  bookmarksEl
}) => {
  const renderCounter = useRef(0)

  const BookmarkMark = () => {
    const isBookmark =
      bookmarksEl.content &&
      bookmarksEl.content.kompetensi &&
      bookmarksEl.content.kompetensi[0].split(" - ")
    return (
      <div className="d-flex">
        <div
          className={`icon-wrapper ${
            !isBookmark || isBookmark.length < 2 ? "bookmark" : ""
          } ${isActive ? "active" : ""}`}
          onClick={() => {
            // console.log({
            //   bookmarks,
            //   postiion: bookmarksEl,
            //   bookmarkIdx: position.bookmarkIdx
            // })

            onClickBookmark(bookmarksEl)
          }}
        >
          <img
            src={isActive ? bookmarkActiveIcon : bookmarkBlueIcon}
            alt="bookmark"
          />
        </div>
        {/* Index kompetensi */}
        <div className="skill-wrapper">
          {bookmarksEl?.content?.kompetensi?.length > 1 && (
            <div>
              <div className="skill-number">
                <span>...</span>
              </div>
            </div>
          )}
          {bookmarksEl?.content?.kompetensi?.length === 1 &&
            bookmarksEl?.content?.kompetensi?.map((v, i) => {
              return (
                <div key={i} className="skill-number">
                  <span>{v.slice(0, 4)}</span>
                </div>
              )
            })}
        </div>
      </div>
    )
  }
  return (
    <div className="d-flex">
      <div
        className="d-flex"
        style={{
          position: "relative",
          left: position?.left,
          top: position?.top
        }}
      >
        {bookmarksEl && (
          <div
            className="bookmark-line"
            style={{
              height: position?.offsetHeight,
              background: isPlayed ? "#0064ff" : "#D3D3D3"
            }}
          >
            {position.markStart && <BookmarkMark />}
          </div>
        )}
      </div>
    </div>
  )
}

export default MarkerComponent

// export default memo(MarkerComponent, (prevProps, nextProps) => {
//   const memorize =
//     prevProps.isPlayed === nextProps.isPlayed &&
//     prevProps.isActive === nextProps.isActive &&
//     prevProps.position.markStart === nextProps.position.markStart

//   if (!memorize) {
//     console.log({ prevProps, nextProps })
//   }

//   // const memorize =
//   //   prevProps.isPlayed === nextProps.isPlayed &&
//   //   prevProps.isActive === nextProps.isActive &&
//   //   JSON.stringify(prevProps.bookmarksEl) === JSON.stringify(nextProps.bookmarksEl) &&
//   //   prevProps.position.markStart ===  nextProps.position.markStart

//   return memorize
// })
