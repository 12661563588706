import { useEffect } from 'react'

export default function useTrackData ({ dataTrack, handleDataReceived }) {
  useEffect(() => {
    if (dataTrack) {
      const onDataReceived = (data) => {
        handleDataReceived(data)
      }

      dataTrack.on('message', onDataReceived)

      return () => {
        dataTrack.off('message', onDataReceived)
      }
    }
  }, [dataTrack, handleDataReceived])

  return null
}
