import PropTypes from "prop-types"
import { useEffect, useRef, useState } from "react"
import MarkerComponent from "./MarkerComponent"
import { Fragment } from "react"

function TranscriptionSection({
  show,
  bookmarks,
  speakerTranscriptions,
  onClickBookmark,
  editHighlightData,
  isShowEditHighlight,
  timestampPlayed
}) {
  const [positions, setPositions] = useState([])
  const [marked, setMarked] = useState(0)
  const mark = document.getElementsByClassName("marked")

  useEffect(() => {
    if (speakerTranscriptions) {
      const heightAboveEl = 166 - 34
      const marks = []
      const heights = []
      let bookmarkIdx = -1
      for (let i = 0; i < mark.length; i++) {
        let element
        mark[i].className.split(" ").includes("markStart") && bookmarkIdx++
        if (mark.length > 0 && i !== 0) {
          element = {
            offsetTop: mark[i].offsetTop,
            offsetHeight: mark[i].offsetHeight,
            top:
              mark[i].offsetTop -
              heightAboveEl -
              heights.reduce((a, b) => a + b),
            bookmark:
              mark[i].offsetTop -
              heightAboveEl -
              heights.reduce((a, b) => a + b),
            left:
              marks[i - 1].offsetTop + marks[i - 1].offsetHeight >
                mark[i].offsetTop &&
              mark[i].className.split(" ").includes("markStart")
                ? marks[i - 1].left + 100
                : 0,
            markStart: mark[i].className.split(" ").includes("markStart"),
            bookmarkIdx,
            content: mark[i].innerText
          }
          marks.push(element)
          heights.push(mark[i].offsetHeight)
        } else if (mark.length > 0) {
          heights.push(mark[i].offsetHeight)
          element = {
            offsetTop: mark[i].offsetTop,
            offsetHeight: mark[i].offsetHeight,
            left: "0px",
            top: mark[i].offsetTop - heightAboveEl,
            bookmark: mark[i].offsetTop - heightAboveEl,
            markStart: mark[i].className.split(" ").includes("markStart"),
            bookmarkIdx,
            content: mark[i].innerText
          }
          marks.push(element)
        }
      }
      setPositions([...marks])
      // set time out to wait className marked appear in DOM
      setTimeout(() => {
        setMarked(document.getElementsByClassName("marked").length)
      }, 100)
    }
  }, [speakerTranscriptions, bookmarks, mark.length, mark])

  return (
    <div className={`speech-to-text d-flex ${show ? "disable-scroll-parent" : ""}`}>
      {speakerTranscriptions}
      <div className="bookmark d-flex">
        <div>
          {positions.length > 1 &&
            positions.map((position, key) => {
              let isPlayed = false,
                isActive = false

              if (bookmarks[position.bookmarkIdx]) {
                isPlayed =
                  bookmarks[position.bookmarkIdx].duration.startTimestamp <=
                    timestampPlayed &&
                  timestampPlayed <
                    bookmarks[position.bookmarkIdx].duration.endTimestamp

                isActive =
                  (editHighlightData === bookmarks[position.bookmarkIdx] &&
                    isShowEditHighlight) ||
                  (bookmarks[position.bookmarkIdx].duration.startTimestamp <=
                    timestampPlayed &&
                    timestampPlayed <
                      bookmarks[position.bookmarkIdx].duration.endTimestamp)
              }
              return (
                <Fragment key={`item-${key}`}>
                  <MarkerComponent
                    key={`item-${key}`}
                    index={key}
                    position={position}
                    bookmarksEl={bookmarks[position.bookmarkIdx]}
                    isPlayed={isPlayed}
                    isActive={isActive}
                    timestampPlayed={timestampPlayed}
                    editHighlightData={editHighlightData}
                    onClickBookmark={onClickBookmark}
                    isShowEditHighlight={isShowEditHighlight}
                  />
                </Fragment>
              )
            })}
        </div>
      </div>
    </div>
  )
}

TranscriptionSection.propTypes = {
  speakerTranscriptions: PropTypes.element
}

export default TranscriptionSection
