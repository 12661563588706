import React from "react"
import chatIcon from "../../assets/icons/chat.png"
import shareScreenIcon from "../../assets/icons/share-screen.png"
import onMicIcon from "../../assets/icons/mic.png"
import onCamIcon from "../../assets/icons/opencam.png"
import endCallIcon from "../../assets/icons/endcall.png"
import meatballIcon from "../../assets/icons/meatball.svg"
import offcamIcon from "../../assets/icons/offcam.png"
import offmicIcon from "../../assets/icons/offmic.png"
import bookmarkIcon from "../../assets/icons/bookmark-menu.png"
import noteIcon from "../../assets/icons/note.png"
import RefreshIcon from "@material-ui/icons/Refresh"
import { IconButton } from "@material-ui/core"
import { variableSession } from "../../constants/otherConstants"

function AssessmentMenu(props) {
  const {
    type,
    isShowChatroom,
    setIsShowChatroom,
    isSharingScreen,
    setIsSharingScreen,
    isOnCam,
    setisOnCam,
    isOnMic,
    setIsOnMic,
    isShowFGD,
    setIsShowFGD,
    isShowBookmark,
    setIsShowBookmark,
    isShowQuestionList,
    setIsShowQuestionList,
    onClickMoreMenu,
    isUnread
  } = props

  const handleRefresh = () => {
    if (confirm("Apakah anda ingin mereload halaman interaksi?")) {
      localStorage.setItem(variableSession.specialReload, true)
      document.location.href = document.location.href
    } else {
      // DO NOTHING
    }
  }

  return (
    <div>
      <div
        className={`menu-wrapper ${
          type === "assessor" ? "assessor" : "participant"
        } ${
          isShowBookmark === "initial-value"
            ? "d-block"
            : isShowBookmark === "show"
            ? "d-none"
            : "d-block"
        } d-flex justify-content-end`}
      >
        {type === "assessor" && (
          <>
            <div className="time-counter">
              <p>00:12:28</p>
            </div>
            <div
              className="bookmark-wrapper d-flex"
              onClick={() => setIsShowBookmark("show")}
            >
              <img src={bookmarkIcon} alt="bookmark" className="bookmark-icon-btn" />
              <p>Bookmark</p>
            </div>
            <div
              className={"icon-wrapper note d-flex justify-content-center"}
              onClick={() => setIsShowQuestionList(!isShowQuestionList)}
            >
              <img src={noteIcon} alt="sharescreen" className="note-icon-btn"/>
            </div>
          </>
        )}

        <div className="">
          <IconButton
            title="Muat Ulang"
            color="secondary"
            className="refresh-icon"
            onClick={handleRefresh}
          >
            <RefreshIcon />
          </IconButton>
        </div>
        <div className="chat-icon">
          {isUnread && <div className="unread"></div>}
          <div
            className="chat-icon-btn icon-wrapper d-flex justify-content-center"
            onClick={() => setIsShowChatroom(!isShowChatroom)}
          >
            <img src={chatIcon} alt="chat" className="chat-icon-img"/>
          </div>
        </div>
        <div
          className={`icon-wrapper ${
            isSharingScreen ? "sharing" : ""
          } d-flex justify-content-center`}
          onClick={() => setIsSharingScreen(!isSharingScreen)}
        >
          <img src={shareScreenIcon} alt="sharescreen" />
        </div>
        <div
          className={`icon-wrapper ${
            !isOnMic ? "off" : ""
          } d-flex justify-content-center`}
          onClick={() => setIsOnMic(!isOnMic)}
        >
          <img src={isOnMic ? onMicIcon : offmicIcon} alt="mic" />
        </div>
        <div
          className={`icon-wrapper ${
            !isOnCam ? "off" : ""
          } d-flex justify-content-center `}
          onClick={() => setisOnCam(!isOnCam)}
        >
          <img src={isOnCam ? onCamIcon : offcamIcon} alt="cam" />
        </div>
        <div className="icon-wrapper d-flex justify-content-center">
          <img src={endCallIcon} alt="endcall" />
        </div>

        <div className="moremenu-parent meatball-icon" onClick={onClickMoreMenu}>
          <div className="moremenu-subparent d-flex justify-content-center">
            <img src={meatballIcon} alt="meatball" className="moremenu-btn"/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AssessmentMenu
