import { useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Creators as RoomActions } from "../redux/RoomRedux"
import useRoomService from "./roomService"

/**
 * Toggle on/off local participant microphone
 *
 * References:
 * https://www.twilio.com/docs/video/javascript-getting-started#mute-your-local-media
 */
export default function useToggleMicrophone() {
  const dispatch = useDispatch()
  const { room, isMicrophoneOn } = useSelector((state) => state.room)
  const { updateRoomLog, updateLogPeserta } = useRoomService()

  const toggleMicrophone = useCallback(() => {
    if (room) {
      if (isMicrophoneOn) {
        // turn off
        room.localParticipant.audioTracks.forEach((publication) => {
          publication.track.disable()
        })

        updateRoomLog({ succes: true, message: "Mematikan Microphone" })
        updateLogPeserta({ succes: true, message: "Mematikan Microphone" })
      } else {
        // turn on
        room.localParticipant.audioTracks.forEach((publication) => {
          publication.track.enable()
        })
        updateRoomLog({ succes: true, message: "Menyalakan Microphone" })
        updateLogPeserta({ succes: true, message: "Menyalakan Microphone" })
      }
      dispatch(RoomActions.toggleMicrophone())
    }
  }, [room, dispatch, isMicrophoneOn])

  return { isMicrophoneOn, toggleMicrophone }
}
