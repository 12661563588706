import React, { useState } from 'react'
import LobbyHeader from '../LobbyHeader'
import SessionEndsFeedback from './SessionEndsFeedback'
import SesssionEndsFooter from './SesssionEndsFooter'

function SessionEndsWrapper () {
  const [rating, setRating] = useState(3)
  const [feedbackText, setFeedbackText] = useState('')
  return (
    <div className='lobby-wrapper'>
      <LobbyHeader />
      <SessionEndsFeedback
        type='assessor'
        ratingValue={rating}
        handleRating={setRating}
        feedbackText={feedbackText}
        onChangeText={(e) => setFeedbackText(e.target.value)}
      />
      <SesssionEndsFooter type={'assessor'} />
    </div>
  )
}

export default SessionEndsWrapper
