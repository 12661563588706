import { useState } from "react"

let INITIAL_VALUE = {
  isShowMoreMenu: false,
  isShowCamMenu: false,
  isShowMicMenu: false,
  isShowQuestionList: false,
  isShowFGD: false,
  isShowConfirmation: false,
  isShowBookmark: "initial-value",
  isShowChatroom: false,
  isShowListParticipant: false
}

export default function usePopupVideoFrame() {
  const [popupState, setPopupState] = useState(INITIAL_VALUE)

  const setState = (key, param) => {
    if (typeof param === "function") {
      if(key === 'isShowQuestionList') INITIAL_VALUE.isShowQuestionList = param(INITIAL_VALUE.isShowQuestionList)
      if(key === 'isShowFGD') INITIAL_VALUE.isShowFGD = param(INITIAL_VALUE.isShowFGD)
      
      setPopupState((c) => ({ ...INITIAL_VALUE, [key]: param(c[key]) }))
    } else {
      if(key === 'isShowQuestionList') INITIAL_VALUE.isShowQuestionList = param
      if(key === 'isShowFGD') INITIAL_VALUE.isShowFGD = param

      setPopupState((c) => ({ ...INITIAL_VALUE, [key]: param }))
    }
  }

  const setIsShowMoreMenu = (param) => setState("isShowMoreMenu", param)
  const setIsShowCamMenu = (param) => setState("isShowCamMenu", param)
  const setIsShowMicMenu = (param) => setState("isShowMicMenu", param)
  const setIsShowFGD = (param) => setState("isShowFGD", param)
  const setIsShowConfirmation = (param) => setState("isShowConfirmation", param)
  const setIsShowBookmark = (param) => setState("isShowBookmark", param)
  const setIsShowChatroom = (param) => setState("isShowChatroom", param)
  const setIsShowQuestionList = (param) => setState("isShowQuestionList", param)
  const setIsShowListParticipants = (param) => setState("isShowListParticipant", param)
  return {
    ...popupState,
    setIsShowMoreMenu,
    setIsShowCamMenu,
    setIsShowMicMenu,
    setIsShowQuestionList,
    setIsShowFGD,
    setIsShowConfirmation,
    setIsShowBookmark,
    setIsShowChatroom,
    setIsShowListParticipants
  }
}
