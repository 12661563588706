import PropTypes from 'prop-types'
import { useEffect, useRef } from 'react'

function DynamicInputText ({ value, onChange, forwardedRef, ...rest }) {
  /**
   * adjust input size
   * https://stackoverflow.com/questions/20727692/dynamically-increase-input-type-text-textbox-width-according-to-the-characters-e
   * @param {obj} obj input text object
   */
  function expand (obj) {
    if (obj.value.length > 0) obj.size = obj.value.length
    else obj.size = 1
  }

  const ref = useRef()
  useEffect(() => {
    if (forwardedRef?.current) expand(forwardedRef.current)
    else expand(ref.current)
  }, [value, forwardedRef])

  return (
    <input
      {...rest}
      ref={forwardedRef || ref}
      size={value.length}
      value={value}
      onChange={onChange}
      style={{ fontFamily: 'Courier' }}
      type='text'
    />
  )
}

DynamicInputText.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
}

export default DynamicInputText
