import React from 'react'

function SesssionEndsFooter ({ type }) {
  return (
    <div className='session-footer'>
      <p>
        {type === 'assessor'
          ? 'Ketahui cara platform kami memudahkan pekerjaan Asesor '
          : 'Temukan Aspirasi Karir Kamu melalui Tes Ini, GRATIS!'}
      </p>
      <button className='btn'>
        {type === 'assessor' ? 'VANIA' : 'Talent Quiz'}
      </button>
    </div>
  )
}

export default SesssionEndsFooter
