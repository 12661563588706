import React from 'react'
import MobileRoomContainer from '../components/MobileRoom/MobileRoomContainer'

function MobileRoom() {
  return (
    <MobileRoomContainer />
  )
}

export default MobileRoom
