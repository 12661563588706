import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import firebase from "firebase/app"
import { variableSession } from "../constants/otherConstants"

export default function useTimestamp () {
  const { configData: config } = useSelector(state => state.config)
  const { room } = useSelector(state => state.room)
  const { isLoggedIn } = useSelector(state => state.auth)
  const [timestamp, setTimestamp] = useState(null)

  window.addEventListener("pagehide", () => {
    localStorage.removeItem(variableSession.loadingSession)
  })

  // listen timer reference
  useEffect(() => {
    let loadingCounter

    if (config && room && isLoggedIn) {
      const database = firebase.database()

      const timerNode = `${config.timer_reference}/${room.sid}/timestamp`

      const timerRef = database.ref(timerNode)
      const handleValueUpdate = snapshot => {
        const data = snapshot.val()
        if (data) {
          // LOADING COUNTER STARTS
          loadingCounter = localStorage.getItem(variableSession.loadingSession)
          if (loadingCounter) {
            loadingCounter = JSON.parse(loadingCounter).counter || 0
          }
          if (loadingCounter > 0) {
            console.log(`INTERAKSI dimuat dalam ${(loadingCounter / 100).toFixed(2)} s`)
          }
          localStorage.removeItem(variableSession.loadingSession)
          // LOADING COUNTER ENDS

          setTimestamp(data)
          console.log('timestamp', data)
          timerRef.off('value')

        }
      }
      timerRef.on('value', handleValueUpdate)

      return () => {
        timerRef.off('value')
      }
    }
  }, [config, isLoggedIn, room])

  return { timestamp }
}
