import { VirtualBackgroundProcessor } from "@twilio/video-processors"
import { useCallback, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import Video from "twilio-video"
import useQuery from "./useQuery"
import SampleImage from "../assets/images/living_room.jpg"

/**
 * References:
 * https://www.twilio.com/blog/2018/06/switching-cameras-twilio-video-chat.html
 * https://www.twilio.com/docs/video/configuring-audio-video-input-and-output-devices
 */
export default function useLocalVideo() {
  const { room } = useSelector((state) => state.room)
  const [videoInputList, setVideoInputList] = useState([])
  const location = useLocation()
  const query = useQuery()

  // init video list options
  useEffect(() => {
    if (room) {
      if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
        console.log("enumerateDevices() not supported.")
      } else {
        const getVideoDevices = () => {
          navigator.mediaDevices
            .enumerateDevices()
            .then((mediaDevices) => {
              let cameras = []
              mediaDevices.forEach((device) => {
                const arrayVirtual = ["OBS", "Virtual", "VCam", "Snap", "XSplit", "ManyCam", "ChromaCam", "SplitCam", "AlterCam", "WebcamMax", "YouCam", "vMix", "EpocCam", "DroidCam", "iVCam", "e2eSoft", "NDI", "CamTwist", "SplitmediaLabs", "SparkoCam", "Webcamoid", "WebcamStudio"]
                const regexPattern = new RegExp(arrayVirtual.join("|"), "i")

                if (device.kind === "videoinput" && !regexPattern.test(device.label)) {
                  cameras.push({ label: device.label, value: device.deviceId })
                  // menyesuaikan format selection option
                }
              })
              setVideoInputList(cameras)
            })
            .catch((error) =>{
              console.log(`Error: ${error.name}: ${error.message}`)
              window.top.postMessage("errorVideo", "*")
            }
            )
        }
        getVideoDevices()

        navigator.mediaDevices.ondevicechange = () => {
          setVideoInputList([])
          getVideoDevices()
        }
      }

      return () => {
        setVideoInputList([])
      }
    }
  }, [room])

  // update video input
  const [videoInputDeviceId, setVideoInputDeviceId] = useState("")

  /**
   * Set default camera
   * - the default capture devices will be listed first.
   * References:
   * https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/enumerateDevices#return_value
   */
  const [defaultUpdated, setDefaultUpdated] = useState(false)
  useEffect(() => {
    if (videoInputList.length > 0 && !defaultUpdated) {
      const camName = new URLSearchParams(location.search).get("camera")
      const usedCamera = videoInputList.filter(
        (cam) => cam.label === camName
      )[0]
      setVideoInputDeviceId(usedCamera?.value || videoInputList[0].value)
      if (!videoInputDeviceId) {
        if (usedCamera) updateVideoInput(usedCamera.value)
        else updateVideoInput(videoInputList[0].value)
      }
      setDefaultUpdated(true)
    }
  }, [defaultUpdated, videoInputList])

  const updateVideoInput = useCallback(
    (deviceId) => {
      if (room) {
        const localVideoTrack = Array.from(
          room.localParticipant.videoTracks.values()
        )[0]?.track
        room.localParticipant.videoTracks.forEach((publication) => {
          if (publication.trackName !== "screen") {
            publication.track.stop()
          }
        })
        if (localVideoTrack) {
          localVideoTrack.restart({ deviceId })
          setVideoInputDeviceId(deviceId)
        }
      }
    },
    [room]
  )

  // References :
  // https://www.twilio.com/blog/change-background-video-calls-twilio-video-processors-library
  // https://www.twilio.com/docs/video/build-js-video-application-recommendations-and-best-practices#testing-the-microphone-and-camera
  // https://giters.com/twilio/twilio-video-processors.js
  const updateVirtualBackground = useCallback(
    async (used) => {
      if (room) {
        const localVideoTrack = Array.from(
          room.localParticipant.videoTracks.values()
        )[0]?.track

        if (localVideoTrack) {
          if (localVideoTrack.processor) {
            localVideoTrack.removeProcessor(localVideoTrack.processor);
          }
          if (used) {
            try {
              // img background
              const loadImage = (name) =>
                new Promise((resolve) => {
                  const image = new Image();
                  image.src = name;
                  image.onload = () => resolve(image);
                });

              let images = await loadImage(SampleImage)

              const imageBackground = new VirtualBackgroundProcessor({
                assetsPath: '/assets_twilio',
                backgroundImage: images,
                maskBlurRadius: 5,
              });

              await imageBackground.loadModel()
              localVideoTrack.addProcessor(imageBackground)

            } catch (error) {
              localVideoTrack.restart()
            }
          }

        }
      }
    },
    [room]
  )

  return {
    videoInputList,
    videoInputDeviceId,
    updateVideoInput,
    updateVirtualBackground
  }
}
