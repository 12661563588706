import { all, call, put, takeLatest } from "redux-saga/effects"
import { signIn, signOut } from "../sevices/authService"
import { Types as AuthTypes, Creators as AuthActions } from "../redux/AuthRedux"

// login saga
function* loginSaga(actions) {
  try {
    const user = yield call(signIn, actions.data)
    if (user) {
      yield put(AuthActions.loginSuccess(user))
    }
  } catch (error) {
    yield put(AuthActions.loginFailed(error))
  }
}

export function* loginRequestSaga() {
  yield takeLatest(AuthTypes.LOGIN_REQUEST, loginSaga)
}

// logout saga
function* logoutSaga(action) {
  try {
    const result = yield call(signOut)
    yield put(AuthActions.logoutSuccess(result))
  } catch (error) {
    yield put(AuthActions.logoutFailed(error))
  }
}

export function* logoutRequestSaga() {
  yield takeLatest(AuthTypes.LOGOUT_REQUEST, logoutSaga)
}

// auth saga
export function* authSaga() {
  yield all([call(loginRequestSaga), call(logoutRequestSaga)])
}
