import firebase from "firebase/app"

/**
 * add key
 * @param {string} path
 */
export const addNewKey = ({ path }) =>
  firebase.database().ref().child(path).push().key

/**
 * References:
 * https://firebase.google.com/docs/database/web/read-and-write
 *
 * @param {string} path
 * @param {object} data
 */
export function updateData({ path, data }) {
  const dataRef = firebase.database().ref().child(path)
  return dataRef.set(data)
}

export function getData(path) {
  return firebase
    .database()
    .ref(path)
    .once("value")
    .then((snapshot) => snapshot.val())
}

/**
 * References:
 * https://firebase.google.com/docs/database/web/read-and-write#update_specific_fields
 *
 * @param {object} data { path1: value1, path2: value2, ... }
 */
export function updateMultipleData({ data }) {
  return firebase.database().ref().update(data)
}

/**
 *
 * @param {string} path
 */
export function removeData({ path }) {
  return firebase.database().ref(path).remove()
}
