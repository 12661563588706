import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { markWords } from "../lib/markWords"
import { Creators as ConfigActions } from "../redux/ClientLoadingRedux"
let waitHighlightData, countWaitHighlight = 0, sttElm

export default function useTranscriptHighlight({
  transcriptData,
  highlightData,
  transcriptEmpty
}) {
  // marked transcription
  const dispatch = useDispatch()
  const { loadingClient } = useSelector((state) => state.client)

  const [transcriptHighlight, setTranscriptHighlight] = useState([])
  const [loadingTranscription, setLoadingTranscription] = useState(true)
  const [timeoutError, setTimeoutError] = useState(false)
  // console.log(transcriptHighlight)
  // console.log(transcriptData)

  useEffect(() => {
    setLoadingTranscription(loadingClient)
  }, [loadingClient])

  useEffect(() => {
    let result = transcriptData ? [...transcriptData] : []
    // if highlightData > 0, add mark to transcriptData
    if (
      (transcriptData?.length > 0 && highlightData.length) ||
      (transcriptData?.length > 0 && highlightData?.length === 0)
    ) {
      // for each highlightData
      for (let i = 0; i < highlightData.length; i++) {
        // clone transcriptData by highlight.transcriptIdx
        const b = highlightData[i]
        const t = { ...result[b.transcriptIdx] }

        // add bookmarks (highlights) to transcriptData by highlight.transcriptIdx
        t.bookmarks = t.bookmarks ? [...t.bookmarks, b] : [b]

        // add mark to related words
        t.words = markWords({
          words: t.words,
          startIndex: b.indexInWords.startIndex,
          endIndex: b.indexInWords.endIndex,
          includeVideoClip: b.videoClip,
          bookmarkId: b.bookmarkId
        })

        // update result
        result = result.map((v, idx) =>
          idx !== b.transcriptIdx ? v : { ...t }
        )
      }
    }


    // countWaitHighlight += 1
    // console.log(countWaitHighlight, loadingTranscription)
    // window.clearTimeout(timeoutWait)
    // timeoutWait = window.setTimeout(() => {
    //   if (countWaitHighlight > 3) {
    //     setTranscriptHighlight(result)
    //     dispatch(ConfigActions.setClientLoading(false))
    //     setTimeoutError(true)
    //     return
    //   }
    //   if (result) {
    //     setTranscriptHighlight(result)
    //     dispatch(ConfigActions.setClientLoading(false))
    //     countWaitHighlight = 0
    //   }
    // }, 250)

    // let waitHighlightData, countWaitHighlight = 0, timeoutWait
    if (result && result.length) {
      setTranscriptHighlight(result)
      dispatch(ConfigActions.setClientLoading(false))
    }

    sttElm = document.querySelector(".speech-to-text")
    let testQuery = /highlight/.test(window.location.search)
    if (sttElm || !testQuery) {
      window.clearInterval(waitHighlightData)
      return
    }

    waitHighlightData = window.setInterval(() => {
      // console.log(result)
      testQuery = /highlight/.test(window.location.pathname)
      sttElm = document.querySelector(".speech-to-text")
      if (sttElm || !testQuery) {
        window.clearInterval(waitHighlightData)
        return
      }

      countWaitHighlight += 4

      if (countWaitHighlight > 240) {
        setTranscriptHighlight(result)
        dispatch(ConfigActions.setClientLoading(false))
        setTimeoutError(true)
        window.clearInterval(waitHighlightData)
      }

      if (result) {
        if (!transcriptEmpty) {
          setTranscriptHighlight(result)
        }
        dispatch(ConfigActions.setClientLoading(false))
        window.clearInterval(waitHighlightData)
      }
    }, 250)

  }, [highlightData, transcriptData])

  return { transcriptHighlight, loadingTranscription, timeoutError, transcriptEmpty }
}
