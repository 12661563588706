import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'

/**
 * References:
 * https://sdk.twilio.com/js/video/releases/2.8.0/docs/LocalVideoTrack.html#restart
 */
export default function useVideoResolution ({ initial }) {
  const { room } = useSelector(state => state.room)
  const [videoResolution, setVideoResolution] = useState({ width: initial.width, height: initial.height })

  const updateVideoResolution = useCallback((constraints) => {
    if (room) {
      const localVideoTrack = Array.from(room.localParticipant.videoTracks.values())[0].track
      localVideoTrack.restart({ ...initial, ...constraints })
      setVideoResolution(constraints)
    }
  }, [room, initial])

  return { videoResolution, updateVideoResolution }
}
