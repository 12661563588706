import React, { useEffect, useState } from 'react'
import { getRandomColor } from '../../../lib/general'

const MobileAvatar = ({
  char,
  style
}) => {

  const {defaultColor} = style
  const [styling, setStyling] = useState()

  useEffect(() => {
    setStyling({
      backgroundColor: defaultColor || getRandomColor(),
      borderRadius: "50%",
      display: " flex",
      justifyContent: "center",
      alignItems: "center",
      ...style
    })
  },[])

  return (
    <div style={styling}>
      { char?.toUpperCase() }
    </div>
  )
}

export default MobileAvatar
