import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Creators as SnackActions } from '../redux/SnackbarRedux'

/**
 * References:
 * https://material-ui.com/components/snackbars/#consecutive-snackbars
 */
export default function useSnackbar () {
  const dispatch = useDispatch()
  const { snackPack, open, messageInfo } = useSelector(state => state.snackbar)

  React.useEffect(() => {
    dispatch(SnackActions.popSnackPack())
  }, [snackPack, messageInfo, open, dispatch])

  const newMessage = (message) => () => {
    dispatch(SnackActions.newSnack(message))
  }

  const handleClose = (event, reason) => {
    dispatch(SnackActions.closeSnack(event, reason))
  }

  const handleExited = () => {
    dispatch(SnackActions.snackExited())
  }

  return {
    open,
    messageInfo,
    newMessage,
    handleClose,
    handleExited
  }
}
