import { combineReducers } from "redux"
import { authReducer } from "./AuthRedux"
import { configReducer } from "./ConfigRedux"
import { chatReducer } from "./ChatRedux"
import { roomReducer } from "./RoomRedux"
import { bookmarkReducer } from "./BookmarkRedux"
import { snackbarReducer } from "./SnackbarRedux"
import { lastSavedReducer } from "./LastSavedRedux"
import { clientReducer } from "./ClientLoadingRedux"

export const rootReducer = combineReducers({
  config: configReducer,
  room: roomReducer,
  auth: authReducer,
  chat: chatReducer,
  bookmark: bookmarkReducer,
  snackbar: snackbarReducer,
  lastSaved: lastSavedReducer,
  client: clientReducer
})
