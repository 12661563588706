// source: https://stackoverflow.com/questions/3605214/javascript-add-leading-zeroes-to-date
export const addLeadingZero = (num) => ("0" + num.toString()).slice(-2)

/**
 * get seconds 0-59 from time counter
 * @param {number} counter time counter
 */
export const getSeconds = (counter) => Math.floor(counter) % 60

/**
 * get minutes 0-59 from time counter
 * @param {number} counter time counter
 */
export const getMinutes = (counter) => Math.floor(counter / 60) % 60

/**
 * get hours 0-... from timer
 * @param {number} counter time counter
 */
export const getHours = (counter) => Math.floor(counter / 3600)

/**
 *
 * @param {number} counter time counter in seconds
 * @returns hh:mm:ss
 */
export const formatStringTimer = (counter) =>
  `${addLeadingZero(getHours(counter))}:${addLeadingZero(
    getMinutes(counter)
  )}:${addLeadingZero(getSeconds(counter))}`

export const formatStringTimestamp = ({
  startTimestamp,
  endTimestamp,
  initialTimestamp
}) => {
  const start = formatStringTimer((startTimestamp - initialTimestamp) / 1000)

  if (!endTimestamp) return start

  const end = formatStringTimer((endTimestamp - initialTimestamp) / 1000)

  return `${start} - ${end}`
}

/**
 *
 * @param {string} string time counter in "00:01:01 - 00:01:21" format
 * @returns seconds
 */
export const stringSeconds = (string) => {
  const total = string.split(":")
  const h = parseInt(total[0])
  const m = parseInt(total[1])
  const s = parseInt(total[2])
  return h * 3600 + m * 60 + s
}

export const formatStringToSecond = (string) => {
  const splittedString = string.split(" - ")
  let start = stringSeconds(splittedString[0])
  let end = stringSeconds(splittedString[1])

  return { start, end }
}
