import React, { useEffect, useRef } from "react"
import PropTypes from 'prop-types'
import closeIcon from '../../assets/icons/close.png'
import bookmarkIcon from '../../assets/icons/bookmark-sidebar.png'
import personIcon from '../../assets/icons/person.png'
import existingBookmarkIcon from '../../assets/icons/bookmark-existing.png'
import savedBookmarkIcon from '../../assets/icons/saved-bookmark.png'
import recordIcon from "../../assets/icons/record.png"

function BookmarkSidebar ({ show, onHide, formComponent, bookmarkItems, stringTimeCounter }) {

  const node = useRef(null)
  const handleBookmarkOutsideClick = (e) => {
    if (show !== "show") return
    const elm = e.target
    // const elmClass = elm.className
    const test = node.current.contains(elm)
    if (!test) {
      // if (/bookmark/i.test(elmClass)) return
      onHide()
    }
  }

  useEffect(() => {
    if (show === "show") {
      document.addEventListener("mousedown", handleBookmarkOutsideClick)
    } else {
      document.removeEventListener("mousedown", handleBookmarkOutsideClick)
    }
    return () => document.removeEventListener("mousedown", handleBookmarkOutsideClick)
  }, [show])

  return (
    <div ref={node}
      className={`bookmark-sidebar-wrapper ${show === 'initial-value' ? 'd-none' : show === 'show' ? 'd-block' : 'd-none'}`}>
      <div className="bookmark-header d-flex justify-content-between">
        <p>Bookmark</p>
        <img src={closeIcon} alt="close" onClick={onHide} />
      </div>

      {/* TIMER UNTUK BOOKMARK SIDEBAR */}
      <div className="timer-bookmark">
        <img
          className="record-icon blink"
          src={recordIcon}
          alt="record"
        />
        <span className="stringTime">{stringTimeCounter || "12:29:00"}</span>
      </div>

      {formComponent}
      {/* <div className="new-bookmark">
                <div className="d-flex justify-content-between">
                    <div className="timestamp d-flex">
                        <img src={bookmarkIcon} alt="bookmark-icon" />
                        <p>00:20:08 - 00:20:28</p>
                    </div>
                    <label className="switch">
                        <input type="checkbox" />
                        <span className="slider round"></span>
                    </label>
                </div>
                <textarea name="new-bookmark" cols="35" rows="6"></textarea>
                <br />
                <div className="d-flex justify-content-end">
                    <button className="btn btn-primary">Simpan</button>
                </div>
            </div> */}
      <div className="existing-bookmark">
        {bookmarkItems}
        {/* <div className="existing-bookmark-item">
                    <div className="timestamp d-flex">
                        <div className="assessor-info d-flex">
                            <img src={personIcon} alt="person" />
                            <p>Asesor 01</p>
                        </div>
                        <div className="time d-flex">
                            <img src={existingBookmarkIcon} alt="existing-bookmark" />
                            <p>00:20:28 - 00:20:28</p>
                        </div>
                    </div>
                    <textarea name="existing-bookmark" cols="35" rows="6" disabled></textarea>
                    <br />
                    <span>Kepemimpinan</span>
                    <span>Daya Analisa</span>
                </div>
                <div className="existing-bookmark-item">
                    <div className="timestamp d-flex">
                        <div className="assessor-info d-flex">
                            <img src={personIcon} alt="person" />
                            <p>Asesor 01</p>
                        </div>
                        <div className="time d-flex">
                            <img src={savedBookmarkIcon} alt="existing-bookmark" />
                            <p>00:25:28</p>
                        </div>
                    </div>
                    <textarea name="existing-bookmark" cols="35" rows="6" value="@peserta01 mampu menjelaskan tujuan dan pencapaian saat ini" disabled ></textarea>
                    <br />
                    <span>Kepemimpinan</span>
                    <span>Daya Analisa</span>
                </div> */}
      </div>
    </div>
  )
}

BookmarkSidebar.propTypes = {
  // show: PropTypes.string.isRequired,
  onHide: PropTypes.func.isRequired,
  formComponent: PropTypes.element
}

// BookmarkSidebar.propTypes = {
//   show: PropTypes.oneOf(["initial-value", "show", "hide"]).isRequired,
//   onHide: PropTypes.func.isRequired,
//   formComponent: PropTypes.element
// }

export default BookmarkSidebar
