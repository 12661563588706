import { createActions, createReducer } from "reduxsauce"

/** Types and Action Creator */
export const { Types, Creators } = createActions({
  connectRoomRequest: ["data"],
  connectRoomSuccess: ["payload"],
  connectRoomFailure: ["error"],

  disconnectRoom: null,

  addParticipant: ["data"],
  removeParticipant: ["data"],

  toggleCamera: null,
  toggleMicrophone: null,
  toggleScreenShare: ["data"],

  setDataTrack: ["data"],
  setDominantSpeaker: ["data"]
})

/** Initial State */
export const INITIAL_STATE = {
  isError: false,
  error: null,
  isConnecting: false,
  room: null,
  participants: [],
  isCameraOn: true,
  isMicrophoneOn: true,
  screenTrack: null,
  dataTrack: null,
  dominantSpeaker: null
}

/** Reducers */

// room
export const connectRoomRequest = (state) => ({
  ...state,
  isConnecting: true,
  isError: false,
  error: null,
  room: null
})

export const connectRoomSuccess = (state, action) => ({
  ...state,
  isConnecting: false,
  room: action.payload
})

export const connectRoomFailure = (state, action) => ({
  ...state,
  isConnecting: false,
  isError: true,
  action,
  error: action.error
})

export const disconnectRoom = (state, action) => ({
  ...state,
  isLoading: true
})

// participant
export const addParticipant = (state, action) => ({
  ...state,
  participants: [
    ...state.participants.filter((p) => p.sid !== action.data.sid), // TODO:
    action.data
  ]
})

export const removeParticipant = (state, action) => ({
  ...state,
  participants: state.participants.filter((p) => p.sid !== action.data.sid)
})

// toggle
export const toggleCamera = (state) => ({
  ...state,
  isCameraOn: !state.isCameraOn
})

export const toggleMicrophone = (state) => ({
  ...state,
  isMicrophoneOn: !state.isMicrophoneOn
})

export const toggleScreenShare = (state, action) => ({
  ...state,
  screenTrack: action.data
})

export const setDataTrack = (state, action) => ({
  ...state,
  dataTrack: action.data
})

export const setDominantSpeaker = (state, action) => ({
  ...state,
  dominantSpeaker: action.data
})

/** ----------- Hookup Reducers to Type ------------- */
export const roomReducer = createReducer(INITIAL_STATE, {
  // room
  [Types.CONNECT_ROOM_REQUEST]: connectRoomRequest,
  [Types.CONNECT_ROOM_SUCCESS]: connectRoomSuccess,
  [Types.CONNECT_ROOM_FAILURE]: connectRoomFailure,
  [Types.DISCONNECT_ROOM]: disconnectRoom,

  // participants
  [Types.ADD_PARTICIPANT]: addParticipant,
  [Types.REMOVE_PARTICIPANT]: removeParticipant,

  // toggle action
  [Types.TOGGLE_CAMERA]: toggleCamera,
  [Types.TOGGLE_MICROPHONE]: toggleMicrophone,
  [Types.TOGGLE_SCREEN_SHARE]: toggleScreenShare,

  [Types.SET_DATA_TRACK]: setDataTrack,
  [Types.SET_DOMINANT_SPEAKER]: setDominantSpeaker
})
