import React, { useCallback, useEffect, useRef, useState } from "react"
import { useHistory } from "react-router-dom"
import useQuery from "../../hooks/useQuery"
import useConfig from "../../hooks/useConfig"

import useRoomConnect from "../../hooks/useRoomConnect"
import useRoomDisconnect from "../../hooks/useRoomDisconnect"
import useParticipant from "../../hooks/useParticipant"

import useToggleCamera from "../../hooks/useToggleCamera"
import useToggleMicrophone from "../../hooks/useToggleMicrophone"
import useToggleScreenSharing from "../../hooks/useToggleScreenShare"
import useToggleRemoteMicrophone from "../../hooks/useToggleRemoteMicrophone"
import useDominantSpeaker from "../../hooks/useDominantSpeaker"
import useLocalAudio from "../../hooks/useLocalAudio"
import useLocalVideo from "../../hooks/useLocalVideo"
import useVideoResolution from "../../hooks/useVideoResolution"

import useFirebase from "../../hooks/useFirebase"
import useChat from "../../hooks/useChat"
import useTimeCounter from "../../hooks/useTimeCounter"
import useTimestamp from "../../hooks/useTimestamp"
import useBookmark from "../../hooks/useBookmark"

import { useDispatch } from "react-redux"
import { Creators as ChatActions } from "../../redux/ChatRedux"

import UnsupportedBrowserWarning from "../../components/UnsupportedBrowserWarning"
import MoreMenus from "../../components/popups/MoreMenus"
import RoomWrapper from "../../components/RoomWrapper"
import RoomHeader from "../../components/RoomHeader"
import ParticipantWrapper from "../../components/ParticipantWrapper"
import Participant from "./Participant"
import BookmarkSidebar from "../../components/AssessmentRoom/BookmarkSidebar"
import BookmarkForm from "../../components/AssessmentRoom/BookmarkForm"
import BookmarkItem from "../../components/AssessmentRoom/BookmarkItem"
import Loading from "../../components/Loading"
import SharedScreenWrapper from "../../components/SharedScreenWrapper"
import FGDPopup from "../../components/popups/FGDPopup"
import QuestionsListPopup from "../../components/QuestionsListPopup"
import Chatroom from "../../components/Chatroom"
import ChatSent from "../../components/ChatSent"
import ChatReceived from "../../components/ChatReceived"
import QuestionItem from "../../components/QuestionItem"
import SharedScreenFrame from "../../components/SharedScreenFrame"
import Warning from "../../components/popups/Warning"
import Snackbar from "@material-ui/core/Snackbar"

import {
  getKompetensi,
  getKompetensiRoom,
  getListPeserta
} from "../../lib/bookmarkFormatter"
import { collaboration } from "../../constants/connectionOptions"
import { useHotkeys } from "react-hotkeys-hook"
import useSnackbar from "../../hooks/useSnackbar"
import usePopupVideoFrame from "../../hooks/usePopupVideoFrame"
import ParticipantFrame from "../../components/ParticipantFrame"
import { Camera } from "@material-ui/icons"
import PermissionError from "../../components/PermissionError"
import { getData } from "../../sevices/firebaseService"
import useRoomService from "../../hooks/roomService"
import ErrorPage from "../../components/ErrorPage"

import Modal from "@material-ui/core/Modal"
import Typography from "@material-ui/core/Typography"
import Box from "@material-ui/core/Box"
import { variableSession } from "../../constants/otherConstants"
import { getRandomColor } from "../../lib/general"
import ErrorPage2 from "../../components/ErrorPage2"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60vw",
  background: "white",
  borderRadius: 8,
  padding: 10
}

const networkQuality = {
  0: "Terputus",
  1: "Sangat Buruk",
  2: "Buruk",
  3: "Cukup",
  4: "Bagus",
  5: "Sangat Bagus"
}

export default function Room() {
  const dispatch = useDispatch()
  const history = useHistory()
  const query = useQuery()
  const queryString = query.get("query")
  const urlConfig = query.get("url_config")
  const config = useConfig({ query: queryString, urlConfig, page: "interaksi" })
  const [permissionerror, setPermissionError] = useState(false)

  const [image, setImage] = React.useState({})
  const [openAttachment, setOpenAttachment] = React.useState(false)
  const handleOpenAttachment = () => setOpenAttachment(true)
  const handleCloseAttachment = () => setOpenAttachment(false)
  const [parti, setParti] = useState("")
  // const handleClose = () => setOpen(false)

  const { updateRoomLog } = useRoomService()

  const roomConnect = useRoomConnect({
    options: collaboration,
    setPermissionError
  })
  const { disconnect } = useRoomDisconnect()
  useEffect(() => {
    return () => {
      if (roomConnect.room) disconnect()
    }
  }, [roomConnect.room, disconnect])

  const {
    localParticipant,
    remoteParticipants,
    localParticipantConnectionLevel
  } = useParticipant()

  const handleError = (e) => {
    if (!permissionerror) {
      setPermissionError(e.name)
    }
    // setPermissionError(e)
  }

  const mailBodyFormat = (rawString) => {
    let result = rawString.replace(/\n/g, "<br />").replace(/-{4,}/g, "<hr>")
    return result
  }

  const dummyData = Array.from(Array(9).keys())

  const { isCameraOn, toggleCamera } = useToggleCamera()
  const { isMicrophoneOn, toggleMicrophone } = useToggleMicrophone()
  const [virtualBackgroundOn, setVirtualBackgroundOn] = useState(false)
  const {
    toggleScreenShare,
    screenTrack,
    remoteScreenTrack,
    handleRemoteScreenTrack,
    screenShareIdentity
  } = useToggleScreenSharing()
  const { sendData } = useToggleRemoteMicrophone()
  const { dominantSpeaker } = useDominantSpeaker()

  const {
    audioInputList,
    audioOutputList,
    audioInputDeviceId,
    audioOutputDeviceId,
    updateAudioInput,
    updateAudioOutput
  } = useLocalAudio()

  const { videoInputList, videoInputDeviceId, updateVideoInput, updateVirtualBackground } =
    useLocalVideo()
  const { videoResolution, updateVideoResolution } = useVideoResolution({
    initial: { ...collaboration.video, deviceId: videoInputDeviceId }
  })

  /**
   * toggle microphone remote participant
   */
  const onDataReceived = useCallback(
    (message) => {
      const data = JSON.parse(message)
      if (
        data &&
        data.type === "toggleMic" &&
        data.participant === roomConnect.room.localParticipant.sid
      ) {
        toggleMicrophone()
      }
    },
    [roomConnect.room, toggleMicrophone]
  )

  useFirebase()

  // duration / time counter
  const { timestamp } = useTimestamp()
  const { stringTimeCounter } = useTimeCounter({ timestamp })

  // ----- component state
  const {
    isShowMoreMenu,
    isShowCamMenu,
    isShowMicMenu,
    isShowQuestionList,
    isShowFGD,
    isShowConfirmation,
    isShowBookmark,
    isShowChatroom,
    setIsShowMoreMenu,
    setIsShowCamMenu,
    setIsShowMicMenu,
    setIsShowQuestionList,
    setIsShowFGD,
    setIsShowConfirmation,
    setIsShowBookmark,
    setIsShowChatroom
  } = usePopupVideoFrame()

  // chat
  const { unRead, sendMessage, chat } = useChat()
  useEffect(() => {
    if (unRead && isShowChatroom) {
      dispatch(ChatActions.messageRead())
    }
  }, [dispatch, isShowChatroom, unRead])

  const [fgdParticipantList, setFgdParticipantList] = useState([])

  useEffect(() => {
    const getFGDParticipantData = async (path) => {
      try {
        let pesertaFGD = await getData(`${path}/peserta`)
        let batchId = config.configData.batchId || ""
        let batchPath = batchId ? batchId : ""
        // PATH PROJECTS
        if (batchPath !== "" && !/^projects\//.test(batchPath)) {
          batchPath = `projects/${batchPath}`
        }
        pesertaFGD = pesertaFGD.map((peserta) => {
          return {
            email: peserta.email,
            uid: peserta.uid,
            reloadPath: `${batchPath}/raw_input/${peserta.uid}/in_basket/manualReload`
          }
        })

        setFgdParticipantList(pesertaFGD)
      } catch (e) {
        console.log({ e })
      }
    }
    if (
      config.configData &&
      config.configData.accessor &&
      config.configData.type === "fgd" &&
      fgdParticipantList.length === 0
    ) {
      const fgdPath = config.configData.observerPath.replace("/observer", "")
      getFGDParticipantData(fgdPath)
    }
  }, [config.configData, config])

  // bookmark
  const { bookmark, addBookmark } = useBookmark()
  const [currentTimestamp, setCurrentTimestamp] = useState(Date.now())
  useEffect(() => {
    if (isShowBookmark) setCurrentTimestamp(Date.now())
  }, [isShowBookmark])

  const onClickMoreMenu = () => {
    if (isShowCamMenu || isShowMicMenu) {
      setIsShowCamMenu(false)
      setIsShowMicMenu(false)
    } else {
      setIsShowMoreMenu(!isShowMoreMenu)
    }
  }

  const arrayToChunks = (arr, perChunk) => {
    const results = arr.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / perChunk)

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = [] // start a new chunk
      }

      resultArray[chunkIndex].push(item)

      return resultArray
    }, [])

    return results
  }

  const [chunks, setChunks] = useState()
  const [numberofchunks, setnumberofchunks] = useState()
  const [length, setLength] = useState()

  function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    })

    useEffect(() => {
      function handleResize() {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        })
      }
      window.addEventListener("resize", handleResize)
      handleResize()
      return () => window.removeEventListener("resize", handleResize)
    }, [])
    return windowSize
  }

  const size = useWindowSize()
  const avatarName = {}
  const sharingScreen = screenTrack || remoteScreenTrack ? "show" : "initial-value"

  useEffect(() => {
    let newArray = []
    if (1 === 1) {
      newArray = [...remoteParticipants]
      newArray.unshift(localParticipant)
    } else {
      newArray = [...dummyData]
    }
    
    if (newArray[0] === null) return
    // let arrL = newArray.length
    // let newnumberofchunks = 1
    // let newLength = 1

    // if (arrL === 2) newLength = 2
    // else if (arrL >= 3 && arrL <= 4) newLength = 4
    // else if (arrL >= 5 && arrL <= 6) newLength = 6
    // else if (arrL >= 7 && arrL <= 9) newLength = 8
    // else if (arrL >= 10 && arrL <= 12) newLength = 12
    // else if (arrL >= 13) newLength = 16

    // if (newLength === 4) newnumberofchunks = 2
    // else if (newLength === 8 || newLength === 6) newnumberofchunks = 3
    // else if (newLength === 12 || newLength === 16) newnumberofchunks = 4

    // const newChunks = arrayToChunks(newArray, newnumberofchunks)
    // setLength(newLength)
    // setnumberofchunks(newnumberofchunks)
    // setChunks(newArray)
    let width = 0
    let height = 0
    let ratio = 3/4
    let margin = 5
    const resizer = (width) => {

      const html = newArray.map((p, index) => {
        const styles = {
          height : width * ratio,
          width : width,
          margin : margin
        }

        const identity = JSON.parse(p.identity)
        const firstCharacter = identity?.name?.charAt(0).toUpperCase()
        let repeat = 1
        if (avatarName[firstCharacter]) {
          repeat = avatarName[firstCharacter] + 1
          avatarName[firstCharacter] = avatarName[firstCharacter] + 1
        }else{
          avatarName[firstCharacter] = 1
        }

        const defaultColor = getRandomColor(firstCharacter, repeat)
        if (index === 0)
          return (
            <Participant
              key={`${index}`}
              styles={styles}
              // key={`${index}-${key}`}
              frames={remoteParticipants?.length + 1}
              participant={localParticipant}
              name={`${
                JSON.parse(localParticipant.identity).name
              } ( Anda )`}
              isLocal={true}
              isAssessor={
                config.configData?.accessor ||
                config.configData?.data_peserta?.role ===
                  "admin"
              }
              audioOutputDeviceId={audioOutputDeviceId}
              defaultColor={defaultColor}
            />
          )
        else
          return (
            <Participant
              key={p.sid}
              styles={styles}
              frames={remoteParticipants.length + 1}
              participant={p}
              identity={identity}
              name={`${identity.name} ${
                identity.role !== "peserta"
                  ? `(${identity.role})`
                  : ""
              }`}
              fgdParticipantList={fgdParticipantList}
              config={config.configData}
              role={
                JSON.parse(localParticipant.identity).role
              }
              isLocal={false}
              isAssessor={
                JSON.parse(p.identity).role !== "peserta"
              }
              isTalking={dominantSpeaker === p}
              handleScreenTrack={handleRemoteScreenTrack}
              handleDataReceived={onDataReceived}
              handleMuteAudio={() => {
                console.log("handleMuteAudio #1")
                sendData({
                  type: "toggleMic",
                  participant: p.sid
                })
              }}
              audioOutputDeviceId={audioOutputDeviceId}
              defaultColor={defaultColor}
            />
          )
      })
      return html
    }

    const areas = (increment) => {
      let i = 0
      let w = 0
      let h = increment * ratio + (margin * 2)
      while (i < (newArray.length)) {
          if ((w + increment) > width) {
              w = 0
              h = h + (increment * ratio) + (margin * 2)
          }
          w = w + increment + (margin * 2)
          i++
      }
      if (h > height || increment > width) return false
      else return increment
    }

    const dimensions = () => {
      let videoContainer 
      if (sharingScreen === "show" || isShowBookmark === "show") {
        videoContainer = document.getElementsByClassName("twilio-wrapper")[0]
        width = videoContainer.offsetWidth
      } else {
        width = (window.innerWidth) - (margin * 4)
      }
      height = (window.innerHeight * 0.8) - (margin)
    }

    const resize = () => {

      dimensions()

      let max = 0
      let i = 1
      while (i < 5000) {
          let area = areas(i)
          if (area === false) {
              max = i - 1
              break
          }
          i++
      }

      max = max - (margin * 2)
      return resizer(max)
    }
    setParti(resize())
  }, [remoteParticipants, localParticipant, size.width, size.height, isShowBookmark, sharingScreen, dominantSpeaker])

  // shortcut
  useHotkeys("alt+c", () => setIsShowChatroom((c) => !c))
  useHotkeys("alt+m", () => toggleMicrophone(), {}, [
    roomConnect.room,
    isMicrophoneOn
  ])
  useHotkeys("alt+k", () => toggleCamera(), {}, [roomConnect.room, isCameraOn])
  useHotkeys(
    "alt+b",
    () => {
      if (config.configData?.accessor) {
        setIsShowBookmark((c) => (/hide|initial/i.test(c) ? "show" : "hide"))
      }
    },
    {},
    [config.configData]
  )
  useHotkeys(
    "alt+l",
    () => {
      if (config.configData?.accessor) {
        if (config.configData?.label === "interview") {
          setIsShowQuestionList((c) => !c)
        } else if (config.configData?.type === "fgd" || config.configData?.label !== "interview") {
          setIsShowFGD((c) => !c)
        }
      }
    },
    {},
    [config.configData]
  )

  const {
    open: snackOpen,
    messageInfo,
    handleClose,
    handleExited
  } = useSnackbar()

  if ((config.isLoading || roomConnect.isConnecting || !timestamp) && !config.isError) {
    return (
      <>
        {permissionerror && (
          <ErrorPage deviceError={permissionerror}></ErrorPage>
        )}
        {!permissionerror && (
          <>
            <Loading where="interaksi" />
            <Snackbar
              className={`toast-left`}
              open={snackOpen}
              autoHideDuration={150000}
              onClose={handleClose}
              onExited={handleExited}
              message={messageInfo ? messageInfo.message : undefined}
            />
          </>
        )}
      </>
    )
  }

  if (!queryString || config.isError || roomConnect.isError) {
    return (
      <>
        {!queryString && <p>Query undefined</p>}
        {/* {config.isError && <p>{config.error.message}</p>} */}
        {config.isError 
          && <ErrorPage2 
              message={`Mohon maaf terjadi gangguan: ${config.error.message} - Mohon coba reload halaman`}
            />
        }
        {roomConnect.isError && <p>{roomConnect.error.message}</p>}
      </>
    )
  }

  return (
    /*<UnsupportedBrowserWarning>*/
    <>
      {roomConnect.room && (
        <>
          {/* Materi fgd */}
          {
          // (config.configData?.accessor ||
          //   config.configData?.data_peserta?.role === "admin") &&


            // config.configData?.type === "fgd" &&
            config.configData?.label !== "interview" &&
            config.configData?.materiFGD && (
              <FGDPopup
              show={isShowFGD}
              label={config.configData?.label || "fgd"}
              onHide={() => setIsShowFGD(false)}>

                {/* TODO: pindahin style */}
                <div
                  style={{ maxHeight: "60vh", overflowY: "auto" }}
                  dangerouslySetInnerHTML={{
                    __html: mailBodyFormat(
                      config.configData?.materiFGD[0]?.materi || "-"
                    )
                  }}
                ></div>
                {/* TOMBOL ATTACHMENTS */}
                <Modal
                  open={openAttachment}
                  onClose={handleCloseAttachment}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box style={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                      {image.name}
                    </Typography>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={image.url}
                      alt={image.name}
                    ></img>
                  </Box>
                </Modal>
                {config.configData?.materiFGD[0]?.attachments &&
                config.configData?.materiFGD[0]?.attachments.length !== 0 && (
                  <div
                    className="attachment"
                    style={{
                      maxHeight: "20vh",
                      overflowY: "auto",
                      padding: "20px 0px"
                    }}
                  >
                    <p style={{ fontSize: 16, fontWeight: "bold" }}>Attachment</p>
                    {config.configData?.materiFGD[0]?.attachments.map(
                      (data, index) => {
                        return (
                          <div key={index}>
                            <p
                              onClick={() => {
                                setImage(data)
                                handleOpenAttachment()
                              }}
                              style={{ cursor: "pointer", color: "blue" }}
                            >
                              {data.name}
                            </p>
                          </div>
                        )
                      }
                    )}
                  </div>
                )}
              </FGDPopup>
            )}
          {/* End materi fgd */}

          {/* List pertanyaan interview */}
          {(config.configData?.accessor ||
            config.configData?.data_peserta?.role === "admin") &&
            // config.configData?.type === "interview" && (
              config.configData?.label === "interview" && (
              <QuestionsListPopup
                show={isShowQuestionList}
                onHide={() => setIsShowQuestionList(false)}
              >
                {/* TODO: pindahin style */}
                <div style={{ maxHeight: "60vh", overflowY: "auto" }}>
                  {config.configData?.list_pertanyaan?.map((v, i) => (
                    <QuestionItem key={i} question={v.pertanyaan} kompetensi={v.kompetensi}/>
                  ))}
                </div>
              </QuestionsListPopup>
            )}
          {/* End list pertanyaan interview */}

          {/* chatroom */}
          <Chatroom
            show={isShowChatroom}
            onHide={() => setIsShowChatroom(false)}
            onSubmit={(v) => v && sendMessage(encodeURIComponent(v))}
          >
            {chat &&
              JSON.stringify(chat) !== "{}" &&
              Object.entries(chat).map((c, idx, arr) => {
                const text = decodeURIComponent(c[1].string)
                const d = new Date(c[1].timestamp)
                let minutes = d.getMinutes()
                if (minutes < 10) minutes = `0${minutes}`
                const time = `${d.getHours()}:${minutes}`

                if (c[1].uid === config.configData?.data_peserta.uid) {
                  return (
                    <ChatSent
                      key={c[0]}
                      text={text}
                      time={time}
                      last={idx === arr.length - 1}
                    />
                  )
                }

                return (
                  <ChatReceived
                    key={c[0]}
                    text={text}
                    time={time}
                    name={c[1].name}
                    last={idx === arr.length - 1}
                  />
                )
              })}
          </Chatroom>
          {/* end chat room */}
          {/* Hang up confirmation */}
          <Warning
            show={isShowConfirmation}
            onHide={() => setIsShowConfirmation(false)}
            mainMessage="Apakah anda ingin keluar dan menghentikan asesmen?"
            confirmMessage="Keluar"
            cancelMessage="Kembali"
            onConfirm={(e) => {
              console.log("clickedd endcall")
              if (localStorage.getItem(variableSession.specialReload)) {
                console.log("hapus sesi special reload")
                localStorage.removeItem(variableSession.specialReload)
              }
              updateRoomLog({ succes: true, message: "Mengakhiri Panggilan" })
              disconnect()
              history.push("/loading?with_real=true")
              // history.push('/session-ends')
            }}
          />

          {/* More menus */}
          <MoreMenus // TODO: integrasi
            show={isShowMoreMenu}
            onHide={() => setIsShowMoreMenu(false)}
            type={
              config.configData?.accessor ||
              config.configData?.data_peserta?.role === "admin"
                ? "assessor"
                : "participant"
            }
            isShowCamMenu={isShowCamMenu}
            isShowMicMenu={isShowMicMenu}
            setIsShowCamMenu={setIsShowCamMenu}
            setIsShowMicMenu={setIsShowMicMenu}
            // CamMoreMenus
            isCameraOn={isCameraOn}
            cameraValue={
              videoInputDeviceId || (videoInputList && videoInputList[0]?.value)
            }
            cameraOptions={videoInputList}
            onCameraChange={(e) => {
              updateVideoInput(e.target.value)
            }}
            virtualBackgroundOn={virtualBackgroundOn}
            onVirtualBackgroundChange={used => {
              setVirtualBackgroundOn(used)
              updateVirtualBackground(used)
            }}
            sendResolution={JSON.stringify(videoResolution)}
            onChangeSendResolution={(e) =>
              updateVideoResolution(JSON.parse(e.target.value))
            }
            // MicMoreMenus
            isMicrophoneOn={isMicrophoneOn}
            microphone={audioInputDeviceId}
            microphoneOptions={audioInputList}
            onMicrophoneChange={(e) => {
              updateAudioInput(e.target.value)
            }}
            speaker={audioOutputDeviceId}
            speakerOptions={audioOutputList}
            onSpeakerChange={(e) => {
              console.log("select speaker (todo):", e.target.value)
              // setSelectedAudioOutput(e.target.value)
              updateAudioOutput(e.target.value)
            }}
          />
          {/* End more menus */}

          {/* room wrapper */}
          <RoomWrapper isShowBookmark={isShowBookmark}>
            <Snackbar
              className={`${
                messageInfo?.message?.search("meninggalkan ruangan") > -1
                  ? "toast-left"
                  : "toast-join"
              }`}
              open={snackOpen}
              autoHideDuration={2000}
              onClose={handleClose}
              onExited={handleExited}
              message={messageInfo ? messageInfo.message : undefined}
            />

            {/* room header */}
            <RoomHeader
              isMicOn={isMicrophoneOn}
              isCameraOn={isCameraOn}
              isSharingScreen={Boolean(screenTrack || remoteScreenTrack)}
              presenter={
                screenShareIdentity && JSON.parse(screenShareIdentity).email
              }
              localEmail={JSON.parse(localParticipant.identity).email}
              // onClickNote={() =>
              //   config.configData?.type === "interview"
              //     ? setIsShowQuestionList((c) => !c)
              //     : setIsShowFGD((c) => !c)
              // }
              onClickNote={() =>
                config.configData?.label === "interview"
                  ? setIsShowQuestionList(!isShowQuestionList)
                  : setIsShowFGD(!isShowFGD)
              }
              onClickSharingScreen={() => toggleScreenShare()}
              onClickToggleMic={() => toggleMicrophone()}
              onClickToggleCamera={() => toggleCamera()}
              onClickEndCall={() => setIsShowConfirmation(true)}
              isShowChatroom={isShowChatroom}
              setIsShowChatroom={setIsShowChatroom} // TODO: ini dan bawahnya sama g sih?
              onClickChat={() => setIsShowChatroom(!isShowChatroom)}
              isShowBookmark={isShowBookmark}
              setIsShowBookmark={setIsShowBookmark}
              isShowFGD={isShowFGD}
              setIsShowFGD={setIsShowFGD}
              // props isUnread true if there is unread message
              isUnread={unRead}
              type={
                config.configData?.accessor ||
                config.configData?.data_peserta?.role === "admin"
                  ? "assessor"
                  : "participant"
              }
              stringTimeCounter={stringTimeCounter}
              onClickMoreMenu={onClickMoreMenu}
              materiFGD={config.configData?.materiFGD}
            />
            {/* End room header */}

            {/* Shared screen */}
            <SharedScreenWrapper
              show={screenTrack || remoteScreenTrack ? "show" : "initial-value"} // gini kah (?)
              isShowBookmark={isShowBookmark}
              presenter={
                screenShareIdentity && JSON.parse(screenShareIdentity).name
              }
              content={
                <SharedScreenFrame
                  screenTrack={screenTrack || remoteScreenTrack}
                />
              }
            />
            {/* End shared screen */}

            {/* Bookmark */}
            {(config.configData?.accessor ||
              config.configData?.data_peserta?.role === "admin") && (
              <BookmarkSidebar
                show={isShowBookmark}
                onHide={() => setIsShowBookmark("hide")}
                stringTimeCounter={stringTimeCounter}
                formComponent={
                  <BookmarkForm
                    timestamp={timestamp}
                    currentTimestamp={currentTimestamp}
                    onClickTimestamp={() => setCurrentTimestamp(Date.now())}
                    listPeserta={getListPeserta(remoteParticipants)}
                    listKompetensi={getKompetensiRoom(
                      config.configData?.kompetensi
                    )}
                    onSubmit={(v, actions) =>
                      addBookmark(v, () => actions.resetForm())
                    }
                    onCancel={() => setIsShowBookmark("hide")}
                    show={isShowBookmark}
                  />
                }
                bookmarkItems={
                  <>
                    {bookmark &&
                      JSON.stringify(bookmark) !== "{}" &&
                      Object.entries(bookmark)
                        .reverse()
                        .map((b) => {
                          return (
                            <BookmarkItem
                              key={b[0]}
                              stringTimestamp={b[1].timestamp.stringTimestamp}
                              kompetensi={b[1].content.kompetensi}
                              name={b[1].name}
                              note={decodeURIComponent(b[1].content.note)}
                            />
                          )
                        })}
                  </>
                }
              />
            )}
            {/* End Bookmark */}

            {/* Participant Wrapper */}
            <ParticipantWrapper
              frames={remoteParticipants?.length + 1}
              length={length}
              isShowBookmark={isShowBookmark}
              isSharingScreen={
                screenTrack || remoteScreenTrack ? "show" : "initial-value"
              } // TODO
              parti={parti}
            >
              {/* {dummyData.map((data, index) => {
                return (
                  <Participant
                    key={index}
                    frames={dummyData.length}
                    participant={localParticipant}
                    name={`${
                      JSON.parse(localParticipant.identity).name
                    } ( Anda )`}
                    isLocal={true}
                    isAssessor={
                      config.configData?.accessor ||
                      config.configData?.data_peserta?.role === "admin"
                    }
                    audioOutputDeviceId={audioOutputDeviceId}
                  />
                )
              })}
              {chunks &&
                chunks.length > 0 &&
                numberofchunks &&
                length &&
                chunks.map((chunk, index) => {
                  if (chunk.length !== numberofchunks) {
                    const wrapperClass = `${
                      Boolean(screenTrack || remoteScreenTrack)
                        ? "sharing box-wrapper-hide"
                        : `box-wrapper${
                            numberofchunks >= 2 ? `-${numberofchunks}` : ""
                          }`
                    }`
                    return (
                      <div key={index} className={wrapperClass}>
                        {chunk.map((p, key) => {
                          const identity = JSON.parse(p.identity)
                          const firstCharacter = identity?.name?.charAt(0).toUpperCase()
                          let repeat = 1
                          if (avatarName[firstCharacter]) {
                            repeat = avatarName[firstCharacter] + 1
                            avatarName[firstCharacter] = avatarName[firstCharacter] + 1
                          }else{
                            avatarName[firstCharacter] = 1
                          }

                          const defaultColor = getRandomColor(firstCharacter, repeat)
                          if (index === 0 && key === 0)
                            return (
                              <Participant
                                key={`${index}-${key}`}
                                frames={remoteParticipants?.length + 1}
                                participant={localParticipant}
                                name={`${
                                  JSON.parse(localParticipant.identity).name
                                } ( Anda )`}
                                isLocal={true}
                                isAssessor={
                                  config.configData?.accessor ||
                                  config.configData?.data_peserta?.role ===
                                    "admin"
                                }
                                audioOutputDeviceId={audioOutputDeviceId}
                                defaultColor={defaultColor}
                              />
                            )
                          else
                            return (
                              <Participant
                                key={p.sid}
                                frames={remoteParticipants.length + 1}
                                participant={p}
                                identity={identity}
                                name={`${identity.name} ${
                                  identity.role !== "peserta"
                                    ? `(${identity.role})`
                                    : ""
                                }`}
                                fgdParticipantList={fgdParticipantList}
                                config={config.configData}
                                role={
                                  JSON.parse(localParticipant.identity).role
                                }
                                isLocal={false}
                                isAssessor={
                                  JSON.parse(p.identity).role !== "peserta"
                                }
                                isTalking={dominantSpeaker === p}
                                handleScreenTrack={handleRemoteScreenTrack}
                                handleDataReceived={onDataReceived}
                                handleMuteAudio={() => {
                                  console.log("handleMuteAudio #1")
                                  sendData({
                                    type: "toggleMic",
                                    participant: p.sid
                                  })
                                }}
                                audioOutputDeviceId={audioOutputDeviceId}
                                defaultColor={defaultColor}
                              />
                            )
                        })}
                      </div>
                    )
                  } else {
                    return chunk.map((p, key) => {
                      const identity = JSON.parse(p.identity)
                      const firstCharacter = identity?.name?.charAt(0).toUpperCase()
                      let repeat = 1
                      if (avatarName[firstCharacter]) {
                        repeat = avatarName[firstCharacter] + 1
                        avatarName[firstCharacter] = avatarName[firstCharacter] + 1
                      }else{
                        avatarName[firstCharacter] = 1
                      }

                      const defaultColor = getRandomColor(firstCharacter, repeat)

                      if (index === 0 && key === 0)
                        return (
                          <Participant
                            key={`${index}-${key}`}
                            frames={remoteParticipants?.length + 1}
                            participant={localParticipant}
                            name={`${
                              JSON.parse(localParticipant.identity).name
                            } ( Anda )`}
                            isLocal={true}
                            isAssessor={
                              config.configData?.accessor ||
                              config.configData?.data_peserta?.role === "admin"
                            }
                            audioOutputDeviceId={audioOutputDeviceId}
                            defaultColor={defaultColor}
                          />
                        )
                      else
                        return (
                          <Participant
                            key={p.sid}
                            frames={remoteParticipants.length + 1}
                            participant={p}
                            identity={identity}
                            name={`${identity.name} ${
                              identity.role !== "peserta"
                                ? `(${identity.role})`
                                : ""
                            }`}
                            fgdParticipantList={fgdParticipantList}
                            config={config.configData}
                            role={JSON.parse(localParticipant.identity).role}
                            isLocal={false}
                            isAssessor={
                              JSON.parse(p.identity).role !== "peserta"
                            }
                            isTalking={dominantSpeaker === p}
                            handleScreenTrack={handleRemoteScreenTrack}
                            handleDataReceived={onDataReceived}
                            handleMuteAudio={() => {
                              console.log("handleMuteAudio #2")
                              try {
                                sendData({
                                  type: "toggleMic",
                                  participant: p.sid
                                })
                              } catch (error) {
                                console.log(error)
                              }
                            }}
                            audioOutputDeviceId={audioOutputDeviceId}
                            defaultColor={defaultColor}
                          />
                        )
                    })
                  }
                })}

              <Participant
                frames={remoteParticipants?.length + 1}
                participant={localParticipant}
                name={`${JSON.parse(localParticipant.identity).name} ( Anda )`}
                isLocal={true}
                isAssessor={
                  config.configData?.accessor ||
                  config.configData?.data_peserta?.role === "admin"
                }
                audioOutputDeviceId={audioOutputDeviceId}
              />

              {remoteParticipants.map((p) => {
                const identity = JSON.parse(p.identity)
                return (
                  <Participant
                    key={p.sid}
                    frames={remoteParticipants.length + 1}
                    participant={p}
                    name={`${identity.name} ${
                      identity.role !== "peserta" ? `(${identity.role})` : ""
                    }`}
                    isLocal={false}
                    isAssessor={JSON.parse(p.identity).role !== "peserta"}
                    isTalking={dominantSpeaker === p}
                    handleScreenTrack={handleRemoteScreenTrack}
                    handleDataReceived={onDataReceived}
                    handleMuteAudio={() =>
                      sendData({ type: "toggleMic", participant: p.sid })
                    }
                    audioOutputDeviceId={audioOutputDeviceId}
                  />
                )
              })} */}
            </ParticipantWrapper>
            <div
              className={`network-quality network-quality__${localParticipantConnectionLevel}`}
              title="Network Quality"
            >
              <span>
                Koneksi: {networkQuality[localParticipantConnectionLevel]}
              </span>
            </div>
          </RoomWrapper>
        </>
      )}
    {/* </UnsupportedBrowserWarning> */}
    </>
  )
}
