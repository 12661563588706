import { useEffect, useRef } from "react"

export default function VideoTrack({ track, isme }) {
  const ref = useRef()

  useEffect(() => {
    if (track) {
      const el = ref.current
      track.attach(el)

      return () => {
        track.detach(el)
      }
    }
  }, [track])

  return (
    <video
      style={{
        maxWidth: "100%",
        transform: `${isme ? "rotateY(180deg)" : "unset"}`
      }}
      id={isme ? "localVideo" : ""}
      ref={ref}
    /> // TODO: refactor style
  )
}
