import React from 'react'

import iconDefaultParticipant from "../../assets/icons/mobile/icon-default-participant.svg"
import iconMuteParticipantList from "../../assets/icons/mobile/icon-mute-participant-list.svg"
import iconClose from "../../assets/icons/mobile/icon-close.svg"
import MobileAvatar from './general/MobileAvatar'
import { getRandomColor } from '../../lib/general'

function MobileListParticipants({ setShowListParticipant }) {
  const participants = ["Siti Munawaroh", 
                        "Siti Barokah",
                        "Rojiun Gulali",
                         "Thunder Mayde Sun",
                          "Robert Ahmad Dahlan Cancello", "Rojiun Gulali", "Thunder Mayde Sun", "Robert Ahmad Dahlan Cancello"]
  const avatarName = []
  return (
    <div className='mobile-container-modal'>
      <div className='mobile-list-participant-close' onClick={() => setShowListParticipant(false)}>
        <img src={ iconClose } />
      </div>
      <div className='mobile-list-participants'>
        <div className='mobile-list-participans-title mobile-modal-title'>
          Dalam Panggilan
        </div>
        <div className='mobile-list-participans-items'>
          <div className='mobile-list-participans-item'>
            <div className='mobile-list-participant-name'>
              <img src={ iconDefaultParticipant } className='mobile-list-participant-items'/>
              <div>Siti Azizah</div>
            </div>
            <div>
              <img src={ iconMuteParticipantList } />
            </div>
          </div>
          { participants.map( (data, index) =>{
            const firstCharacter = data.charAt(0).toUpperCase()
            let repeat = 1
            if (avatarName[firstCharacter]) {
              repeat = avatarName[firstCharacter] + 1
              avatarName[firstCharacter] = avatarName[firstCharacter] + 1
            }else{
              avatarName[firstCharacter] = 1
            }
            let defaultColor = getRandomColor(firstCharacter, repeat)
            return <div className='mobile-list-participans-item' key={index}>
                    <div className='mobile-list-participant-name'>
                      {/* <img src={ iconDefaultParticipant } className='mobile-list-participant-items'/> */}
                      <MobileAvatar
                          char={data.charAt(0)}
                          style={{
                            width: "24px",
                            height: "24px",
                            fontSize: "18px",
                            textShadow: "0 1px 1px #000",
                            fontWeight: "700",
                            defaultColor,
                            color: "#EDF2F7",
                            marginRight: "8px"
                          }}
                        />
                      <div>{ data }</div>
                    </div>
                  </div>
          })}
        </div>
      </div>
    </div>
  )
}

export default MobileListParticipants
