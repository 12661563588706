import { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"

function ChatForm({ onSubmit, show }) {
  const [value, setValue] = useState("")
  const nodeTextarea = useRef(null)

  useEffect(() => {
    if (!show) return
    nodeTextarea && nodeTextarea.current && nodeTextarea.current.focus()
  }, [show])

  return (
    <div style={{ marginTop: 10 }}>
      <span style={{ fontSize: 12, opacity: 0.4 }}>
        Tekan enter untuk mengirim pesan
      </span>
      <textarea
        ref={nodeTextarea}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        name="typing-box"
        cols="27"
        rows="3"
        style={{ padding: 10, backgroundColor: "aliceblue", marginTop: 0 }}
        placeholder="Silakan tulis pesan..."
        onKeyDown={(event) => {
          if (/enter/i.test(event.key || "") || event.keyCode === 13) {
            if (!event.shiftKey) {
              event.preventDefault()
              onSubmit && onSubmit(value)
              setValue("")
            }
          }
        }}
      />
    </div>
  )
}

ChatForm.propTypes = {
  onSubmit: PropTypes.func
}

export default ChatForm
