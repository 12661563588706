import PropTypes from "prop-types"

import ParticipantFrame from "../../components/ParticipantFrame"
import AudioTrack from "../../components/AudioTrack"
import VideoTrack from "../../components/VideoTrack"

import useTrack from "../../hooks/useTrack"
import useTrackAudio from "../../hooks/useTrackAudio"
import useTrackVideo from "../../hooks/useTrackVideo"
import useTrackData from "../../hooks/useTrackData"
import ParticipantFrameMobile from "./ParticipantFrameMobile"
import { useEffect, useState } from "react"

/**
 *
 */
function ParticipantMobile({
  frames,
  participant,
  name,
  role,
  isLocal,
  isAssessor,
  isTalking, // remote participant
  handleScreenTrack, // remote participant
  handleDataReceived, // remote participant
  handleMuteAudio, // remote participant
  audioOutputDeviceId,
  config,
  fgdParticipantList,
  identity,
  showShareScreen,
  type,
  orientation,
  listParticipant,
  setListParticipant,
  defaultColor,
  chunks
}) {
  const { audioTrack, videoTrack, dataTrack } = useTrack({
    participant,
    handleScreenTrack
  })

  const [detector, setDetector] = useState("")
  const [soundDetect, setSoundDetect] = useState(false)

  const { audioOn } = useTrackAudio({ audioTrack })
  const { videoOn } = useTrackVideo({ videoTrack })
  useTrackData({ dataTrack, handleDataReceived })

  const [colorAvatar, setColorAvatar] = useState(defaultColor)

  useEffect(() => {
    if (isLocal) {
      // console.log({audioTrack: !!audioTrack, audioOn})
      if (audioTrack && audioOn) {
        if (detector) window.cancelAnimationFrame(detector)
        setDetector("")
        const newMediaStream = new MediaStream()
        newMediaStream.addTrack(audioTrack?.mediaStreamTrack)

        const audioContext = new AudioContext()
        const audioStreamSource = audioContext.createMediaStreamSource(
            newMediaStream)
        const analyser = audioContext.createAnalyser()
        analyser.minDecibels = -60
        audioStreamSource.connect(analyser)
        const bufferLength = analyser.frequencyBinCount
        const domainData = new Uint8Array(bufferLength)
        const detectSound = () => {
            setSoundDetect(false)
            analyser.getByteFrequencyData(domainData)
            let i = 0
            while (i < bufferLength) {
                const value = domainData[i]
                if (value > 0) {
                    setSoundDetect(true)
                    break
                }
                i += 1
            }

            setDetector(window.requestAnimationFrame(detectSound))
            // console.log("sound detect dalam", soundDetect)
        }

        setDetector(window.requestAnimationFrame(detectSound))
      } else {
        if (detector || !audioOn){
          window.cancelAnimationFrame(detector)
          setDetector("")
          setSoundDetect(false)
        }
      }
    }

    return () => {
      if (detector){
        window.cancelAnimationFrame(detector)
        setDetector("")
        setSoundDetect(false)
      }
    }

  }, [audioTrack, audioOn])

  useEffect(() => {

    const mapList = (list) => {
      let tempList = list
      let flag = true
      if ( tempList && tempList.length > 0 ) {
        tempList = tempList.map((d) => {
          if ( d.name === name) {
            flag = false
            setColorAvatar(d.defaultColor)
            return {
              name,
              audioOn,
              defaultColor : d.defaultColor
            }
          } else {
            return d
          }
        })
      }

      // if (key === "0-0") {
      //   tempList = []
      // }

      tempList && flag && tempList.push({ name, audioOn, defaultColor })

      return tempList
    }

    setListParticipant(listParticipant => mapList(listParticipant))

    return () => {
      setListParticipant(listParticipant => listParticipant.filter((data) => data.name !== name))
    }
  }, [audioOn])


  return (
    <ParticipantFrameMobile
      frames={frames}
      name={name}
      config={config}
      identity={identity}
      participant={participant}
      isTalking={isTalking || soundDetect} // dominant speaker
      videoComponent={<VideoTrack track={videoTrack} isme={isLocal} />}
      fgdParticipantList={fgdParticipantList}
      audioComponent={
        <AudioTrack
          track={audioTrack}
          audioOutputDeviceId={audioOutputDeviceId}
        />
      }
      audioOn={audioOn}
      videoOn={videoOn}
      isLocal={isLocal}
      role={role}
      isAssessor={isAssessor}
      handleMuteAudio={handleMuteAudio}
      showShareScreen={showShareScreen}
      type={type}
      orientation={orientation}
      defaultColor={colorAvatar}
    />
  )
}

ParticipantMobile.propTypes = {
  // total frame (UI)
  frames: PropTypes.number.isRequired,
  participant: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  isLocal: PropTypes.bool,
  isAssessor: PropTypes.bool,
  isTalking: PropTypes.bool,
  handleScreenTrack: PropTypes.func,
  handleDataReceived: PropTypes.func,
  handleMuteAudio: PropTypes.func
}

export default ParticipantMobile
