import React from "react"
import AssessorRoomContainer from "../components/AssessmentRoom/AssessorRoomContainer"
import AssessorRoomContainerV2 from "../components/AssessmentRoomV2/AssessorRoomContainerV2"

function AssessorRoom() {
  return (
    <div>
      <AssessorRoomContainer />
    </div>
  )
}

export default AssessorRoom
