/**
 *
 * @param {object[]} words
 * @param {number} startIndex
 * @param {number} endIndex
 * @param {boolean} includeVideoClip
 * @param {number} bookmarkId
 * @returns marked words
 */
export const markWords = ({
  words,
  startIndex,
  endIndex,
  includeVideoClip,
  bookmarkId
}) =>
  words.map((w, idx) => {
    const existingHighlights =
      w.highlighted?.length > 0 ? [...w.highlighted] : []

    // start & end of bookmark
    if (idx === startIndex && idx === endIndex) {
      return {
        ...w,
        highlighted: existingHighlights.concat({
          bookmarkId,
          startWord: true,
          endWord: true,
          mark: includeVideoClip
        })
      }
    }

    // start of bookmark
    if (idx === startIndex) {
      return {
        ...w,
        highlighted: existingHighlights.concat({
          bookmarkId,
          startWord: true,
          endWord: false,
          mark: includeVideoClip
        })
      }
    }

    // end of bookmark
    if (idx === endIndex) {
      return {
        ...w,
        highlighted: existingHighlights.concat({
          bookmarkId,
          startWord: false,
          endWord: true,
          mark: includeVideoClip
        })
      }
    }

    // part of bookmark
    if (idx > startIndex && idx < endIndex) {
      return {
        ...w,
        highlighted: existingHighlights.concat({
          bookmarkId,
          startWord: false,
          endWord: false,
          mark: includeVideoClip
        })
      }
    }

    // not part of bookmark
    return { ...w }
  })
