import React from "react"

const ErrorPage = ({ deviceError }) => {
  return (
    <div style={{ width: "100%", height: "100vh" }}>
      <div
        style={{
          position: "sticky",
          top: 0,
          width: "100vw",
          padding: 20,
          backgroundColor: "yellow",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "red",
          fontSize: 24
        }}
      >
        Error
        <span
          style={{
            fontWeight: "bold"
          }}
        >
          &nbsp;
          {deviceError}&nbsp;
        </span>
        <br />
        <span> - Mohon untuk mencoba solusi berikut</span>
      </div>
      <iframe
        style={{ width: "100%", height: "100%" }}
        src="https://help-topic.web.app/#6"
      ></iframe>
    </div>
  )
}

export default ErrorPage
