import PropTypes from "prop-types"

import muteIcon from "../../assets/icons/mute.png"
import avatarDefault from "../../assets/icons/mobile/avatar-mobile.svg"
import micIcon from "../../assets/icons/mic.png"
import muteParticipantIcon from "../../assets/icons/mic-large.png"
import deleteIcon from "../../assets/icons/delete.png"
import { Fragment, useState, useEffect } from "react"
import ReactTooltip from "react-tooltip"
import Modal from "@material-ui/core/Modal"
import Typography from "@material-ui/core/Typography"
import Box from "@material-ui/core/Box"
import { updateData } from "../../sevices/firebaseService"
import firebase from "firebase/app"
import useRoomService from "../../hooks/roomService"
import MobileAvatar from "../../components/MobileRoom/general/MobileAvatar"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "25vw",
  background: "white",
  borderRadius: 8,
  padding: 10
}

function ParticipantFrameMobile({
  isTalking,
  name,
  role,
  videoComponent,
  audioComponent,
  audioOn,
  videoOn,
  handleMuteAudio,
  isAssessor,
  isLocal,
  config,
  fgdParticipantList,
  identity,
  showShareScreen,
  type,
  orientation,
  defaultColor
}) {
  const [removeModal, setRemoveModal] = useState(undefined)
  const [muteModal, setMuteModal] = useState(undefined)
  const [audioSyncronus, setAudioSyncronous] = useState(audioOn)

  useEffect(() => {
    setAudioSyncronous(audioOn)
  }, [audioOn])

  const { updateRoomLog } = useRoomService()
  // give className of "video2", if there are 2 frames, "video3", if there are 3 frames, etc.

  const doKick = async () => {
    if (config) {
      if (config.type === "interview") {
        const path = config.peserta_path_listen.replace("/webcall", "")
        await updateData({ path: `${path}/manualReload`, data: true })
        updateRoomLog({
          succes: true,
          message: `Mengeluarkan peserta Interview`
        })
      } else {
        if (fgdParticipantList.length > 0) {
          let peserta = fgdParticipantList.filter(
            (f) => f.email === identity.email
          )[0]
          if (peserta) {
            await updateData({
              path: peserta.reloadPath,
              data: true
            })

            updateRoomLog({
              succes: true,
              message: `Mengeluarkan ${identity.email} dari ruangan`
            })
          } else {
            console.warn(`Peserta ${identity.email} tidak ditemukan`)
          }
        }
      }
    }
    setRemoveModal(undefined)
  }

  return (
      <div className={ `mobile-content-items-${type} ${showShareScreen && orientation === "landscape" && type === "one-on-one" && "mobile-width-100"}`}>
        <div className={ `mobile-content-item-${type}` }>
          <div className={ `mobile-content-item-video-${type} ${isTalking && "mobile-is-talking"}` }>
            {/* <img src={peserta.image} alt="video" /> */}
            {videoOn && videoComponent}
            {audioOn && audioComponent}
            {!videoOn && (
              // <img 
              //   src={ avatarDefault } 
              //   alt="video"
              //   style={{height: "auto", width: "auto"}}
              // />
              <MobileAvatar
                char={name?.charAt(0)}
                style={{
                  width: "5rem",
                  height: "5rem",
                  fontSize: "2.5rem",
                  textShadow: "0 1px 1px #000",
                  fontWeight: "700",
                  defaultColor: defaultColor || "grey",
                  color: "#EDF2F7"
                }}
              />

            )}
          </div>
          {
            name.length < 18 ? 
            <div className={ `mobile-content-item-name-${type}` }>
              { name }
            </div> :
            <div className={ `mobile-content-item-name-${type}` }>
              <div data-tip data-for={name.replace(/[^A-Z0-9]/ig, "")} data-event="click">
                { name.substring(0,17) + "..." }
              </div>
            </div>
          }
          
          {/* icon mute */}
          <div
            className="icon-mic-wrapper-mobile"
          >
            {!audioSyncronus && (
            <img
              className="utils-icon"
              style={{
                marginTop: 0,
                padding: 7,
                height: 30
              }}
              src={muteIcon}
              alt="mute"
            />
            )}
          </div>
        </div>
        {
            name.length >= 18 &&
            <ReactTooltip 
              id={name.replace(/[^A-Z0-9]/ig, "")} 
              place="top" 
              effect="solid"
              backgroundColor="white"
              border
              borderColor="black"
              textColor="black"
            >
              { name }
            </ReactTooltip>
          }
      </div>
  )
}

ParticipantFrameMobile.propTypes = {
  // total frame
  frames: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  isTalking: PropTypes.bool,
  videoComponent: PropTypes.element,
  audioComponent: PropTypes.element,
  audioOn: PropTypes.bool,
  videoOn: PropTypes.bool,
  handleMuteAudio: PropTypes.func,
  isAssessor: PropTypes.bool.isRequired,
  isLocal: PropTypes.bool.isRequired
}

export default ParticipantFrameMobile
