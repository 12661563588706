import { expectResponDataHighlight, expectResponDataInteraksi } from "../constants/otherConstants"

export const colorDictionary = {
  A: [218, 148, 28],
  B: [240, 163, 245],
  C: [0, 117, 220],
  D: [153, 63, 0],
  E: [76, 0, 92],
  F: [25, 25, 25],
  G: [0, 92, 49],
  H: [43, 206, 72],
  I: [245,204, 153],
  J: [128, 128, 128],
  K: [148, 245, 181],
  L: [143, 124, 0],
  M: [157, 204, 0],
  N: [194, 0, 136],
  O: [0, 51, 128],
  P: [245, 164, 5],
  Q: [245, 168, 187],
  R: [66, 102, 0],
  S: [245, 0, 16],
  T: [94, 241, 242],
  U: [0, 153, 143],
  V: [224, 245, 102],
  W: [116, 10, 245],
  X: [153, 0, 0],
  Y: [245, 245, 128],
  Z: [245, 80, 5],
}


export const getRandomColor = (char, repeat) => { //char = character huruf
  let rgb = colorDictionary[char.toUpperCase()].slice()
  if (repeat > 1 && repeat < 10){
    for (let index = 0; index < 3; index++) {
      rgb[index] = trims255(rgb[index] + ((repeat-1) * 20))
    }
  }

  const result = `rgb(${rgb.toString()})`
  return result
}

export const trims255 = (value) => {
  if (value > 255){
    return (value - 255)
  } else {
    return value
  }
}

export const checkTypeData = (typeData, data) => {
  let result = false
  if (typeData === "string") {
    typeof data === "string" && (result = true)
  } else if (typeData === "number") {
    typeof data === "number" && (result = true)
  } else if (typeData === "object") {
    data && typeof data === "object" && (result = true)
  } else if (typeData === "boolean") {
    typeof data === "boolean" && (result = true)
  }

  return result
}

export const checkDataResponFrameConfig = (responData, page = "interaksi") => {
  let result = true

  const expectResponData = page === "interaksi" ? expectResponDataInteraksi : expectResponDataHighlight
  let dataNotComplete = []
  let dataAccessor = ""
  let dataType = ""
  let dataLabel = ""
  if (responData) {
    expectResponData.forEach(data => {
      
      if (!(data.name in responData)) {
        dataNotComplete.push(data.name)
      } else {
        if (data.name === "accessor") dataAccessor = responData.accessor
        if (data.name === "type") dataType = responData.type
        if (data.name === "label") dataLabel = responData.label

        // if (data.typeData) {
        //   !(checkTypeData(data.typeData, responData[data.name])) && dataNotComplete.push(data.name)
        // }
      }

    });

    if (dataAccessor === true) {
      !("kompetensi" in responData) && dataNotComplete.push("kompetensi")
      // if (dataType === "interview") {
      if (dataLabel === "interview") {
        !("list_pertanyaan" in responData) && dataNotComplete.push("list_pertanyaan")
      } else if (dataLabel !== "interview") {
        !("materiFGD" in responData) && dataNotComplete.push("materiFGD")
      }
    }

    if (dataNotComplete.length > 0) {
      result = false
    }
  }

  return { 
    status : result,
    dataNotComplete
  }
}