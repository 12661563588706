import { useEffect, useRef } from "react"
import PropTypes from "prop-types"

function SharedScreenFrame({ screenTrack, mobile = false }) {
  const screenRef = useRef()

  useEffect(() => {
    if (screenTrack) {
      const el = screenRef.current
      screenTrack.attach(el)

      return () => {
        screenTrack.detach(el)
      }
    }
  }, [screenTrack])

  return (
    screenTrack && !mobile ? (
      <div className="video-container">
        <video style={{ borderRadius: "unset" }} ref={screenRef} />
      </div>
    ) : 
    (
      <video style={{ borderRadius: "unset" }} ref={screenRef} />
    )
  )
}

SharedScreenFrame.propTypes = {
  screenTrack: PropTypes.object
}

export default SharedScreenFrame
