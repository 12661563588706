import React from 'react'
import HighlightWrapper from '../components/highlight/HighlightWrapper'

function Highlight () {
  return (
        <div>
            <HighlightWrapper/>
        </div>
  )
}

export default Highlight
