import { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import firebase from "firebase/app"
import {
  prepareTranscriptData,
  prepareUpdateTranscript
} from "../lib/transcriptFunctions"
import { updateData } from "../sevices/firebaseService"

import { Creators as LastSavedActions } from "../redux/LastSavedRedux"

export default function useTranscript() {
  const dispatch = useDispatch()
  const { configData: configHighlight } = useSelector((state) => state.config)
  const { isLoggedIn } = useSelector((state) => state.auth)

  const [transcriptDataEdit, setTranscriptDataEdit] = useState([])

  /**
   * listen to transcriptData edit
   */
  const [transcriptEmpty, setTranscriptEmpty] = useState(false)
  useEffect(() => {
    if (configHighlight && isLoggedIn) {
      const database = firebase.database()

      // transcriptData edit
      const path = `${configHighlight.highlight_path}/transcriptData`
      const transcriptEditRef = database.ref(path)

      transcriptEditRef.on("value", (snapshot) => {
        let data = snapshot.val()
        if (data && data.message === "Empty") {
          setTranscriptEmpty(true)
          data = []
        }

        // save state
        if (data && data.constructor === Array) {
          setTranscriptDataEdit([...data])
        }
      })

      return () => {
        transcriptEditRef.off("value")
      }
    }
  }, [configHighlight, isLoggedIn])

  /**
   * prepared transcript for ui
   */
  const [transcriptData, setTranscriptData] = useState([])

  useEffect(() => {
    if (configHighlight && transcriptDataEdit?.length > 0) {
      // prepare
      const preparedTranscript = prepareTranscriptData({
        transcriptData: transcriptDataEdit,
        initTimestamp: configHighlight.roomTimestamp
      })

      setTranscriptData(preparedTranscript)
    }
  }, [configHighlight, transcriptDataEdit])

  /**
   * update a word in transcription by its index
   * @param {number} indexTranscript transcription index
   * @param {number} indexWord word index in words
   * @param {string} newWord
   */
  const updateWord = useCallback(
    ({ indexTranscript, indexWord, newWord, removeWordIfEmpty }) => {
      if (configHighlight && transcriptDataEdit.length > 0) {
        const updatedTranscript = prepareUpdateTranscript(
          {
            transcriptDataOri: transcriptDataEdit,
            indexTranscript,
            indexWord,
            newWord
          },
          removeWordIfEmpty
        )
        // console.log('updatedTranscript', updatedTranscript)
        const path = `${configHighlight.highlight_path}/transcriptData`
        const key = indexTranscript
        updateData({
          path: `${path}/${key}`,
          data: updatedTranscript
        })
          .then(() => dispatch(LastSavedActions.setLastSaved()))
          .catch((error) => console.log("error", error))
      }
    },
    [configHighlight, dispatch, transcriptDataEdit]
  )

  /**
   * submit transcript data
   */
  const submitTranscriptData = useCallback(() => {
    if (configHighlight && transcriptDataEdit) {
      const path = `${configHighlight.submit_path}/transcriptData`
      updateData({
        path: `${path}`,
        data: transcriptDataEdit
      })
        .then((result) => console.log("success", result))
        .catch((error) => console.log("error", error))
    }
  }, [configHighlight, transcriptDataEdit])

  return { transcriptData, updateWord, submitTranscriptData, transcriptEmpty }
}
