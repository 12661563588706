import PropTypes from "prop-types"

import muteIcon from "../assets/icons/mute.png"
import micIcon from "../assets/icons/mic.png"
import muteParticipantIcon from "../assets/icons/mic-large.png"
import deleteIcon from "../assets/icons/delete.png"
import { Fragment, useState, useEffect } from "react"
import ReactTooltip from "react-tooltip"
import Modal from "@material-ui/core/Modal"
import Typography from "@material-ui/core/Typography"
import Box from "@material-ui/core/Box"
import { updateData } from "../sevices/firebaseService"
import firebase from "firebase/app"
import useRoomService from "../hooks/roomService"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "25vw",
  background: "white",
  borderRadius: 8,
  padding: 10
}

function ParticipantFrame({
  isTalking,
  name,
  role,
  videoComponent,
  audioComponent,
  audioOn,
  videoOn,
  handleMuteAudio,
  isAssessor,
  isLocal,
  config,
  fgdParticipantList,
  identity,
  defaultColor,
  styles
}) {
  const [removeModal, setRemoveModal] = useState(undefined)
  const [muteModal, setMuteModal] = useState(undefined)
  const [audioSyncronus, setAudioSyncronous] = useState(audioOn)

  useEffect(() => {
    setAudioSyncronous(audioOn)
  }, [audioOn])

  const { updateRoomLog } = useRoomService()
  // give className of "video2", if there are 2 frames, "video3", if there are 3 frames, etc.

  const doKick = async () => {
    if (config) {
      if (config.type === "interview") {
        const path = config.peserta_path_listen.replace("/webcall", "")
        await updateData({ path: `${path}/manualReload`, data: true })
        updateRoomLog({
          succes: true,
          message: `Mengeluarkan peserta Interview`
        })
      } else {
        if (fgdParticipantList.length > 0) {
          let peserta = fgdParticipantList.filter(
            (f) => f.email === identity.email
          )[0]
          if (peserta) {
            await updateData({
              path: peserta.reloadPath,
              data: true
            })

            updateRoomLog({
              succes: true,
              message: `Mengeluarkan ${identity.email} dari ruangan`
            })
          } else {
            console.warn(`Peserta ${identity.email} tidak ditemukan`)
          }
        }
      }
    }
    setRemoveModal(undefined)
  }

  return (
    <div className={`box ${isTalking ? "is-talking" : "no-talking"}`}
         style={styles}
    >
      <ReactTooltip />
      {removeModal !== undefined && (
        <Modal
          open={true}
          onClose={() => setRemoveModal(undefined)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box style={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Apakah Anda ingin memindahkan {removeModal} ke ruang tunggu?
            </Typography>
            <div
              className="d-flex action-button"
              style={{ marginTop: 40, justifyContent: "flex-end" }}
            >
              <button
                type="submit"
                className="btn btn-primary"
                onClick={doKick}
                style={{ width: 100 }}
              >
                Ya
              </button>
              <div style={{ marginLeft: 20 }}></div>
              <button
                type="button"
                className="btn cancel-btn"
                onClick={() => setRemoveModal(undefined)}
                style={{ width: 100 }}
              >
                Batalkan
              </button>
            </div>
          </Box>
        </Modal>
      )}
      {muteModal !== undefined && (
        <Modal
          open={true}
          onClose={() => setMuteModal(undefined)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box style={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Apakah anda ingin {!audioOn ? "unmute" : "mute"} {muteModal}?
            </Typography>
            <div
              className="d-flex action-button"
              style={{ marginTop: 40, justifyContent: "flex-end" }}
            >
              <button
                type="submit"
                className="btn btn-primary"
                onClick={async () => {
                  setMuteModal(undefined)
                  setAudioSyncronous(!audioOn)
                  try {
                    await handleMuteAudio()
                  } catch (error) {
                    console.log(error)
                    setAudioSyncronous(audioOn)
                  }
                  // setTimeout(() => {
                  //   audioSyncronus !== audioOn && setAudioSyncronous(audioOn)
                  //   console.log("set timeout audio")
                  // },5000)
                }}
                style={{ width: 100 }}
              >
                Ya
              </button>
              <div style={{ marginLeft: 20 }}></div>
              <button
                type="button"
                className="btn cancel-btn"
                onClick={() => setMuteModal(undefined)}
                style={{ width: 100 }}
              >
                Batalkan
              </button>
            </div>
          </Box>
        </Modal>
      )}
      <div className="name-display mx-3 d-flex">
        { 
          // name.length < 18 ?
          // <h5>{name}</h5>
          // :
          // <h5 title={name}>{name.substring(0,17) + "..."}</h5>
          <h5 className="trim-name" title={name}>{name}</h5>
        }
      </div>
      <div
        className="icon-mic-wrapper"
      >
        {!audioSyncronus && (
        // {!audioOn && (
          <img
            className="utils-icon"
            style={{
              marginTop: 0,
              padding: 8
            }}
            src={muteIcon}
            alt="mute"
          />
        )}
      </div>
      {!isAssessor && !isLocal && role !== "peserta" && (
        <div className="participant-mute">
          <div className="peserta-utils-container" style={{ display: "flex" }}>
            <img
              data-tip="Mute peserta"
              className="utils-icon"
              src={muteParticipantIcon}
              onClick={() => setMuteModal(name)}
            />
            <div style={{ marginLeft: 20 }}></div>
            <img
              data-tip="Remove peserta"
              className="utils-icon"
              src={deleteIcon}
              alt="remove participant"
              onClick={() => setRemoveModal(name)}
            />
          </div>
        </div>
      )}
      {!videoOn && (
        <Fragment>
          <div 
            className="no-photo d-flex justify-content-center align-items-center"
            style={{backgroundColor : defaultColor}}
          >
            <h4 style={{textShadow: "0 1px 1px #000"}}>{name[0]}</h4>
          </div>
        </Fragment>
      )}
      {videoOn && videoComponent}
      {audioOn && audioComponent}
    </div>
    // <div
    //   className={`twilio-video video${frames > 10 ? 10 : frames} ${
    //     isTalking ? "is-talking" : "no-talking"
    //   } ${videoOn ? "oncam" : ""} `}
    // >
    //   <div className="name-display mx-3 d-flex">
    //     <h5>{name}</h5>
    //     <img
    //       style={{ marginTop: 0 }}
    //       src={audioOn ? micIcon : muteIcon}
    //       alt="mute"
    //     />
    //   </div>
    //   <div className="profile-wrapper">
    //     {!isAssessor && !isLocal && (
    //       <div className="participant-mute" onClick={handleMuteAudio}>
    //         <img src={muteParticipantIcon} alt="participant-mute" />
    //       </div>
    //     )}
    //     {/* if off cam, show below */}
    //     {!videoOn && (
    //       <div className="no-photo d-flex justify-content-center align-items-center">
    //         {/* if no photo profile, show below */}
    //         <h4>{name[0]}</h4>
    //         {/* if there's photo profile, show below */}
    //         {/* <img src={profileImage} alt='profile' /> */}
    //       </div>
    //     )}
    //     {/* if on cam, show below */}
    //     {videoOn && videoComponent}
    //     {audioOn && audioComponent}
    //   </div>
    // </div>
  )
}

ParticipantFrame.propTypes = {
  // total frame
  frames: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  isTalking: PropTypes.bool,
  videoComponent: PropTypes.element,
  audioComponent: PropTypes.element,
  audioOn: PropTypes.bool,
  videoOn: PropTypes.bool,
  handleMuteAudio: PropTypes.func,
  isAssessor: PropTypes.bool.isRequired,
  isLocal: PropTypes.bool.isRequired
}

export default ParticipantFrame
